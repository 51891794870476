import React, { useState, useEffect, useRef } from 'react';
import { Link as SmoothScroll } from 'react-scroll';

// Components
import SVGIcon from './SVGIcon';

const MobileScroll = ({ pathname }) => {
	const [menuOptions, setMenuOptions] = useState(null);
	const [active, setActive] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const menuRef = useRef(null);

	useEffect(() => {
		if (menuOptions) {
			setTimeout(() => {
				setLoaded(true);
			}, 5000);
		}
	}, [menuOptions]);

	useEffect(() => {
		if (pathname.includes('/articles/single') || pathname.includes('/articles/match-ratings')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Top Stories',
					anchor: 'top-stories',
				},
				{
					title: 'Latest Video',
					anchor: 'latest-video',
				},
				{
					title: 'League Table',
					anchor: 'league-table',
				},
				{
					title: 'Trending',
					anchor: 'trending-content',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/articles/quizzes/')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Other Quizzes',
					anchor: 'top-stories',
				},
				{
					title: 'Latest Video',
					anchor: 'latest-video',
				},
				{
					title: 'League Table',
					anchor: 'league-table',
				},
				{
					title: 'Trending',
					anchor: 'trending-content',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/articles/quizzes')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Other Quizzes',
					anchor: 'top-stories',
				},
				{
					title: 'Media Watch',
					anchor: 'media-watch',
				},
				{
					title: 'Trending',
					anchor: 'trending-content',
				},
				{
					title: 'Featured Authors',
					anchor: 'featured-authors',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/articles')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Top Stories',
					anchor: 'top-stories',
				},
				{
					title: 'Media Watch',
					anchor: 'media-watch',
				},
				{
					title: 'Trending',
					anchor: 'trending-content',
				},
				{
					title: 'Featured Authors',
					anchor: 'featured-authors',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/videos/playlists')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Playlists',
					anchor: 'playlists',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/videos/transfers')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'All Transfer Videos',
					anchor: 'video-grid-section',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/videos/most-popular')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Most Popular Videos',
					anchor: 'video-grid-section',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/videos/all-videos')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'All Videos',
					anchor: 'video-grid-section',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/videos')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Must Watch',
					anchor: 'must-watch',
				},
				{
					title: 'Playlists',
					anchor: 'video-playlists',
				},
				{
					title: 'Recommended',
					anchor: 'recommended-videos',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/podcasts/most-popular')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Most Popular Podcasts',
					anchor: 'podcast-grid-section',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/podcasts/all-podcasts')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Latest Podcasts',
					anchor: 'podcast-grid-section',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/podcasts')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Top Podcasts',
					anchor: 'top-podcasts',
				},
				{
					title: 'Podcast Playlists',
					anchor: 'podcast-slider',
				},
				{
					title: 'Recommended Videos',
					anchor: 'recommended-videos',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		if (pathname.includes('/timeline')) {
			return setMenuOptions([
				{
					title: 'Return to Top of Page',
					anchor: 'root',
				},
				{
					title: 'Top Stories',
					anchor: 'top-stories',
				},
				{
					title: 'Featured Authors',
					anchor: 'featured-authors',
				},
				{
					title: 'Website Footer',
					anchor: 'footer',
				},
			]);
		}

		setMenuOptions(null);
	}, [pathname]);

	const [height, setHeight] = useState(null);

	useEffect(() => {
		if (menuOptions) {
			setHeight(menuRef.current.offsetHeight);
		}
	}, [menuOptions]);

	return (
		<div
			className='mobile-scroller'
			style={{ bottom: !loaded ? `${-height - 55}px` : active ? '67px' : `${-height + 76}px` }}
		>
			<div onClick={() => setActive(!active)} className='mobile-scroller__button'>
				<SVGIcon icon='mouse-scroll' />
			</div>
			<div ref={menuRef} className='mobile-scroller__menu'>
				<ul>
					{menuOptions &&
						menuOptions.map((item, index) => (
							<li key={index}>
								<SmoothScroll
									onClick={() => setActive(false)}
									to={item.anchor}
									smooth={true}
									offset={-80}
								>
									<span>{item.title}</span>
								</SmoothScroll>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default MobileScroll;
