import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getPopularPodcasts } from '../../../../actions/podcastActions';

// Components
import PodcastHeader from '../Elements/PodcastHeader';
import LatestPodcast from '../Elements/LatestPodcast';
import PodcastGrid from '../Elements/PodcastGrid';
import ContentRec from '../../../base/Elements/ContentRec';

const MostPopular = props => {
	useEffect(() => {
		props.getPopularPodcasts();
	}, []);

	return (
		<main>
			<PodcastHeader />
			<LatestPodcast title='Most Popular Podcast' podcast={props.popular && props.popular[0].details} />
			<PodcastGrid title='Most Popular Podcasts' podcasts={props.popular} />
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		popular: state.podcast.popular,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPopularPodcasts: () => dispatch(getPopularPodcasts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MostPopular);
