import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import NotFound from '../../../../pages/NotFound';

// Images
import DefaultImage from '../../../../../img/uploader-image.png';
import LargeBlocked from '../../../../../img/large-blocked.png';
import SmallBlocked from '../../../../../img/small-blocked.png';

// Utils
import shortDate from '../../../../../utils/shortDate';
import isEmpty from '../../../../../utils/isEmpty';
import abbrNum from '../../../../../utils/abbrNum';
import useWindowSize from '../../../../../utils/useWindowSize';

// Actions
import { getArticleAdverts, getAdverts } from '../../../../../actions/contentfulActions';
import { getSingleQuiz, likeQuiz, unlikeQuiz, incrementShareCount } from '../../../../../actions/quizActions';
import { setModal } from '../../../../../actions/generalActions';

// Components
import QuizHeader from './QuizHeader';
import TrendingContent from '../../Elements/TrendingContent';
import RecommendedBar from '../../Elements/RecommendedBar';
import ShareModal from '../../../../base/Elements/ShareModal';
import SVGIcon from '../../../../base/Elements/SVGIcon';
import FeaturedAuthors from '../../Elements/FeaturedAuthors';
import ContentRec from '../../../../base/Elements/ContentRec';
import LeagueTable from '../../Elements/LeagueTable';
import Sponsor from '../../Elements/Sponsor';
import LatestVideo from '../../Elements/LatestVideo';

const SingleArticle = props => {
	const [width] = useWindowSize();
	const [notFound, setNotFound] = React.useState(false);

	const alphabetArray = String.fromCharCode(...Array(91).keys())
		.slice(65)
		.split('');

	const [like, setLike] = useState(false);

	useEffect(() => {
		props.getSingleQuiz(props.match.params.slug);
		props.getArticleAdverts();
	}, []);

	const displayAdverts = props.articleAdverts && props.articleAdverts.displayAdverts;

	const [renderSponsor, setRenderSponsor] = useState(false);

	useEffect(() => {
		if (displayAdverts && renderSponsor && window.refreshBid && window.killSlot) {
			window.killSlot([
				'snack_dex2',
				'snack_dex3',
				'snack_dex4',
				'snack_mex11',
				'snack_mex4',
				'snack_mex5',
				'snack_mex8',
				'snack_mex9',
			]);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}
	}, [displayAdverts, renderSponsor]);

	useEffect(() => {
		if (displayAdverts && props.adBlockDetected) {
			const ads = document.querySelectorAll(
				'.article-main__top-sponsor, .article-main__content--middle-sponsor, .article-main__bottom-sponsor'
			);

			for (const ad of ads) {
				const child = ad.children && ad.children[0] && ad.children[0].children && ad.children[0].children[0];

				if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
					const size = ad.classList[1];
					const image = size === 'large' ? LargeBlocked : SmallBlocked;
					const element = document.createElement('img');
					element.src = image;

					const container = document.createElement('div');
					container.classList.add('blocked-image');
					container.appendChild(element);

					child.parentNode.appendChild(container);
					child.parentNode.removeChild(child);
				}
			}
		}
		// if (displayAdverts) {
		// 	setTimeout(() => {
		// 		const ads = document.querySelectorAll(
		// 			'.article-main__top-sponsor, .article-main__content--middle-sponsor, .article-main__bottom-sponsor'
		// 		);

		// 		for (const ad of ads) {
		// 			const child =
		// 				ad.children && ad.children[0] && ad.children[0].children && ad.children[0].children[0];

		// 			if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
		// 				const size = ad.classList[1];
		// 				const image = size === 'large' ? LargeBlocked : SmallBlocked;
		// 				const element = document.createElement('img');
		// 				element.src = image;

		// 				const container = document.createElement('div');
		// 				container.classList.add('blocked-image');
		// 				container.appendChild(element);

		// 				child.parentNode.appendChild(container);
		// 				child.parentNode.removeChild(child);
		// 			}
		// 		}
		// 	}, 6000);
		// }
	}, [displayAdverts, props.adBlockDetected]);

	switch (true) {
		case ((width >= 992 && width < 1200) || width >= 1800) && renderSponsor !== 'large':
			setRenderSponsor('large');
			break;
		case ((width < 1800 && width >= 1200) || width < 992) && renderSponsor !== 'small':
			setRenderSponsor('small');
			break;
	}

	const displayTopAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.topGoogleAdsense;
	const displayMiddleAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.middleGoogleAdsense;
	const displayBottomAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.bottomGoogleAdsense;

	useEffect(() => {
		const likedContent = JSON.parse(localStorage.getItem('likedContent'));

		if (!isEmpty(likedContent) && likedContent.includes(props.match.params.slug)) {
			setLike(true);
		} else {
			setLike(false);
		}
	}, [props.single]);

	const likeHandler = () => {
		props.likeQuiz(props.match.params.slug);
		setLike(true);
	};

	const unlikeHandler = () => {
		props.unlikeQuiz(props.match.params.slug);
		setLike(false);
	};

	const shareHandler = () => {
		props.setModal();
		props.incrementShareCount(props.match.params.slug);
	};

	const authorImage = DefaultImage;
	const authorAlt = 'The United Stand Logo';
	const authorTitle = 'The United Stand Logo';

	const [answers, setAnswers] = useState(null);

	useEffect(() => {
		if(props.singleQuiz == null && props.singleQuiz !== undefined) {
			return setNotFound(true);
		}
		
		if (props.singleQuiz) {
			const initialState = props.singleQuiz.questions.map(_ => false);

			setAnswers(initialState);
		}
	}, [props.singleQuiz]);

	const [complete, setComplete] = useState(false);

	const answerHandler = (e, index, isTrue) => {
		if (answers[index] && answers[index].submitted) {
			return;
		}

		if (isTrue) {
			e.currentTarget.classList.add('correct');
		} else {
			e.currentTarget.classList.add('incorrect');
		}

		const newState = [...answers];
		newState[index] = { submitted: true, isTrue };
		setAnswers(newState);

		if (answers.filter(answer => answer.submitted).length + 1 === answers.length) {
			setComplete(true);
		}
	};

	const [result, setResult] = useState(null);

	if(notFound) {
		return <NotFound />;
	}

	return (
		<main>
			<Helmet>
				<title>
					{props.singleQuiz ? `${props.singleQuiz.title} | The United Stand` : ' Quiz | The United Stand'}
				</title>
				<meta property='og:url' content={`${window.location.href}`} />
				<meta property='og:title' content={`${props.singleQuiz && props.singleQuiz.title}`} />
				<meta property='og:description' content={`${props.singleQuiz && props.singleQuiz.subheading}`} />
				<meta property='og:type' content='article' />
				<meta
					property='og:image'
					content={
						props.singleQuiz &&
						props.singleQuiz.featuredImageSrc.replace(
							'https://cms.theunitedstand.com/wp-content/uploads/',
							'https://theunitedstand.com/api/media/wp-media/'
						)
					}
				/>
				<meta property='twitter:site' content='@UnitedStandMUFC' />
				<meta property='twitter:card' content='summary_large_image' />
				<meta
					name='twitter:image:src'
					content={
						props.singleQuiz &&
						props.singleQuiz.featuredImageSrc.replace(
							'https://cms.theunitedstand.com/wp-content/uploads/',
							'https://theunitedstand.com/api/media/wp-media/'
						)
					}
				/>
				<meta name='twitter:image:width' content='280' />
				<meta name='twitter:image:height' content='480' />
			</Helmet>
			<ShareModal
				shareText={
					complete &&
					result &&
					`I scored ${answers.filter(answer => answer.isTrue).length} out of ${
						answers.length
					} on The United Stand's Quiz!`
				}
			/>
			<QuizHeader />
			<div className='single-article-section'>
				<div className='container'>
					<div className='single-article-grid'>
						<div className='single-article-grid__left'>
							{/* <TrendingContent /> */}
							{width < 1200 && <ContentRec classes='mobile-version' />}
							<RecommendedBar quiz={true} carousel={true} classes='hide-desktop' id='top-stories' />
							<Sponsor adverts={props.adverts} top={true} classes='hide-mobile' />
							<LatestVideo />
							<LeagueTable />
							<Sponsor adverts={props.adverts} bottom={true} classes='margin-mobile' />
						</div>
						<div className='single-article-grid__middle'>
							<div className='article-main'>
								{props.singleQuiz && (
									<div className='article-main__category quiz'>
										<h2>Quiz</h2>
									</div>
								)}
								{displayAdverts && !displayTopAdsense && (
									<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
										{props.articleAdverts.topHeader && <h3>{props.articleAdverts.topHeader}</h3>}

										<a
											href={props.articleAdverts.topImageLink}
											target='_blank'
											rel='nofollow noopener'
										>
											<div>
												<img
													src={props.articleAdverts.topSrc}
													alt={props.articleAdverts.topAlt}
												/>
											</div>
										</a>
									</div>
								)}
								{displayAdverts && displayTopAdsense && (
									<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
										<div className='sponsor-container'>
											{((width >= 992 && width < 1200) || width >= 1800) &&
											props.articleAdverts.topDimensions === 'large' ? (
												<div
													id='snack_dex4'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '728px', 'important');
															el.style.setProperty('height', '90px', 'important');
															el.style.setProperty('max-width', '728px', 'important');
															el.style.setProperty('max-height', '90px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											) : (
												<div
													id='snack_mex11'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '320px', 'important');
															el.style.setProperty('height', '50px', 'important');
															el.style.setProperty('max-width', '320px', 'important');
															el.style.setProperty('max-height', '50px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											)}
										</div>
									</div>
								)}
								<div className='article-main__heading'>
									<h1>{props.singleQuiz && props.singleQuiz.title}</h1>
								</div>
								<div className='article-main__info'>
									<div className='article-main__info--author'>
										<div className='article-main__info--author_image'>
											<img src={authorImage} alt={authorAlt} title={authorTitle} />
										</div>
										<div className='article-main__info--author_text'>
											<p>
												Created By <span>The United Stand</span>
											</p>
											<p>{props.singleQuiz && shortDate(props.singleQuiz.published)}</p>
										</div>
									</div>
									<div onClick={shareHandler} className='article-main__info--share cta dark'>
										<span>Share This Page</span>
										<FontAwesomeIcon icon='share-alt' />
									</div>
								</div>
								<div className='article-main__content'>
									<div className='article-main__content--stats'>
										<div>
											<SVGIcon icon='eye-icon' />
											<span>
												{(props.singleQuiz &&
													props.singleQuiz.views &&
													abbrNum(props.singleQuiz.views, 1)) ||
													0}
											</span>
										</div>
										<div>
											<FontAwesomeIcon icon={['far', 'heart']} />
											<span>
												{(props.singleQuiz &&
													props.singleQuiz.likes &&
													abbrNum(props.singleQuiz.likes, 1)) ||
													0}
											</span>
										</div>
										<div>
											<SVGIcon icon='share-arrow-full' />
											<span>
												{(props.singleQuiz &&
													props.singleQuiz.shares &&
													abbrNum(props.singleQuiz.shares, 1)) ||
													0}
											</span>
										</div>
									</div>
									<div className='article-main__content--image'>
										<img
											src={
												props.singleQuiz &&
												props.singleQuiz.featuredImageSrc.replace(
													'https://cms.theunitedstand.com/wp-content/uploads/',
													'/api/media/wp-media/'
												)
											}
											alt={props.singleQuiz && props.singleQuiz.featuredImageAlt}
										/>
										{props.single && props.single.imageSource && (
											<div className='article-main__content--image_source'>
												<ReactMarkdown source={props.single.imageSource} linkTarget='_blank' />
											</div>
										)}
									</div>
									{displayAdverts && !displayMiddleAdsense && (
										<div
											className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
										>
											{props.articleAdverts.middleHeader && (
												<h3>{props.articleAdverts.middleHeader}</h3>
											)}

											<a
												href={props.articleAdverts.middleImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.middleSrc}
														alt={props.articleAdverts.middleAlt}
													/>
												</div>
											</a>
										</div>
									)}
									{displayAdverts && displayMiddleAdsense && (
										<div
											className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
										>
											<div className='sponsor-container'>
												{((width >= 992 && width < 1200) || width >= 1800) &&
												props.articleAdverts.middleDimensions === 'large' ? (
													<div
														id='snack_dex2'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '728px', 'important');
																el.style.setProperty('height', '90px', 'important');
																el.style.setProperty('max-width', '728px', 'important');
																el.style.setProperty('max-height', '90px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												) : (
													<div
														id='snack_mex8'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '320px', 'important');
																el.style.setProperty('height', '50px', 'important');
																el.style.setProperty('max-width', '320px', 'important');
																el.style.setProperty('max-height', '50px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												)}
											</div>
										</div>
									)}
									{props.singleQuiz && props.singleQuiz.subheading && (
										<div
											className='article-main__content--text quiz'
											dangerouslySetInnerHTML={{ __html: props.singleQuiz.subheading }}
										></div>
									)}
									<div className='article-main__content--questions'>
										{props.singleQuiz &&
											props.singleQuiz.questions.map((item, index) => (
												<div className='question'>
													<div className='question__header'>
														<h4>{`Question ${index + 1}`}</h4>
													</div>
													{item.question_type === 'multiple-choice-header-image' && (
														<div className='question__image'>
															<img
																src={
																	item.question_header_image &&
																	item.question_header_image.url &&
																	item.question_header_image.url.replace(
																		'https://cms.theunitedstand.com/wp-content/uploads/',
																		'/api/media/wp-media/'
																	)
																}
																alt={item.question_header_image.alt}
																title={item.question_header_image.title}
															/>
														</div>
													)}
													<div className='question__title'>
														<h2>{item.question}</h2>
													</div>
													{(item.question_type === 'multiple-choice-text' ||
														item.question_type === 'multiple-choice-header-image') && (
														<div className='question__text-answers'>
															{item.text_answers.map((child, answerIndex) => (
																<div
																	onClick={e =>
																		answerHandler(e, index, child.correct_answer)
																	}
																	key={answerIndex}
																	className={`question__text-answers--answer ${
																		answers &&
																		answers[index] &&
																		answers[index].submitted &&
																		!answers[index].isTrue &&
																		child.correct_answer
																			? 'correct'
																			: ''
																	}`}
																>
																	<div className='answer-icon'>
																		<div className='answer-icon__tick'></div>
																		<div className='answer-icon__cross'></div>
																		<span>{alphabetArray[answerIndex]}</span>
																	</div>
																	<p>{child.answer}</p>
																</div>
															))}
														</div>
													)}
													{item.question_type === 'true-or-false' && (
														<div className='question__boolean-answers'>
															<div
																onClick={e =>
																	answerHandler(e, index, true === item.true_or_false)
																}
																className={`question__boolean-answers--answer ${
																	answers &&
																	answers[index] &&
																	answers[index].submitted &&
																	!answers[index].isTrue &&
																	true === item.true_or_false
																		? 'correct'
																		: ''
																}`}
															>
																<div className='answer-icon'>
																	<div className='answer-icon__tick'></div>
																	<div className='answer-icon__cross'></div>
																	<span>A</span>
																</div>
																<p>True</p>
															</div>
															<div
																onClick={e =>
																	answerHandler(
																		e,
																		index,
																		false === item.true_or_false
																	)
																}
																className={`question__boolean-answers--answer ${
																	answers &&
																	answers[index] &&
																	answers[index].submitted &&
																	!answers[index].isTrue &&
																	false === item.true_or_false
																		? 'correct'
																		: ''
																}`}
															>
																<div className='answer-icon'>
																	<div className='answer-icon__tick'></div>
																	<div className='answer-icon__cross'></div>
																	<span>B</span>
																</div>
																<p>False</p>
															</div>
														</div>
													)}
													{item.question_type === 'multiple-choice-image' && (
														<div className='question__image-answers'>
															{item.image_answers.map((child, answerIndex) => (
																<div
																	onClick={e =>
																		answerHandler(e, index, child.correct_answer)
																	}
																	key={answerIndex}
																	className={`question__image-answers--answer ${
																		answers &&
																		answers[index] &&
																		answers[index].submitted &&
																		!answers[index].isTrue &&
																		child.correct_answer
																			? 'correct'
																			: ''
																	}`}
																>
																	<div className='answer-image'>
																		<img
																			src={
																				child.image &&
																				child.image.url &&
																				child.image.url.replace(
																					'https://cms.theunitedstand.com/wp-content/uploads/',
																					'/api/media/wp-media/'
																				)
																			}
																			alt={child.image.alt}
																			title={child.image.title}
																		/>
																	</div>
																	<div className='answer-text'>
																		<div className='answer-icon'>
																			<div className='answer-icon__tick'></div>
																			<div className='answer-icon__cross'></div>
																			<span>{alphabetArray[answerIndex]}</span>
																		</div>
																		<p>{child.answer}</p>
																	</div>
																</div>
															))}
														</div>
													)}
												</div>
											))}
									</div>
									<div className='article-main__content--result'>
										{complete && (
											<React.Fragment>
												{!result ? (
													<button
														onClick={() => setResult(true)}
														className='cta yellow cta-large'
													>
														See Your Results!
													</button>
												) : (
													<div className='article-main__content--result_confirmation'>
														<h3>
															You scored {answers.filter(answer => answer.isTrue).length}{' '}
															out of {answers.length}!
														</h3>
														{answers.filter(answer => answer.isTrue).length /
															answers.length <=
														0.25 ? (
															<p>You call yourself a United fan?</p>
														) : answers.filter(answer => answer.isTrue).length /
																answers.length <=
														  0.5 ? (
															<p>Extra training sessions for you!</p>
														) : answers.filter(answer => answer.isTrue).length /
																answers.length <=
														  0.69 ? (
															<p>Decent performance, hit the showers.</p>
														) : answers.filter(answer => answer.isTrue).length /
																answers.length <=
														  0.89 ? (
															<p>Not bad! Sir Alex would be impressed!</p>
														) : answers.filter(answer => answer.isTrue).length /
																answers.length <=
														  0.99 ? (
															<p>MOTM performance! Match ball is yours.</p>
														) : (
															<p>PERFECT! Hope you didn’t Google...</p>
														)}
														<button
															onClick={() => props.setModal()}
															className='cta yellow cta-large'
														>
															Share Your Results!
														</button>
													</div>
												)}
											</React.Fragment>
										)}
									</div>
									<div className='article-main__content--bottom'>
										{!like ? (
											<div className='article-main__content--bottom_like'>
												<div
													onClick={likeHandler}
													className='article-main__content--bottom_like-icon'
												>
													<FontAwesomeIcon icon={['far', 'heart']} />
												</div>
												<p>
													Like This Quiz <span>if you enjoyed it!</span>
												</p>
											</div>
										) : (
											<div className='article-main__content--bottom_like'>
												<div
													onClick={unlikeHandler}
													className='article-main__content--bottom_like-icon'
												>
													<FontAwesomeIcon icon='heart' />
												</div>
												<p>Thank you!</p>
											</div>
										)}
										<div onClick={shareHandler} className='article-main__content--bottom_share'>
											<FontAwesomeIcon icon='share-alt' />
										</div>
									</div>
								</div>
								{!displayAdverts && <hr />}
								{displayAdverts && !displayBottomAdsense && (
									<div
										className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
									>
										<div className='sponsor-container'>
											{((width >= 992 && width < 1200) || width >= 1800) &&
											props.articleAdverts.bottomDimensions === 'large' ? (
												<div
													id='snack_dex3'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '728px', 'important');
															el.style.setProperty('height', '90px', 'important');
															el.style.setProperty('max-width', '728px', 'important');
															el.style.setProperty('max-height', '90px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											) : (
												<div
													id='snack_mex9'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '320px', 'important');
															el.style.setProperty('height', '50px', 'important');
															el.style.setProperty('max-width', '320px', 'important');
															el.style.setProperty('max-height', '50px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											)}
										</div>
									</div>
								)}
								{displayAdverts && displayBottomAdsense && (
									<div
										className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
									>
										{props.articleAdverts.bottomHeader && (
											<h3>{props.articleAdverts.bottomHeader}</h3>
										)}

										<a
											href={props.articleAdverts.bottomImageLink}
											target='_blank'
											rel='nofollow noopener'
										>
											<div>
												<img
													src={props.articleAdverts.bottomSrc}
													alt={props.articleAdverts.bottomAlt}
												/>
											</div>
										</a>
									</div>
								)}
							</div>
						</div>
						<div className='single-article-grid__right'>
							<RecommendedBar quiz={true} slug={props.match.params.slug} classes='show-desktop' />
							<TrendingContent classes='hide-mobile' />
						</div>
					</div>
				</div>
			</div>
			<TrendingContent id='trending-content' classes='hide-desktop' />
			{width > 1200 && <ContentRec classes='more-padding' />}
			<FeaturedAuthors classes='single' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		single: state.contentful.single,
		articleAdverts: state.contentful.articleAdverts,
		singleQuiz: state.quiz.singleQuiz,
		adverts: state.contentful.adverts,
		adBlockDetected: state.general.adBlockDetected,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		likeQuiz: slug => dispatch(likeQuiz(slug)),
		unlikeQuiz: slug => dispatch(unlikeQuiz(slug)),
		incrementShareCount: slug => dispatch(incrementShareCount(slug)),
		setModal: () => dispatch(setModal()),
		getArticleAdverts: () => dispatch(getArticleAdverts()),
		getSingleQuiz: slug => dispatch(getSingleQuiz(slug)),
		getAdverts: () => dispatch(getAdverts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleArticle);
