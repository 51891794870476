import axios from 'axios';

import * as actionTypes from './types';

export const getTrendingContent = () => dispatch => {
	axios
		.get('/api/rankings')
		.then(response => {
			dispatch({
				type: actionTypes.GET_TRENDING_CONTENT,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getPastDayContent = () => dispatch => {
	axios
		.get('/api/rankings/last-day')
		.then(response => {
			dispatch({
				type: actionTypes.GET_PAST_DAY_CONTENT,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getMustWatch = () => dispatch => {
	axios
		.get('/api/rankings/must-watch')
		.then(response => {
			dispatch({
				type: actionTypes.GET_MUST_WATCH,
				payload: response.data,
			});
		})
		.catch(err => console.log(err.response));
};
