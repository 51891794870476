import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import removeMd from 'remove-markdown';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import he from 'he';
import striptags from 'striptags';

// Actions
import { getLatestPosts } from '../../../actions/contentfulActions';

// Utils
import timeElapsed from '../../../utils/timeElapsed';
import truncateText from '../../../utils/truncateText';
import abbrNum from '../../../utils/abbrNum';
import SVGIcon from '../../base/Elements/SVGIcon';
import NavIcon from '../../base/Elements/NavIcon';

// Images
import Placeholder from '../../../img/article-placeholder.jpg';

const FeaturedPosts = props => {
	useEffect(() => {
		props.getLatestPosts();
	}, []);

	const posts =
		props.latestPosts &&
		props.latestPosts.map((post, index) => {
			if (index >= 4) {
				return;
			}

			if (post.questions) {
				return (
					<Link to={`/articles/quizzes/${post.slug}`} key={index} className='featured-posts__post quiz'>
						<div className='quiz-icon'>
							<NavIcon icon='quiz' />
						</div>
						<div className='featured-posts__post--image'>
							<img
								src={post.featuredImageSrc.replace(
									'https://cms.theunitedstand.com/wp-content/uploads/',
									'/api/media/wp-media/'
								)}
								alt={post.featuredImageAlt}
							/>
						</div>
						<div className='featured-posts__post--details'>
							<h2>{post.title}</h2>
							<hr />
							<div className='featured-posts__post--details_stats'>
								<span>The United Stand</span>
								<span>{timeElapsed(post.published)}</span>
							</div>
							<div className='featured-posts__post--details_excerpt'>
								<p>{truncateText(he.decode(post.subheading.replace(/<[^>]+>/g, '')), 150)}</p>
							</div>
						</div>
						<div className='featured-posts__post--icons'>
							<div className='featured-posts__post--icons_left'>
								<div>
									<FontAwesomeIcon icon={['far', 'heart']} />
									<span>{post.likes ? post.likes : 0}</span>
								</div>
								<div>
									<SVGIcon icon='eye-icon' />
									<span>{post.views ? abbrNum(post.views, 1) : 0}</span>
								</div>
							</div>
							<div className='featured-posts__post--icons_right'>
								<SVGIcon icon='read-icon' />
							</div>
						</div>
					</Link>
				);
			}

			if (post.players) {
				const postClass = slugify(post.scoreBlock.category, {
					lower: true,
				});

				return (
					<Link
						to={`/articles/match-ratings/${post.slug}`}
						key={index}
						className={`featured-posts__post rating-${postClass} match-ratings`}
					>
						<div className='featured-posts__post--image'>
							<img
								src={post.featuredImageSrc.replace(
									'https://cms.theunitedstand.com/wp-content/uploads/',
									'/api/media/wp-media/'
								)}
								alt={post.featuredImageAlt}
							/>
							<div className='featured-posts__post--image_banner'>
								<span>Player Ratings</span>
							</div>
						</div>
						<div className='featured-posts__post--details'>
							<div className='featured-posts__post--details_scoreblock'>
								<div className='left-block'>
									<div className='team-logo'>
										<img
											src={post.scoreBlock.homeLogo.src.replace(
												'https://cms.theunitedstand.com/wp-content/uploads/',
												'/api/media/wp-media/'
											)}
											alt={post.scoreBlock.homeLogo.alt}
										/>
									</div>
									<div className='team-score'>
										<div>
											<span>{post.scoreBlock.homeScore}</span>
										</div>
									</div>
								</div>
								<div className='right-block'>
									<div className='team-score'>
										<div>
											<span>{post.scoreBlock.awayScore}</span>
										</div>
									</div>
									<div className='team-logo'>
										<img
											src={post.scoreBlock.awayLogo.src.replace(
												'https://cms.theunitedstand.com/wp-content/uploads/',
												'/api/media/wp-media/'
											)}
											alt={post.scoreBlock.awayLogo.alt}
										/>
									</div>
								</div>
							</div>
							<h2>{post.title}</h2>
							<hr />
							<div className='featured-posts__post--details_stats match-rating'>
								<span>The United Stand</span>
								<span>{timeElapsed(post.published)}</span>
							</div>
						</div>
						<div className='featured-posts__post--icons'>
							<div className='featured-posts__post--icons_left'>
								<div>
									<FontAwesomeIcon icon={['far', 'heart']} />
									<span>{post.likes && post.likes}</span>
								</div>
								<div>
									<SVGIcon icon='eye-icon' />
									<span>{post.views && abbrNum(post.views, 1)}</span>
								</div>
							</div>
							<div className='featured-posts__post--icons_right'>
								<SVGIcon icon='read-icon' />
							</div>
						</div>
					</Link>
				);
			}

			const article = post.article || post;
			const isArticle = post.article;

			let postClass =
				(article.category && slugify(post.article.category, { lower: true }).replace("'", '')) ||
				(article.scoreBlock && slugify(article.scoreBlock.category, { lower: true })) ||
				(article.categories[0] &&
					slugify(article.categories[0].name, {
						lower: true,
					}).replace("'", ''));

			// Breaking news time check
			const date = new Date();
			date.setHours(date.getHours() - 6);
			const pastDate = date.getTime();
			const postedDate = new Date(article.createdAt).getTime();

			if (postClass === 'breaking-news' && pastDate > postedDate) {
				postClass = 'uncategorised';
			}

			const category =
				article.category || (article.scoreBlock && article.scoreBlock.category) || article.categories[0].name;

			let articleImageSrc = '';

			if (article.featuredImageSrc) {
				articleImageSrc = article.featuredImageSrc.replace(
					'https://cms.theunitedstand.com/wp-content/uploads/',
					'/api/media/wp-media/'
				);
			} else {
				articleImageSrc =
					(article.file &&
						article.file.url.replace(
							'https://cms.theunitedstand.com/wp-content/uploads/',
							'/api/media/wp-media/'
						)) ||
					Placeholder;
			}

			return (
				<Link
					to={`/articles/single/${article.slug}`}
					key={index}
					className={`featured-posts__post ${article.scoreBlock ? 'rating-' : ''}${postClass}`}
				>
					<div className='featured-posts__post--image'>
						<img src={articleImageSrc} alt={article.imageAlt || article.featuredImageAlt} />
						<div className='featured-posts__post--image_banner'>
							<span>{category}</span>
						</div>
					</div>
					<div className='featured-posts__post--details'>
						<h2>{truncateText(article.title, 100)}</h2>
						<hr />
						<div className='featured-posts__post--details_stats'>
							<span>{article.authorName || 'The United Stand'}</span>
							<span>{timeElapsed(article.createdAt)}</span>
						</div>
						<div className='featured-posts__post--details_excerpt'>
							{isArticle ? (
								<p>{truncateText(removeMd(article.body), 125)}</p>
							) : (
								<p>{truncateText(he.decode(striptags(article.body)), 125)}</p>
							)}
						</div>
					</div>
					<div className='featured-posts__post--icons'>
						<div className='featured-posts__post--icons_left'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{(article.likes && article.likes) || 0}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{(article.views && abbrNum(article.views, 1)) || 0}</span>
							</div>
						</div>
						<div className='featured-posts__post--icons_right'>
							<SVGIcon icon='read-icon' />
						</div>
					</div>
				</Link>
			);
		});

	return (
		<div className='container'>
			<div className='featured-posts'>{posts}</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		latestPosts: state.contentful.latestPosts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestPosts: () => dispatch(getLatestPosts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedPosts);
