import axios from 'axios';

import * as actionTypes from './types';

export const getLatestUploads = () => dispatch => {
	axios
		.get('/api/youtube/latest')
		.then(response => {
			dispatch({
				type: actionTypes.GET_LATEST_UPLOADS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err.response));
};

export const getSocialStats = () => dispatch => {
	axios.get('/api/social/social-stats').then(response => {
		dispatch({
			type: actionTypes.GET_SOCIAL_STATS,
			payload: response.data,
		});
	});
};

export const getPlaylists = (pageToken = '') => dispatch => {
	dispatch({
		type: actionTypes.SET_LOADING,
	});
	axios.get('/api/youtube/playlists', { params: { pageToken } }).then(response => {
		dispatch({
			type: actionTypes.GET_PLAYLISTS,
			payload: {
				pageToken: response.data.nextPageToken,
				content: response.data,
			},
		});
		dispatch({
			type: actionTypes.SET_LOADING,
		});
	});
};

export const getRecommendedVideos = () => dispatch => {
	axios
		.get('/api/youtube/recommended')
		.then(response => {
			dispatch({
				type: actionTypes.GET_RECOMMENDED_VIDEOS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getTransferPlaylist = () => dispatch => {
	axios
		.get('/api/youtube/transfer-playlist')
		.then(response => {
			dispatch({
				type: actionTypes.GET_TRANSFER_PLAYLIST,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getPopularVideos = () => dispatch => {
	axios.get('/api/youtube/most-popular').then(response => {
		dispatch({
			type: actionTypes.GET_POPULAR_VIDEOS,
			payload: response.data,
		});
	});
};

export const getSingleVideo = videoId => dispatch => {
	dispatch({
		type: actionTypes.GET_SINGLE_VIDEO,
		payload: undefined,
	});

	axios
		.get('/api/youtube/single-video', { params: { videoId } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_SINGLE_VIDEO,
				payload: response.data,
			});
		})
		.catch(err => {
			console.log(err);
			dispatch({
				type: actionTypes.GET_SINGLE_VIDEO,
				payload: null,
			});
		});
};

export const getChatMessages = (url, liveChatId) => async dispatch => {
	try {
		const response = await axios.get('/api/youtube/live-chat', {
			params: {
				url,
				liveChatId,
			},
		});

		const pollingIntervalMillis = response.data && response.data.pollingIntervalMillis;
		const chatMessages = response.data && response.data.chatMessages;
		const liveId = response.data && response.data.liveChatId;

		dispatch({
			type: actionTypes.GET_CHAT_MESSAGES,
			payload: chatMessages,
		});

		return [pollingIntervalMillis, liveId];
	} catch (err) {
		throw err.response.data;
	}
};

export const cancelPolling = () => dispatch => {
	dispatch({
		type: actionTypes.STOP_POLLING,
	});
};
