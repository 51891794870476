import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getFeaturedBanner } from '../../../actions/contentfulActions';

import VideoModal from './VideoModal';

const FeaturedBanner = props => {
	useEffect(() => {
		props.getFeaturedBanner();
	}, []);

	const thumbnail =
		props.latest &&
		(props.latest[0].thumbnails.maxres ||
			props.latest[0].thumbnails.high ||
			props.latest[0].thumbnails.medium ||
			props.latest[0].thumbnails.default);

	const featuredVideo = props.featuredBanner &&
		props.featuredBanner.bannerVideo && (
			<VideoModal
				id={
					props.featuredBanner.bannerVideo
						.split('v=')
						.pop()
						.split('&')[0]
				}
				buttonText='Watch Video'
				buttonClasses='cta cta-large'
			/>
		);
	const featuredArticle = props.featuredBanner &&
		props.featuredBanner.relatedArticle && (
			<Link
				to={`/articles/single/${props.featuredBanner.relatedArticle}`}
				className='cta cta-large transparent'
			>
				Related Article
			</Link>
		);

	const featuredBanner = props.featuredBanner && (
		<div
			className='featured-banner'
			style={{ backgroundImage: `url(${props.featuredBanner.imageSrc})` }}
		>
			{/* <img
				src={props.featuredBanner.imageSrc}
				alt={props.featuredBanner.imageAlt}
				title={props.featuredBanner.imageTitle}
			/> */}
			<div className='featured-banner__info'>
				<h1>{props.featuredBanner.bannerTitle}</h1>
				{(props.featuredBanner.bannerVideo ||
					props.featuredBanner.relatedArticle) && (
					<div className='featured-banner__info--links'>
						{featuredVideo}
						{featuredArticle}
					</div>
				)}
			</div>
			<div className='featured-banner__latest'>
				<img
					src={thumbnail && thumbnail.url}
					alt='Thumbnail for the latest youtube upload'
				/>
				<VideoModal
					id={props.latest && props.latest[0].videoId}
					buttonText='Latest Video'
					buttonClasses='cta cta-large transparent'
				/>
			</div>
		</div>
	);

	return <div className='container'>{featuredBanner}</div>;
};

const mapStateToProps = state => {
	return {
		featuredBanner: state.contentful.featuredBanner,
		latest: state.youtube.latest,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getFeaturedBanner: () => dispatch(getFeaturedBanner()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedBanner);
