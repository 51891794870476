import React from 'react';

const Ads = () => {
	React.useEffect(() => {
		// need to refresh bids on component mount!
		window.refreshBid([
			'snack_ldb',
			'snack_mpu',
			'snack_dmpu',
			'snack_dex2',
			'snack_dex3',
			'snack_mex1',
			'snack_mex4',
			'snack_mex5',
			'snack_mex8',
			'snack_mex9',
			'snack_mex10',
			'snack_mex11',
		]);
	}, []);

	return (
		<main className='ads-page'>
			<div className='container'>
				<h1>Temporary Ads Page</h1>

				<h3>snack_ldb - Desktop - 970x250/728x90 | Mobile - 300x250/320x50/320x100</h3>
				<div id='snack_ldb'></div>

				<h3>snack_mpu - Desktop - 300x250 | Mobile - 300x250/320x50/320x100</h3>
				<div id='snack_mpu'></div>

				<h3>snack_dmpu - Desktop - 300x600/300x250 | Mobile - 300x250/320x50/320x100</h3>
				<div id='snack_dmpu'></div>

				<h3>snack_dex2 - Desktop - 728x90 | Mobile - 320x50</h3>
				<div id='snack_dex2'></div>

				<h3>snack_dex3 - Desktop - 728x90 | Mobile - 320x50</h3>
				<div id='snack_dex3'></div>

				<h3>snack_mex1 - 320x50</h3>
				<div id='snack_mex1'></div>

				<h3>snack_mex4 - 300x250</h3>
				<div id='snack_mex4'></div>

				<h3>snack_mex5 - 300x250</h3>
				<div id='snack_mex5'></div>

				<h3>snack_mex8 - 320x50</h3>
				<div id='snack_mex8'></div>

				<h3>snack_mex9 - 320x50</h3>
				<div id='snack_mex9'></div>

				<h3>snack_mex10 - 320x50</h3>
				<div id='snack_mex10'></div>

				<h3>snack_mex11 - 320x50</h3>
				<div id='snack_mex11'></div>
			</div>
		</main>
	);
};

export default Ads;
