import axios from 'axios';

import * as actionTypes from './types';

export const getLatestPodcasts = () => dispatch => {
	axios.get('/api/podcasts/latest').then(response => {
		dispatch({
			type: actionTypes.GET_LATEST_PODCASTS,
			payload: response.data,
		});
	});
};

export const getTopPodcasts = () => dispatch => {
	axios.get('/api/podcasts/featured').then(response => {
		dispatch({
			type: actionTypes.GET_TOP_PODCASTS,
			payload: response.data,
		});
	});
};

export const getDatedPodcasts = () => dispatch => {
	axios.get('/api/podcasts/date-podcasts').then(response => {
		dispatch({
			type: actionTypes.GET_DATED_PODCASTS,
			payload: response.data,
		});
	});
};

export const getPopularPodcasts = () => dispatch => {
	axios.get('/api/podcasts/most-popular').then(response => {
		dispatch({
			type: actionTypes.GET_POPULAR_PODCASTS,
			payload: response.data,
		});
	});
};
