import React from 'react';
import { Link } from 'react-router-dom';

const WebAuthors = props => {
	const team =
		props.team &&
		props.team.map((member, index) => {
			const {
				fields: {
					name,
					slug,
					smallImage: {
						fields: {
							title,
							description: alt,
							file: { url: src },
						},
					},
				},
			} = member;

			return (
				<Link
					key={index}
					to={`/articles/author/${slug}`}
					className='web-author-grid__card'
				>
					<div className='web-author-grid__card--image'>
						<img src={src} alt={alt} title={title} />
					</div>
					<div className='web-author-grid__card--name'>
						<p>{name}</p>
					</div>
				</Link>
			);
		});

	return (
		<div className='web-author-wrapper'>
			<div className='container'>
				<div className='web-author-container'>
					<h2>Web Authors</h2>
					<div className='web-author-grid'>{team}</div>
				</div>
			</div>
		</div>
	);
};

export default WebAuthors;
