import React from 'react';

// Components
import SVGIcon from '../../base/Elements/SVGIcon';

// Utils
import dayDate from '../../../utils/dayDate';

const FixtureItem = props => {
	const { fixture } = props;

	let status;

	switch (true) {
		case fixture.statusShort === 'NS':
			status = 'Next';
			break;
		case fixture.statusShort === 'FT':
			status = fixture.statusShort;
			break;
		default:
			status = fixture.status;
			break;
	}

	return (
		<div className='fixture-container'>
			<div className='fixture'>
				<div className='fixture__top'>
					<div className='fixture__top--left'>
						<p>{dayDate(fixture.event_date)}</p>
						<p>{fixture.league.name}</p>
					</div>
					<div className='fixture__top--right'>
						{fixture.league.logo && (
							<img
								src={fixture.league.logo}
								// alt={`${fixture.league.name} logo`}
							/>
						)}
					</div>
				</div>
				<div className='fixture__middle'>
					<div className='fixture__middle--team'>
						<div className='fixture__middle--team_left'>
							<img
								src={fixture.homeTeam.logo}
								// alt={`${fixture.homeTeam.team_name} logo`}
							/>
							<span>{fixture.homeTeam.team_name}</span>
						</div>
						<div className='fixture__middle--team_right'>
							{fixture.goalsHomeTeam !== null && (
								<span>{fixture.goalsHomeTeam}</span>
							)}
						</div>
					</div>
					<div className='fixture__middle--team'>
						<div className='fixture__middle--team_left'>
							<img
								src={fixture.awayTeam.logo}
								// alt={`${fixture.awayTeam.team_name} logo`}
							/>
							<span>{fixture.awayTeam.team_name}</span>
						</div>
						<div className='fixture__middle--team_right'>
							{fixture.goalsAwayTeam !== null && (
								<span>{fixture.goalsAwayTeam}</span>
							)}
						</div>
					</div>
				</div>
				<div className='fixture__bottom'>
					<div className='fixture__bottom--left'>
						<span>{status}</span>
					</div>
					<div className='fixture__bottom--right'>
						<SVGIcon icon='play-button' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default FixtureItem;
