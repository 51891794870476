import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';
import parse from 'html-react-parser';
import NotFound from '../../../pages/NotFound';

// Images
import Logo from '../../../../img/logo.png';
import DefaultImage from '../../../../img/uploader-image.png';
import LargeBlocked from '../../../../img/large-blocked.png';
import SmallBlocked from '../../../../img/small-blocked.png';
import TextAdBlocked from '../../../../img/blocked-text.jpg';
import Camera from '../../../../img/camera.png';

// Utils
import shortDate from '../../../../utils/shortDate';
import isEmpty from '../../../../utils/isEmpty';
import abbrNum from '../../../../utils/abbrNum';
import truncateText from '../../../../utils/truncateText';
import useWindowSize from '../../../../utils/useWindowSize';

// Actions
import {
	getSingleArticle,
	getNextArticle,
	likeArticle,
	unlikeArticle,
	incrementShareCount,
	getArticleAdverts,
	getAdverts,
	clearSingleArticle,
} from '../../../../actions/contentfulActions';
import { setModal } from '../../../../actions/generalActions';

// Components
import ArticleHeader from './ArticleHeader';
import FeaturedAuthors from '../Elements/FeaturedAuthors';
import TrendingContent from '../Elements/TrendingContent';
import RecommendedBar from '../Elements/RecommendedBar';
import ShareModal from '../../../base/Elements/ShareModal';
import SVGIcon from '../../../base/Elements/SVGIcon';
import DayAndMonth from '../../../base/Elements/DayAndMonth';
import LeagueTable from '../Elements/LeagueTable';
import Sponsor from '../Elements/Sponsor';
import LatestVideo from '../Elements/LatestVideo';
import ContentRec from '../../../base/Elements/ContentRec';

const SingleArticle = props => {
	const [width] = useWindowSize();
	const [render, setRender] = useState(false);

	const videoAd = useCallback(
		node => {
			if (node !== null && window.$bp) {
				window.$bp('Brid_13660665', {
					id: '25743',
					width: '16',
					height: '9',
					playlist: '13022',
					autoplayStickyAdOnly: true,
				});
			}
		},
		[props.match.params.slug]
	);

	useEffect(() => {
		props.getSingleArticle(props.match.params.slug);
		props.getArticleAdverts();
		props.getAdverts();

		return () => {
			props.clearSingleArticle();
		};
	}, []);

	useEffect(() => {
		const likedContent = JSON.parse(localStorage.getItem('likedContent'));

		if (!isEmpty(likedContent) && likedContent.includes(props.match.params.slug)) {
			setLike(true);
		} else {
			setLike(false);
		}
	}, [props.single]);

	useEffect(() => {
		if (props.single) {
			const published = props.single.createdAt || props.single.published;
			props.getNextArticle(published);
		}
	}, [props.single]);

	useEffect(() => {
		switch (true) {
			case width >= 576:
				setRender(false);
				break;
			case width >= 380 && width < 576 && (!render || render < 380):
				setRender(width);
				break;
			case width < 380 && (!render || render > 380):
				setRender(width);
				break;
		}
	}, [width]);

	const [like, setLike] = useState(false);

	const displayAdverts = props.articleAdverts && props.articleAdverts.displayAdverts;
	const textBlockAdvertTop = props.articleAdverts && props.articleAdverts.textBlockAdvertTop;
	const textBlockAdvertBottom = props.articleAdverts && props.articleAdverts.textBlockAdvertBottom;

	const [renderSponsor, setRenderSponsor] = useState(false);

	useEffect(() => {
		if (displayAdverts && renderSponsor && window.killSlot) {
			window.killSlot([
				'snack_dex2',
				'snack_dex3',
				'snack_dex4',
				'snack_mex11',
				'snack_mex4',
				'snack_mex5',
				'snack_mex8',
				'snack_mex9',
			]);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}
	}, [displayAdverts, renderSponsor]);

	useEffect(() => {
		if (displayAdverts && props.adBlockDetected) {
			const ads = document.querySelectorAll('.text-sponsor__wrapper--inner');

			for (const ad of ads) {
				const child = ad.children && ad.children[0];
				if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
					const element = document.createElement('img');
					element.src = TextAdBlocked;

					const container = document.createElement('div');
					container.classList.add('blocked-image');
					container.appendChild(element);

					child.parentNode.appendChild(container);
					child.parentNode.removeChild(child);
				}
			}
		}
	}, [displayAdverts, props.adBlockDetected]);

	useEffect(() => {
		if (displayAdverts && props.adBlockDetected) {
			const ads = document.querySelectorAll(
				'.article-main__top-sponsor, .article-main__content--middle-sponsor, .article-main__bottom-sponsor'
			);

			for (const ad of ads) {
				const child = ad.children && ad.children[0] && ad.children[0].children && ad.children[0].children[0];

				if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
					const size = ad.classList[1];
					const image = size === 'large' ? LargeBlocked : SmallBlocked;
					const element = document.createElement('img');
					element.src = image;

					const container = document.createElement('div');
					container.classList.add('blocked-image');
					container.appendChild(element);

					child.parentNode.appendChild(container);
					child.parentNode.removeChild(child);
				}
			}
		}
	}, [displayAdverts, props.adBlockDetected]);

	useEffect(() => {
		const images = document.querySelectorAll('.article-main__content--text > p > img');

		if (images.length > 0) {
			for (const image of images) {
				const parent = image.parentNode;

				const container = document.createElement('div');
				container.classList.add('in-article-image');

				const heading = document.createElement('h6');
				heading.innerText = 'Content Continues Below';

				container.appendChild(heading);
				container.appendChild(image);
				if (image.alt) {
					const description = document.createElement('div');
					description.classList.add('in-article-image__description');

					const cameraImage = document.createElement('img');
					cameraImage.src = Camera;
					cameraImage.alt = 'Camera Icon';

					const p = document.createElement('p');
					p.innerText = image.alt;

					const hr = document.createElement('hr');

					description.appendChild(cameraImage);
					description.appendChild(p);

					container.appendChild(hr);
					container.appendChild(description);
				}

				parent.parentNode.insertBefore(container, parent.nextSibling);
			}
		}
	}, [props.single]);

	const secondBody = props.single && props.single.bodyTwo;
	const thirdBody = props.single && props.single.bodyThree;

	const isArticle = props.single && props.single.articleId;

	useEffect(() => {
		if (displayAdverts && renderSponsor && secondBody && window.refreshBid && window.killSlot) {
			window.killSlot(['snack_mex4', 'snack_mex5']);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}

		if (displayAdverts && renderSponsor && thirdBody && window.refreshBid && window.killSlot) {
			window.killSlot(['snack_mex4', 'snack_mex5']);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}
	}, [secondBody, thirdBody, displayAdverts, renderSponsor]);

	switch (true) {
		case ((width >= 992 && width < 1200) || width >= 1800) && renderSponsor !== 'large':
			setRenderSponsor('large');
			break;
		case ((width < 1800 && width >= 1200) || width < 992) && renderSponsor !== 'small':
			setRenderSponsor('small');
			break;
	}

	const displayTopAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.topGoogleAdsense;
	const displayMiddleAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.middleGoogleAdsense;
	const displayBottomAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.bottomGoogleAdsense;

	const likeHandler = () => {
		props.likeArticle(props.match.params.slug);
		setLike(true);
	};

	const unlikeHandler = () => {
		props.unlikeArticle(props.match.params.slug);
		setLike(false);
	};

	const shareHandler = () => {
		props.setModal();
		props.incrementShareCount(props.match.params.slug);
	};

	let authorImage;
	let authorAlt;
	let authorTitle;

	if (props.single && (props.single.authorImage === 'default' || !props.single.authorImage)) {
		authorImage = DefaultImage;
		authorAlt = 'The United Stand Logo';
		authorTitle = 'The United Stand Logo';
	} else {
		authorImage = props.single && props.single.authorImage.file.url;
		authorAlt = props.single && props.single.authorImage.description;
		authorTitle = props.single && props.single.authorImage.title;
	}

	const ldJson = {
		'@context': 'https://schema.org',
		'@type': 'NewsArticle',
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': `${window.location.href}`,
		},
		headline: `${props.single && props.single.title}`,
		description: `${props.single && props.single.subTitle}`,
		image: `https:${
			props.single && props.single.file ? props.single.file.url : props.single && props.single.featuredImageSrc
		}`,
		author: {
			'@type': 'Person',
			name: `${(props.single && props.single.authorName) || 'The United Stand'}`,
		},
		publisher: {
			'@type': 'Organization',
			name: 'The United Stand',
			logo: {
				'@type': 'ImageObject',
				url: `${Logo}`,
				width: 197,
				height: 60,
			},
		},
		datePublished: `${props.single && props.single.createdAt.substring(0, 10)}`,
		dateModified: `${props.single && props.single.updatedAt.substring(0, 10)}`,
	};

	let articleClass =
		props.single && props.single.category
			? slugify(props.single.category, {
					lower: true,
			  }).replace("'", '')
			: props.single &&
			  props.single.categories &&
			  props.single.categories[0] &&
			  slugify(props.single.categories[0].name, { lower: true }).replace("'", '');

	// Breaking news time check
	const date = new Date();
	date.setHours(date.getHours() - 6);
	const pastDate = date.getTime();
	const postedDate = props.single && new Date(props.single.createdAt).getTime();

	if (articleClass === 'breaking-news' && pastDate > postedDate) {
		articleClass = 'uncategorised';
	}

	let limit;

	switch (true) {
		case width >= 1800:
			limit = 65;
			break;
		case width >= 1200:
			limit = 50;
			break;
		case width > 550:
			limit = 60;
			break;
		case width > 500:
			limit = 50;
			break;
		case width > 470:
			limit = 45;
			break;
		case width > 440:
			limit = 40;
			break;
		case width > 400:
			limit = 55;
			break;
		case width > 365:
			limit = 50;
			break;
		case width > 340:
			limit = 40;
			break;
		default:
			limit = 35;
	}

	// const [renderWidth, setRenderWidth] = useState(null);

	// useEffect(() => {
	// 	if (!renderWidth) {
	// 		setRenderWidth(width);
	// 	}

	// 	switch (true) {
	// 		case width >= 1800 && renderWidth < 1800:
	// 			setRenderWidth(width);
	// 			break;
	// 		case width >= 1440 && (renderWidth >= 1800 || renderWidth < 1440):
	// 			setRenderWidth(width);
	// 			break;
	// 		case width >= 1200 && (renderWidth >= 1440 || renderWidth < 1200):
	// 			setRenderWidth(width);
	// 			break;
	// 		case width >= 992 && (renderWidth >= 1200 || renderWidth < 992):
	// 			setRenderWidth(width);
	// 			break;
	// 		case width >= 576 && (renderWidth >= 992 || renderWidth < 576):
	// 			setRenderWidth(width);
	// 			break;
	// 		case width >= 360 && (renderWidth >= 576 || renderWidth < 360):
	// 			setRenderWidth(width);
	// 			break;
	// 		case width < 360 && renderWidth >= 360:
	// 			setRenderWidth(width);
	// 			break;
	// 	}
	// }, [width]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		const ads = document.querySelectorAll('ins.articleads');

	// 		for (const ad of ads) {
	// 			if (ad && ad.innerHTML.replace(/\s/g, '').length === 0) {
	// 				const size = ad.classList[1];
	// 				const image = size === 'large' ? LargeBlocked : SmallBlocked;
	// 				ad.style.display = 'none';
	// 				let element = document.createElement('img');
	// 				element.src = image;
	// 				ad.parentNode.appendChild(element);
	// 			}
	// 		}
	// 	}, 8000);
	// }, []);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		const ads = document.querySelectorAll('ins.textcontentsponsor');

	// 		for (const ad of ads) {
	// 			if (ad && ad.innerHTML.replace(/\s/g, '').length === 0) {
	// 				ad.style.display = 'none';
	// 				const image = SmallBlocked;
	// 				let element = document.createElement('img');
	// 				element.src = image;
	// 				ad.parentNode.appendChild(element);
	// 			}
	// 		}
	// 	}, 7000);
	// }, []);

	if(props.single == null && props.single !== undefined) {
		return <NotFound />;
	}

	return (
		<main>
			<Helmet>
				<title>
					{props.single ? `${props.single.title} | The United Stand` : ' Article | The United Stand'}
				</title>
				<script type='application/ld+json'>{JSON.stringify(ldJson)}</script>
				<meta property='og:url' content={`${window.location.href}`} />
				<meta property='og:title' content={`${props.single && props.single.title}`} />
				<meta property='og:description' content={`${props.single && props.single.subTitle}`} />
				<meta property='og:type' content='article' />
				<meta
					property='og:image'
					content={`https:${
						props.single && props.single.file
							? props.single.file.url
							: props.single && props.single.featuredImageSrc
					}`}
				/>
				<meta property='twitter:site' content='@UnitedStandMUFC' />
				<meta property='twitter:card' content='summary_large_image' />
				<meta
					name='twitter:image:src'
					content={`https:${
						props.single && props.single.file
							? props.single.file.url
							: props.single && props.single.featuredImageSrc
					}`}
				/>
				<meta name='twitter:image:width' content='280' />
				<meta name='twitter:image:height' content='480' />
			</Helmet>
			<ShareModal />
			<ArticleHeader article={props.single && props.single} />
			<div className='single-article-section'>
				<div className='container'>
					<div className='single-article-grid'>
						<div className='single-article-grid__left'>
							{/* <TrendingContent /> */}
							{width < 1200 && <ContentRec classes='mobile-version' />}
							<RecommendedBar carousel={true} classes='hide-desktop' id='top-stories' />
							<Sponsor adverts={props.adverts} top={true} classes='hide-mobile' />
							<LatestVideo />
							<LeagueTable />
							<Sponsor adverts={props.adverts} bottom={true} classes='margin-mobile' />
						</div>
						<div className='single-article-grid__middle'>
							{props.single && props.single.scoreBlock && (
								<div
									className={`score-block ${slugify(props.single.scoreBlock.category, {
										lower: true,
									})}`}
								>
									<div className='score-block__top'>
										<div className='score-block__top--title'>
											<h2>{props.single.scoreBlock.category}</h2>
										</div>
										{props.single.scoreBlock.matchInfo && (
											<div className='score-block__top--info'>
												<p>{props.single.scoreBlock.matchInfo}</p>
											</div>
										)}
										<div className='score-block__top--status'>
											<p>{props.single.scoreBlock.matchStatus}</p>
										</div>
									</div>
									<div className='score-block__middle'>
										<div className='score-block__middle--left'>
											<div>
												<div className='team-logo'>
													<img
														src={props.single.scoreBlock.homeLogo.src}
														alt={props.single.scoreBlock.homeLogo.alt}
													/>
												</div>
												<div className='team-score'>
													<div>
														<span>{props.single.scoreBlock.homeScore}</span>
													</div>
												</div>
											</div>
											<p>{props.single.scoreBlock.homeTeam}</p>
										</div>
										<div className='score-block__middle--right'>
											<div>
												<div className='team-score'>
													<div>
														<span>{props.single.scoreBlock.awayScore}</span>
													</div>
												</div>
												<div className='team-logo'>
													<img
														src={props.single.scoreBlock.awayLogo.src}
														alt={props.single.scoreBlock.awayLogo.alt}
													/>
												</div>
											</div>
											<p>{props.single.scoreBlock.awayTeam}</p>
										</div>
									</div>
									<div className='score-block__status'>
										<div
											className={`score-block__status--ht ${
												props.single.scoreBlock.homeScoreAggregate ? 'agg' : ''
											}`}
										>
											<div>
												<div>
													<span>{props.single.scoreBlock.homeScoreHT}</span>
												</div>
											</div>
											<p>HT</p>
											<div>
												<div>
													<span>{props.single.scoreBlock.awayScoreHT}</span>
												</div>
											</div>
										</div>
										{props.single.scoreBlock.homeScoreAggregate && (
											<div className='score-block__status--aggregate'>
												<div>
													<div>
														<span>{props.single.scoreBlock.homeScoreAggregate}</span>
													</div>
												</div>
												<p>Agg</p>
												<div>
													<div>
														<span>{props.single.scoreBlock.awayScoreAggregate}</span>
													</div>
												</div>
											</div>
										)}
									</div>
									{props.single.scoreBlock.penaltiesHome && (
										<div className='score-block__penalties'>
											<div className='score-block__penalties--balls'>
												<div>
													{props.single.scoreBlock.penaltiesHome.map((item, index) => (
														<div key={index} className={`penalty-ball ${item.shot}`}>
															<SVGIcon icon='football' />
														</div>
													))}
												</div>
												<div>
													{props.single.scoreBlock.penaltiesAway.map((item, index) => (
														<div key={index} className={`penalty-ball ${item.shot}`}>
															<SVGIcon icon='football' />
														</div>
													))}
												</div>
											</div>
											<p className='score-block__penalties--text'>
												{props.single.scoreBlock.penaltiesText}
											</p>
										</div>
									)}
									{(props.single.scoreBlock.goalScorersHome ||
										props.single.scoreBlock.goalScorersAway) && (
										<div
											className={`score-block__goals ${
												!props.single.scoreBlock.goalScorersAway
													? 'left'
													: !props.single.scoreBlock.goalScorersHome
													? 'right'
													: 'center'
											}`}
										>
											<div
												className={`score-block__goals--left ${
													props.single.scoreBlock.goalScorersAway ? 'shift' : ''
												}`}
											>
												{props.single.scoreBlock.goalScorersHome &&
													props.single.scoreBlock.goalScorersHome.map((item, index) => (
														<div key={index} className='scorer'>
															<span>{item.player}</span>
															<div className='scorer__balls'>
																{[...Array(+item.goals).keys()].map((_, subIndex) => (
																	<SVGIcon key={subIndex} icon='football' />
																))}
															</div>
														</div>
													))}
											</div>
											<div className='score-block__goals--right'>
												{props.single.scoreBlock.goalScorersAway &&
													props.single.scoreBlock.goalScorersAway.map((item, index) => (
														<div key={index} className='scorer'>
															<div className='scorer__balls'>
																{[...Array(+item.goals).keys()].map((_, subIndex) => (
																	<SVGIcon key={subIndex} icon='football' />
																))}
															</div>
															<span>{item.player}</span>
														</div>
													))}
											</div>
										</div>
									)}
								</div>
							)}
							<div className='article-main'>
								{props.single && (
									<div className={`article-main__category ${articleClass}`}>
										<h2>
											{props.single && articleClass !== 'uncategorised'
												? props.single.category ||
												  (props.single.categories && props.single.categories[0].name)
												: 'News Article'}
										</h2>
									</div>
								)}
								{displayAdverts && !displayTopAdsense && (
									<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
										{props.articleAdverts.topHeader && <h3>{props.articleAdverts.topHeader}</h3>}

										<a
											href={props.articleAdverts.topImageLink}
											target='_blank'
											rel='nofollow noopener'
										>
											<div>
												<img
													src={props.articleAdverts.topSrc}
													alt={props.articleAdverts.topAlt}
												/>
											</div>
										</a>
									</div>
								)}
								{displayAdverts && displayTopAdsense && (
									<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
										<div className='sponsor-container'>
											{((width >= 992 && width < 1200) || width >= 1800) &&
											props.articleAdverts.topDimensions === 'large' ? (
												<div
													id='snack_dex4'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '728px', 'important');
															el.style.setProperty('height', '90px', 'important');
															el.style.setProperty('max-width', '728px', 'important');
															el.style.setProperty('max-height', '90px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											) : (
												<div
													id='snack_mex11'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '320px', 'important');
															el.style.setProperty('height', '50px', 'important');
															el.style.setProperty('max-width', '320px', 'important');
															el.style.setProperty('max-height', '50px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											)}
										</div>
									</div>
								)}
								{/* {displayAdverts && (
									<div className='article-main__top-sponsor'>
										<div className='sponsor-container'>
											{((width >= 992 && width < 1200) || width >= 1800) && (
												<div id='snack_dex4'></div>
											)}
											{((width < 1800 && width >= 1200) || width < 992) && (
												<div id='snack_mex1'></div>
											)}
										</div>
									</div>
								)} */}
								{/* {displayAdverts && (
									<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
										{props.articleAdverts.topHeader && <h3>{props.articleAdverts.topHeader}</h3>}

										<a
											href={props.articleAdverts.topImageLink}
											target='_blank'
											rel='nofollow noopener'
										>
											<div>
												<img
													src={props.articleAdverts.topSrc}
													alt={props.articleAdverts.topAlt}
												/>
											</div>
										</a>
									</div>
								)} */}
								<div className='article-main__heading'>
									<h1>{props.single && props.single.title}</h1>
									{props.single && props.single.subTitle && <h3>{props.single.subTitle}</h3>}
								</div>
								<div className='article-main__info'>
									<div className='article-main__info--author'>
										<div className='article-main__info--author_image'>
											<img src={authorImage} alt={authorAlt} title={authorTitle} />
										</div>
										<div className='article-main__info--author_text'>
											<p>
												Written By{' '}
												<span>
													{(props.single && props.single.authorName) || 'The United Stand'}
												</span>
											</p>
											<p>{props.single && shortDate(props.single.createdAt)}</p>
										</div>
									</div>
									<div onClick={shareHandler} className='article-main__info--share cta dark'>
										<span>Share This Page</span>
										<FontAwesomeIcon icon='share-alt' />
									</div>
								</div>
								<div className='article-main__content'>
									<div className='article-main__content--stats'>
										<div>
											<SVGIcon icon='eye-icon' />
											<span>
												{(props.single &&
													props.single.views &&
													abbrNum(props.single.views, 1)) ||
													0}
											</span>
										</div>
										<div>
											<FontAwesomeIcon icon={['far', 'heart']} />
											<span>
												{(props.single &&
													props.single.likes &&
													abbrNum(props.single.likes, 1)) ||
													0}
											</span>
										</div>
										<div>
											<SVGIcon icon='share-arrow-full' />
											<span>
												{(props.single &&
													props.single.shares &&
													abbrNum(props.single.shares, 1)) ||
													0}
											</span>
										</div>
									</div>
									<div className='article-main__content--image'>
										<img
											src={
												(props.single && props.single.file && props.single.file.url) ||
												(props.single && props.single.featuredImageSrc)
											}
											alt={
												(props.single && props.single.imageAlt) ||
												(props.single && props.single.featuredImageAlt)
											}
										/>
									</div>
									{props.single && props.single.imageSource && (
										<React.Fragment>
											{props.single.imageSource.url ? (
												<div className='article-main__content--source'>
													<a
														href={props.single.imageSource.url}
														target='_blank'
														rel='nofollow noreferrer'
													>
														{props.single.imageSource.title || 'Image Source'}
													</a>
												</div>
											) : (
												<div className='article-main__content--source'>
													<ReactMarkdown
														source={props.single.imageSource}
														linkTarget='_blank'
													/>
												</div>
											)}
										</React.Fragment>
									)}
									<div className='article-main__content--video'>
										<h6>Content Continues Below</h6>
										<div className='article-main__content--video_playlist_container'>
											<div
												ref={videoAd}
												key={props.match.params.slug}
												id='Brid_13660665'
												className='brid'
												style={{ width: 16, height: 9 }}
											></div>
										</div>
									</div>
									{displayAdverts && !displayMiddleAdsense && (
										<div
											className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
										>
											{props.articleAdverts.middleHeader && (
												<h3>{props.articleAdverts.middleHeader}</h3>
											)}

											<a
												href={props.articleAdverts.middleImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.middleSrc}
														alt={props.articleAdverts.middleAlt}
													/>
												</div>
											</a>
										</div>
									)}
									{displayAdverts && displayMiddleAdsense && (
										<div
											className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
										>
											<div className='sponsor-container'>
												{((width >= 992 && width < 1200) || width >= 1800) &&
												props.articleAdverts.middleDimensions === 'large' ? (
													<div
														id='snack_dex2'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '728px', 'important');
																el.style.setProperty('height', '90px', 'important');
																el.style.setProperty('max-width', '728px', 'important');
																el.style.setProperty('max-height', '90px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												) : (
													<div
														id='snack_mex8'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '320px', 'important');
																el.style.setProperty('height', '50px', 'important');
																el.style.setProperty('max-width', '320px', 'important');
																el.style.setProperty('max-height', '50px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												)}
											</div>
										</div>
									)}
									{/* {displayAdverts && displayMiddleAdsense && (
										<div
											className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
										>
											<div className='sponsor-container'>
												{((width >= 992 && width < 1200) || width >= 1800) && (
													<div
														id='snack_dex2'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '728px', 'important');
																el.style.setProperty('height', '90px', 'important');
																el.style.setProperty('max-width', '728px', 'important');
																el.style.setProperty('max-height', '90px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												)}
												{((width < 1800 && width >= 1200) || width < 992) && (
													<div
														id='snack_mex8'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '320px', 'important');
																el.style.setProperty('height', '50px', 'important');
																el.style.setProperty('max-width', '320px', 'important');
																el.style.setProperty('max-height', '50px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												)}
											</div>
										</div>
									)} */}
									{/* {displayAdverts && (
										<div
											className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
										>
											{props.articleAdverts.middleHeader && (
												<h3>{props.articleAdverts.middleHeader}</h3>
											)}

											<a
												href={props.articleAdverts.middleImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.middleSrc}
														alt={props.articleAdverts.middleAlt}
													/>
												</div>
											</a>
										</div>
									)} */}
									{/* {displayMiddleAdsense && (
										<div
											className={`article-main__content--middle-sponsor ${
												props.articleAdverts && props.articleAdverts.middleDimensions
											}`}
										>
											{props.articleAdverts.middleHeader && (
												<h3>{props.articleAdverts.middleHeader}</h3>
											)}

											<div className='sponsor-container'>
												<ins
													key={renderWidth}
													className={`adsbygoogle articleads ${
														props.articleAdverts && props.articleAdverts.middleDimensions
													}`}
													style={{ display: 'block' }}
													data-ad-client='ca-pub-4853990283074336'
													data-ad-slot='6801017079'
												></ins>
											</div>
										</div>
									)} */}
									<div className='article-main__content--text'>
										{props.single && isArticle ? (
											<ReactMarkdown source={props.single.body} linkTarget='_blank' />
										) : (
											props.single && <React.Fragment>{parse(props.single.body)}</React.Fragment>
										)}
									</div>
									{props.single && isArticle && props.single.editorsPicks && (
										<div className='article-main__content--editors-picks'>
											<div className='editors-picks'>
												<p className='editors-picks__notice'>Keep Scrolling To Read!</p>
												<h5>Editor's Picks</h5>
												<div className='editors-picks-list'>
													<ul>
														{props.single.editorsPicks.map((item, index) => (
															<li key={index}>
																<Link to={`/articles/single/${item.slug}`}>
																	<div className='editors-picks-list__image'>
																		<div>
																			<img
																				src={
																					item.heroImage &&
																					item.heroImage.fields &&
																					item.heroImage.fields.file &&
																					item.heroImage.fields.file.url
																				}
																				alt={
																					item.heroImage &&
																					item.heroImage.fields &&
																					item.heroImage.fields.title
																				}
																			/>
																		</div>
																	</div>
																	<h6>{item.title}</h6>
																</Link>
															</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									)}
									{secondBody && (
										<div className='article-main__content--text'>
											<div className='text-sponsor'>
												<div className='text-sponsor__wrapper'>
													<div className='text-sponsor__wrapper--heading'>
														<small>Content After Advert</small>
													</div>
													<div className='text-sponsor__wrapper--inner'>
														{textBlockAdvertTop && textBlockAdvertTop === 'AdSense' && (
															<div
																id='snack_mex4'
																ref={el => {
																	if (el) {
																		el.style.setProperty(
																			'width',
																			'300px',
																			'important'
																		);
																		el.style.setProperty(
																			'height',
																			'250px',
																			'important'
																		);
																		el.style.setProperty(
																			'max-width',
																			'300px',
																			'important'
																		);
																		el.style.setProperty(
																			'max-height',
																			'250px',
																			'important'
																		);
																		el.style.setProperty(
																			'overflow',
																			'hidden',
																			'important'
																		);
																	}
																}}
															></div>
														)}
														{textBlockAdvertTop && textBlockAdvertTop !== 'AdSense' && (
															<a
																href={
																	props.articleAdverts &&
																	props.articleAdverts.textBlockImageTopLink
																}
																class={
																	props.articleAdverts &&
																	props.articleAdverts.textBlockAdvertTopDimensions
																}
																target='_blank'
																rel='nofollow noopener'
															>
																<img
																	src={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageTopSrc
																	}
																	title={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageTopTitle
																	}
																	alt={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageTopAlt
																	}
																/>
															</a>
														)}
														{/* {textBlockAdvertTop === 'AdSense' ? (
																<ins
																	key={render}
																	className='adsbygoogle textcontentsponsor'
																	style={{ display: 'block' }}
																	data-ad-client='ca-pub-4853990283074336'
																	data-ad-slot='4941974648'
																></ins>
															) : (
																<a
																	href={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageTopLink
																	}
																	target='_blank'
																	rel='nofollow noopener'
																>
																	<img
																		src={
																			props.articleAdverts &&
																			props.articleAdverts.textBlockImageTopSrc
																		}
																		title={
																			props.articleAdverts &&
																			props.articleAdverts.textBlockImageTopTitle
																		}
																		alt={
																			props.articleAdverts &&
																			props.articleAdverts.textBlockImageTopAlt
																		}
																	/>
																</a>
															)} */}
													</div>
												</div>
											</div>
											{isArticle ? (
												<ReactMarkdown source={secondBody} linkTarget='_blank' />
											) : (
												<React.Fragment>{parse(secondBody)}</React.Fragment>
											)}
										</div>
									)}
									{thirdBody && (
										<div className='article-main__content--text'>
											<div className='text-sponsor'>
												<div className='text-sponsor__wrapper'>
													<div className='text-sponsor__wrapper--heading'>
														<small>Content After Advert</small>
													</div>
													<div className='text-sponsor__wrapper--inner'>
														{textBlockAdvertBottom && textBlockAdvertBottom === 'AdSense' && (
															<div
																id='snack_mex5'
																ref={el => {
																	if (el) {
																		el.style.setProperty(
																			'width',
																			'300px',
																			'important'
																		);
																		el.style.setProperty(
																			'height',
																			'250px',
																			'important'
																		);
																		el.style.setProperty(
																			'max-width',
																			'300px',
																			'important'
																		);
																		el.style.setProperty(
																			'max-height',
																			'250px',
																			'important'
																		);
																		el.style.setProperty(
																			'overflow',
																			'hidden',
																			'important'
																		);
																	}
																}}
															></div>
														)}
														{textBlockAdvertBottom && textBlockAdvertBottom !== 'AdSense' && (
															<a
																href={
																	props.articleAdverts &&
																	props.articleAdverts.textBlockImageBottomLink
																}
																class={
																	props.articleAdverts &&
																	props.articleAdverts.textBlockAdvertBottomDimensions
																}
																target='_blank'
																rel='nofollow noopener'
															>
																<img
																	src={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageBottomSrc
																	}
																	title={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageBottomTitle
																	}
																	alt={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageBottomAlt
																	}
																/>
															</a>
														)}
														{/* {textBlockAdvertBottom === 'AdSense' ? (
																<ins
																	key={render}
																	className='adsbygoogle textcontentsponsor'
																	style={{ display: 'block' }}
																	data-ad-client='ca-pub-4853990283074336'
																	data-ad-slot='6801017079'
																></ins>
															) : (
																<a
																	href={
																		props.articleAdverts &&
																		props.articleAdverts.textBlockImageBottomLink
																	}
																	target='_blank'
																	rel='nofollow noopener'
																>
																	<img
																		src={
																			props.articleAdverts &&
																			props.articleAdverts.textBlockImageBottomSrc
																		}
																		title={
																			props.articleAdverts &&
																			props.articleAdverts
																				.textBlockImageBottomTitle
																		}
																		alt={
																			props.articleAdverts &&
																			props.articleAdverts.textBlockImageBottomAlt
																		}
																	/>
																</a>
															)} */}
													</div>
												</div>
											</div>
											{isArticle ? (
												<ReactMarkdown source={thirdBody} linkTarget='_blank' />
											) : (
												<React.Fragment>{parse(thirdBody)}</React.Fragment>
											)}
										</div>
									)}
									{props.single && props.single.relatedVideo && (
										<div className='article-main__content--video'>
											<h6>Content Continues Below</h6>
											<h5>Related Video</h5>
											<div className='article-main__content--video_container'>
												<iframe
													src={`https://www.youtube.com/embed/${
														props.single.relatedVideo
															.split('v=')
															.pop()
															.split('.be/')
															.pop()
															.split('&')[0]
													}`}
													frameborder='0'
												></iframe>
											</div>
											{props.single && props.single.relatedVideoDescription && (
												<>
													<hr />
													<div className='article-main__content--video_description'>
														<p>{props.single.relatedVideoDescription}</p>
													</div>
												</>
											)}
										</div>
									)}
									{props.nextArticle && (
										<div className='article-main__content--next'>
											<a
												href={props.nextArticle && props.nextArticle.slug}
												className='next-article-card'
											>
												<div className='next-article-card__image'>
													<div>
														<img
															src={
																(props.nextArticle &&
																	props.nextArticle.file &&
																	props.nextArticle.file.url) ||
																props.nextArticle.featuredImageSrc
															}
															alt={
																(props.nextArticle && props.nextArticle.imageAlt) ||
																props.nextArticle.featuredImageAlt
															}
														/>
													</div>
												</div>
												<div className='next-article-card__title'>
													<p>{props.nextArticle && props.nextArticle.title}</p>
												</div>
												<div className='next-article-card__icon'>
													<FontAwesomeIcon icon={['fa', 'angle-right']} />
												</div>
											</a>
										</div>
									)}
									<div className='article-main__content--bottom'>
										{!like ? (
											<div className='article-main__content--bottom_like'>
												<div
													onClick={likeHandler}
													className='article-main__content--bottom_like-icon'
												>
													<FontAwesomeIcon icon={['far', 'heart']} />
												</div>
												<p>
													Like This Article <span>if you enjoyed it!</span>
												</p>
											</div>
										) : (
											<div className='article-main__content--bottom_like'>
												<div
													onClick={unlikeHandler}
													className='article-main__content--bottom_like-icon'
												>
													<FontAwesomeIcon icon='heart' />
												</div>
												<p>Thank you!</p>
											</div>
										)}
										<div onClick={shareHandler} className='article-main__content--bottom_share'>
											<FontAwesomeIcon icon='share-alt' />
										</div>
									</div>
								</div>
								{!displayAdverts && <hr />}
								{displayAdverts && !displayBottomAdsense && (
									<div
										className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
									>
										<div className='sponsor-container'>
											{((width >= 992 && width < 1200) || width >= 1800) &&
											props.articleAdverts.bottomDimensions === 'large' ? (
												<div
													id='snack_dex3'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '728px', 'important');
															el.style.setProperty('height', '90px', 'important');
															el.style.setProperty('max-width', '728px', 'important');
															el.style.setProperty('max-height', '90px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											) : (
												<div
													id='snack_mex9'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '320px', 'important');
															el.style.setProperty('height', '50px', 'important');
															el.style.setProperty('max-width', '320px', 'important');
															el.style.setProperty('max-height', '50px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											)}
										</div>
									</div>
								)}
								{displayAdverts && displayBottomAdsense && (
									<div
										className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
									>
										{props.articleAdverts.bottomHeader && (
											<h3>{props.articleAdverts.bottomHeader}</h3>
										)}

										<a
											href={props.articleAdverts.bottomImageLink}
											target='_blank'
											rel='nofollow noopener'
										>
											<div>
												<img
													src={props.articleAdverts.bottomSrc}
													alt={props.articleAdverts.bottomAlt}
												/>
											</div>
										</a>
									</div>
								)}
								{/* {displayAdverts && (
									<div
										className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
									>
										<div className='sponsor-container'>
											{((width >= 992 && width < 1200) || width >= 1800) && (
												<div
													id='snack_dex3'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '728px', 'important');
															el.style.setProperty('height', '90px', 'important');
															el.style.setProperty('max-width', '728px', 'important');
															el.style.setProperty('max-height', '90px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											)}
											{((width < 1800 && width >= 1200) || width < 992) && (
												<div
													id='snack_mex9'
													ref={el => {
														if (el) {
															el.style.setProperty('width', '320px', 'important');
															el.style.setProperty('height', '50px', 'important');
															el.style.setProperty('max-width', '320px', 'important');
															el.style.setProperty('max-height', '50px', 'important');
															el.style.setProperty('overflow', 'hidden', 'important');
														}
													}}
												></div>
											)}
										</div>
									</div>
								)} */}
								{/* {displayAdverts && (
									<div
										className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
									>
										{props.articleAdverts.bottomHeader && (
											<h3>{props.articleAdverts.bottomHeader}</h3>
										)}

										<a
											href={props.articleAdverts.bottomImageLink}
											target='_blank'
											rel='nofollow noopener'
										>
											<div>
												<img
													src={props.articleAdverts.bottomSrc}
													alt={props.articleAdverts.bottomAlt}
												/>
											</div>
										</a>
									</div>
								)} */}
								{/* {displayBottomAdsense && (
									<div
										className={`article-main__bottom-sponsor ${
											props.articleAdverts && props.articleAdverts.bottomDimensions
										}`}
									>
										{props.articleAdverts.bottomHeader && (
											<h3>{props.articleAdverts.bottomHeader}</h3>
										)}

										<div className='sponsor-container'>
											<ins
												key={renderWidth}
												className={`adsbygoogle articleads ${
													props.articleAdverts && props.articleAdverts.bottomDimensions
												}`}
												style={{ display: 'block' }}
												data-ad-client='ca-pub-4853990283074336'
												data-ad-slot='3055012050'
											></ins>
										</div>
									</div>
								)} */}
								{props.single &&
									(props.single.sourceLink1 ||
										props.single.sourceLink2 ||
										props.single.sourceLink3 ||
										props.single.sourceLink4) && (
										<div className='article-main__sources'>
											<h2>Source List</h2>
											<div className='article-main__sources--list'>
												{props.single.sourceLink1 && (
													<a
														href={props.single.sourceLink1}
														target='_blank'
														rel='nofollow noopener'
														className='article-main__sources--list_item'
													>
														<div className='source-image'>
															<img
																src={props.single.sourceImageUrl1}
																alt={props.single.sourceImageAlt1}
															/>
														</div>
														<div className='source-details'>
															<h4 className='source-details__title'>
																{truncateText(props.single.sourceTitle1, limit)}
															</h4>
															<hr />
															<div className='source-details__bottom'>
																<div className='source-details__bottom--date'>
																	{props.single.sourceDate1 && (
																		<DayAndMonth date={props.single.sourceDate1} />
																	)}
																</div>
																<div className='source-details__bottom--icon'>
																	<SVGIcon icon='globe' />
																</div>
															</div>
														</div>
													</a>
												)}
												{props.single.sourceLink2 && (
													<a
														href={props.single.sourceLink2}
														target='_blank'
														rel='nofollow noopener'
														className='article-main__sources--list_item'
													>
														<div className='source-image'>
															<img
																src={props.single.sourceImageUrl2}
																alt={props.single.sourceImageAlt2}
															/>
														</div>
														<div className='source-details'>
															<h4 className='source-details__title'>
																{truncateText(props.single.sourceTitle2, limit)}
															</h4>
															<hr />
															<div className='source-details__bottom'>
																<div className='source-details__bottom--date'>
																	{props.single.sourceDate2 && (
																		<DayAndMonth date={props.single.sourceDate2} />
																	)}
																</div>
																<div className='source-details__bottom--icon'>
																	<SVGIcon icon='globe' />
																</div>
															</div>
														</div>
													</a>
												)}
												{props.single.sourceLink3 && (
													<a
														href={props.single.sourceLink3}
														target='_blank'
														rel='nofollow noopener'
														className='article-main__sources--list_item'
													>
														<div className='source-image'>
															<img
																src={props.single.sourceImageUrl3}
																alt={props.single.sourceImageAlt3}
															/>
														</div>
														<div className='source-details'>
															<h4 className='source-details__title'>
																{truncateText(props.single.sourceTitle3, limit)}
															</h4>
															<hr />
															<div className='source-details__bottom'>
																<div className='source-details__bottom--date'>
																	{props.single.sourceDate3 && (
																		<DayAndMonth date={props.single.sourceDate3} />
																	)}
																</div>
																<div className='source-details__bottom--icon'>
																	<SVGIcon icon='globe' />
																</div>
															</div>
														</div>
													</a>
												)}
												{props.single.sourceLink4 && (
													<a
														href={props.single.sourceLink4}
														target='_blank'
														rel='nofollow noopener'
														className='article-main__sources--list_item'
													>
														<div className='source-image'>
															<img
																src={props.single.sourceImageUrl4}
																alt={props.single.sourceImageAlt4}
															/>
														</div>
														<div className='source-details'>
															<h4 className='source-details__title'>
																{truncateText(props.single.sourceTitle4, limit)}
															</h4>
															<hr />
															<div className='source-details__bottom'>
																<div className='source-details__bottom--date'>
																	{props.single.sourceDate4 && (
																		<DayAndMonth date={props.single.sourceDate4} />
																	)}
																</div>
																<div className='source-details__bottom--icon'>
																	<SVGIcon icon='globe' />
																</div>
															</div>
														</div>
													</a>
												)}
											</div>
										</div>
									)}
								{props.single && props.single.sources && props.single.sources[0] && (
									<div className='article-main__sources'>
										<h2>Source List</h2>
										<div className='article-main__sources--list'>
											{props.single.sources.map((item, index) => (
												<a
													key={index}
													href={item.link.url}
													target='_blank'
													rel='nofollow noreferrer'
													className='article-main__sources--list_item'
												>
													<div className='source-image'>
														<img src={item.image.url} alt={item.image.alt} />
													</div>
													<div className='source-details'>
														<h4 className='source-details__title'>
															{truncateText(item.title, limit)}
														</h4>
														<hr />
														<div className='source-details__bottom'>
															<div className='source-details__bottom--date'>
																{item.date && <DayAndMonth date={item.date} />}
															</div>
															<div className='source-details__bottom--icon'>
																<SVGIcon icon='globe' />
															</div>
														</div>
													</div>
												</a>
											))}
										</div>
									</div>
								)}
								<div className='article-main__footer'>
									{props.single && props.single.tags && (
										<React.Fragment>
											<h2>Tags</h2>
											<div className='article-main__footer--tags'>
												{props.single.tags.map((tag, index) => (
													<Link
														key={index}
														to={`/search-results/${isArticle ? tag : tag.name}`}
													>
														{isArticle ? tag : tag.name}
													</Link>
												))}
											</div>
										</React.Fragment>
									)}
								</div>
							</div>
						</div>
						<div className='single-article-grid__right'>
							<RecommendedBar classes='show-desktop' />
							<TrendingContent classes='hide-mobile' />
						</div>
					</div>
				</div>
			</div>
			<TrendingContent id='trending-content' classes='hide-desktop' />
			{width > 1200 && <ContentRec classes='more-padding' />}
			<FeaturedAuthors classes='single' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		single: state.contentful.single,
		nextArticle: state.contentful.nextArticle,
		articleAdverts: state.contentful.articleAdverts,
		adverts: state.contentful.adverts,
		adBlockDetected: state.general.adBlockDetected,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getSingleArticle: slug => dispatch(getSingleArticle(slug)),
		getNextArticle: date => dispatch(getNextArticle(date)),
		likeArticle: slug => dispatch(likeArticle(slug)),
		unlikeArticle: slug => dispatch(unlikeArticle(slug)),
		incrementShareCount: slug => dispatch(incrementShareCount(slug)),
		setModal: () => dispatch(setModal()),
		getArticleAdverts: () => dispatch(getArticleAdverts()),
		getAdverts: () => dispatch(getAdverts()),
		clearSingleArticle: () => dispatch(clearSingleArticle()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleArticle);
