import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { getRecommendedVideos } from '../../../actions/youtubeActions';
import { getAdverts } from '../../../actions/contentfulActions';

// Utils
import abbrNum from '../../../utils/abbrNum';
import timeElapsed from '../../../utils/timeElapsed';
import truncateText from '../../../utils/truncateText';

// Images
// import OneFootball from '../../../img/onefootball.png';
// import Paddy from '../../../img/paddy.png';
import PlayButton from '../../../img/play-button.png';

const RecommendedBar = props => {
	useEffect(() => {
		props.getRecommendedVideos();
		props.getAdverts();
	}, []);

	const [width, setWidth] = useState(false);

	const widthHandler = () => {
		setWidth(window.innerWidth);
	};

	let resizeId;
	const resize = () => {
		clearTimeout(resizeId);
		resizeId = setTimeout(widthHandler, 500);
	};

	useEffect(() => {
		setWidth(window.innerWidth);

		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, [widthHandler]);

	let limit;
	switch (true) {
		case width >= 992:
			limit = 25;
			break;
		case width >= 768:
			limit = 25;
			break;
		// case width >= 440:
		// 	limit = 40;
		// 	break;
		case width >= 370:
			limit = 40;
			break;
		default:
			limit = 25;
	}

	const videos =
		props.recommended &&
		props.recommended.map((video, index) => {
			const thumbnail =
				video.thumbnails.maxres ||
				video.thumbnails.standard ||
				video.thumbnails.high ||
				video.thumbnails.medium ||
				video.thumbnails.default;

			return (
				<Link key={index} to={`/videos/single/${video.videoId}`} className='recommended__videos--list_card'>
					<div className='recommended__videos--list_card-image'>
						<img src={thumbnail.url} alt='Thumbnail for a youtube video' />
					</div>
					<div className='recommended__videos--list_card-content'>
						<p>{truncateText(video.title, limit)}</p>
						<hr />
						<div className='recommended__videos--list_card-content__stats'>
							<span>{timeElapsed(video.publishedAt)}</span>
							<span>{video.views ? abbrNum(video.views, 0) : 0} views</span>
						</div>
						<img src={PlayButton} alt='Circle with a red background and white play symbol in the middle' />
					</div>
				</Link>
			);
		});

	return (
		<div id='recommended-videos' className='recommended'>
			<div>
				{props.adverts && props.adverts.topImage && (
					<div className='recommended__offer'>
						<h2>Exclusive Offer</h2>
						<a
							href={props.adverts.topImageLink}
							target='_blank'
							rel='noopener noreferrer'
							className='recommended__offer--image'
						>
							<img
								src={props.adverts.topImage.fields.file.url}
								alt={props.adverts.topImage.fields.description}
								title={props.adverts.topImage.fields.title}
							/>
						</a>
					</div>
				)}
				<div className='recommended__videos'>
					<h2>Recommended Videos</h2>
					<div className='recommended__videos--list'>{videos}</div>
				</div>
				{props.adverts && props.adverts.bottomImage && (
					<div className='recommended__ad'>
						<h2>Advertisement</h2>
						<a
							href={props.adverts.bottomImageLink}
							target='_blank'
							rel='noopener noreferrer'
							className='recommended__ad--image'
						>
							<img
								src={props.adverts.bottomImage.fields.file.url}
								alt={props.adverts.bottomImage.fields.description}
								title={props.adverts.bottomImage.fields.title}
							/>
						</a>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		recommended: state.youtube.recommended,
		adverts: state.contentful.adverts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getRecommendedVideos: () => dispatch(getRecommendedVideos()),
		getAdverts: () => dispatch(getAdverts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedBar);
