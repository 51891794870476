import React from 'react';

const Spinner = props => {
	return (
		<div className={`loader ${props.classes ? props.classes : ''}`}>
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default Spinner;
