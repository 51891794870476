import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLatestUploads } from '../../../../actions/youtubeActions';

const LatestVideo = props => {
	useEffect(() => {
		props.getLatestUploads();
	}, []);

	const thumbnail =
		props.latest &&
		props.latest.length > 0 &&
		(props.latest[0].thumbnails.maxres ||
			props.latest[0].thumbnails.standard ||
			props.latest[0].thumbnails.high ||
			props.latest[0].thumbnails.medium ||
			props.latest[0].thumbnails.default);

	return (
		<div id='latest-video' className='latest-video-section'>
			<h2>Latest Video</h2>
			<a
				href={`/videos/single/${props.latest && props.latest.length > 0 && props.latest[0].videoId}`}
				className='latest-video-section__card'
			>
				<img src={thumbnail && thumbnail.url} alt='YouTube video thumbnail' />
			</a>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		latest: state.youtube.latest,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestUploads: () => dispatch(getLatestUploads()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestVideo);
