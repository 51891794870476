import axios from 'axios';

import * as actionTypes from './types';

export const getLatestTweets = () => dispatch => {
	axios.get('/api/social/latest-tweets').then(response => {
		dispatch({
			type: actionTypes.GET_LATEST_TWEETS,
			payload: response.data,
		});
	});
};

export const getLatestFacebookPosts = () => dispatch => {
	axios.get('/api/social/latest-facebook-posts').then(response => {
		dispatch({
			type: actionTypes.GET_LATEST_FACEBOOK_POSTS,
			payload: response.data,
		});
	});
};

export const getLatestInstagramPosts = () => dispatch => {
	axios.get('/api/social/latest-instagram-posts').then(response => {
		dispatch({
			type: actionTypes.GET_LATEST_INSTAGRAM_POSTS,
			payload: response.data,
		});
	});
};

export const getLatestSocialPosts = () => dispatch => {
	axios.get('/api/social/all-social').then(response => {
		dispatch({
			type: actionTypes.GET_ALL_SOCIAL_MEDIA,
			payload: response.data,
		});
	});
};
