import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// Actions
import { getPlaylists } from '../../../../actions/youtubeActions';

// Components
import PlaylistSlider from './PlaylistSlider';
import VideoHeader from '../Elements/VideoHeader';
import Spinner from '../../../base/Elements/Spinner';
import ContentRec from '../../../base/Elements/ContentRec';

const Playlists = props => {
	useEffect(() => {
		props.getPlaylists();
	}, []);

	const [limit, setLimit] = useState(4);

	const playlists =
		props.playlists.content.length > 0 &&
		props.playlists.content.map((playlist, index) => {
			if (index + 1 <= limit && playlist.videos && playlist.videos.length > 0) {
				return (
					<PlaylistSlider
						classes={index % 2 === 0 ? 'dark' : 'light'}
						playlist={playlist}
						key={index}
						index={index}
						container={true}
					/>
				);
			}
			return;
		});

	const button = props.loading ? (
		<Spinner />
	) : (
		<button
			onClick={() => setLimit(limit * 2)}
			className={`cta ${props.playlists.content.length <= limit ? 'disabled' : ''}`}
		>
			{props.playlists.content.length <= limit ? "You've reached the end" : 'Load More'}
		</button>
	);

	return (
		<main>
			<VideoHeader />
			<div id='playlists'>{playlists}</div>
			<div className='load-more'>{button}</div>
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		playlists: state.youtube.playlists,
		loading: state.youtube.loading,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPlaylists: pageToken => dispatch(getPlaylists(pageToken)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlists);
