import React from 'react';
import { Link } from 'react-router-dom';

// Images
import Plus from '../../../img/plus.png';

// Components
import FormModal from '../../base/Elements/FormModal';

const TeamGrid = props => {
	const team =
		props.team &&
		props.team.map((member, index) => {
			const {
				fields: {
					name,
					slug,
					// image: {
					// 	fields: {
					// 		title,
					// 		description: alt,
					// 		file: { url: src },
					// 	},
					// },
				},
			} = member;

			const title =
				member.fields && member.fields.image && member.fields.image.fields && member.fields.image.fields.title;
			const alt =
				member.fields &&
				member.fields.image &&
				member.fields.image.fields &&
				member.fields.image.fields.description;
			const src =
				member.fields &&
				member.fields.image &&
				member.fields.image.fields &&
				member.fields.image.fields.file &&
				member.fields.image.fields.file.url;

			return (
				<Link key={index} to={`/our-team/${slug}`} className='team-grid__card'>
					<div className='team-grid__card--image'>
						<img src={src} alt={alt} title={title} />
					</div>
					<div className='team-grid__card--name'>
						<p>{name}</p>
					</div>
				</Link>
			);
		});

	return (
		<div className='team-wrapper'>
			<div className='container'>
				<div className='team-container'>
					<h2>Our Team</h2>
					<div className='team-grid'>
						{team}
						<div className='team-grid__contact'>
							<img src={Plus} alt='Plus symbol' />
							<h3>Could it be you?</h3>
							<p>
								We’re always on the look for new talent, so if you fancy joining our team, why not get
								in touch?
							</p>
							<FormModal
								buttonText='Get In Touch'
								buttonClasses='cta cta-large'
								location='Our Team Page'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamGrid;
