import * as actionTypes from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_TRENDING_CONTENT:
			return {
				...state,
				trending: action.payload,
			};
		case actionTypes.GET_PAST_DAY_CONTENT:
			return {
				...state,
				pastDay: action.payload,
			};
		case actionTypes.GET_MUST_WATCH:
			return {
				...state,
				mustWatch: action.payload,
			};
		default:
			return state;
	}
};
