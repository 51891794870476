import React from 'react';
import { Link } from 'react-router-dom';
import removeMd from 'remove-markdown';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import he from 'he';
import striptags from 'striptags';

// Utils
import timeElapsed from '../../../utils/timeElapsed';
import truncateText from '../../../utils/truncateText';
import abbrNum from '../../../utils/abbrNum';
import SVGIcon from '../../base/Elements/SVGIcon';
import NavIcon from '../../base/Elements/NavIcon';

import Placeholder from '../../../img/article-placeholder.jpg';

const ArticleGrid = props => {
	const posts =
		props.articles &&
		props.articles.map((post, index) => {
			if (post.questions) {
				return (
					<Link to={`/articles/quizzes/${post.slug}`} key={index} className='article-grid__post quiz'>
						<div className='quiz-icon'>
							<NavIcon icon='quiz' />
						</div>
						<div className='article-grid__post--image'>
							<div>
								<img
									src={post.featuredImageSrc.replace(
										'https://cms.theunitedstand.com/wp-content/uploads/',
										'/api/media/wp-media/'
									)}
									alt={post.featuredImageAlt}
								/>
							</div>
						</div>
						<div className='article-grid__post--details'>
							<h2>{truncateText(post.title, 100)}</h2>
							<hr />
							<div className='article-grid__post--details_stats'>
								<span>The United Stand</span>
								<span>{timeElapsed(post.published)}</span>
							</div>
							<div className='article-grid__post--details_excerpt'>
								<p>{truncateText(he.decode(post.subheading.replace(/<[^>]+>/g, '')), 125)}</p>
							</div>
						</div>
						<div className='article-grid__post--icons'>
							<div className='article-grid__post--icons_left'>
								<div>
									<FontAwesomeIcon icon={['far', 'heart']} />
									<span>{post.likes ? abbrNum(post.likes, 1) : 0}</span>
								</div>
								<div>
									<SVGIcon icon='eye-icon' />
									<span>{post.views ? abbrNum(post.views, 1) : 0}</span>
								</div>
							</div>
							<div className='article-grid__post--icons_right'>
								<SVGIcon icon='read-icon' />
							</div>
						</div>
					</Link>
				);
			}

			if (post.players) {
				const postClass = slugify(post.scoreBlock.category, {
					lower: true,
				});

				return (
					<Link
						to={`/articles/match-ratings/${post.slug}`}
						key={index}
						className={`article-grid__post rating-${postClass} match-ratings`}
					>
						<div className='article-grid__post--image'>
							<div>
								<img
									src={post.featuredImageSrc.replace(
										'https://cms.theunitedstand.com/wp-content/uploads/',
										'/api/media/wp-media/'
									)}
									alt={post.featuredImageAlt}
								/>
								<div className='article-grid__post--image_banner'>
									<span>Player Ratings</span>
								</div>
							</div>
						</div>
						<div className='article-grid__post--details'>
							<div className='article-grid__post--details_scoreblock'>
								<div className='left-block'>
									<div className='team-logo'>
										<img
											src={post.scoreBlock.homeLogo.src.replace(
												'https://cms.theunitedstand.com/wp-content/uploads/',
												'/api/media/wp-media/'
											)}
											alt={post.scoreBlock.homeLogo.alt}
										/>
									</div>
									<div className='team-score'>
										<div>
											<span>{post.scoreBlock.homeScore}</span>
										</div>
									</div>
								</div>
								<div className='right-block'>
									<div className='team-score'>
										<div>
											<span>{post.scoreBlock.awayScore}</span>
										</div>
									</div>
									<div className='team-logo'>
										<img
											src={post.scoreBlock.awayLogo.src.replace(
												'https://cms.theunitedstand.com/wp-content/uploads/',
												'/api/media/wp-media/'
											)}
											alt={post.scoreBlock.awayLogo.alt}
										/>
									</div>
								</div>
							</div>
							<h2>{truncateText(post.title, 80)}</h2>
							<hr />
							<div className='article-grid__post--details_stats match-rating'>
								<span>The United Stand</span>
								<span>{timeElapsed(post.published)}</span>
							</div>
						</div>
						<div className='article-grid__post--icons'>
							<div className='article-grid__post--icons_left'>
								<div>
									<FontAwesomeIcon icon={['far', 'heart']} />
									<span>{post.likes ? abbrNum(post.likes, 1) : 0}</span>
								</div>
								<div>
									<SVGIcon icon='eye-icon' />
									<span>{post.views ? abbrNum(post.views, 1) : 0}</span>
								</div>
							</div>
							<div className='article-grid__post--icons_right'>
								<SVGIcon icon='read-icon' />
							</div>
						</div>
					</Link>
				);
			}

			const article = post.article || post;
			const isArticle = post.article;

			const category =
				article.category || (article.scoreBlock && article.scoreBlock.category) || article.categories[0].name;

			if (props.filter === 'transfers' && props.filter !== slugify(category, { lower: true })) {
				return;
			}

			let postClass =
				(article.category && slugify(post.article.category, { lower: true }).replace("'", '')) ||
				(article.scoreBlock && slugify(article.scoreBlock.category, { lower: true })) ||
				(article.categories[0] &&
					slugify(article.categories[0].name, {
						lower: true,
					}).replace("'", ''));

			// Breaking news time check
			const date = new Date();
			date.setHours(date.getHours() - 6);
			const pastDate = date.getTime();
			const postedDate = new Date(article.createdAt).getTime();

			if (postClass === 'breaking-news' && pastDate > postedDate) {
				postClass = 'uncategorised';
			}

			let articleImageSrc = '';

			if (article.featuredImageSrc) {
				articleImageSrc = article.featuredImageSrc.replace(
					'https://cms.theunitedstand.com/wp-content/uploads/',
					'/api/media/wp-media/'
				);
			} else {
				articleImageSrc =
					(article.file &&
						article.file.url.replace(
							'https://cms.theunitedstand.com/wp-content/uploads/',
							'/api/media/wp-media/'
						)) ||
					Placeholder;
			}

			return (
				<Link
					to={`/articles/single/${article.slug}`}
					key={index}
					className={`article-grid__post ${article.scoreBlock ? 'rating-' : ''}${postClass}`}
				>
					<div className='article-grid__post--image'>
						<div>
							<img src={articleImageSrc} alt={article.imageAlt || article.featuredImageAlt} />
							<div className='article-grid__post--image_banner'>
								<span>{category}</span>
							</div>
						</div>
					</div>
					<div className='article-grid__post--details'>
						<h2>{truncateText(article.title, 100)}</h2>
						<hr />
						<div className='article-grid__post--details_stats'>
							<span>{article.authorName || 'The United Stand'}</span>
							<span>{timeElapsed(article.createdAt)}</span>
						</div>
						<div className='article-grid__post--details_excerpt'>
							{isArticle ? (
								<p>{truncateText(removeMd(article.body), 125)}</p>
							) : (
								<p>{truncateText(he.decode(striptags(article.body)), 125)}</p>
							)}
						</div>
					</div>
					<div className='article-grid__post--icons'>
						<div className='article-grid__post--icons_left'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{article.likes ? abbrNum(article.likes, 1) : 0}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{article.views ? abbrNum(article.views, 1) : 0}</span>
							</div>
						</div>
						<div className='article-grid__post--icons_right'>
							<SVGIcon icon='read-icon' />
						</div>
					</div>
				</Link>
			);
		});

	return (
		<div className='article-grid-container'>
			<div className='container'>
				<div className='article-grid-wrapper'>
					<div className='article-grid large'>{posts}</div>
				</div>
			</div>
		</div>
	);
};

export default ArticleGrid;
