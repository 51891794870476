import React from 'react';

const NavIcon = props => {
	let icon;

	switch (props.icon.toLowerCase()) {
		case 'videos':
			icon = (
				<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 1100 1100'>
					<path
						d='M550,0C246.243,0,0,246.243,0,550s246.243,550,550,550s550-246.243,550-550S853.757,0,550,0z
	 M375.416,810.699V289.301c0-18.831,20.663-30.356,36.685-20.462l422.185,260.699c15.218,9.397,15.218,31.528,0,40.925
	L412.1,831.161C396.078,841.055,375.416,829.53,375.416,810.699z'
					/>
				</svg>
			);
			break;
		case 'written':
			icon = (
				<svg
					version='1.1'
					id='_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 528.927 528.927'
				>
					<g>
						<g>
							<g>
								<path
									d='M479.34,0H115.704C88.31,0,66.117,22.187,66.117,49.588v33.057h-16.53
				C22.195,82.645,0,104.832,0,132.233v330.578c0,36.512,29.604,66.116,66.117,66.116h396.695c36.511,0,66.115-29.604,66.115-66.116
				V49.588C528.928,22.187,506.717,0,479.34,0z M495.87,462.811c0,18.208-14.85,33.057-33.058,33.057H66.117
				c-18.232,0-33.059-14.849-33.059-33.057V132.233c0-9.128,7.41-16.529,16.529-16.529h16.53v330.578
				c0,9.137,7.393,16.529,16.529,16.529c9.135,0,16.529-7.392,16.529-16.529V49.588c0-9.128,7.409-16.529,16.529-16.529H479.34
				c9.104,0,16.529,7.401,16.529,16.529v413.223H495.87z'
								/>
								<path
									d='M322.283,214.957h132.232c4.584,0,8.264-3.704,8.264-8.264s-3.68-8.264-8.264-8.264H322.283
				c-4.552,0-8.264,3.703-8.264,8.264C314.02,211.253,317.731,214.957,322.283,214.957z'
								/>
								<path
									d='M322.283,165.37h132.232c4.584,0,8.264-3.704,8.264-8.264s-3.68-8.266-8.264-8.266H322.283
				c-4.552,0-8.264,3.705-8.264,8.266C314.02,161.667,317.731,165.37,322.283,165.37z'
								/>
								<path
									d='M322.283,115.784h132.232c4.584,0,8.264-3.705,8.264-8.266c0-4.558-3.68-8.264-8.264-8.264
				H322.283c-4.552,0-8.264,3.705-8.264,8.264C314.02,112.079,317.731,115.784,322.283,115.784z'
								/>
								<path
									d='M272.729,446.347H140.497c-4.56,0-8.265,3.713-8.265,8.264c0,4.585,3.705,8.264,8.265,8.264
				h132.231c4.552,0,8.265-3.679,8.265-8.264C280.993,450.06,277.281,446.347,272.729,446.347z'
								/>
								<path
									d='M272.729,396.759H140.497c-4.56,0-8.265,3.713-8.265,8.266c0,4.583,3.705,8.264,8.265,8.264
				h132.231c4.552,0,8.265-3.681,8.265-8.264C280.993,400.472,277.281,396.759,272.729,396.759z'
								/>
								<path
									d='M272.729,347.173H140.497c-4.56,0-8.265,3.713-8.265,8.264c0,4.585,3.705,8.266,8.265,8.266
				h132.231c4.552,0,8.265-3.681,8.265-8.266C280.993,350.886,277.281,347.173,272.729,347.173z'
								/>
								<path
									d='M454.546,446.347h-132.23c-4.585,0-8.265,3.713-8.265,8.264c0,4.585,3.68,8.264,8.265,8.264
				h132.23c4.553,0,8.266-3.679,8.266-8.264C462.812,450.06,459.099,446.347,454.546,446.347z'
								/>
								<path
									d='M454.546,396.759h-132.23c-4.585,0-8.265,3.713-8.265,8.266c0,4.583,3.68,8.264,8.265,8.264
				h132.23c4.553,0,8.266-3.681,8.266-8.264C462.812,400.472,459.099,396.759,454.546,396.759z'
								/>
								<path
									d='M454.546,347.173h-132.23c-4.585,0-8.265,3.713-8.265,8.264c0,4.585,3.68,8.266,8.265,8.266
				h132.23c4.553,0,8.266-3.681,8.266-8.266C462.812,350.886,459.099,347.173,454.546,347.173z'
								/>
								<path
									d='M454.546,247.999H140.497c-4.56,0-8.265,3.705-8.265,8.266c0,4.56,3.705,8.264,8.265,8.264
				h314.049c4.553,0,8.266-3.703,8.266-8.264S459.099,247.999,454.546,247.999z'
								/>
								<path
									d='M454.546,297.587H140.497c-4.56,0-8.265,3.711-8.265,8.264c0,4.585,3.705,8.266,8.265,8.266
				h314.049c4.553,0,8.266-3.681,8.266-8.266C462.812,301.298,459.099,297.587,454.546,297.587z'
								/>
								<path
									d='M148.762,214.876h115.702c9.136,0,16.529-7.384,16.529-16.528V82.71
				c0-9.145-7.393-16.53-16.529-16.53H148.762c-9.137,0-16.53,7.385-16.53,16.53v115.638
				C132.233,207.475,139.626,214.876,148.762,214.876z'
								/>
							</g>
						</g>
					</g>
				</svg>
			);
			break;
		case 'podcasts':
			icon = (
				<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 973.333 1425.118'>
					<g>
						<path
							d='M487.43,953.547L487.43,953.547c157.775,0,285.677-127.902,285.677-285.676V285.676
		C773.107,127.902,645.205,0,487.431,0h-0.001C329.656,0,201.754,127.901,201.754,285.676v382.195
		C201.753,825.646,329.655,953.547,487.43,953.547z'
						/>
						<path
							d='M911.788,556.759L911.788,556.759c-34.835,0-63.075,28.239-63.075,63.074v53.857
		c0,199.531-161.752,361.282-361.282,361.282h-0.001c-199.531,0-361.282-161.752-361.282-361.282v-53.857
		c0-34.835-28.239-63.074-63.074-63.074l0,0C28.239,556.759,0,584.998,0,619.833v53.857c0,65.748,12.905,129.595,38.356,189.769
		c24.556,58.058,59.685,110.173,104.409,154.897s96.839,79.852,154.897,104.409c40.732,17.228,83.147,28.699,126.695,34.318v141.886
		H294.23c-34.588,0-63.526,27.412-64.103,61.995c-0.591,35.329,27.871,64.154,63.065,64.154H680.63
		c34.588,0,63.526-27.412,64.104-61.995c0.591-35.329-27.872-64.154-63.066-64.154H550.504v-141.885
		c43.547-5.62,85.963-17.09,126.695-34.318c58.058-24.557,110.173-59.685,154.897-104.409s79.853-96.839,104.409-154.897
		c25.452-60.175,38.356-124.022,38.356-189.769v-53.857C974.862,584.999,946.622,556.759,911.788,556.759z'
						/>
					</g>
				</svg>
			);
			break;
		case 'social':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 5000 5000'
				>
					<g>
						<g>
							<path
								d='M2500.043,0C1121.473,0,0,1121.474,0,2500.045C0,3878.527,1121.473,5000,2500.043,5000
			C3878.527,5000,5000,3878.528,5000,2500.045C4999.998,1121.472,3878.527,0,2500.043,0z M2500.043,4603.236
			c-1159.818,0-2103.281-943.547-2103.281-2103.191c0-1159.822,943.462-2103.372,2103.281-2103.372
			c1159.733,0,2103.195,943.551,2103.195,2103.372C4603.238,3659.687,3659.776,4603.236,2500.043,4603.236z'
							/>
							<path
								d='M3160.104,1877.179c265.245,0,480.364-215.118,480.364-480.455s-215.118-480.365-480.364-480.365
			c-265.335,0-480.455,215.028-480.455,480.365c0,42.334,5.578,83.251,15.763,122.308l-1004.48,620.38
			c-84.667-74.567-195.635-119.911-317.321-119.911c-265.246,0-480.366,215.118-480.366,480.455
			c0,265.337,215.118,480.366,480.366,480.366c121.686,0,232.653-45.254,317.32-119.829l1004.48,620.296
			c-10.184,39.055-15.763,80.151-15.763,122.395c0,265.337,215.118,480.455,480.455,480.455
			c265.245,0,480.364-215.116,480.364-480.455c0-265.335-215.118-480.365-480.364-480.365
			c-121.685,0-232.655,45.254-317.323,119.914l-1004.48-620.384c10.273-39.059,15.765-80.064,15.765-122.395
			c0-42.248-5.49-83.336-15.765-122.395l1004.48-620.293C2927.449,1831.836,3038.419,1877.179,3160.104,1877.179z'
							/>
						</g>
					</g>
				</svg>
			);
			break;
		case 'events':
			icon = (
				<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49.15 49.65'>
					<polygon points='19.78 19.81 21.58 23.46 25.61 24.04 22.69 26.89 23.38 30.9 19.78 29 16.17 30.9 16.86 26.89 13.95 24.04 17.98 23.46 19.78 19.81' />
					<path
						d='M839.43,842.16V827.08a5.57,5.57,0,0,0-5.57-5.57h-1.7v-5.27h-5.1v5.27h-15v-5.27H807v5.27h-1.35a5.57,5.57,0,0,0-5.57,5.57v26.14a5.57,5.57,0,0,0,5.57,5.57h20.68a12,12,0,1,0,13.1-16.63Zm-35.09,12.21V830.65h30.37v11.58a12,12,0,0,0-9.42,11.7c0,.15,0,.29,0,.44Zm30.37,7.1-7.76-8.2,3.55-3.33,4.43,4.21,7.32-7.76,4,3.77Z'
						transform='translate(-800.08 -816.24)'
					/>
				</svg>
			);
			break;
		case 'timeline':
			icon = (
				<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38.57 50.55'>
					<path
						d='M851.65,852.32H858a5.74,5.74,0,1,0-.08-3h-6.23V835.6h6.76a5.76,5.76,0,1,0-.42-3h-6.34v-7.05h-3v14.92h-6.32a5.74,5.74,0,1,0,0,3h6.32v14.72H842.2a5.74,5.74,0,1,0-.2,3h6.65V876.1h3v-6.71h6.21a5.74,5.74,0,1,0-.1-3h-6.11Zm11.81-4.39a2.74,2.74,0,1,1-2.74,2.73A2.73,2.73,0,0,1,863.46,847.93Zm.21-17.36a2.74,2.74,0,1,1-2.73,2.74A2.74,2.74,0,0,1,863.67,830.57ZM836.8,844.71a2.74,2.74,0,1,1,2.74-2.74A2.74,2.74,0,0,1,836.8,844.71Zm-.22,17.35a2.74,2.74,0,1,1,2.74-2.74A2.74,2.74,0,0,1,836.58,862.06ZM863.34,865a2.74,2.74,0,1,1-2.74,2.73A2.74,2.74,0,0,1,863.34,865Z'
						transform='translate(-830.84 -825.55)'
					/>
				</svg>
			);
			break;
		case 'playlists':
			icon = (
				<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 25 25'>
					<g>
						<path
							d='M23.226,7.515H1.774C0.798,7.515,0,8.33,0,9.327v13.86C0,24.184,0.798,25,1.774,25h21.452
		C24.202,25,25,24.184,25,23.187V9.327C25,8.33,24.202,7.515,23.226,7.515z M17.962,16.712l-4.78,2.367
		c-0.011,0.005-0.021,0.011-0.032,0.017l-4.039,2.383c-0.33,0.195-0.742-0.049-0.742-0.438v-4.784v-4.784
		c0-0.389,0.412-0.633,0.742-0.438l4.039,2.383c0.01,0.006,0.021,0.012,0.032,0.017l4.78,2.367
		C18.334,15.986,18.334,16.528,17.962,16.712z'
						/>
						<path
							d='M23.034,5.587H1.949V4.81c0-0.727,0.589-1.316,1.316-1.316h18.453c0.727,0,1.316,0.589,1.316,1.316
		V5.587z'
						/>
						<path
							d='M19.943,2.093H5.04V1.177C5.04,0.527,5.567,0,6.217,0l12.548,0c0.65,0,1.177,0.527,1.177,1.177
		V2.093z'
						/>
					</g>
				</svg>
			);
			break;
		case 'transfers':
			icon = (
				<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 25 25'>
					<g>
						<path
							d='M12.5,0C5.596,0,0,5.596,0,12.5C0,19.404,5.596,25,12.5,25S25,19.404,25,12.5
		C25,5.596,19.404,0,12.5,0z M13.951,14.939v1.1v0.935h-4.5v3.368l-5.68-4.303l-0.726-0.55l0.726-0.55l5.68-4.303v3.368h4.5V14.939z
		 M21.132,10.048l-5.68,4.303v-3.368h-4.5v-0.935v-1.1V8.014h4.5V4.646l5.68,4.303l0.726,0.55L21.132,10.048z'
						/>
					</g>
				</svg>
			);
			break;
		case 'transfers-diagonal':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 25 25'
					style={{ enableBackground: 'new 0 0 25 25' }}
				>
					<g>
						<path
							d='M3.661,3.661c-4.882,4.882-4.882,12.796,0,17.678s12.796,4.882,17.678,0s4.882-12.796,0-17.678
		S8.543-1.221,3.661,3.661z M15.251,13.199l0.778,0.778l0.661,0.661l-3.182,3.182l2.382,2.382L8.83,21.175l-0.902,0.124l0.124-0.902
		l0.974-7.059l2.382,2.382l3.182-3.182L15.251,13.199z M16.87,4.662l-0.974,7.059L13.515,9.34l-3.182,3.182l-0.661-0.661
		l-0.778-0.778l-0.66-0.66l3.182-3.182L9.034,4.859l7.059-0.974l0.902-0.124L16.87,4.662z'
						/>
					</g>
				</svg>
			);
			break;
		case 'popular':
			icon = (
				<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 25 25'>
					<path
						d='M16.684,24.268c1.204-1.821,3.557-6.95-2.378-13.398c-0.099-0.107-0.288-0.075-0.329,0.062
	c-0.311,1.029-1.384,4.293-2.872,5.638c0,0-0.152-1.864-0.523-2.844c-0.056-0.149-0.272-0.16-0.348-0.019
	c-0.498,0.916-2.203,3.173-2.742,3.841c-0.553,0.686-2.637,4.072-0.911,7.039c0.137,0.235-0.107,0.502-0.36,0.384
	c-1.813-0.851-5.786-3.248-6.184-8.046c-0.404-4.872,2.626-7.971,3.973-9.094c0.207-0.173,0.525-0.004,0.481,0.255
	c-0.195,1.131-0.463,3.304,0.068,4.55c0.741,1.738,4.99-4.949,5.361-6.225c0.343-1.182,1.62-3.92,0.331-6.067
	c-0.112-0.186,0.076-0.401,0.287-0.33c1.466,0.495,5.161,2.38,8.176,9.256l0.519,0.851c0,0,1.68-0.968,0.24-4.656
	c-0.11-0.283,0.26-0.514,0.478-0.296c1.701,1.701,4.908,5.535,5.047,10.386c0.152,5.333-5.693,8.194-7.966,9.103
	C16.776,24.76,16.536,24.492,16.684,24.268z'
					/>
				</svg>
			);
			break;
		case 'all-videos':
			icon = (
				<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 25 25'>
					<g>
						<path
							d='M6.54,3.595H20.6V2.129C20.6,0.958,19.637,0,18.46,0H2.14C0.963,0,0,0.958,0,2.129v16.235
		c0,1.171,0.963,2.129,2.14,2.129h1.259V6.718C3.399,4.996,4.809,3.595,6.54,3.595z'
						/>
						<path
							d='M22.051,3.884h-2.123H6.723c-1.626,0-2.95,1.316-2.95,2.934v12.937v2.311
		c0,1.618,1.324,2.933,2.95,2.933h15.328C23.677,25,25,23.684,25,22.067V6.818C25,5.2,23.677,3.884,22.051,3.884z M23.122,22.067
		c0,0.587-0.481,1.065-1.07,1.065H6.723c-0.591,0-1.071-0.478-1.071-1.065v-2.311V6.818c0-0.588,0.481-1.065,1.071-1.065h13.205
		h2.123c0.59,0,1.07,0.478,1.07,1.065V22.067z'
						/>
						<path
							d='M20.565,10.451l-0.831-0.827c-0.366-0.364-0.966-0.364-1.333,0l-6.462,6.429l-1.345-1.338
		c-0.366-0.364-0.966-0.364-1.332,0l-0.831,0.827c-0.366,0.365-0.366,0.961,0,1.326l2.011,2c0,0,0,0,0,0l0.831,0.827
		c0.366,0.365,0.966,0.365,1.332,0l7.96-7.918C20.931,11.412,20.931,10.815,20.565,10.451z'
						/>
					</g>
				</svg>
			);
			break;
		case 'exclamation':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 35 35'
					style={{ enableBackground: 'new 0 0 35 35' }}
				>
					<path
						style={{
							fillRule: 'evenodd',
							clipRule: 'evenodd',
						}}
						d='M15.806,19.021l-0.495-7.372
	c-0.089-1.435-0.136-2.465-0.136-3.09c0-0.857,0.22-1.518,0.667-1.997c0.447-0.478,1.035-0.714,1.765-0.714
	c0.883,0,1.472,0.305,1.771,0.914c0.3,0.61,0.447,1.492,0.447,2.643c0,0.678-0.036,1.361-0.11,2.06l-0.662,7.588
	c-0.068,0.904-0.22,1.598-0.457,2.081c-0.236,0.479-0.631,0.72-1.172,0.72c-0.557,0-0.94-0.231-1.156-0.699
	C16.052,20.687,15.9,19.977,15.806,19.021 M17.514,29.152c-0.626,0-1.172-0.205-1.64-0.609c-0.468-0.405-0.699-0.972-0.699-1.697
	c0-0.641,0.22-1.182,0.667-1.629c0.447-0.447,0.993-0.668,1.644-0.668c0.647,0,1.198,0.221,1.655,0.668
	c0.458,0.447,0.683,0.988,0.683,1.629c0,0.715-0.231,1.282-0.694,1.692C18.669,28.948,18.133,29.152,17.514,29.152z M17.5,0
	C27.163,0,35,7.837,35,17.5C35,27.163,27.163,35,17.5,35C7.836,35,0,27.163,0,17.5C0,7.837,7.836,0,17.5,0z'
					/>
				</svg>
			);
			break;
		case 'quiz':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 63 61'
					style={{ enableBackground: 'new 0 0 63 61' }}
				>
					<g>
						<path
							d='M30.26,25.03c0.655-0.919,1.599-1.923,2.857-3.014c0.957-0.833,1.674-1.544,2.127-2.156
		c0.466-0.613,0.692-1.299,0.692-2.083c0-1.09-0.453-1.972-1.359-2.646s-2.115-1.005-3.625-1.005c-2.87,0-5.035,0.992-6.508,2.989
		l-3.864-2.45c1.145-1.531,2.618-2.695,4.431-3.516s3.94-1.225,6.382-1.225c2.983,0,5.35,0.625,7.124,1.887
		c1.762,1.262,2.656,2.977,2.656,5.182c0,1.066-0.176,1.997-0.541,2.793c-0.365,0.809-0.793,1.482-1.297,2.046
		c-0.503,0.551-1.171,1.201-2.001,1.923c-1.007,0.882-1.762,1.654-2.253,2.328c-0.491,0.674-0.743,1.47-0.743,2.401h-5.073
		C29.278,27.112,29.606,25.949,30.26,25.03z M29.543,36.914c-0.604-0.588-0.894-1.299-0.894-2.156c0-0.858,0.302-1.568,0.894-2.132
		c0.592-0.564,1.359-0.858,2.291-0.858c0.906,0,1.662,0.282,2.253,0.858c0.592,0.576,0.894,1.286,0.894,2.132
		c0,0.858-0.302,1.568-0.894,2.156c-0.604,0.588-1.347,0.87-2.253,0.87C30.902,37.783,30.134,37.502,29.543,36.914z'
						/>
					</g>
					<path
						d='M41.614,0H21.373C9.592,0,0,9.336,0,20.803v6.518c0,10.843,8.572,19.774,19.473,20.717l-0.088,9.581
	c-0.013,1.36,0.806,2.585,2.09,3.112c0.441,0.184,0.894,0.27,1.359,0.27c0.881,0,1.75-0.331,2.404-0.956L37.8,48.111h3.827
	C53.408,48.111,63,38.776,63,27.308v-6.518C62.987,9.336,53.408,0,41.614,0z M59.211,27.321c0,9.446-7.892,17.128-17.597,17.128
	h-5.362L23.161,56.884l0.113-12.435h-1.901c-9.705,0-17.597-7.682-17.597-17.128v-6.518c0-9.446,7.892-17.128,17.597-17.128h20.253
	c9.705,0,17.597,7.682,17.597,17.128v6.518H59.211z'
					/>
				</svg>
			);
			break;
		case 'fan':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 79 73'
					style={{ enableBackground: 'new 0 0 79 73' }}
				>
					<g>
						<path
							d='M26.265,20.951c2.775,0,5.018-2.298,5.018-5.143s-2.242-5.143-5.018-5.143s-5.018,2.298-5.018,5.143
		C21.26,18.653,23.502,20.951,26.265,20.951z'
						/>
						<path
							d='M16.961,31.059v2.933c1.598,0.076,3.097,1.117,3.766,2.667l3.184,7.352
		c0.074-3.301,2.701-5.968,5.934-5.968c2.515,0,4.658,1.613,5.526,3.886l4.621-2.705l-3.655-10.742l-2.069-6.07
		c-0.446-1.054-0.458-2.26-0.025-3.327c0.446-1.092,1.276-1.917,2.354-2.349c0.458-0.178,0.929-0.267,1.412-0.267
		c1.276,0,2.49,0.635,3.308,1.663l5.191-10.755c0.892-1.575,0.359-3.593-1.177-4.508s-3.506-0.368-4.398,1.206l-8.288,15.783
		c-1.214,2.006-3.209,3.378-5.476,3.771c-0.669,0.114-1.363,0.114-2.032,0c-1.598-0.279-2.961-1.346-3.618-2.87L14.384,6.438
		c-0.706-1.638-2.54-2.552-4.163-1.917c-1.734,0.686-2.54,2.705-1.796,4.432L16.961,31.059z'
						/>
						<path
							d='M52.976,36.925c2.775,0,5.018-2.298,5.018-5.143c0-2.844-2.242-5.143-5.018-5.143
		c-2.775,0-5.018,2.298-5.018,5.143C47.958,34.627,50.201,36.925,52.976,36.925z'
						/>
						<path
							d='M40.872,19.123c-0.619-1.016-1.722-1.663-2.85-1.663c-0.359,0-0.719,0.063-1.078,0.203
		c-1.734,0.686-2.54,2.705-1.796,4.432l1.784,5.219l3.915,11.466l1.673-2.781c0.743-1.308,2.119-2.133,3.593-2.133
		c0.322,0,0.657,0.051,0.966,0.127l-5.984-14.399C41.033,19.415,40.958,19.275,40.872,19.123z'
						/>
						<path
							d='M59.356,35.871c-1.214,2.006-3.209,3.378-5.476,3.771c-0.335,0.063-0.681,0.089-1.016,0.089
		s-0.681-0.025-1.016-0.089c-0.607-0.102-1.177-0.33-1.697-0.648c-0.087,0.419-0.223,0.838-0.434,1.219l-6.058,11.631v8.8h18.41
		V46.5l8.895-18.425l-5.637-3.568L59.356,35.871z'
						/>
						<path
							d='M77.098,11.733c0,0-1.227-1.473-3.172-1.778l4.225-7.53c0.458-0.813,0.149-1.841-0.669-2.26
		C77.259,0.051,77.011,0,76.775,0c-0.545,0-1.065,0.279-1.363,0.787l-5.798,10.006h-1.004c-1.202,0-2.242,0.863-2.503,2.07
		l-2.019,9.561l1.425,0.902l5.637,3.568l1.127,0.711l6.034-6.971C78.324,20.647,80.48,15.301,77.098,11.733z'
						/>
						<path
							d='M29.845,39.008c-2.775,0-5.018,2.298-5.018,5.143c0,0.178,0.037,0.343,0.05,0.521
		c0.26,2.59,2.366,4.622,4.968,4.622s4.708-2.032,4.968-4.622c0.012-0.178,0.05-0.343,0.05-0.521
		C34.863,41.319,32.621,39.008,29.845,39.008z'
						/>
						<path
							d='M48.9,39.77c0.26-0.457,0.384-0.94,0.409-1.435c0.012-0.254,0-0.495-0.037-0.749
		c-0.112-0.711-0.446-1.384-0.979-1.892c-0.173-0.165-0.359-0.317-0.582-0.444c-0.05-0.025-0.087-0.038-0.136-0.063
		c-0.471-0.254-0.966-0.381-1.474-0.381c-1.115,0-2.193,0.597-2.788,1.651l-2.081,3.467l-0.31,0.521l-0.31,0.521l-4.373,7.263
		c-1.214,2.006-3.209,3.378-5.476,3.771c-0.335,0.063-0.681,0.089-1.016,0.089s-0.681-0.025-1.016-0.089
		c-1.598-0.279-2.961-1.346-3.618-2.87l-1.933-4.47l-3.296-7.619c-0.533-1.219-1.685-2.032-2.911-2.108
		c-0.062,0-0.112-0.013-0.173-0.013c-0.359,0-0.719,0.063-1.078,0.203c-1.734,0.686-2.54,2.705-1.796,4.432l6.628,19.834V73h18.41
		V58.867l3.791-7.263l0.458-0.889l0.471-0.889L48.9,39.77z'
						/>
						<polygon points='6.793,21.206 7.644,20.661 15.793,34.024 14.942,34.569 	' />
						<polygon points='0,28.938 3.283,32.646 9.242,27.11 6.157,21.967 	' />
					</g>
				</svg>
			);
			break;
		case 'match':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 73 72'
					style={{ enableBackground: 'new 0 0 73 72' }}
				>
					<g>
						<path
							d='M42.832,12.882l4.679-1.482l1.788-5.378c-1.788-2.103-4.066-3.717-6.651-4.72l-4.691,3.334v4.792
		L42.832,12.882z'
						/>
						<path
							d='M31.098,21.188l-1.862-5.605l-4.679-1.482l-4.691,3.322c0.184,2.725,1.053,5.342,2.535,7.636h5.806
		L31.098,21.188z'
						/>
						<path
							d='M25.464,11.4l4.679,1.482l4.887-3.466V4.625l-4.691-3.334c-2.584,1.004-4.875,2.629-6.651,4.72
		L25.464,11.4z'
						/>
						<path
							d='M39.501,22.873h-6.038l-2.891,3.884l1.788,5.39c2.682,0.669,5.548,0.669,8.231,0l1.788-5.39
		L39.501,22.873z'
						/>
						<path
							d='M43.727,15.583l-1.862,5.605l2.891,3.884h5.806c1.494-2.294,2.364-4.912,2.535-7.636l-4.691-3.322
		L43.727,15.583z'
						/>
						<path
							d='M53.133,14.101c0-0.012,0-0.024,0-0.024l-0.012-0.06c-0.159-1.052-0.416-2.091-0.784-3.095
		c-0.049-0.131-0.098-0.251-0.147-0.382l-0.012-0.024c-0.245-0.621-0.527-1.219-0.845-1.804l-1.078,3.346L53.133,14.101z'
						/>
						<path
							d='M39.427,0.239l-0.992-0.143l-1.078-0.084L36.88,0c0,0-0.282,0-0.404,0c-0.122,0-0.233,0-0.355,0
		l-1.409,0.084l-1.188,0.167l2.952,2.103L39.427,0.239z'
						/>
						<polygon points='32.103,14.83 33.683,20.172 39.305,20.172 41.044,14.95 40.824,14.782 36.476,11.711 	' />
						<path
							d='M24.374,27.76c1.372,1.386,3.001,2.533,4.765,3.382l-1.115-3.382
		C28.024,27.76,24.374,27.76,24.374,27.76z'
						/>
						<path d='M21.655,8.64c-0.943,1.709-1.556,3.561-1.825,5.485l2.964-2.103L21.655,8.64z' />
						<path
							d='M43.824,31.142c0.049-0.024,0.086-0.048,0.135-0.072c0.208-0.108,0.416-0.203,0.625-0.323
		c0.208-0.108,0.404-0.227,0.6-0.347c1.237-0.729,2.388-1.637,3.393-2.653h-3.638L43.824,31.142z'
						/>
						<g>
							<path
								d='M5.377,58.771V32.325c0-0.466-0.38-0.837-0.857-0.837H0v28.119h4.52
			C4.997,59.608,5.377,59.237,5.377,58.771z'
							/>
							<path d='M7.178,39.233v13.121c3.356,0,6.075-2.94,6.075-6.561C13.253,42.172,10.534,39.233,7.178,39.233z' />
							<path
								d='M18.14,19.359H4.017C1.801,19.359,0,21.116,0,23.279v6.871h4.703c1.274,0,2.303,1.004,2.303,2.247
			v4.744c4.52,0.287,8.096,4.063,8.096,8.688s-3.577,8.401-8.096,8.688v4.434c0,1.243-1.029,2.247-2.303,2.247H0v6.871
			c0,2.163,1.801,3.92,4.017,3.92h31.723V56.513c-5.487-0.394-9.823-4.864-9.823-10.325s4.336-9.919,9.823-10.325v-1.219
			C26.787,34.309,19.487,27.808,18.14,19.359z'
							/>
							<path d='M27.877,46.2c0,4.684,3.515,8.485,7.863,8.485V37.703C31.392,37.703,27.877,41.503,27.877,46.2z' />
							<path
								d='M67.623,32.325v26.446c0,0.466,0.38,0.837,0.857,0.837H73V31.489h-4.52
			C68.003,31.489,67.623,31.859,67.623,32.325z'
							/>
							<path
								d='M65.822,52.342V39.233c-3.356,0-6.075,2.94-6.075,6.561C59.747,49.414,62.466,52.342,65.822,52.342
			z'
							/>
							<path
								d='M68.983,19.359H54.86c-1.347,8.449-8.647,14.95-17.601,15.308v1.219
			c5.487,0.394,9.823,4.864,9.823,10.325s-4.336,9.919-9.823,10.325V72h31.723C71.199,72,73,70.243,73,68.08v-6.871h-4.703
			c-1.274,0-2.303-1.004-2.303-2.247v-4.434c-4.52-0.287-8.096-4.063-8.096-8.688s3.577-8.401,8.096-8.688v-4.744
			c0-1.243,1.029-2.247,2.303-2.247H73v-6.871C73,21.116,71.199,19.359,68.983,19.359z'
							/>
							<path d='M37.259,37.703v16.981c4.336,0,7.863-3.8,7.863-8.485C45.123,41.503,41.608,37.703,37.259,37.703z' />
						</g>
					</g>
				</svg>
			);
			break;
		case 'media':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 44 63'
					style={{ enableBackground: 'new 0 0 44 63' }}
				>
					<path
						d='M40.798,22.684c0.462-0.327,0.204-1.033-0.367-0.993c-14.396,0.745-21.859-7.634-23.435-11.882
	c-0.204-0.549-1.047-0.444-1.115,0.144l-0.285,9.137c-0.027,0.275-0.258,0.484-0.544,0.497l-6.253,0.379
	c-2.352,0.144-4.35,1.686-5.016,3.856L0.153,35.691c-0.449,1.451,0.109,3.02,1.387,3.909l3.942,2.745
	c1.155,0.81,1.55,2.288,0.952,3.529L1.513,55.966c-0.585,1.216-0.775,2.588-0.421,3.882c0.72,2.601,2.855,3.19,4.799,3.15
	c2.012-0.052,3.847-1.072,4.948-2.68c1.754-2.562,4.785-6.993,6.484-9.477c0.734-1.085,2.134-1.542,3.412-1.137l5.22,1.66
	c1.495,0.471,3.14-0.013,4.092-1.203l7.313-9.046c1.278-1.582,1.536-3.699,0.68-5.516l-4.037-8.614
	c-0.109-0.235-0.027-0.523,0.19-0.68L40.798,22.684z M7.616,39.691c-0.054,0.013-0.095,0-0.136-0.026l-1.319-0.68
	c-0.082-0.039-0.122-0.105-0.095-0.183l1.278-5.111c0-0.026-0.014-0.039-0.027-0.052c-0.027-0.013-0.041,0-0.054,0.026l-3.14,4.118
	c-0.027,0.026-0.068,0.052-0.109,0.065c-0.041,0.013-0.095,0-0.136-0.026l-1.155-0.588c-0.095-0.052-0.122-0.118-0.068-0.196
	l5.057-6.601c0.027-0.026,0.068-0.052,0.109-0.052s0.095,0,0.136,0.026l1.291,0.667c0.082,0.039,0.122,0.105,0.095,0.183
	l-1.237,5.085c-0.014,0.026,0,0.039,0.014,0.039c0.027,0.013,0.041,0,0.068-0.013l3.113-4.078c0.027-0.026,0.068-0.052,0.109-0.065
	s0.095,0,0.136,0.026l1.155,0.588c0.041,0.026,0.068,0.052,0.082,0.092c0.014,0.039,0,0.078-0.027,0.105l-5.057,6.601
	C7.698,39.665,7.671,39.691,7.616,39.691z M15.229,38.358l-0.585,0.745c-0.014,0.039-0.054,0.052-0.109,0.065s-0.095,0-0.136-0.026
	l-1.618-0.837c-0.041-0.026-0.082-0.013-0.095,0.013l-1.427,1.856c-0.014,0.039-0.014,0.065,0.041,0.092l2.732,1.399
	c0.041,0.026,0.068,0.052,0.082,0.092c0.014,0.039,0,0.078-0.027,0.105l-0.557,0.745c-0.027,0.026-0.068,0.052-0.109,0.065
	c-0.041,0.013-0.082,0-0.136-0.026l-4.132-2.118c-0.095-0.052-0.122-0.118-0.068-0.196l5.057-6.601
	c0.027-0.026,0.068-0.052,0.109-0.052c0.041,0,0.095,0,0.136,0.026l4.119,2.118c0.041,0.026,0.068,0.052,0.082,0.092
	c0.014,0.039,0,0.078-0.027,0.105l-0.557,0.745c-0.027,0.026-0.068,0.052-0.109,0.065s-0.082,0-0.136-0.026l-2.746-1.412
	c-0.041-0.026-0.082-0.013-0.095,0.013l-1.427,1.843c-0.014,0.039-0.014,0.065,0.041,0.092l1.618,0.837
	c0.041,0.026,0.068,0.052,0.082,0.092C15.269,38.292,15.256,38.332,15.229,38.358z M20.231,46.201l-1.087-0.562
	c-0.095-0.052-0.122-0.118-0.082-0.196l2.325-4.588c0.014-0.026,0.014-0.052,0-0.052c-0.014-0.013-0.041,0-0.082,0.013l-3.956,3.752
	c-0.068,0.065-0.15,0.065-0.245,0.026l-1.087-0.562c-0.109-0.052-0.136-0.118-0.095-0.196l3.358-7.464l0.014-0.026
	c0.041-0.078,0.122-0.092,0.217-0.039l1.223,0.627c0.095,0.052,0.122,0.118,0.082,0.196l-2.229,4.523
	c-0.014,0.026-0.014,0.039,0.014,0.039c0.014,0.013,0.041,0,0.054-0.013l3.929-3.66c0.068-0.065,0.15-0.078,0.245-0.026l1.006,0.523
	c0.109,0.052,0.136,0.118,0.095,0.196l-2.257,4.51c0,0.026,0,0.039,0.027,0.052c0.014,0.013,0.041,0,0.054-0.013l3.915-3.66
	c0.068-0.065,0.15-0.065,0.245-0.026l1.196,0.614c0.041,0.026,0.068,0.052,0.082,0.091c0.014,0.039,0,0.078-0.041,0.105
	l-6.661,5.778C20.408,46.253,20.326,46.253,20.231,46.201z M31.283,44.384c-0.041,0.065-0.109,0.17-0.204,0.314l-0.163,0.235
	c-0.027,0.026-0.068,0.052-0.122,0.052c-0.054,0.013-0.095,0-0.136-0.026l-1.142-0.588c-0.041-0.026-0.068-0.052-0.082-0.092
	s0-0.078,0.027-0.105l0.19-0.261c0.027-0.039,0.068-0.092,0.109-0.17c0.122-0.222,0.15-0.444,0.082-0.641s-0.217-0.366-0.449-0.484
	c-0.285-0.144-0.571-0.17-0.87-0.092c-0.299,0.078-0.53,0.275-0.707,0.588c-0.109,0.209-0.136,0.431-0.068,0.68
	c0.068,0.248,0.217,0.614,0.476,1.111c0.258,0.497,0.462,0.902,0.585,1.19c0.122,0.301,0.19,0.588,0.204,0.85
	c0.014,0.275-0.068,0.536-0.204,0.797c-0.041,0.065-0.095,0.157-0.19,0.288c-0.435,0.562-0.992,0.889-1.686,0.993
	c-0.693,0.105-1.387-0.013-2.08-0.366c-0.639-0.327-1.06-0.732-1.278-1.229c-0.217-0.484-0.177-0.98,0.082-1.464
	c0.041-0.065,0.109-0.17,0.19-0.288l0.19-0.261c0.027-0.026,0.068-0.052,0.109-0.065s0.095,0,0.136,0.026l1.155,0.588
	c0.095,0.052,0.122,0.118,0.068,0.196l-0.163,0.196c-0.027,0.026-0.068,0.092-0.109,0.157c-0.122,0.235-0.163,0.458-0.095,0.654
	c0.068,0.209,0.217,0.366,0.435,0.484c0.285,0.144,0.585,0.183,0.897,0.092s0.544-0.275,0.707-0.575
	c0.122-0.209,0.136-0.431,0.068-0.68c-0.082-0.235-0.258-0.614-0.53-1.15c-0.217-0.405-0.394-0.758-0.503-1.046
	c-0.122-0.288-0.177-0.588-0.19-0.902c-0.014-0.314,0.068-0.627,0.245-0.941c0.027-0.052,0.095-0.157,0.19-0.288
	c0.408-0.536,0.952-0.863,1.631-0.954c0.666-0.105,1.346,0.026,2.039,0.379c0.639,0.327,1.06,0.732,1.264,1.229
	C31.609,43.377,31.568,43.874,31.283,44.384z'
					/>
					<path
						d='M36.258,1.639c-5.764-2.941-12.642-1.791-17.06,2.392c-1.074,1.02-1.319,2.614-0.53,3.856
	c2.189,3.477,5.369,6.484,9.42,8.549c4.064,2.065,8.442,2.928,12.656,2.706c1.427-0.078,2.692-1.046,2.977-2.405
	C44.944,10.841,42.09,4.606,36.258,1.639z'
					/>
				</svg>
			);
			break;
		default:
			return null;
	}

	return icon;
};

export default NavIcon;
