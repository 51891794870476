import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getMediaWatch, incrementClickCount } from '../../../../actions/contentfulActions';

// Components
import DayAndMonth from '../../../base/Elements/DayAndMonth';
import SVGIcon from '../../../base/Elements/SVGIcon';

// Utils
import abbrNum from '../../../../utils/abbrNum';
import truncateText from '../../../../utils/truncateText';
import useWindowSize from '../../../../utils/useWindowSize';

const MediaWatch = props => {
	useEffect(() => {
		props.getMediaWatch();
	}, []);

	const clickHandler = link => {
		props.incrementClickCount(link);
	};

	const [width] = useWindowSize();

	let limit;

	switch (true) {
		case width < 440:
			limit = 50;
			break;
		case width < 576:
			limit = 55;
			break;
		case width < 768:
			limit = 30;
			break;
		default:
			limit = 50;
			break;
	}

	return (
		<div id='media-watch'>
			{props.mediaWatchItems && props.mediaWatchItems.length > 0 && (
				<div className='media-watch'>
					<div className='container'>
						<h2>Media Watch</h2>
						<div className='media-watch__inner'>
							{props.mediaWatchItems.map((item, index) => (
								<a
									key={index}
									href={item.link}
									className='media-watch__inner--item'
									target='_blank'
									rel='nofollow noopener'
									onClick={() => clickHandler(item.link)}
								>
									<div className='media-watch__inner--item_image'>
										<div>
											<img src={item.image.src} alt={item.image.alt} title={item.image.title} />
										</div>
									</div>
									<div className='media-watch__inner--item_middle'>
										<div className='item-date'>
											<DayAndMonth date={item.date} />
										</div>
										<p>{truncateText(item.headline, limit)}</p>
									</div>
									<div className='media-watch__inner--item_bottom'>
										<div className='click-count'>
											<SVGIcon icon='share-arrow' />
											<span>{item.clicks ? abbrNum(item.clicks, 1) : 0}</span>
										</div>
										<SVGIcon icon='globe' />
									</div>
								</a>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		mediaWatchItems: state.contentful.mediaWatch,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getMediaWatch: () => dispatch(getMediaWatch()),
		incrementClickCount: link => dispatch(incrementClickCount(link)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaWatch);
