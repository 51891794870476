import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Images
import ArrowLeft from '../../../../img/arrow-left.png';
import ArrowRight from '../../../../img/arrow-right.png';

// Utils
import abbrNum from '../../../../utils/abbrNum';
import formatDate from '../../../../utils/formatDate';
import truncateText from '../../../../utils/truncateText';

const PlaylistSlider = props => {
	const [position, setPosition] = useState(0);

	const [maxWidth, setMaxWidth] = useState(0);

	const [sliderWidth, setSliderWidth] = useState(0);

	const [active, setActive] = useState({
		left: false,
		right: true,
	});

	const widthHandler = () => {
		const sliders = document.querySelectorAll(
			`.playlist-slider__single.index-${props.index}`
		);

		let widthLimit = 0;
		let width = 0;

		for (const slider of sliders) {
			widthLimit += slider.getBoundingClientRect().width + 10;
			width = slider.getBoundingClientRect().width;
		}

		setMaxWidth(widthLimit);
		setSliderWidth(width);
	};

	let resizeId;
	const resize = () => {
		clearTimeout(resizeId);
		resizeId = setTimeout(widthHandler, 500);
	};

	useEffect(() => {
		const sliders = document.querySelectorAll(`.playlist-slider__single`);

		let widthLimit = 0;
		let width = 0;

		for (const slider of sliders) {
			widthLimit += slider.getBoundingClientRect().width + 10;
			width = slider.getBoundingClientRect().width;
		}

		setMaxWidth(widthLimit);
		setSliderWidth(width);

		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, [widthHandler]);

	const clickHandler = layout => {
		if (layout === 'left') {
			if (position >= 0 || position + (sliderWidth + 10) >= 0) {
				setActive({ left: false, right: true });
				setPosition(0);
				return;
			}
			setActive({ left: true, right: true });
			setPosition(position + (sliderWidth + 10));
			return;
		}

		if (layout === 'right') {
			const container = document.querySelector(
				'.playlist-container .container'
			);
			const limit =
				maxWidth -
				Math.abs(position) -
				container.getBoundingClientRect().width;

			const sliderWrapper = document.querySelector(
				'.playlist-slider-wrapper'
			);

			if (window.innerWidth >= 768 && limit < 172) {
				setActive({ left: true, right: false });
				// setPosition(position - limit - 172);
				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					)
				);
				return;
			}

			if (
				window.innerWidth < 768 &&
				window.innerWidth >= 576 &&
				limit < 193
			) {
				setActive({ left: true, right: false });
				// setPosition(position - limit - 193);
				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					)
				);
				return;
			}

			if (window.innerWidth < 576 && limit < 300) {
				setActive({ left: true, right: false });
				// setPosition(position - limit - 300);
				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					)
				);
				// setPosition(position - limit + (window.innerWidth - 30));
				return;
			}

			setActive({ left: true, right: true });
			setPosition(position - (sliderWidth + 10));
		}
	};

	return (
		<div
			className={`playlist-container ${
				props.classes ? props.classes : ''
			}`}
		>
			<div className='container'>
				<div className='playlist-wrapper'>
					<h3>Transfers & Rumours</h3>
					<div className='playlist-nav-wrapper'>
						<div className='playlist-slider-wrapper'>
							<div
								className='playlist-slider'
								style={{
									position: 'relative',
									left: `${position}px`,
									width: `${maxWidth}px`,
								}}
							>
								{props.playlist &&
									props.playlist.map((video, index) => {
										const thumbnail =
											video.thumbnails &&
											(video.thumbnails.maxres ||
												video.thumbnails.standard ||
												video.thumbnails.high ||
												video.thumbnails.medium ||
												video.thumbnails.default);

										if (
											video.description
												.toLowerCase()
												.includes(
													'this video is private'
												) ||
											video.description
												.toLowerCase()
												.includes(
													'this video is unavailable'
												)
										) {
											return;
										}

										return (
											<Link
												key={index}
												to={`/videos/single/${video.videoId}`}
												className={`playlist-slider__single transfers`}
											>
												<div className='playlist-slider__single--image'>
													<img
														src={
															thumbnail &&
															thumbnail.url
														}
														alt='YouTube Video Thumbnail'
													/>
												</div>
												<div className='playlist-slider__single--details'>
													<p>
														{window.innerWidth < 576
															? truncateText(
																	video.title,
																	25
															  )
															: truncateText(
																	video.title,
																	35
															  )}
													</p>
													<div className='playlist-slider__single--details_stats'>
														<span>
															{formatDate(
																new Date(
																	video.publishedAt
																)
															)}
														</span>
														<span>
															{video.views &&
																abbrNum(
																	video.views,
																	0
																)}{' '}
															views
														</span>
													</div>
												</div>
											</Link>
										);
									})}
							</div>
						</div>
						<div className='playlist-wrapper__navigation'>
							<div
								onClick={() => clickHandler('left')}
								className={`playlist-wrapper__navigation--left ${
									active.left ? 'active' : ''
								}`}
							>
								<img src={ArrowLeft} alt='Arrow Left' />
							</div>
							<div
								onClick={() => clickHandler('right')}
								className={`playlist-wrapper__navigation--right ${
									active.right ? 'active' : ''
								}`}
							>
								<img src={ArrowRight} alt='Arrow Right' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlaylistSlider;
