import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getMustWatch } from '../../../../actions/rankingActions';

// Components
import VideoCard from './VideoCard';

const MustWatch = props => {
	useEffect(() => {
		props.getMustWatch();
	}, []);

	const videos =
		props.mustWatch &&
		props.mustWatch.map((video, index) => {
			const thumbnail =
				video.thumbnails.maxres ||
				video.thumbnails.standard ||
				video.thumbnails.high ||
				video.thumbnails.medium ||
				video.thumbnails.default;

			return (
				<VideoCard
					key={index}
					videoId={video.videoId}
					image={thumbnail.url}
					title={video.title}
					published={video.publishedAt}
					views={+video.views}
					description={video.description}
					width={props.width}
					pageViews={video.pageViews}
				/>
			);
		});

	return (
		<div id='must-watch' className='must-watch'>
			<div className='container'>
				<h1>Must Watch</h1>
				<div className='must-watch__container'>{videos}</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		mustWatch: state.ranking.mustWatch,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getMustWatch: () => dispatch(getMustWatch()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MustWatch);
