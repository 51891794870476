import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Images
import Logo from '../../../img/logo.png';
import RedCrumb from '../../../img/red-breadcrumb.svg';
import LightGrayCrumb from '../../../img/light-gray-breadcrumb.svg';

const TeamHeader = props => {
	const [width, setWidth] = useState([false, false]);

	useEffect(() => {
		const content = document.querySelectorAll(
			'.breadcrumb-dynamic-content > span'
		);

		if (content) {
			const newWidth = [];
			content.forEach((element, index) => {
				newWidth[index] = Math.ceil(
					element.getBoundingClientRect().width
				);
				setWidth(newWidth);
			});
		}
	}, [props.profile, props.team]);

	return (
		<div className='articles-wrapper'>
			<Helmet>
				<title>
					{props.profile
						? `${props.profile.current.details.name} | The United Stand`
						: 'Our Team | The United Stand'}
				</title>
			</Helmet>
			<div className='container'>
				<div
					className={`articles-top single ${
						props.profile ? 'profile' : ''
					}`}
				>
					<div className='articles-top__left'>
						<div className='articles-top__left--breadcrumbs'>
							<Link
								to='/'
								className='articles-top__left--breadcrumbs_icon'
								style={{ maxWidth: '95px' }}
							>
								<img
									src={RedCrumb}
									alt='Red Arrow pointing right'
								/>
								<div className='breadcrumb-content'>
									<img
										src={Logo}
										alt='The United Stand Logo'
										style={{ maxWidth: '15px' }}
									/>
									<span>Home</span>
								</div>
							</Link>
							<Link
								to={`/our-team`}
								className={`articles-top__left--breadcrumbs_icon`}
								style={{ maxWidth: width[0] + 60 }}
							>
								<img
									src={LightGrayCrumb}
									alt='Gray arrow pointing right'
								/>
								<div className='breadcrumb-dynamic-content'>
									<span>Our Team</span>
								</div>
							</Link>
							{props.profile && (
								<Link
									to={`/our-team/${props.profile.current.details.slug}`}
									className={`articles-top__left--breadcrumbs_icon`}
									style={{ width: width[1] + 80 }}
								>
									<img
										src={LightGrayCrumb}
										alt='Gray arrow pointing right'
									/>
									<div className='breadcrumb-dynamic-content'>
										<span>
											{props.profile.current.details.name}
										</span>
									</div>
								</Link>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		team: state.contentful.team,
		profile: state.contentful.profile,
	};
};

export default connect(mapStateToProps)(TeamHeader);
