import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// Images
import LargeBlocked from '../../../img/large-blocked.png';

const ContentRec = ({ classes, ...props }) => {
	const contentRec = useRef(null);

	useEffect(() => {
		// setTimeout(() => {
		if (contentRec.current) {
			const script = document.createElement('script');
			script.src = 'https://assets.revcontent.com/master/delivery.js';
			script.defer = 'defer';
			contentRec.current.appendChild(script);
		}
		// }, 2500);
	}, [contentRec.current]);

	useEffect(() => {
		// setTimeout(() => {
		if (
			contentRec.current &&
			props.adBlockDetected
			// (widgetRef.current && widgetRef.current.children[0] && widgetRef.current.children[0].innerHTML.replace(/\s/g, '').length === 0) ||
			// (widgetRef.current && widgetRef.current.innerHTML.replace(/\s/g, '').length === 0)
		) {
			const container = document.createElement('div');
			container.classList.add('blocked-image');

			const imageWrapper = document.createElement('div');
			container.appendChild(imageWrapper);

			const image = document.createElement('img');
			image.src = LargeBlocked;
			image.alt = 'David de Gea blocking a football';

			imageWrapper.appendChild(image);

			contentRec.current.appendChild(container);
		}
		// }, 5000);
	}, [contentRec.current, props.adBlockDetected]);

	return (
		<div className={`content-rec-wrapper ${classes ? classes : ''}`}>
			<div className='container'>
				<div ref={contentRec} className='content-rec'>
					<h2>Sponsored Links</h2>
					<div
						id='rc-widget-939e7f'
						data-rc-widget
						data-widget-host='habitat'
						data-endpoint='//trends.revcontent.com'
						data-widget-id='142612'
					></div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		adBlockDetected: state.general.adBlockDetected,
	};
};

export default connect(mapStateToProps)(ContentRec);
