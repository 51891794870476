import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLatestQuizzes } from '../../../../actions/quizActions';
import { getAdverts } from '../../../../actions/contentfulActions';

// Components
import ArticleHeader from '../Elements/ArticleHeader';
import TopStory from '../TopStory';
import ArticleGrid from '../Elements/ArticleGrid';
import FeaturedAuthors from '../Elements/FeaturedAuthors';
import TrendingContent from '../Elements/TrendingContent';
import RecommendedBar from '../Elements/RecommendedBar';
import MediaWatch from '../Elements/MediaWatch';
import ContentRec from '../../../base/Elements/ContentRec';
import LeagueTable from '../Elements/LeagueTable';
import Sponsor from '../Elements/Sponsor';
import LatestVideo from '../Elements/LatestVideo';

const Quizzes = props => {
	useEffect(() => {
		props.getLatestQuizzes();
		props.getAdverts();
	}, []);

	return (
		<main>
			<ArticleHeader />
			<TopStory quiz={true} />
			<div className='articles-home'>
				<div className='container'>
					<div className='articles-home__grid'>
						<div className='articles-home__grid--left'>
							<div className='articles-home__grid--left_bar'>
								{/* <TrendingContent /> */}
								<Sponsor adverts={props.adverts} top={true} classes='hide-mobile' />
								<LatestVideo />
								<LeagueTable />
								<RecommendedBar quiz={true} carousel={true} classes='hide-desktop' id='top-stories' />
								<Sponsor adverts={props.adverts} bottom={true} classes='margin-mobile' />
							</div>
						</div>
						<div className='articles-home__grid--middle'>
							<ArticleGrid quiz={true} adverts={props.adverts} />
						</div>
						<div className='articles-home__grid--right'>
							<div className='articles-home__grid--right_bar'>
								<RecommendedBar quiz={true} classes='show-desktop' />
								<TrendingContent classes='hide-mobile' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<MediaWatch />
			<TrendingContent id='trending-content' classes='hide-desktop' />
			<ContentRec />
			<FeaturedAuthors />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		latestQuizzes: state.quiz.latestQuizzes,
		adverts: state.contentful.adverts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestQuizzes: () => dispatch(getLatestQuizzes()),
		getAdverts: () => dispatch(getAdverts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Quizzes);
