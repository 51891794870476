import * as actionTypes from '../actions/types';

const initialState = {
	searchFilter: 'articles',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_FEATURED_BANNER:
			return {
				...state,
				featuredBanner: action.payload,
			};
		case actionTypes.GET_FEATURED_ITEM:
			return {
				...state,
				featuredItem: action.payload,
			};
		case actionTypes.GET_LATEST_POSTS:
			return {
				...state,
				latestPosts: action.payload,
			};
		case actionTypes.GET_TOP_STORY:
			return {
				...state,
				topStory: action.payload,
			};
		case actionTypes.GET_AUTHORS:
			return {
				...state,
				authors: action.payload,
			};
		case actionTypes.GET_SINGLE_ARTICLE:
			return {
				...state,
				single: action.payload,
			};
		case actionTypes.GET_TOP_LIKED_ARTICLES:
			return {
				...state,
				topLiked: action.payload,
			};
		case actionTypes.GET_AUTHOR_POSTS:
			return {
				...state,
				authorPosts: action.payload,
			};
		case actionTypes.GET_TEAM:
			return {
				...state,
				team: action.payload,
			};
		case actionTypes.GET_SINGLE_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case actionTypes.CLEAR_SINGLE_PROFILE:
			const { profile, ...newState } = state;
			return newState;
		case actionTypes.SEARCH_ARTICLES:
			return {
				...state,
				search: action.payload,
			};
		case actionTypes.SET_FILTER:
			return {
				...state,
				searchFilter: action.payload,
			};
		case actionTypes.GET_PRODUCTS:
			return {
				...state,
				products: action.payload,
			};
		case actionTypes.GET_TESTIMONIALS:
			return {
				...state,
				testimonials: action.payload,
			};
		case actionTypes.GET_ADVERTS:
			return {
				...state,
				adverts: action.payload,
			};
		case actionTypes.GET_ARTICLE_ADVERTS:
			return {
				...state,
				articleAdverts: action.payload,
			};
		case actionTypes.GET_MOBILE_AD:
			return {
				...state,
				mobileAd: action.payload,
			};
		case actionTypes.GET_PUBLISHED_ARTICLE_MONTHS:
			return {
				...state,
				publishedArticleMonths: action.payload,
			};
		case actionTypes.GET_MEDIA_WATCH:
			return {
				...state,
				mediaWatch: action.payload,
			};
		case actionTypes.GET_NEXT_ARTICLE:
			return {
				...state,
				nextArticle: action.payload,
			};
		case actionTypes.CLEAR_SINGLE_ARTICLE:
			return {
				...state,
				single: null,
			};
		default:
			return state;
	}
};
