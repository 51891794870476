import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getTransferPlaylist } from '../../../../actions/youtubeActions';

// Components
import VideoHeader from '../Elements/VideoHeader';
import FeaturedVideos from '../Elements/FeaturedVideos';
import VideoGrid from '../Elements/VideoGrid';
import ContentRec from '../../../base/Elements/ContentRec';

const Transfers = props => {
	useEffect(() => {
		props.getTransferPlaylist();
	}, []);

	return (
		<main>
			<VideoHeader />
			<FeaturedVideos
				heading='Latest Transfer Videos'
				videos={props.transferPlaylist && props.transferPlaylist}
				transfers={true}
			/>
			<VideoGrid
				title='All Transfer Videos'
				videos={props.transferPlaylist && props.transferPlaylist}
				transfers={true}
			/>
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		transferPlaylist: state.youtube.transferPlaylist,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTransferPlaylist: () => dispatch(getTransferPlaylist()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
