import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Images
import Logo from '../../../../img/logo.png';
import RedCrumb from '../../../../img/red-breadcrumb.svg';
import LightGrayCrumb from '../../../../img/light-gray-breadcrumb.svg';

const ArticleHeader = props => {
	const [width, setWidth] = useState([100, 100]);

	useEffect(() => {
		const content = document.querySelectorAll('.breadcrumb-dynamic-content > span');

		if (content) {
			const newWidth = [];
			content.forEach((element, index) => {
				newWidth[index] = Math.ceil(element.getBoundingClientRect().width);
				setWidth(newWidth);
			});
		}
	}, [props]);

	return (
		<div className='articles-wrapper'>
			<div className='container'>
				<div className='articles-top single'>
					<div className='articles-top__left'>
						<div className='articles-top__left--breadcrumbs'>
							<Link to='/' className='articles-top__left--breadcrumbs_icon' style={{ maxWidth: '95px' }}>
								<img src={RedCrumb} alt='Red Arrow pointing right' />
								<div className='breadcrumb-content'>
									<img src={Logo} alt='The United Stand Logo' style={{ maxWidth: '15px' }} />
									<span>Home</span>
								</div>
							</Link>
							<Link
								to={`/articles`}
								className={`articles-top__left--breadcrumbs_icon`}
								style={{ maxWidth: width[0] + 60 }}
							>
								<img src={LightGrayCrumb} alt='Gray arrow pointing right' />
								<div className='breadcrumb-dynamic-content'>
									<span>Articles</span>
								</div>
							</Link>
							{props.article && props.article.authorSlug && (
								<Link
									to={`/articles/author/${props.article && props.article.authorSlug}`}
									className={`articles-top__left--breadcrumbs_icon`}
									style={{ width: width[1] + 80 }}
								>
									<img src={LightGrayCrumb} alt='Gray arrow pointing right' />
									<div className='breadcrumb-dynamic-content'>
										<span>{props.article && props.article.authorName}</span>
									</div>
								</Link>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(ArticleHeader);
