import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-css';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { getLatestSocialPosts } from '../../../actions/socialActions';
import { setModal } from '../../../actions/generalActions';

// Utils
import timeElapsed from '../../../utils/timeElapsed';

// Components
import SocialHeader from './Elements/SocialHeader';
import RecommendedBar from '../Videos/RecommendedBar';
import ShareModal from '../../base/Elements/ShareModal';
import ContentRec from '../../base/Elements/ContentRec';

const Social = props => {
	const [link, setLink] = useState(null);

	useEffect(() => {
		props.getLatestSocialPosts();
	}, []);

	const clickHandler = url => {
		setLink(url);
		props.setModal();
	};

	const breakpointColumnsObj = {
		default: 4,
		1440: 3,
		1200: 4,
		992: 3,
		768: 2,
		440: 1,
	};

	const posts =
		props.all &&
		props.all.map((post, index) => {
			if (index >= 20) {
				return;
			}

			if (post.content.type === 'twitter') {
				const { tweetId, publishedAt, text, media, profileImage } = post.content;

				return (
					<React.Fragment key={index}>
						<div className='tweet'>
							<div className='tweet__top'>
								<div className='tweet__top--left'>
									<img src={profileImage} alt='Profile picture for The United Stand' />
									<div className='tweet__top--left_text'>
										<p>The United Stand</p>
										<span>{timeElapsed(publishedAt)}</span>
									</div>
								</div>
								<div className='tweet__top--right'>
									<FontAwesomeIcon icon={['fab', 'twitter']} />
								</div>
							</div>
							<div className='tweet__content'>
								{media &&
									media.map((item, index) => (
										<img key={index} src={item.media_url_https} alt='Tweet image' />
									))}
								<ReactMarkdown source={text} linkTarget='_blank' />
							</div>
							<div className='tweet__bottom'>
								<a
									href={`https://twitter.com/intent/retweet?tweet_id=${tweetId}`}
									target='_blank'
									rel='noopener noreferrer'
								>
									<FontAwesomeIcon icon='retweet' />
								</a>
								<a
									href={`https://twitter.com/intent/like?tweet_id=${tweetId}`}
									target='_blank'
									rel='noopener noreferrer'
								>
									<FontAwesomeIcon icon='heart' />
								</a>
								<div
									onClick={() =>
										clickHandler(`https://twitter.com/UnitedStandMUFC/status/${tweetId}`)
									}
								>
									<FontAwesomeIcon icon='share' />
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}

			if (post.content.type === 'facebook') {
				const { publishedAt, message, fullPicture, profilePic, permalink } = post.content;

				return (
					<React.Fragment key={index}>
						<div className='facebook'>
							<div className='facebook__top'>
								<div className='facebook__top--left'>
									<img src={profilePic} alt='Profile picture for The United Stand' />
									<div className='facebook__top--left_text'>
										<p>The United Stand</p>
										<span>{timeElapsed(publishedAt)}</span>
									</div>
								</div>
								<div className='facebook__top--right'>
									<FontAwesomeIcon icon={['fab', 'facebook-square']} />
								</div>
							</div>
							<div className='facebook__content'>
								{fullPicture && <img src={fullPicture} alt='Post picture' />}
								<ReactMarkdown source={message} linkTarget='_blank' />
							</div>
							<div className='facebook__bottom'>
								<a href={permalink} target='_blank' rel='noopener noreferrer'>
									<FontAwesomeIcon icon='thumbs-up' />
								</a>
								<div onClick={() => clickHandler(permalink)}>
									<FontAwesomeIcon icon='share' />
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}

			if (post.content.type === 'instagram') {
				const { publishedAt, caption, thumbnailUrl, profilePic, permalink, mediaUrl } = post.content;

				return (
					<React.Fragment key={index}>
						<div className='instagram'>
							<div className='instagram__top'>
								<div className='instagram__top--left'>
									<img src={profilePic} alt='Profile picture for The United Stand' />
									<div className='instagram__top--left_text'>
										<p>The United Stand</p>
										<span>{timeElapsed(publishedAt)}</span>
									</div>
								</div>
								<div className='instagram__top--right'>
									<FontAwesomeIcon icon={['fab', 'instagram']} />
								</div>
							</div>
							<div className='instagram__content'>
								{thumbnailUrl ? (
									<img src={thumbnailUrl} alt='Post picture' />
								) : (
									<img src={mediaUrl} alt='Post picture' />
								)}
								<ReactMarkdown source={caption} linkTarget='_blank' />
							</div>
							<div className='instagram__bottom'>
								<a href={permalink} target='_blank' rel='noopener noreferrer'>
									<FontAwesomeIcon icon='heart' />
								</a>
								<div onClick={() => clickHandler(permalink)}>
									<FontAwesomeIcon icon='share' />
								</div>
							</div>
						</div>
					</React.Fragment>
				);
			}
		});

	return (
		<main>
			<ShareModal link={link} />
			<SocialHeader />
			<div className='social-grid-wrapper'>
				<div className='container'>
					<div className='social-grid'>
						<div className='social-grid__left'>
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className='my-masonry-grid'
								columnClassName='my-masonry-grid_column'
							>
								{posts}
							</Masonry>
						</div>
						<div className='social-grid__right'>
							<RecommendedBar />
						</div>
					</div>
				</div>
			</div>
			<ContentRec classes='dark no-border' />
			{/* <div className='bottom-bar gray'></div> */}
		</main>
	);
};

const mapStateToProps = state => {
	return {
		all: state.social.all,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestSocialPosts: () => dispatch(getLatestSocialPosts()),
		setModal: () => dispatch(setModal()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Social);
