import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getTopPodcasts, getDatedPodcasts } from '../../../actions/podcastActions';

// Components
import RecommendedBar from '../Videos/RecommendedBar';
import TopPodcast from './Elements/TopPodcast';
import PodcastSlider from './Elements/PodcastSlider';
import ContentRec from '../../base/Elements/ContentRec';

const MainPodcasts = props => {
	useEffect(() => {
		props.getTopPodcasts();
		props.getDatedPodcasts();
	}, []);

	const podcasts =
		props.top && props.top.map((podcast, index) => <TopPodcast key={index} podcast={podcast.details} />);

	const podcastSliders =
		props.dated &&
		props.dated.map((playlist, index) => <PodcastSlider playlist={playlist} key={index} index={index} />);

	return (
		<React.Fragment>
			<div className='main-podcasts-wrapper'>
				<div className='container'>
					<div className='podcasts-grid'>
						<div className='podcasts-grid__left'>
							<div id='top-podcasts' className='top-podcasts'>
								<h1>Top Podcasts</h1>
								<div className='top-podcasts__grid'>{podcasts}</div>
							</div>
							<div id='podcast-slider' className='podcast-slider'>
								{podcastSliders}
							</div>
						</div>
						<div className='podcasts-grid__right'>
							<RecommendedBar />
						</div>
					</div>
				</div>
			</div>
			<ContentRec classes='darker no-border' />
			{/* <div className='bottom-bar dark'></div> */}
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		top: state.podcast.top,
		dated: state.podcast.dated,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTopPodcasts: () => dispatch(getTopPodcasts()),
		getDatedPodcasts: () => dispatch(getDatedPodcasts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPodcasts);
