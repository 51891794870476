import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLatestPodcasts } from '../../../actions/podcastActions';

// Components
import PodcastHeader from './Elements/PodcastHeader';
import LatestPodcast from './Elements/LatestPodcast';
import MainPodcasts from './MainPodcasts';

const Podcasts = props => {
	useEffect(() => {
		props.getLatestPodcasts();
	}, []);

	return (
		<main>
			<PodcastHeader />
			<LatestPodcast
				title='Latest Podcast'
				podcast={props.latest && props.latest[0].details}
			/>
			<MainPodcasts />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		latest: state.podcast.latest,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestPodcasts: () => dispatch(getLatestPodcasts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Podcasts);
