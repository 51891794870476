import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

// Images
import ArrowLeft from '../../../../img/arrow-left.png';
import ArrowRight from '../../../../img/arrow-right.png';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';
import truncateText from '../../../../utils/truncateText';
import abbrNum from '../../../../utils/abbrNum';

// Components
import Spinner from '../../../base/Elements/Spinner';

const FeaturedVideos = props => {
	const [width, setWidth] = useState(1000);
	const [totalWidth, setTotalWidth] = useState(0);
	const [position, setPosition] = useState(0);
	const [active, setActive] = useState({
		left: false,
		right: true,
	});

	const widthHandler = useCallback(
		event => {
			const featuredContainer = document.querySelector(
				'.featured-container'
			);
			setWidth(featuredContainer.getBoundingClientRect().width - 30);

			setTotalWidth(featuredContainer.getBoundingClientRect().width * 5);

			const newPosition =
				Math.round(position / (totalWidth / 5)) *
					featuredContainer.getBoundingClientRect().width -
				Math.abs(Math.round(position / (totalWidth / 5))) * 10;

			setPosition(newPosition);
			return;
		},
		[position, totalWidth]
	);

	let resizeId;
	const resize = () => {
		clearTimeout(resizeId);
		resizeId = setTimeout(widthHandler, 500);
	};

	useEffect(() => {
		const featuredContainer = document.querySelector('.featured-container');
		setWidth(featuredContainer.getBoundingClientRect().width - 30);

		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, [widthHandler]);

	useEffect(() => {
		const featuredContainer = document.querySelector('.featured-container');
		setTotalWidth(featuredContainer.getBoundingClientRect().width * 5);
	}, [position]);

	let limit;

	switch (true) {
		case window.innerWidth > 1800:
			limit = 300;
			break;
		case window.innerWidth > 1440:
			limit = 200;
			break;
		case window.innerWidth > 1200:
			limit = 100;
			break;
		case window.innerWidth > 992:
			limit = 300;
			break;
		default:
			limit = 150;
			break;
	}

	const featuredVideos =
		props.videos && props.videos.length > 0 ? (
			props.videos.map((video, index) => {
				if (index >= 5) {
					return;
				}

				const thumbnail =
					video.thumbnails.maxres ||
					video.thumbnails.standard ||
					video.thumbnails.high ||
					video.thumbnails.medium ||
					video.thumbnails.default;

				const title = video.title;
				const description = video.description;
				const publishedAt = video.publishedAt;
				const views = video.views;
				const publishedTime = publishedAt
					? timeElapsed(publishedAt)
					: '';
				const pageViews = video.pageViews;

				return (
					<div
						key={index}
						className='video-container'
						style={{ maxWidth: `${width}px` }}
					>
						<div className='featured-video'>
							<div
								className={`featured-video__thumbnail ${
									props.transfers ? 'transfers' : ''
								}`}
							>
								<img
									src={thumbnail.url}
									alt='Thumbnail for The United Stand YouTube video'
								/>
							</div>
							<div className='featured-video__content'>
								<div className='featured-video__content--header'>
									<p>{props.heading}</p>
									<h1>{title}</h1>
								</div>
								<hr />
								<div className='featured-video__content--stats'>
									<span>{publishedTime}</span>
									<span>{abbrNum(views, 0)} views</span>
								</div>
								<div className='featured-video__content--description'>
									<ReactMarkdown
										source={
											description &&
											truncateText(description, limit)
										}
										linkTarget='_blank'
									/>
								</div>
								<Link
									to={`/videos/single/${video.videoId}`}
									className='cta cta-large'
								>
									Watch Video
								</Link>
							</div>
						</div>
					</div>
				);
			})
		) : (
			<Spinner />
		);

	const clickHandler = layout => {
		const slider = document.querySelector('.video-container');
		if (layout === 'right') {
			if (position <= -Math.abs(width * 4 + 160)) {
				setPosition(-Math.abs(width * 4 + 160));
				setActive({ left: true, right: false });
				return;
			}
			setPosition(position - (slider.getBoundingClientRect().width + 40));
			setActive({ left: true, right: true });
			return;
		}

		if (layout === 'left') {
			if (position >= 0) {
				setPosition(0);
				setActive({ left: false, right: true });
				return;
			}
			setPosition(position + (slider.getBoundingClientRect().width + 40));
			setActive({ left: true, right: true });
			return;
		}
	};

	return (
		<div className='featured-wrapper'>
			<div className='container'>
				<div className='featured-container'>
					<div
						className='featured-slider'
						style={{ width: `10000px`, left: `${position}px` }}
					>
						{featuredVideos}
					</div>
					<div className='featured-container__navigation'>
						<div
							onClick={() => clickHandler('left')}
							className={`featured-container__navigation--left ${
								active.left ? 'active' : ''
							}`}
						>
							<img src={ArrowLeft} alt='Arrow pointing left' />
						</div>
						<div
							onClick={() => clickHandler('right')}
							className={`featured-container__navigation--right ${
								active.right ? 'active' : ''
							}`}
						>
							<img src={ArrowRight} alt='Arrow pointing right' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeaturedVideos;
