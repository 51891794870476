import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

// Images
// import PlayButton from '../../../../img/play-button.svg';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';
import abbrNum from '../../../../utils/abbrNum';
import truncateText from '../../../../utils/truncateText';

// Components
import SVGIcon from '../../../base/Elements/SVGIcon';

const VideoCard = props => {
	let limit;
	switch (true) {
		case props.width >= 1800:
			limit = 50;
			break;
		case props.width >= 1440:
			limit = 30;
			break;
		case props.width >= 1200:
			limit = 40;
			break;
		case props.width >= 768:
			limit = 30;
			break;
		case props.width >= 576:
			limit = 25;
			break;
		default:
			limit = 20;
	}

	return (
		<Link to={`/videos/single/${props.videoId}`}>
			<div className='video-card'>
				<div className='video-card__image'>
					<img src={props.image} alt='Thumbnail for YouTube video' />
				</div>
				<div className='video-card__content'>
					<h2>{truncateText(props.title, limit)}</h2>
					<hr />
					<div className='video-card__content--stats'>
						<span>{timeElapsed(props.published)}</span>
						<span>{abbrNum(props.views, 0)} views</span>
					</div>
					<ReactMarkdown
						source={truncateText(props.description, 120)}
						linkTarget='_blank'
					/>
					<div className='video-card__content--views'>
						<SVGIcon icon='eye-icon' />
						<span>
							{props.pageViews ? abbrNum(props.pageViews, 1) : 0}
						</span>
					</div>
					<SVGIcon icon='play-button' />
				</div>
			</div>
		</Link>
	);
};

export default VideoCard;
