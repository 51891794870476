const shortDate = date => {
	return new Date(date)
		.toJSON()
		.slice(0, 10)
		.split('-')
		.reverse()
		.join('/');
};

export default shortDate;
