import React from 'react';

const DayAndMonth = props => {
	const day = new Date(props.date).toISOString().slice(8, 10);
	const month = new Date(props.date).toString().slice(4, 7).toUpperCase();

	return (
		<React.Fragment>
			<span>{day}</span>
			<span>{month}</span>
		</React.Fragment>
	);
};

export default DayAndMonth;
