import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Images
import Logo from '../../img/logo-large.png';

const NotFound = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Page Not Found | The United Stand</title>
			</Helmet>
			<main>
				<div className='container'>
					<div className='not-found'>
						<div className='not-found__content'>
							<img src={Logo} alt='The United Stand Logo' />
							<div className='not-found__content--number'>
								4<span>0</span>4
							</div>
							<h1>Sorry, this page could not be found.</h1>
							<Link to='/'>
								<span>Home</span>
							</Link>
						</div>
					</div>
				</div>
			</main>
		</React.Fragment>
	);
};

export default NotFound;
