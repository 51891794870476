const dayDate = dateString => {
	const weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

	const date = new Date(dateString);
	const day = date.getDay();

	const string = dateString
		.substring(5, 10)
		.split('-')
		.reverse()
		.join('/');

	return `${weekdays[day]} ${string}`;
};

export default dayDate;
