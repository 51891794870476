import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

// Images
import Logo from '../../../img/logo.png';
import Brace from '../../../img/brace.png';

// Components
import FormField from '../../base/Elements/FormField';
import Spinner from '../../base/Elements/Spinner';
import FormModal from '../../base/Elements/FormModal';
import MobileScroll from '../../base/Elements/MobileScroll';

const Footer = props => {
	const [value, setValue] = useState({ name: '', email: '', message: '' });
	const [errors, setErrors] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const inputHandler = e => {
		setValue({
			...value,
			[e.target.name]: e.target.value,
		});
	};

	const submitHandler = e => {
		e.preventDefault();
		setLoading(true);

		const data = {
			...value,
			location: 'Contact Us',
		};

		axios
			.post('/api/contact/enquiry', data)
			.then(response => {
				setLoading(false);
				setSuccess(response.data.success);
			})
			.catch(err => {
				setLoading(false);
				setErrors(err.response.data);
			});
	};

	const menuOptions = [
		{
			parent: 'Latest Content',
			children: [
				{
					title: 'Videos',
					link: '/videos',
				},
				{
					title: 'Written',
					link: '/articles',
				},
				{
					title: 'Podcasts',
					link: '/podcasts',
				},
				{
					title: 'Social',
					link: '/social-wall',
				},
				{
					title: 'Events',
					link: '/events',
				},
			],
		},
		{
			parent: 'Man Utd',
			children: [
				{
					title: 'Man Utd',
					link: '/man-utd',
				},
			],
		},
	];

	const [active, setActive] = useState(false);

	const clickHandler = index => {
		if (index === active) {
			setActive(false);
		} else {
			setActive(index);
		}
	};

	const footerDropdown = menuOptions.map((item, index) => (
		<li key={index}>
			<div onClick={() => clickHandler(index)} className={`parent ${active === index ? 'active' : ''}`}>
				<span>{item.parent}</span>
				<FontAwesomeIcon icon='caret-down' />
			</div>
			{item.children.length > 0 && (
				<div onClick={() => setActive(false)} className={`dropdown ${active === index ? 'active' : ''}`}>
					<ul>
						{item.children.map((child, index) => (
							<li key={index}>
								<NavLink to={child.link}>{child.title}</NavLink>
							</li>
						))}
					</ul>
				</div>
			)}
		</li>
	));

	return (
		<>
			<MobileScroll pathname={props.location.pathname} />
			<footer
				className={`footer ${
					props.location.pathname.includes('video') ||
					props.location.pathname.includes('podcasts') ||
					props.location.pathname.includes('social') ||
					props.location.pathname.includes('live-chat-messages')
						? 'dark'
						: ''
				}`}
			>
				<div className='footer-top'>
					<div className='container'>
						<div>
							<div className='footer-top__form'>
								<h2>Contact Us</h2>
								{!success ? (
									<form noValidate onSubmit={submitHandler}>
										<FormField
											name='name'
											type='text'
											placeholder='Your Name'
											value={value.name}
											onChange={inputHandler}
											error={errors.name}
										/>
										<FormField
											name='email'
											type='email'
											placeholder='Your Email'
											value={value.email}
											onChange={inputHandler}
											error={errors.email}
										/>
										<FormField
											name='message'
											type='textarea'
											placeholder='Your Message...'
											value={value.message}
											onChange={inputHandler}
											error={errors.message}
										/>
										{loading ? (
											<Spinner classes='white' />
										) : (
											<button type='submit' className='cta yellow cta-large'>
												Send Message
											</button>
										)}
									</form>
								) : (
									<p>{success}</p>
								)}
							</div>
							<div className='footer-top__details'>
								<div className='footer-top__details--block'>
									<h2>Our Mission</h2>
									<p>
										Established in 2014, The United Stand has fast become the World’s leading
										Independent Manchester United Fan Channel. We are focussed on giving fans of the
										club their voice in a world of ‘Professional’ pundits.
									</p>
									<p>
										From instant reactions from match-going fans, to the latest in fan news, The
										United Stand is the place to be if you love Manchester United!
									</p>
								</div>
								<div className='footer-top__details--block'>
									<h2>Navigate</h2>
									<nav>
										<ul>
											{footerDropdown}
											<li>
												<NavLink to='/our-team'>Our Team</NavLink>
											</li>
											<li>
												<NavLink to='/store'>Store</NavLink>
											</li>
											<li>
												<NavLink to='/login'>Login</NavLink>
											</li>
											<li>
												<NavLink to='/videos'>Latest Video</NavLink>
											</li>
											<li>
												<NavLink to='/join-tus'>Join TUS+</NavLink>
											</li>
											<li>
												<a
													href='https://www.youtube.com/channel/UCMmVPVb0BwSIOWVeDwlPocQ?sub_confirmation=1'
													target='_blank'
													rel='noopener noreferrer'
												>
													Go To YouTube
												</a>
											</li>
										</ul>
									</nav>
								</div>
								<div className='footer-top__details--block'>
									<h2>Legal Stuff</h2>
									<ul>
										<li>
											<NavLink to='/privacy-policy'>Our Privacy Policy</NavLink>
										</li>
										<li>
											<NavLink to='/cookie-policy'>Cookie Policy</NavLink>
										</li>
										<li>
											<NavLink to='/terms-of-service'>Terms of Service</NavLink>
										</li>
									</ul>
								</div>
							</div>
							<div className='footer-top__logo'>
								<img src={Logo} alt='The United Stand Logo' />
							</div>
						</div>
					</div>
				</div>
				<div className='footer-bottom'>
					<div className='footer-bottom__top'>
						<div className='container'>
							<div>
								<div className='footer-bottom__top--block'>
									<h2>Join Our Team</h2>
									<p>
										Fancy joining our team? Are you an aspiring writer or just want to get your face
										seen on YouTube, then why not apply for our Partnership Programme here?
									</p>
									<FormModal buttonText='Enquire' buttonClasses='cta' location='Join Our Team' />
								</div>
								<div className='footer-bottom__top--block'>
									<h2>Advertise With Us</h2>
									<p>
										The United Stand garners millions of views per month via a vartiety of different
										social media platforms. Therefore we believe we can help your business to reach
										a much wider audience if you partner with us, so why not drop us a line?
									</p>
									<FormModal buttonText='Apply' buttonClasses='cta' location='Advertise With Us' />
								</div>
								<div className='footer-bottom__top--block'>
									<h4>Disclaimer</h4>
									<small>
										The United Stand is a solely Independent company and is not associated in any
										way with Manchester United PLC. Any associations made between The United Stand
										and Manchester United are purely coincidental. We do not hold any image rights,
										licence to sell or distribute any official merchandise relating to Manchester
										United. Any photographs used on this website have been purchased through the
										proper media channels with a licence.
									</small>
								</div>
								<div className='footer-bottom__top--block'>
									<h4>Content Notice</h4>
									<small>
										The opinions expressed (where published) on this website are of the sole opinion
										of the author(s), and are not the ‘voice’ or exclusive view of The United Stand.
										Any news aggregated on this website from various news & media outlets is quoted
										and or attributed to the original source where applicable.
									</small>
								</div>
							</div>
						</div>
					</div>
					<div className='footer-bottom__middle'>
						<div className='container'>
							<div>
								<div className='footer-bottom__middle--social'>
									<a
										href='https://www.facebook.com/UnitedStandMUFC/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<FontAwesomeIcon icon={['fab', 'facebook-square']} />
									</a>
									<a
										href='https://www.instagram.com/theunitedstandtv/'
										target='_blank'
										rel='noopener noreferrer'
									>
										<FontAwesomeIcon icon={['fab', 'instagram']} />
									</a>
									<a
										href='https://twitter.com/UnitedStandMUFC'
										target='_blank'
										rel='noopener noreferrer'
									>
										<FontAwesomeIcon icon={['fab', 'twitter']} />
									</a>
								</div>
								<div className='footer-bottom__middle--brace'>
									<a href='https://www.brace.co.uk/' target='_blank' rel='noopener noreferrer'>
										<img src={Brace} alt='Brace Creative Agency Logo' />
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className='footer-bottom__bottom'>
						<span>&copy;2014 - {new Date().getFullYear()} The United Stand</span>
						<span>
							Designed By The United Stand's Drawty Devil & Created by Brace Creative Agency<span>|</span>
							Company No 0506444
						</span>
					</div>
				</div>
			</footer>
		</>
	);
};

export default withRouter(Footer);
