import React, { useState, useEffect } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';

// Images
import Logo from '../../../../img/logo.png';
import RedCrumb from '../../../../img/red-breadcrumb.svg';
import LightGrayCrumb from '../../../../img/light-gray-breadcrumb.svg';

// Elements
import SVGIcon from '../../../base/Elements/SVGIcon';

// Actions
import { getPublishedArticleMonths } from '../../../../actions/contentfulActions';

// Utils
import ScrollElementToTop from '../../../../utils/scrollElementToTop';
import isDate from '../../../../utils/isDate';
import getDateString from '../../../../utils/getDateString';

const ArticleHeader = props => {
	const [width, setWidth] = useState(false);

	const [section, setSection] = useState(false);

	const locationArray = props.location.pathname.split('/');
	locationArray.shift();

	useEffect(() => {
		if (locationArray.length === 2) {
			if (isDate(locationArray[1])) {
				const formattedString = getDateString(locationArray[1]);

				return setSection(formattedString);
			}

			const formattedString = locationArray[1]
				.split('-')
				.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
				.join(' ');

			return setSection(formattedString);
		}
	}, []);

	useEffect(() => {
		const content = document.querySelector('.breadcrumb-dynamic-content > span');

		if (content) {
			setWidth(Math.ceil(content.getBoundingClientRect().width));
		}
	}, [section]);

	useEffect(() => {
		props.getPublishedArticleMonths();
	}, []);

	const monthList =
		props.publishedArticleMonths &&
		props.publishedArticleMonths.map(date => {
			return {
				slug: date,
				name: getDateString(date),
			};
		});

	const categories = [
		'Quizzes',
		'Match Ratings',
		'Latest News',
		'Transfer News',
		'Transfer Special',
		'Match Reviews',
		'Match Preview',
		'Fan Editorial',
		'Breaking News',
		'Injury Update',
		"Women's Team",
		'Contract News',
		'Champions League',
		'Special Editorial',
		'U23s',
		'Exclusive Story',
		'Featured Story',
		'Uncategorised',
	];

	const formattedCategories = categories.map(item => {
		return {
			slug: slugify(item, { lower: true, remove: "'" }),
			name: item,
		};
	});

	const filterOptions = [
		{
			heading: 'Categories',
			list: formattedCategories,
		},
		{
			heading: 'Months',
			list: monthList,
		},
	];

	const [open, setOpen] = useState(false);

	const clickHandler = () => {
		if (open) {
			const list = document.querySelector('.category-filter__list');
			ScrollElementToTop(list, 500);
		}

		setOpen(!open);
	};

	return (
		<div className='articles-wrapper'>
			<Helmet>
				<title>{`${section} | The United Stand`}</title>
			</Helmet>
			<div className='container'>
				<div className='articles-top'>
					<div className='articles-top__left'>
						<div className='articles-top__left--breadcrumbs'>
							<Link to='/' className='articles-top__left--breadcrumbs_icon' style={{ maxWidth: '95px' }}>
								<img src={RedCrumb} alt='Red Arrow pointing right' />
								<div className='breadcrumb-content'>
									<img src={Logo} alt='The United Stand Logo' style={{ maxWidth: '15px' }} />
									<span>Home</span>
								</div>
							</Link>
							<Link
								to='/articles'
								className={`articles-top__left--breadcrumbs_icon`}
								style={{ maxWidth: width + 60 }}
							>
								<img src={LightGrayCrumb} alt='Gray arrow pointing right' />
								<div className='breadcrumb-dynamic-content'>
									<span>Articles</span>
								</div>
							</Link>
						</div>
						<div className='articles-top__left--title'>
							<h1>Written Articles</h1>
							{section && <h1>{section}</h1>}
						</div>
					</div>
					<div className='articles-top__right'>
						<div onClick={clickHandler} className={`category-filter ${open ? 'open' : ''}`}>
							<div className='category-filter__title'>
								<p>Search By Category/Month</p>
								<SVGIcon icon='caret-right' />
							</div>
							<div className='category-filter__list'>
								{filterOptions.map(option => (
									<React.Fragment>
										<p className='category-filer__list--heading'>{option.heading}</p>
										{option.list &&
											option.list.map(item => (
												<NavLink
													className={item.slug.includes('transfer') ? 'transfers' : ''}
													strict
													to={item.slug}
												>
													{item.name}
												</NavLink>
											))}
									</React.Fragment>
								))}
							</div>
						</div>
						{/* <NavLink to='/articles/latest-news'>
							<span>Latest News</span>
						</NavLink>
						<NavLink to='/articles/transfer-news'>
							<span>Transfer News</span>
						</NavLink>
						<NavLink to='/articles/match-reviews'>
							<span>Match Reviews</span>
						</NavLink>
						<NavLink to='/articles/all-articles'>
							<span>All Articles</span>
						</NavLink> */}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		publishedArticleMonths: state.contentful.publishedArticleMonths,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPublishedArticleMonths: () => dispatch(getPublishedArticleMonths()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleHeader));
