import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// Actions
import { getTopStory, getLatestPosts, getAdverts } from '../../../actions/contentfulActions';

// Components
import ArticleHeader from './Elements/ArticleHeader';
import TopStory from './TopStory';
import ArticleGrid from './Elements/ArticleGrid';
import FeaturedAuthors from './Elements/FeaturedAuthors';
import TrendingContent from './Elements/TrendingContent';
import RecommendedBar from './Elements/RecommendedBar';
import MediaWatch from './Elements/MediaWatch';
import ContentRec from '../../base/Elements/ContentRec';
import LeagueTable from './Elements/LeagueTable';
import Sponsor from './Elements/Sponsor';
import LatestVideo from './Elements/LatestVideo';

// Utils
import isDate from '../../../utils/isDate';

const Articles = props => {
	const history = useHistory();

	useEffect(() => {
		props.getTopStory(props.match.params.slug);
		getPosts();
		props.getAdverts();
	}, []);

	const getPosts = async () => {
		const error = await props.getLatestPosts(props.match.params.slug);
		if (error) {
			toast.error(error.data.message, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			history.push('/articles/latest-news');
		}
	};

	return (
		<main>
			<ArticleHeader />
			<TopStory slug={!isDate(props.match.params.slug) && props.match.params.slug} />
			<div className='articles-home'>
				<div className='container'>
					<div className='articles-home__grid'>
						<div className='articles-home__grid--left'>
							<div className='articles-home__grid--left_bar'>
								{/* <TrendingContent /> */}
								<Sponsor adverts={props.adverts} top={true} classes='hide-mobile' />
								<LatestVideo />
								<LeagueTable />
								<RecommendedBar carousel={true} classes='hide-desktop' id='top-stories' />
								<Sponsor adverts={props.adverts} bottom={true} classes='margin-mobile' />
							</div>
						</div>
						<div className='articles-home__grid--middle'>
							<ArticleGrid adverts={props.adverts} />
						</div>
						<div className='articles-home__grid--right'>
							<div className='articles-home__grid--right_bar'>
								<RecommendedBar classes='show-desktop' />
								<TrendingContent classes='hide-mobile' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<MediaWatch />
			<TrendingContent id='trending-content' classes='hide-desktop' />
			<ContentRec />
			<FeaturedAuthors />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		article: state.contentful.topStory,
		adverts: state.contentful.adverts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTopStory: slug => dispatch(getTopStory(slug)),
		getLatestPosts: slug => dispatch(getLatestPosts(slug)),
		getAdverts: () => dispatch(getAdverts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
