import React from 'react';

const SVGIcon = props => {
	let icon;

	switch (props.icon.toLowerCase()) {
		case 'mouse-scroll':
			icon = (
				<svg
					version='1.1'
					id='_x3C_Layer_x3E_'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 940.965 2196.255'
					style={{ enableBackground: 'new 0 0 940.965 2196.255' }}
				>
					<g>
						<path
							style={{
								fill: 'none',
								stroke: '#587b8d',
								strokeWidth: '101.88',
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: '10',
							}}
							d='
		M470.474,1731.284h0.003c231.693,0,419.516-187.824,419.516-419.516V884.53c0-231.692-187.824-419.516-419.516-419.516h-0.003
		c-231.693,0-419.516,187.824-419.516,419.516v427.237C50.958,1543.46,238.782,1731.284,470.474,1731.284z'
						/>

						<line
							style={{
								fill: 'none',
								stroke: '#587b8d',
								strokeWidth: '101.88',
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: '10',
							}}
							x1='470.474'
							y1='704.369'
							x2='470.474'
							y2='906.452'
						/>
					</g>
					<line
						style={{
							fill: 'none',
							stroke: '#587b8d',
							strokeWidth: '101.88',
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: '10',
						}}
						x1='277.36'
						y1='244.166'
						x2='470.474'
						y2='51.049'
					/>
					<line
						style={{
							fill: 'none',
							stroke: '#587b8d',
							strokeWidth: '101.88',
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: '10',
						}}
						x1='663.592'
						y1='244.166'
						x2='470.474'
						y2='51.049'
					/>
					<line
						style={{
							fill: 'none',
							stroke: '#587b8d',
							strokeWidth: '101.88',
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: '10',
						}}
						x1='277.36'
						y1='1952.132'
						x2='470.474'
						y2='2145.249'
					/>
					<line
						style={{
							fill: 'none',
							stroke: '#587b8d',
							strokeWidth: '101.88',
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: '10',
						}}
						x1='663.592'
						y1='1952.132'
						x2='470.474'
						y2='2145.249'
					/>
				</svg>
			);
			break;
		case 'play-button':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 134 134'
					style={{ enableBackground: 'new 0 0 134 134' }}
				>
					<ellipse
						style={{
							fill: '#E81D1D',
							stroke: '#CA0D0D',
							strokeWidth: '4',
							strokeMiterlimit: '10',
						}}
						cx='67'
						cy='67.07'
						rx='65'
						ry='65.07'
					/>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M97.275,64.845c3.411,2.17,3.411,7.594,0,9.764L74.444,89.131l-22.831,14.522
	c-3.411,2.17-7.675-0.542-7.675-4.882V69.727V40.683c0-4.34,4.264-7.052,7.675-4.882l22.831,14.522L97.275,64.845z'
					/>
				</svg>
			);
			break;
		case 'bell-icon':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 134 132'
					style={{ enableBackground: 'new 0 0 134 132' }}
				>
					<ellipse
						style={{
							fill: '#E81D1D',
							stroke: '#CA0D0D',
							strokeWidth: '4',
							strokeMiterlimit: '10',
						}}
						cx='67'
						cy='66'
						rx='65'
						ry='64'
					/>
					<g>
						<path
							style={{ fill: 'none' }}
							d='M67.311,87.969c-2.415,0-4.76,0.061-7.003,0.175c0.08,0.303,0.13,0.613,0.13,0.932
		c0,2.969-3.648,5.376-8.147,5.376c-3.871,0-7.105-1.784-7.935-4.172c-3.793,1.005-6.056,2.264-6.056,3.633
		c0,3.283,12.989,5.944,29.011,5.944c16.022,0,29.011-2.661,29.011-5.944C96.322,90.63,83.333,87.969,67.311,87.969z'
						/>
					</g>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M97.082,90.205c-2.588-1.655-4.054-4.607-4.054-7.679V60.848c0-13.626-10.742-24.861-24.176-25.666
	v-1.197c0-0.711-0.454-1.381-1.145-1.554c-1.022-0.255-1.937,0.511-1.937,1.492v1.259c-13.433,0.804-24.176,12.039-24.176,25.666
	v21.677c0,3.072-1.466,6.024-4.054,7.679c-1.355,0.866-2.098,1.806-2.098,2.789c0,4.312,14.268,7.808,31.869,7.808
	c17.6,0,31.868-3.496,31.868-7.808C99.179,92.011,98.436,91.071,97.082,90.205z M67.311,98.953c-16.022,0-29.011-2.661-29.011-5.944
	c0-3.283,12.989-5.944,29.011-5.944c16.022,0,29.011,2.661,29.011,5.944C96.322,96.291,83.333,98.953,67.311,98.953z'
					/>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M57.944,93.917c4.499,0.069,8.176-1.806,8.213-4.189c0.004-0.256-0.042-0.505-0.119-0.75
	c-6.438,0.163-12.029,0.693-15.976,1.469C50.861,92.376,54.073,93.857,57.944,93.917z'
					/>
				</svg>
			);
			break;
		case 'read-icon':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 140 140'
					style={{ enableBackground: 'new 0 0 140 140' }}
				>
					<circle
						style={{
							fill: '#E81D1D',
							stroke: '#CA0D0D',
							strokeWidth: '4',
							strokeMiterlimit: '10',
						}}
						cx='70'
						cy='70'
						r='68'
					/>
					<g>
						<path
							style={{ fill: '#FFFFFF' }}
							d='M54.732,45.362c-3.549-0.855-7.054-1.34-10.4-1.494c-0.619-0.028-1.165,0.354-1.29,0.886
		l-6.908,29.511l-2.482,10.601c-0.17,0.726,0.508,1.395,1.35,1.316c5.156-0.479,10.99-0.075,16.941,1.358
		c5.469,1.317,10.344,3.337,14.319,5.767c0.84,0.513,1.986,0,1.986-0.896V77.284v-26.03c0-0.378-0.214-0.732-0.578-0.935
		C63.967,48.251,59.582,46.53,54.732,45.362z'
						/>
						<path
							style={{ fill: '#FFFFFF' }}
							d='M83.278,45.362c3.549-0.855,7.054-1.34,10.4-1.494c0.619-0.028,1.165,0.354,1.29,0.886l6.908,29.511
		l2.482,10.601c0.17,0.726-0.508,1.395-1.35,1.316c-5.156-0.479-10.99-0.075-16.941,1.358c-5.469,1.317-10.344,3.337-14.319,5.767
		c-0.84,0.513-1.986,0-1.986-0.896V77.284v-26.03c0-0.378,0.214-0.732,0.578-0.935C74.043,48.251,78.428,46.53,83.278,45.362z'
						/>
						<g>
							<path
								style={{ fill: '#FFFFFF' }}
								d='M73.339,98.953h-9.117c-0.209,0-0.412-0.062-0.576-0.176L62.529,98
			c-4.264-2.971-7.553-3.899-13.767-5.381c-6.596-1.573-13.854-0.599-19.684,0.184l-2.48,0.332
			c-0.642,0.086-1.183-0.418-1.045-0.974l9.576-38.653c0.092-0.372,0.468-0.638,0.903-0.639l2.875-0.006
			c0.592-0.001,1.033,0.477,0.907,0.984L31.484,87.3c-0.137,0.551,0.391,1.05,1.029,0.976c5.392-0.626,11.506-0.98,17.462,0.441
			c7.13,1.701,10.934,2.986,15.428,6.118l0.105,0.073h5.906c0.206,0,0.408-0.062,0.57-0.173c4.681-3.214,9.087-4.382,15.944-6.018
			c6.404-1.527,12.864-1.567,17.646-1.243c0.618,0.042,1.101-0.452,0.968-0.982l-8.193-32.647c-0.127-0.506,0.313-0.985,0.904-0.985
			h2.729c0.434,0,0.81,0.264,0.904,0.635l9.63,37.909c0.15,0.589-0.461,1.109-1.129,0.963l-2.026-0.443
			c-0.03-0.007-0.059-0.012-0.09-0.016c-4.202-0.552-12.266-1.164-20.132,0.712c-6.452,1.539-10.404,2.583-15.172,5.895
			L73.339,98.953z'
							/>
						</g>
					</g>
				</svg>
			);
			break;
		case 'shopping-icon':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 140 140'
					style={{ enableBackground: 'new 0 0 140 140' }}
				>
					<circle
						style={{
							fill: '#E81D1D',
							stroke: '#CA0D0D',
							strokeWidth: '4',
							strokeMiterlimit: '10',
						}}
						cx='70'
						cy='70'
						r='68'
					/>
					<g>
						<path
							style={{ fill: '#FFFFFF' }}
							d='M82.795,50.067h-2.011c-0.605,0-1.095-0.49-1.095-1.095v-4.939c0-5.155-3.882-9.664-9.025-10.008
		c-5.641-0.377-10.352,4.105-10.352,9.667v5.28c0,0.605-0.49,1.095-1.095,1.095h-2.011c-0.605,0-1.095-0.49-1.095-1.095v-4.877
		c0-7.471,5.729-13.908,13.191-14.277c7.976-0.394,14.59,5.982,14.59,13.874v5.28C83.89,49.577,83.4,50.067,82.795,50.067z'
						/>
					</g>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M94.178,51.865H45.822c-0.976,0-1.818,0.683-2.02,1.638l-9.661,45.748
	c-0.271,1.283,0.708,2.491,2.02,2.491h67.678c1.312,0,2.291-1.207,2.02-2.491l-9.661-45.748
	C95.996,52.548,95.154,51.865,94.178,51.865z M58.826,59.832c-1.842,0.444-3.46-1.173-3.016-3.016
	c0.209-0.866,0.919-1.576,1.785-1.785c1.842-0.444,3.46,1.174,3.016,3.016C60.402,58.913,59.692,59.623,58.826,59.832z
	 M82.405,59.832c-1.842,0.444-3.46-1.173-3.016-3.016c0.209-0.866,0.919-1.576,1.785-1.785c1.842-0.444,3.46,1.174,3.016,3.016
	C83.981,58.913,83.271,59.623,82.405,59.832z'
					/>
				</svg>
			);
			break;
		case 'heart-icon':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 20 20'
					style={{ enableBackground: 'new 0 0 20 20' }}
				>
					<path
						style={{ fill: '#C5C5C5' }}
						d='M10,20l-0.27-0.306c-4.015-4.557-8.318-9.512-8.881-10.31L0.827,9.348L0.777,9.26
	C0.269,8.315,0,7.228,0,6.118C0,2.744,2.457,0,5.476,0c1.317,0,2.591,0.532,3.587,1.499L10,2.407l0.937-0.909
	C11.933,0.532,13.207,0,14.523,0C17.543,0,20,2.744,20,6.118c0,1.112-0.269,2.2-0.778,3.146l-0.071,0.121
	c-0.571,0.807-4.872,5.759-8.881,10.309L10,20z M1.455,8.877C1.956,9.572,5.438,13.613,10,18.795
	c4.556-5.174,8.038-9.214,8.545-9.918l0.03-0.053c0.437-0.813,0.668-1.749,0.668-2.707c0-2.907-2.117-5.272-4.72-5.272
	c-1.134,0-2.232,0.459-3.09,1.292L10,3.527l-1.433-1.39C7.709,1.304,6.611,0.845,5.476,0.845c-2.602,0-4.72,2.365-4.72,5.272
	c0,0.956,0.231,1.89,0.667,2.703L1.455,8.877z'
					/>
				</svg>
			);
			break;
		case 'eye-icon':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 23 16'
					style={{ enableBackground: 'new 0 0 23 16' }}
				>
					<g>
						<path
							style={{ fill: '#BCBCBC' }}
							d='M11.5,12.313c-2.699,0-4.895-1.988-4.895-4.433S8.801,3.447,11.5,3.447s4.895,1.988,4.895,4.433
		S14.199,12.313,11.5,12.313z M11.5,5.145c-1.771,0-3.212,1.227-3.212,2.735s1.441,2.735,3.212,2.735s3.212-1.227,3.212-2.735
		S13.271,5.145,11.5,5.145z'
						/>
					</g>
					<g>
						<path
							style={{ fill: '#BCBCBC' }}
							d='M11.5,16c-2.769,0-5.366-1.081-7.312-3.044l-3.678-3.71c-0.681-0.687-0.681-1.805,0-2.492
		l3.678-3.71C6.134,1.081,8.731,0,11.5,0c2.769,0,5.366,1.081,7.312,3.044l3.678,3.71c0.681,0.687,0.681,1.805,0,2.492l-3.678,3.71
		C16.866,14.919,14.269,16,11.5,16z M11.5,1.698c-2.32,0-4.494,0.904-6.122,2.547l-3.678,3.71c-0.024,0.024-0.023,0.067,0,0.091
		l3.678,3.71c1.628,1.642,3.802,2.547,6.122,2.547c2.32,0,4.494-0.904,6.121-2.546l3.678-3.71c0.024-0.024,0.024-0.067,0-0.091
		l-3.678-3.71C15.994,2.602,13.82,1.698,11.5,1.698z'
						/>
					</g>
				</svg>
			);
			break;
		case 'globe':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 26.17 26.14'
					style={{ enableBackground: 'new 0 0 26.17 26.14' }}
				>
					<g>
						<path
							style={{ fill: '#EB180A' }}
							d='M13.09,25.21c-6.71,0-12.16-5.46-12.16-12.16c0-6.71,5.46-12.16,12.16-12.16c6.71,0,12.16,5.46,12.16,12.16
		C25.25,19.75,19.79,25.21,13.09,25.21z'
						/>
						<path
							style={{ fill: '#CC0500' }}
							d='M13.09,1.38c6.43,0,11.66,5.23,11.66,11.66s-5.23,11.66-11.66,11.66S1.42,19.48,1.42,13.05
		S6.66,1.38,13.09,1.38 M13.09,0.38c-6.99,0-12.66,5.67-12.66,12.66s5.67,12.66,12.66,12.66c6.99,0,12.66-5.67,12.66-12.66
		S20.08,0.38,13.09,0.38L13.09,0.38z'
						/>
					</g>
					<path
						style={{ fill: '#FFFFFF' }}
						d='M19.21,7.56c-0.76-0.85-1.68-1.52-2.71-2c-1.07-0.49-2.22-0.74-3.41-0.74h-0.01c-0.01,0-0.01,0-0.02,0l0.01,0v0
	c-0.03,0-0.01,0-0.01,0c-1.18,0-2.33,0.25-3.4,0.74c-1.03,0.47-1.94,1.15-2.71,2l0.32,0.29h0L6.96,7.57
	c-1.35,1.51-2.09,3.45-2.09,5.48c0,2.03,0.75,3.97,2.1,5.48l0,0c0.76,0.85,1.67,1.52,2.7,2c1.07,0.49,2.21,0.75,3.39,0.75h0h0
	c0,0,0,0,0,0h0c0,0,0.01,0,0.01,0h0.01c1.19,0,2.34-0.25,3.41-0.74c1.04-0.47,1.95-1.15,2.71-2c1.35-1.51,2.09-3.45,2.09-5.48
	C21.31,11.02,20.56,9.07,19.21,7.56z M10.03,6.36c0.24-0.11,0.49-0.21,0.74-0.29c-0.48,0.57-0.9,1.33-1.22,2.21
	C8.98,8.13,8.47,7.95,8,7.73C8.6,7.16,9.27,6.7,10.03,6.36z M7.39,8.4C7.95,8.68,8.58,8.92,9.27,9.1c-0.3,1.08-0.48,2.27-0.51,3.51
	H5.75C5.84,11.07,6.41,9.6,7.39,8.4z M5.75,13.48h3.02c0.03,1.24,0.21,2.43,0.51,3.51c-0.69,0.19-1.32,0.42-1.89,0.71
	C6.41,16.5,5.84,15.02,5.75,13.48z M10.03,19.73C9.27,19.39,8.6,18.93,8,18.36c0.46-0.21,0.98-0.4,1.54-0.54
	c0.33,0.89,0.74,1.64,1.22,2.21C10.51,19.94,10.27,19.84,10.03,19.73z M12.64,20.34c-0.38-0.11-0.77-0.37-1.13-0.77
	c-0.43-0.48-0.82-1.15-1.12-1.95c0.72-0.14,1.47-0.22,2.25-0.24V20.34z M12.64,16.51c-0.87,0.02-1.72,0.12-2.53,0.28
	c-0.29-1.01-0.46-2.15-0.49-3.31h3.01V16.51z M12.64,12.62H9.63c0.03-1.16,0.2-2.3,0.49-3.32c0.81,0.16,1.66,0.25,2.53,0.28V12.62z
	 M12.64,8.71c-0.78-0.02-1.53-0.1-2.25-0.24c0.31-0.8,0.69-1.47,1.12-1.95c0.36-0.4,0.75-0.66,1.13-0.77V8.71z M20.43,12.62h-3.04
	c-0.03-1.24-0.21-2.43-0.51-3.51c0.7-0.19,1.34-0.42,1.91-0.71C19.76,9.6,20.34,11.07,20.43,12.62z M16.14,6.35
	c0.76,0.35,1.44,0.81,2.03,1.38c-0.47,0.21-1,0.4-1.56,0.55c-0.33-0.89-0.75-1.66-1.24-2.23C15.64,6.14,15.89,6.24,16.14,6.35z
	 M13.51,5.75c0.38,0.11,0.77,0.37,1.13,0.77c0.43,0.48,0.82,1.15,1.12,1.96c-0.72,0.14-1.47,0.22-2.25,0.24V5.75z M13.51,9.58
	c0.87-0.02,1.72-0.11,2.53-0.27c0.29,1.01,0.46,2.15,0.49,3.31h-3.02V9.58z M13.51,13.48h3.02c-0.03,1.16-0.2,2.3-0.49,3.31
	c-0.81-0.16-1.66-0.25-2.53-0.28V13.48z M13.51,20.34v-2.96c0.78,0.02,1.53,0.1,2.25,0.24c-0.31,0.8-0.69,1.47-1.13,1.96
	C14.27,19.97,13.89,20.23,13.51,20.34z M16.14,19.74c-0.25,0.11-0.5,0.21-0.76,0.3c0.48-0.57,0.91-1.33,1.24-2.23
	c0.56,0.15,1.09,0.34,1.56,0.55C17.58,18.93,16.89,19.39,16.14,19.74z M18.79,17.69c-0.57-0.28-1.21-0.52-1.91-0.71
	c0.3-1.08,0.48-2.29,0.51-3.5h3.04C20.34,15.02,19.76,16.49,18.79,17.69z'
					/>
				</svg>
			);
			break;
		case 'caret-right':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 576 1024'
					style={{ enableBackground: 'new 0 0 576 1024' }}
				>
					<path
						d='M576.371,512.011c0,17.333-6.333,32.333-19,45l-448,448c-12.667,12.667-27.667,19-45,19s-32.333-6.333-45-19
	s-19-27.667-19-45v-896c0-17.333,6.333-32.333,19-45s27.667-19,45-19s32.333,6.333,45,19l448,448
	C570.037,479.678,576.371,494.678,576.371,512.011z'
					/>
				</svg>
			);
			break;
		case 'share-arrow':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 980.115 823.648'
					style={{ enableBackground: 'new 0 0 980.115 823.648' }}
				>
					<g>
						<path d='M94.115,617.048' />
						<path
							d='M968.715,332.748l-397.1-325.9c-9.2-7.5-21.8-8.9-32.5-3.9c-10.7,5.1-17.5,15.8-17.5,27.7l-0.9,171.9
		c-178,5.7-315.8,62.9-405.1,167.4c-145.5,170.1-113,417.4-111.5,427.7c2.3,15,13.3,26,28.4,26c0.3,0,0.7,0,1.1,0
		c15.5-0.5,25.3-12.5,26.6-28c1-11.3,31.5-286.2,460.6-285.9l0.9,175.8c0,11.8,6.8,22.6,17.6,27.7c10.6,5.1,23.4,3.5,32.5-4
		l397.1-329c7.1-5.8,11.2-14.5,11.2-23.7C980.015,347.248,975.815,338.548,968.715,332.748z M582.915,620.648v-142
		c0-8.1-3.2-15.9-9-21.7c-5.8-5.7-13.5-8.9-21.6-8.9h-0.1c-306.4,0.2-423.5,103.7-490.4,195c9.8-73.2,38.3-161.4,100.5-233.5
		c82.6-95.8,215.7-146.3,390-146.3c16.9,0,30.6-13.7,30.6-30.6v-137.6l318.1,261.6L582.915,620.648z'
						/>
					</g>
				</svg>
			);
			break;
		case 'share-arrow-full':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 980 952'
					style={{ enableBackground: 'new 0 0 980 952' }}
					fill='currentColor'
				>
					<g>
						<path d='M980,355.2L490,0v164.3C204.5,265.2,0,537.5,0,857.5c0,32,2.1,63.6,6,94.5c37-234,239.6-413,484-413v171.5L980,355.2z' />
					</g>
				</svg>
			);
			break;
		case 'tick-box':
			icon = (
				<svg
					version='1.1'
					id='Capa_1'
					xmlns='http://www.w3.org/2000/svg'
					x='0px'
					y='0px'
					viewBox='0 0 489 435.45'
					style={{ enableBackground: 'new 0 0 489 435.45' }}
				>
					<g>
						<g>
							<path
								fill='currentColor'
								d='M0,411.05V65.45c0-13.5,10.9-24.4,24.4-24.4h255.1h51.3l-48.7,48.7h-2.6H48.7v296.9h296.9v-53.3v-72.8l48.7-48.7v121.5
			v77.7c0,13.4-10.9,24.4-24.4,24.4H24.4C10.9,435.45,0,424.55,0,411.05z M212.1,224.05l-52-52c-14.6-14.6-38.3-14.6-52.9,0
			c-7.1,7.1-11,16.5-11,26.5s3.9,19.4,11,26.4l78.4,78.4c14.6,14.6,38.3,14.6,52.9,0L478,63.85c7.1-7.1,11-16.5,11-26.4
			c0-10-3.9-19.4-11-26.5c-14.6-14.6-38.3-14.6-52.9,0L212.1,224.05z'
							/>
						</g>
					</g>
				</svg>
			);
			break;
		case 'football':
			icon = (
				<svg
					version='1.1'
					id='Layer_1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 18.95 18.937'
					style={{ enableBackground: 'new 0 0 18.95 18.937' }}
					fill='currentColor'
				>
					<g>
						<path
							d='M10.93,2.027c-1.19,0.46-2.25,1.18-3.16,2.15c-1.09,1.17-1.97,2.71-2.6,4.57c1.21,0.56,2.58,0.71,4.06,0.45
		c1.1-2.98,3.4-4.15,5.14-4.61c1.22-0.32,2.34-0.35,3.04-0.32c-0.73-1.11-1.7-2.07-2.82-2.79C14.2,1.447,12.68,1.347,10.93,2.027z'
						/>
						<path
							d='M10.65,16.107c0.19-0.67,0.29-1.32,0.3-1.95c-1.98,0.37-3.81,0.37-5.42,0c-1.39-0.32-2.62-0.9-3.68-1.74
		c-0.82-0.65-1.4-1.34-1.8-1.92c0.18,1.67,0.8,3.24,1.81,4.6c0.85,0.73,3.87,2.97,8.35,2.2C10.38,16.907,10.53,16.507,10.65,16.107z
		'
						/>
						<path
							d='M2.24,11.937c0.98,0.78,2.14,1.32,3.43,1.61c1.55,0.35,3.32,0.34,5.24-0.03c-0.12-1.32-0.67-2.56-1.63-3.7H9.27
		c-0.48,0.08-0.96,0.13-1.41,0.13c-2.49,0-4.21-1.23-5.21-2.26c-0.84-0.87-1.38-1.78-1.69-2.38c-0.59,1.2-0.91,2.5-0.96,3.85
		C0.19,9.567,0.84,10.837,2.24,11.937z'
						/>
						<path
							d='M17.79,4.917c-0.22-0.02-0.64-0.05-1.17-0.03c1.61,2.15,2.11,4.39,2.21,6.06c0.08-0.49,0.12-0.99,0.12-1.49
		C18.95,7.877,18.55,6.307,17.79,4.917z'
						/>
						<path
							d='M14.51,5.187c-0.62,0.16-1.2,0.39-1.72,0.67c1.33,1.57,2.24,3.18,2.71,4.79c0.4,1.36,0.49,2.72,0.27,4.05
		c-0.17,1.03-0.51,1.88-0.83,2.51c1.37-0.97,2.46-2.27,3.16-3.81c0.2-1.13,0.61-4.95-2.23-8.45C15.4,4.997,14.94,5.077,14.51,5.187z
		'
						/>
						<path
							d='M3.92,17.117c-0.02-0.01-0.05-0.02-0.07-0.03c1.56,1.16,3.44,1.81,5.42,1.85c0.14-0.2,0.37-0.53,0.6-0.96
		C7.79,18.257,5.79,17.967,3.92,17.117z'
						/>
						<path
							d='M5.64,0.857c0.07-0.05,0.13-0.1,0.2-0.14c-1.86,0.77-3.46,2.15-4.52,3.91c0.1,0.22,0.27,0.56,0.51,0.98
		C2.64,3.717,3.95,2.077,5.64,0.857z'
						/>
						<path
							d='M2.26,6.237c0.26,0.37,0.55,0.71,0.85,1.02c0.47,0.48,0.97,0.88,1.51,1.2c0.66-1.91,1.57-3.49,2.72-4.71
		c0.97-1.04,2.11-1.81,3.39-2.3c1.02-0.39,1.96-0.55,2.69-0.59c-1.56-0.71-3.3-1-5.03-0.79C8,0.197,7.06,0.597,6.01,1.357
		C4.26,2.627,3,4.267,2.26,6.237z'
						/>
						<path
							d='M12.25,6.187c-1.13,0.76-1.95,1.86-2.46,3.26c2.1,2.52,1.95,5.11,1.45,6.84c-0.33,1.15-0.85,2.07-1.22,2.64
		c1.3-0.07,2.55-0.41,3.71-1c0.18-0.23,1.1-1.44,1.41-3.34c0.2-1.23,0.12-2.49-0.26-3.76C14.44,9.277,13.55,7.717,12.25,6.187z'
						/>
					</g>
				</svg>
			);
			break;
		default:
			return null;
	}

	return icon;
};

export default SVGIcon;
