import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Images
import Pause from '../../../../img/pause.png';

// Utils
import formatDate from '../../../../utils/formatDate';
import abbrNum from '../../../../utils/abbrNum';

const TopPodcast = props => {
	const audioClip = useRef(null);
	const progressBar = useRef(null);

	const [playing, setPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const [duration, setDuration] = useState('00:00');
	const [current, setCurrent] = useState('00:00');

	const playHandler = () => {
		audioClip.current.play();
		setPlaying(true);
	};

	const pauseHandler = () => {
		audioClip.current.pause();
		setPlaying(false);
	};

	const clickHandler = e => {
		const x = e.pageX - progressBar.current.offsetLeft;
		const percentage = (x / progressBar.current.offsetWidth) * 100;

		audioClip.current.currentTime =
			(audioClip.current.duration / 100) * percentage;
	};

	const playBackHandler = () => {
		setProgress(
			Math.floor(
				(audioClip.current.currentTime / audioClip.current.duration) *
					100
			)
		);

		setCurrent(
			new Date(audioClip.current.currentTime * 1000)
				.toISOString()
				.substr(14, 5)
		);
	};

	const durationHandler = () => {
		if (audioClip.current) {
			setDuration(
				new Date(audioClip.current.duration * 1000)
					.toISOString()
					.substr(14, 5)
			);
		}
	};

	const rewindHandler = () => {
		setCurrent((audioClip.current.currentTime -= 10));
	};
	const fastForwardHandler = () => {
		setCurrent((audioClip.current.currentTime += 10));
	};

	const endHandler = () => {
		setPlaying(false);
	};

	useEffect(() => {
		const clip = audioClip.current;
		clip.addEventListener('timeupdate', playBackHandler);
		clip.addEventListener('loadedmetadata', durationHandler);
		clip.addEventListener('ended', endHandler);

		return () => {
			clip.removeEventListener('timeupdate', playBackHandler);
			clip.removeEventListener('loadedmetadata', durationHandler);
			clip.removeEventListener('ended', endHandler);
		};
	}, []);

	if (audioClip.current) {
		audioClip.current.addEventListener('timeupdate', playBackHandler);
		audioClip.current.addEventListener('loadedmetadata', durationHandler);
	}

	return (
		<div className='top-podcasts__grid--item'>
			<div className='top-podcasts__grid--item_image'>
				<img
					src={props.podcast.channel.urls.logo_image.original}
					alt='Logo for podcast'
				/>
			</div>
			<div className='top-podcasts__grid--item_stats'>
				<span>
					{props.podcast &&
						formatDate(new Date(props.podcast.uploaded_at))}
				</span>
				<span>
					{props.podcast && abbrNum(props.podcast.counts.plays, 1)}{' '}
					Listens
				</span>
			</div>
			<p>{props.podcast && props.podcast.title}</p>
			<div className='top-podcasts__grid--item_controls'>
				<audio
					ref={audioClip}
					src={props.podcast && props.podcast.urls.high_mp3}
				></audio>
				<div className='top-podcasts__grid--item_controls-bar'>
					<div
						ref={progressBar}
						onClick={e => clickHandler(e)}
						className='progress-bar'
					>
						<div style={{ width: `${progress}%` }}></div>
					</div>
					<div className='progress-bar-time'>
						<small>{current}</small>
						<small>{duration}</small>
					</div>
				</div>
				<div className='top-podcasts__grid--item_controls-buttons'>
					<button onClick={rewindHandler}>
						<svg
							version='1.1'
							id='Layer_1'
							xmlns='http://www.w3.org/2000/svg'
							x='0px'
							y='0px'
							viewBox='0 0 40 40'
							style={{
								enableBackground: 'new 0 0 40 40',
							}}
						>
							<g>
								<path
									style={{ fill: '#F08616' }}
									d='M8.983,19.021c0.543,0.011,0.974,0.461,0.962,1.004c-0.002,0.075-0.002,0.15-0.002,0.225
		c-0.001,5.584,4.468,10.173,10.08,10.292c0.075,0.002,0.15,0.002,0.224,0.002c5.585,0.001,10.174-4.468,10.293-10.08
		c0.002-0.075,0.002-0.15,0.002-0.224c0.002-4.626-3.071-8.562-7.31-9.847l-0.019,2.155l-5.576-3.285l5.633-3.187l-0.02,2.283
		c5.339,1.347,9.257,6.169,9.259,11.88c0,0.089-0.001,0.178-0.003,0.266c-0.144,6.684-5.605,12.004-12.259,12.005
		c-0.088,0-0.177-0.001-0.266-0.003C13.297,32.365,7.977,26.904,7.976,20.25c0-0.089,0.001-0.178,0.003-0.267
		C7.991,19.44,8.44,19.009,8.983,19.021z'
								/>
								<path
									style={{ fill: '#F08616' }}
									d='M0,20c0.001,11.046,8.954,19.999,20,20c11.046-0.001,20-8.954,20-20C39.999,8.954,31.046,0.001,20,0
		C8.954,0.001,0.001,8.954,0,20z M1.967,20c0.001-4.983,2.017-9.485,5.282-12.751C10.515,3.984,15.017,1.967,20,1.967
		c4.983,0,9.485,2.017,12.752,5.282c3.265,3.266,5.281,7.768,5.282,12.751c0,4.983-2.017,9.485-5.282,12.751
		c-3.266,3.265-7.769,5.281-12.752,5.282c-4.983-0.001-9.485-2.017-12.751-5.282C3.984,29.485,1.967,24.983,1.967,20z'
								/>
							</g>
							<g>
								<path
									style={{ fill: '#F08616' }}
									d='M17.31,23.568h-0.888v-5.657c-0.214,0.204-0.494,0.408-0.841,0.612s-0.658,0.357-0.935,0.459v-0.858
		c0.496-0.233,0.93-0.516,1.302-0.848c0.372-0.332,0.635-0.654,0.789-0.967h0.572V23.568z'
								/>
								<path
									style={{ fill: '#F08616' }}
									d='M19.584,20.002c0-0.855,0.088-1.543,0.264-2.064c0.176-0.521,0.437-0.923,0.784-1.206
		c0.347-0.283,0.783-0.424,1.309-0.424c0.388,0,0.728,0.078,1.021,0.234s0.534,0.381,0.725,0.676s0.34,0.653,0.449,1.075
		c0.108,0.422,0.163,0.992,0.163,1.709c0,0.848-0.087,1.533-0.261,2.054c-0.174,0.521-0.435,0.924-0.782,1.208
		c-0.347,0.284-0.785,0.427-1.314,0.427c-0.697,0-1.244-0.25-1.642-0.75C19.822,22.34,19.584,21.36,19.584,20.002z M20.496,20.002
		c0,1.187,0.139,1.977,0.417,2.37c0.278,0.393,0.621,0.589,1.028,0.589c0.408,0,0.75-0.197,1.028-0.592
		c0.278-0.395,0.417-1.184,0.417-2.367c0-1.19-0.139-1.981-0.417-2.372s-0.624-0.587-1.038-0.587c-0.408,0-0.733,0.173-0.976,0.518
		C20.649,18.002,20.496,18.816,20.496,20.002z'
								/>
							</g>
						</svg>
					</button>
					{playing ? (
						<button onClick={pauseHandler}>
							<img src={Pause} alt='Pause bars' />
						</button>
					) : (
						<button onClick={playHandler}>
							<FontAwesomeIcon icon={['fas', 'play']} />
						</button>
					)}
					<button onClick={fastForwardHandler}>
						<svg
							version='1.1'
							id='Layer_1'
							xmlns='http://www.w3.org/2000/svg'
							x='0px'
							y='0px'
							viewBox='0 0 40 40'
							style={{
								enableBackground: 'new 0 0 40 40',
							}}
						>
							<g>
								<path
									style={{ fill: '#F08616' }}
									d='M31.017,19.021c-0.543,0.011-0.974,0.461-0.962,1.004c0.002,0.075,0.002,0.15,0.002,0.225
		c0.001,5.584-4.468,10.173-10.08,10.292c-0.075,0.002-0.15,0.002-0.224,0.002c-5.585,0.001-10.174-4.468-10.293-10.08
		c-0.002-0.075-0.002-0.15-0.002-0.224c-0.002-4.626,3.071-8.562,7.31-9.847l0.019,2.155l5.576-3.285l-5.633-3.187l0.02,2.283
		C11.41,9.706,7.492,14.529,7.49,20.24c0,0.089,0.001,0.178,0.003,0.266c0.144,6.684,5.605,12.004,12.259,12.005
		c0.088,0,0.177-0.001,0.266-0.003c6.684-0.144,12.005-5.604,12.005-12.259c0-0.089-0.001-0.178-0.003-0.267
		C32.009,19.44,31.56,19.009,31.017,19.021z'
								/>
								<path
									style={{ fill: '#F08616' }}
									d='M20,0c-11.046,0.001-20,8.954-20,20c0.001,11.046,8.954,19.999,20,20
		c11.046-0.001,19.999-8.954,20-20C39.999,8.954,31.046,0.001,20,0z M32.751,32.751c-3.266,3.265-7.768,5.281-12.751,5.282
		c-4.983-0.001-9.485-2.017-12.752-5.282C3.984,29.485,1.967,24.983,1.967,20c0-4.983,2.017-9.485,5.282-12.751
		C10.515,3.984,15.017,1.967,20,1.967c4.983,0,9.485,2.017,12.751,5.282c3.265,3.266,5.281,7.768,5.282,12.751
		C38.033,24.983,36.016,29.485,32.751,32.751z'
								/>
							</g>
							<g>
								<path
									style={{ fill: '#F08616' }}
									d='M17.31,23.568h-0.888v-5.657c-0.214,0.204-0.494,0.408-0.841,0.612s-0.658,0.357-0.935,0.459v-0.858
		c0.496-0.233,0.93-0.516,1.302-0.848c0.372-0.332,0.635-0.654,0.789-0.967h0.572V23.568z'
								/>
								<path
									style={{ fill: '#F08616' }}
									d='M19.584,20.002c0-0.855,0.088-1.543,0.264-2.064c0.176-0.521,0.437-0.923,0.784-1.206
		c0.347-0.283,0.783-0.424,1.309-0.424c0.388,0,0.728,0.078,1.021,0.234s0.534,0.381,0.725,0.676s0.34,0.653,0.449,1.075
		c0.108,0.422,0.163,0.992,0.163,1.709c0,0.848-0.087,1.533-0.261,2.054c-0.174,0.521-0.435,0.924-0.782,1.208
		c-0.347,0.284-0.785,0.427-1.314,0.427c-0.697,0-1.244-0.25-1.642-0.75C19.822,22.34,19.584,21.36,19.584,20.002z M20.496,20.002
		c0,1.187,0.139,1.977,0.417,2.37c0.278,0.393,0.621,0.589,1.028,0.589c0.408,0,0.75-0.197,1.028-0.592
		c0.278-0.395,0.417-1.184,0.417-2.367c0-1.19-0.139-1.981-0.417-2.372s-0.624-0.587-1.038-0.587c-0.408,0-0.733,0.173-0.976,0.518
		C20.649,18.002,20.496,18.816,20.496,20.002z'
								/>
							</g>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default TopPodcast;
