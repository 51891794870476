import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// Components
import Spinner from '../../../base/Elements/Spinner';
import PodcastGridItem from './PodcastGridItem';

const PodcastGrid = props => {
	const [baseLimit, setBaseLimit] = useState(24);
	const [limit, setLimit] = useState(24);
	const [width, setWidth] = useState(window.innerwidth);
	const [counter, setCounter] = useState(1);

	let textLimit = 30;

	switch (true) {
		case window.innerWidth > 1200:
			textLimit = 35;
			break;
		case window.innerWidth > 992:
			textLimit = 30;
			break;
		case window.innerWidth > 576:
			textLimit = 35;
			break;
		case window.innerWidth > 440:
			textLimit = 40;
			break;
		default:
			textLimit = 30;
	}

	const usePrevious = value => {
		const ref = useRef();

		useEffect(() => {
			ref.current = value;
		}, [value]);

		return ref.current;
	};

	useEffect(() => {
		switch (true) {
			case window.innerWidth < 768:
				setBaseLimit(8);
				setLimit(8);
				break;
			case window.innerWidth < 992:
				setBaseLimit(12);
				setLimit(12);
				break;
			case window.innerWidth < 1800:
				setBaseLimit(20);
				setLimit(20);
				break;
			case window.innerWidth >= 1800:
				setBaseLimit(24);
				setLimit(24);
				break;
			default:
				setBaseLimit(24);
				setLimit(24);
				break;
		}
	}, []);

	const prevWidth = usePrevious(width);

	const widthHandler = () => {
		setWidth(window.innerWidth);
	};

	let resizeId;
	const resize = () => {
		clearTimeout(resizeId);
		resizeId = setTimeout(widthHandler, 500);
	};

	useEffect(() => {
		if (prevWidth !== width) {
			switch (true) {
				case width < 768:
					setLimit(counter * 8);
					setBaseLimit(8);
					break;
				case width < 992:
					setLimit(counter * 12);
					setBaseLimit(12);
					break;
				case width < 1800:
					setLimit(counter * 20);
					setBaseLimit(20);
					break;
				case width >= 1800:
					setLimit(counter * 24);
					setBaseLimit(24);
					break;
				default:
					setLimit(24);
					setBaseLimit(24);
					break;
			}
		}

		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, [widthHandler]);

	const clickHandler = () => {
		setLimit(limit + baseLimit);
		setCounter(counter + 1);
	};

	const podcasts =
		props.podcasts &&
		props.podcasts.map((podcast, index) => {
			if (index >= limit) {
				return;
			}
			const {
				details: {
					uploaded_at: publishedAt,
					title,
					channel: {
						urls: {
							logo_image: { original: src },
						},
					},
					urls: { high_mp3 },
					counts: { plays: listens },
				},
			} = podcast;

			const data = {
				title,
				publishedAt,
				src,
				listens,
				high_mp3,
			};

			return <PodcastGridItem key={index} podcast={data} textLimit={textLimit} />;
		});

	const button = props.loading ? (
		<Spinner />
	) : (
		<button
			onClick={clickHandler}
			className={`cta ${props.podcasts && props.podcasts.length <= limit ? 'disabled' : ''}`}
		>
			{props.podcasts && props.podcasts.length <= limit ? "You've reached the end" : 'Load More'}
		</button>
	);

	return (
		<React.Fragment>
			<section id='podcast-grid-section' className='podcast-grid-section'>
				<div className='container'>
					<div className='podcast-grid-section__container'>
						<h1>{props.title}</h1>
						<div className='podcast-grid'>{podcasts}</div>
					</div>
				</div>
			</section>
			<div className='load-more'>{button}</div>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.youtube.loading,
	};
};

export default connect(mapStateToProps)(PodcastGrid);
