import React from 'react';
import ReactMarkdown from 'react-markdown';

// Images
import Quote from '../../../../img/quote-large.png';

const Bio = props => {
	return (
		<div className='profile-bio-wrapper'>
			<div className='container'>
				<div className='profile-bio'>
					<div className='profile-bio__section'>
						<h2>Bio</h2>
						<ReactMarkdown
							source={
								props.profile &&
								props.profile.current.details.leftBio
							}
							linkTarget='_blank'
						/>
						<img src={Quote} alt='Quote Mark' />
					</div>
					<div className='profile-bio__section'>
						<ReactMarkdown
							source={
								props.profile &&
								props.profile.current.details.rightBio
							}
							linkTarget='_blank'
						/>
						<img src={Quote} alt='Quote Mark' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Bio;
