import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Images
import SmallBlocked from '../../../img/small-blocked.png';

// Utils
import useWindowSize from '../../../utils/useWindowSize';

// Actions
import { getMobileAd } from '../../../actions/contentfulActions';

const MobileAd = props => {
	useEffect(() => {
		props.getMobileAd();
	}, []);

	const [width] = useWindowSize();

	const [show, setShow] = useState(false);

	switch (true) {
		case width > 576 && show:
			setShow(false);
			break;
		case width < 576 && !show:
			setShow(true);
			break;
	}

	const displayAdvert = props.mobileAd && props.mobileAd.displayAd;
	const displayAdsense = props.mobileAd && props.mobileAd.displayAd && props.mobileAd.googleAdsense;

	useEffect(() => {
		if (displayAdvert && displayAdsense && show && window.refreshBid && window.killSlot) {
			window.killSlot(['snack_mex10']);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}
	}, [displayAdvert, displayAdsense, show]);

	useEffect(() => {
		if (displayAdvert && props.adBlockDetected) {
			const ad = document.querySelector('#snack_mex10');

			if (ad && ad.innerHTML.replace(/\s/g, '').length === 0 && show) {
				const element = document.createElement('img');
				element.src = SmallBlocked;

				const container = document.createElement('div');
				container.classList.add('blocked-image');
				container.appendChild(element);

				ad.appendChild(container);
			}
		}
	}, [displayAdvert, displayAdsense, show, props.adBlockDetected]);

	const adLink = props.mobileAd && props.mobileAd.adLink;
	const adImage = props.mobileAd && props.mobileAd.adImage;
	const adLink2 = props.mobileAd && props.mobileAd.adLink2;
	const adImage2 = props.mobileAd && props.mobileAd.adImage2;
	const adLink3 = props.mobileAd && props.mobileAd.adLink3;
	const adImage3 = props.mobileAd && props.mobileAd.adImage3;

	const adArray = [];

	if (adLink && adImage) {
		adArray.push({ adLink, adImage });
	}
	if (adLink2 && adImage2) {
		adArray.push({ adLink: adLink2, adImage: adImage2 });
	}
	if (adLink3 && adImage3) {
		adArray.push({ adLink: adLink3, adImage: adImage3 });
	}

	const [position, setPosition] = useState(false);

	// The changed state is purely there to make sure the useEffect containing setTimeout only runs when the slide state is updated by the timeout, not by a user's click. Otherwise it would run the effect every single time the user clicks an indicator, causing a setTimeout to be registered each time.
	const [changed, setChanged] = useState(false);

	useEffect(() => {
		if (adArray.length > 0 && !position) {
			const indexArray = [];

			for (let i = 0; i < adArray.length; i++) {
				if (i === 0) {
					indexArray.push(true);
				} else {
					indexArray.push(false);
				}
			}

			setPosition(indexArray);
			setChanged(!changed);
		}
	}, [props.mobileAd]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (position.length > 0) {
				const positionArray = position;
				// Rotates the array by moving the last element to the front
				positionArray.splice(0, 0, positionArray.pop());

				setPosition([...positionArray]);
				setChanged(!changed);
			}
		}, 20000);

		return () => clearTimeout(timeoutId);
	}, [changed]);

	const clickHandler = index => {
		const newArray = position.map(_ => false);
		newArray[index] = true;

		setPosition(newArray);
	};

	return (
		<div className={`mobile-sticky ${displayAdvert ? 'show' : ''}`}>
			<div className={`mobile-sticky__inner ${displayAdvert && displayAdsense ? 'extra-padding' : ''}`}>
				{displayAdvert && !displayAdsense && (
					<React.Fragment>
						<small>Advertisement</small>
						{adArray.length > 1 && (
							<div className='ad-indicator'>
								{adArray.map((_, index) => (
									<span
										key={index}
										onClick={() => clickHandler(index)}
										className={position[index] ? 'active' : ''}
									></span>
								))}
							</div>
						)}
						{adArray.map((item, index) => (
							<a
								key={index}
								href={item.adLink}
								target='_blank'
								rel='nofollow noopener'
								className={position[index] ? 'show' : ''}
							>
								<img src={item.adImage.src} alt={item.adImage.alt} title={item.adImage.title} />
							</a>
						))}
					</React.Fragment>
				)}
				{displayAdvert && displayAdsense && show && (
					<div
						id='snack_mex10'
						ref={el => {
							if (el) {
								el.style.setProperty('width', '320px', 'important');
								el.style.setProperty('height', '50px', 'important');
								el.style.setProperty('max-width', '320px', 'important');
								el.style.setProperty('max-height', '50px', 'important');
								el.style.setProperty('overflow', 'hidden', 'important');
							}
						}}
					></div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		mobileAd: state.contentful.mobileAd,
		adBlockDetected: state.general.adBlockDetected,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getMobileAd: () => dispatch(getMobileAd()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileAd);
