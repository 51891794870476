import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { CountUp } from 'countup.js';

// Utils
import counterAbbr from '../../../utils/counterAbbr';

// Images
import WhiteCircle from '../../../img/circle.png';
import RedCircle from '../../../img/circle2.png';
import abbrNum from '../../../utils/abbrNum';

const threshold = [0, 0.5, 0.75, 1];

const Badge = props => {
	const [largeRef, viewLarge, largeEntry] = useInView({
		threshold,
	});

	const [smallRef, viewSmall, smallEntry] = useInView({
		threshold,
	});

	if (viewLarge && largeEntry.intersectionRatio < 0.6) {
		const [largeStat, suffix] = counterAbbr(props.largeStat, 1);
		const countUp = new CountUp(largeEntry.target, largeStat, {
			separator: '',
			decimalPlaces: largeStat % 1 !== 0,
			suffix,
		});
		countUp.start();
	}

	if (viewSmall && smallEntry.intersectionRatio < 0.6) {
		const [smallStat, suffix] = counterAbbr(props.smallStat, 1);
		const countUp = new CountUp(smallEntry.target, smallStat, {
			separator: '',
			decimalPlaces: smallStat % 1 !== 0,
			suffix,
		});
		countUp.start();
	}

	const [largeStat] = counterAbbr(props.largeStat, 1);
	let smallStat = null;

	if (props.smallStat || props.smallStat === 0) {
		const [smallNumber] = counterAbbr(props.smallStat, 1);
		smallStat = smallNumber;
	}

	return (
		<div className='social-reach__icons--wrapper'>
			<div>
				<div className='large-circle'>
					<img src={WhiteCircle} alt='White Circle' />
					<span ref={largeRef}>{abbrNum(props.largeStat, 1)}</span>
					{(props.smallStat || props.smallStat === 0) && (
						<div className='small-circle'>
							<img src={RedCircle} alt='Red Circle' />
							<span ref={smallRef}>
								{abbrNum(props.smallStat, 1)}
							</span>
							<img src={props.smallIcon} alt={props.smallAlt} />
						</div>
					)}
				</div>
			</div>
			<div className='social-reach__icons--wrapper_icon'>
				<img src={props.bottomIcon} alt={props.bottomAlt} />
			</div>
		</div>
	);
};

export default Badge;
