import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import NotFound from '../../../pages/NotFound';

// Images
import Logo from '../../../../img/logo.png';
import RedCrumb from '../../../../img/red-breadcrumb.svg';
import GrayCrumb from '../../../../img/gray-breadcrumb.svg';
import Uploader from '../../../../img/uploader-image.png';
import LogoPlus from '../../../../img/logo-plus-large.png';
import Click from '../../../../img/click.png';

// Actions
import { getSingleVideo, getTransferPlaylist } from '../../../../actions/youtubeActions';

// Utils
import shortDate from '../../../../utils/shortDate';
import truncateText from '../../../../utils/truncateText';
import useWindowSize from '../../../../utils/useWindowSize';

// Components
import RecommendedBar from '../RecommendedBar';
import PlaylistSlider from './PlaylistSlider';
import ContentRec from '../../../base/Elements/ContentRec';

const SingleVideo = props => {
	const [autoPlay, setAutoPlay] = useState(false);

	useEffect(() => {
		props.getSingleVideo(props.match.params.videoId);
		props.getTransferPlaylist();
	}, []);

	const loadVideo = () => {
		// the Player object is created uniquely based on the id in props
		const player = new window.YT.Player('player', {
			videoId: props.match.params.videoId,
			events: {
				onReady: onPlayerReady,
				onStateChange: onPlayerStateChange,
			},
		});
	};

	const onPlayerReady = event => {
		event.target.playVideo();
	};

	// Autoplay feature
	const onPlayerStateChange = event => {
		console.log(props);
		if (event.data === 0) {
			const random = Math.floor(Math.random() * 49) + 1;
			axios
				.get('/api/youtube/latest')
				.then(response => {
					const { videoId } = response.data[random];
					props.history.push(`/videos/single/${videoId}`);
				})
				.catch(err => console.log(err));
		}
	};

	useEffect(() => {
		if (!window.YT) {
			// If not, load the script asynchronously
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';

			// onYouTubeIframeAPIReady will load the video after the script is loaded
			window.onYouTubeIframeAPIReady = loadVideo;

			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		} else {
			// If script is already there, load the video directly
			loadVideo();
		}
	}, []);

	const [width, height] = useWindowSize();

	let limit = 40;

	if (width >= 370) {
		limit = 60;
	}

	if (width >= 440) {
		limit = 80;
	}

	if(props.single == null && props.single !== undefined) {
		return <NotFound />
	}

	return (
		<main>
			<Helmet>
				<title>{`${props.single ? props.single.title : 'Videos'} | The United Stand`}</title>
			</Helmet>
			<div className='video-wrapper'>
				<div className='container'>
					<div className='video-top'>
						<div className='video-top__left'>
							<div className='video-top__left--breadcrumbs'>
								<Link to='/' className='video-top__left--breadcrumbs_icon' style={{ width: '95px' }}>
									<img src={RedCrumb} alt='Red Arrow pointing right' />
									<div className='breadcrumb-content'>
										<img src={Logo} alt='The United Stand Logo' style={{ width: '15px' }} />
										<span>Home</span>
									</div>
								</Link>
								<Link
									to='/videos'
									className={`video-top__left--breadcrumbs_icon`}
									style={{ width: 95 }}
								>
									<img src={GrayCrumb} alt='Gray arrow pointing right' />
									<div className='breadcrumb-dynamic-content'>
										<span>Videos</span>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='single-video-section'>
				<div className='container'>
					<div className='single-video-section__container'>
						<div className='video-area'>
							<div className='video-area__top'>
								<div className='video-area__top--heading'>
									<h1>{props.single && truncateText(props.single.title, limit)}</h1>
									<a
										href='https://www.youtube.com/channel/UCMmVPVb0BwSIOWVeDwlPocQ?sub_confirmation=1'
										target='_blank'
										rel='noopener noreferrer'
										className='video-area__top--heading_subscribe'
									>
										<img src={Click} alt="Icon of a red hand clicking with it's index finger" />
										<span>Subscribe</span>
									</a>
								</div>
								<div className='video-area__top--video'>
									<div id='player'></div>
								</div>
								<div className='video-area__top--stats'>
									<div className='video-area__top--stats_views'>
										<span>
											{props.single &&
												new Intl.NumberFormat('en-GB', {
													style: 'decimal',
												}).format(props.single.views)}
										</span>
										<span>views</span>
									</div>
									<div className='autoplay-button'>
										<span>Auto-Play</span>
										<input type='checkbox' className='tgl tgl-ios' id='cb2' />
										<label
											onClick={() => setAutoPlay(!autoPlay)}
											htmlFor='cb2'
											className='tgl-btn'
										></label>
									</div>
								</div>
							</div>
							<div className='video-area__middle'>
								<div className='video-area__middle--details'>
									<img src={Uploader} alt='The United Stand Logo' />
									<div className='video-area__middle--details_text'>
										<p>
											Uploaded By <span>The United Stand to YouTube</span>
										</p>
										<p>{props.single && shortDate(props.single.publishedAt)}</p>
									</div>
								</div>
								<div className='video-area__middle--share'></div>
							</div>
							<div className='video-area__bottom'>
								<div className='video-area__bottom--left'>
									{props.single && (
										<ReactMarkdown
											source={truncateText(props.single.description, 350)}
											linkTarget='_blank'
										/>
									)}
									<hr />
									{props.single && props.single.tags && (
										<React.Fragment>
											<h2>Tags</h2>
											<div className='video-area__bottom--left_tags'>
												{props.single.tags.map(tag => (
													<span>{tag}</span>
												))}
											</div>
										</React.Fragment>
									)}
								</div>
								<div className='video-area__bottom--right'>
									<h2>For Exclusive Content</h2>
									<div className='video-area__bottom--right_cta'>
										<img src={LogoPlus} alt='The United Stand Plus Logo' />
										<h3>Join United Stand Plus</h3>
										<Link to='/join-tus' className='cta cta-large yellow'>
											Join Now
										</Link>
									</div>
								</div>
							</div>
						</div>
						<RecommendedBar />
					</div>
				</div>
			</div>
			<PlaylistSlider playlist={props.transferPlaylist && props.transferPlaylist} />
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		single: state.youtube.single,
		transferPlaylist: state.youtube.transferPlaylist,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getSingleVideo: videoId => dispatch(getSingleVideo(videoId)),
		getTransferPlaylist: () => dispatch(getTransferPlaylist()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleVideo);
