import * as actionTypes from '../actions/types';

const initialState = {
	playlists: {
		content: [],
	},
	chatMessages: [],
	polling: false,
	loading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_LATEST_UPLOADS:
			return {
				...state,
				latest: action.payload,
			};
		case actionTypes.GET_SOCIAL_STATS:
			return {
				...state,
				socialStats: action.payload,
			};
		case actionTypes.GET_PLAYLISTS:
			return {
				...state,
				playlists: {
					pageToken: action.payload.pageToken,
					content: action.payload.content,
				},
			};
		case actionTypes.SET_LOADING:
			return {
				...state,
				loading: !state.loading,
			};
		case actionTypes.GET_RECOMMENDED_VIDEOS:
			return {
				...state,
				recommended: action.payload,
			};
		case actionTypes.GET_TRANSFER_PLAYLIST:
			return {
				...state,
				transferPlaylist: action.payload,
			};
		case actionTypes.GET_POPULAR_VIDEOS:
			return {
				...state,
				mostPopular: action.payload,
			};
		case actionTypes.GET_SINGLE_VIDEO:
			return {
				...state,
				single: action.payload,
			};
		case actionTypes.GET_CHAT_MESSAGES:
			const lastElement = state.chatMessages[state.chatMessages.length - 1];
			let newArray;

			if (lastElement) {
				const lastId = lastElement.id;
				const newIndex = action.payload.findIndex(item => item.id === lastId);
				newArray = [...state.chatMessages, ...action.payload.splice(0, newIndex + 1)];
			}

			newArray = [...state.chatMessages, ...action.payload];

			return {
				...state,
				polling: true,
				chatMessages: newArray,
			};
		case actionTypes.STOP_POLLING:
			return {
				...state,
				polling: false,
			};
		default:
			return state;
	}
};
