import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Images
import Logo from '../../../img/logo.png';
import RedCrumb from '../../../img/red-breadcrumb.svg';
import LightGrayCrumb from '../../../img/light-gray-breadcrumb.svg';

// Actions
import { setFilter } from '../../../actions/contentfulActions';

const SearchHeader = props => {
	const [width, setWidth] = useState(false);

	const [section, setSection] = useState(false);

	const locationArray = props.location.pathname.split('/');
	locationArray.shift();

	useEffect(() => {
		if (locationArray.length === 2) {
			// const formattedString = locationArray[1]
			// 	.split('-')
			// 	.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
			// 	.join(' ');

			setSection(locationArray[1]);
		}
	}, []);

	useEffect(() => {
		const content = document.querySelector(
			'.breadcrumb-dynamic-content > span'
		);

		if (content) {
			setWidth(Math.ceil(content.getBoundingClientRect().width));
		}
	}, [section]);

	return (
		<div className='search-wrapper'>
			<div className='container'>
				<div className='search-top'>
					<div className='search-top__left'>
						<div className='search-top__left--breadcrumbs'>
							<Link
								to='/'
								className='search-top__left--breadcrumbs_icon'
								style={{ maxWidth: '95px' }}
							>
								<img
									src={RedCrumb}
									alt='Red Arrow pointing right'
								/>
								<div className='breadcrumb-content'>
									<img
										src={Logo}
										alt='The United Stand Logo'
										style={{ maxWidth: '15px' }}
									/>
									<span>Home</span>
								</div>
							</Link>
							<div
								className={`search-top__left--breadcrumbs_icon`}
								style={{ maxWidth: width + 80 }}
							>
								<img
									src={LightGrayCrumb}
									alt='Gray arrow pointing right'
								/>
								<div className='breadcrumb-dynamic-content'>
									<span>Search Results</span>
								</div>
							</div>
						</div>
						<div className='search-top__left--title'>
							<h1>Search Results </h1>
							<h1>for {`'${section ? section : ''}'`}</h1>
						</div>
					</div>
					<div className='search-top__right'>
						<button
							className={
								props.searchFilter === 'articles'
									? 'active'
									: ''
							}
							onClick={() => props.setFilter('articles')}
						>
							<span>Articles</span>
						</button>
						<button
							className={
								props.searchFilter === 'transfers'
									? 'active'
									: ''
							}
							onClick={() => props.setFilter('transfers')}
						>
							<span>Transfer News</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		searchFilter: state.contentful.searchFilter,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setFilter: string => dispatch(setFilter(string)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SearchHeader));
