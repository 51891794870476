import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getPopularVideos } from '../../../../actions/youtubeActions';

// Components
import VideoHeader from '../Elements/VideoHeader';
import FeaturedVideos from '../Elements/FeaturedVideos';
import VideoGrid from '../Elements/VideoGrid';
import ContentRec from '../../../base/Elements/ContentRec';

const PopularVideos = props => {
	useEffect(() => {
		props.getPopularVideos();
	}, []);

	return (
		<main>
			<VideoHeader />
			<FeaturedVideos heading='Most Popular Videos' videos={props.popular && props.popular} />
			<VideoGrid title='Most Popular Videos' videos={props.popular && props.popular} />
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		popular: state.youtube.mostPopular,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPopularVideos: () => dispatch(getPopularVideos()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PopularVideos);
