import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Images
// import Bell from '../../../img/bell-icon.png';

// Utils
import SVGIcon from '../../base/Elements/SVGIcon';

// Actions
import { getPastDayContent } from '../../../actions/rankingActions';

// Components
import TimelineHeader from './Elements/TimelineHeader';
import NewsTimeline from './Elements/NewsTimeline';
import FeaturedAuthors from '../Articles/Elements/FeaturedAuthors';
import RecommendedBar from '../Articles/Elements/RecommendedBar';
import ContentRec from '../../base/Elements/ContentRec';

const Timeline = props => {
	useEffect(() => {
		props.getPastDayContent();
	}, []);

	return (
		<main>
			<Helmet>
				<title>Timeline | The United Stand</title>
			</Helmet>
			<TimelineHeader />
			<div className='main-timeline-wrapper'>
				<div className='container'>
					<div className='main-timeline'>
						<div className='main-timeline__left'>
							<div className='main-timeline__left--message'>
								{/* <img src={Bell} alt='Bell Icon' /> */}
								<SVGIcon icon='bell-icon' />
								<h3>Stay Up-To-Date</h3>
								<p>
									Here you'll find the very latest content from The United Stand in chronological
									order for the last 24 hours.
								</p>
							</div>
							<NewsTimeline content={props.pastDay} />
						</div>
						<div className='main-timeline__right'>
							<RecommendedBar />
						</div>
					</div>
				</div>
			</div>
			<RecommendedBar carousel={true} classes='hide-desktop extra-margin no-minus-margin' id='top-stories' />
			<ContentRec />
			<FeaturedAuthors />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		pastDay: state.ranking.pastDay,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPastDayContent: () => dispatch(getPastDayContent()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
