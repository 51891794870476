import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import removeMd from 'remove-markdown';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import he from 'he';
import striptags from 'striptags';

// Images
import LargeBlocked from '../../../../img/large-blocked.png';
import Placeholder from '../../../../img/article-placeholder.jpg';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';
import truncateText from '../../../../utils/truncateText';
import abbrNum from '../../../../utils/abbrNum';
import useWindowSize from '../../../../utils/useWindowSize';

// Components
import SVGIcon from '../../../base/Elements/SVGIcon';
import NavIcon from '../../../base/Elements/NavIcon';
import Sponsor from './Sponsor';

const ArticleGrid = props => {
	const [width] = useWindowSize();
	const [limit, setLimit] = useState(8);

	const [renderSponsor, setRenderSponsor] = useState(false);

	const smallLimit = width < 440 ? 45 : 75;
	const normalLimit = 125;

	const normalTitleLimit = 100;

	useEffect(() => {
		switch (true) {
			case ((width >= 992 && width < 1200) || width >= 1800) && renderSponsor !== 'large':
				setRenderSponsor('large');
				break;
			case ((width < 1800 && width >= 1200) || width < 992) && renderSponsor !== 'small':
				setRenderSponsor('small');
				break;
		}
	}, [width]);

	let adRendered = false;

	// useEffect(() => {
	// 	if (renderSponsor && adRendered && window.refreshBid) {
	// 		setTimeout(() => {
	// 			window.refreshBid(['snack_dex4', 'snack_mex11']);
	// 		}, 100);
	// 	}
	// }, [renderSponsor, adRendered]);

	const sponsorRef = useCallback(
		node => {
			if (node !== null && props.adBlockDetected) {
				const child = node.children && node.children[0];

				if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
					const container = document.createElement('div');
					container.classList.add('blocked-image');
					const image = document.createElement('img');
					image.src = LargeBlocked;
					container.appendChild(image);
					node.appendChild(container);
					node.removeChild(child);
				}
			}

			if (node !== null && !props.adBlockDetected && window.refreshBid && window.killSlot) {
				window.killSlot(['snack_dex4', 'snack_mex11']);
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}
		},
		[props.adBlockDetected, renderSponsor, window.refreshBid]
	);

	const posts =
		!props.quiz && props.latestPosts
			? props.latestPosts.map((post, index) => {
					if (index >= limit) {
						return;
					}

					let sponsor = null;
					let adUnit = null;

					if (index === 1) {
						sponsor = <Sponsor key={index + 1} adverts={props.adverts} top={true} classes='in-article' />;
					}

					if (index === 2) {
						adUnit = (
							<div key={index + 1} ref={sponsorRef} className='sponsor-container'>
								{(width >= 992 && width < 1200) || width >= 1800 ? (
									<div
										id='snack_dex4'
										ref={el => {
											if (el) {
												el.style.setProperty('width', '728px', 'important');
												el.style.setProperty('height', '90px', 'important');
												el.style.setProperty('max-width', '728px', 'important');
												el.style.setProperty('max-height', '90px', 'important');
												el.style.setProperty('overflow', 'hidden', 'important');
											}
										}}
									></div>
								) : (
									<div
										id='snack_mex11'
										ref={el => {
											if (el) {
												el.style.setProperty('width', '320px', 'important');
												el.style.setProperty('height', '50px', 'important');
												el.style.setProperty('max-width', '320px', 'important');
												el.style.setProperty('max-height', '50px', 'important');
												el.style.setProperty('overflow', 'hidden', 'important');
											}
										}}
									></div>
								)}
							</div>
						);

						adRendered = true;
					}

					if (post.questions) {
						return (
							<>
								{sponsor}
								{adUnit}
								<Link
									to={`/articles/quizzes/${post.slug}`}
									key={index}
									className='article-grid__post quiz'
								>
									<div className='quiz-icon'>
										<NavIcon icon='quiz' />
									</div>
									<div className='article-grid__post--image'>
										<div>
											<img
												src={post.featuredImageSrc.replace(
													'https://cms.theunitedstand.com/wp-content/uploads/',
													'/api/media/wp-media/'
												)}
												alt={post.featuredImageAlt}
											/>
										</div>
									</div>
									<div className='article-grid__post--details'>
										<h2>
											{truncateText(
												post.title,
												width <= 576 && index >= 4 ? smallLimit : normalTitleLimit
											)}
										</h2>
										<hr />
										<div className='article-grid__post--details_stats'>
											<span>The United Stand</span>
											<span>{timeElapsed(post.published)}</span>
										</div>
										<div className='article-grid__post--details_excerpt'>
											<p>
												{truncateText(he.decode(post.subheading.replace(/<[^>]+>/g, '')), 125)}
											</p>
										</div>
									</div>
									<div className='article-grid__post--icons'>
										<div className='article-grid__post--icons_left'>
											<div>
												<FontAwesomeIcon icon={['far', 'heart']} />
												<span>{post.likes ? abbrNum(post.likes, 1) : 0}</span>
											</div>
											<div>
												<SVGIcon icon='eye-icon' />
												<span>{post.views ? abbrNum(post.views, 1) : 0}</span>
											</div>
										</div>
										<div className='article-grid__post--icons_right'>
											<SVGIcon icon='read-icon' />
										</div>
									</div>
								</Link>
							</>
						);
					}

					if (post.players) {
						const postClass = slugify(post.scoreBlock.category, {
							lower: true,
						});

						return (
							<>
								{sponsor}
								{adUnit}
								<Link
									to={`/articles/match-ratings/${post.slug}`}
									key={index}
									className={`article-grid__post rating-${postClass} match-ratings`}
								>
									<div className='article-grid__post--image'>
										<div>
											<img
												src={post.featuredImageSrc.replace(
													'https://cms.theunitedstand.com/wp-content/uploads/',
													'/api/media/wp-media/'
												)}
												alt={post.featuredImageAlt}
											/>
											<div className='article-grid__post--image_banner'>
												<span>Player Ratings</span>
											</div>
										</div>
									</div>
									<div className='article-grid__post--details'>
										<div className='article-grid__post--details_scoreblock'>
											<div className='left-block'>
												<div className='team-logo'>
													<img
														src={post.scoreBlock.homeLogo.src.replace(
															'https://cms.theunitedstand.com/wp-content/uploads/',
															'/api/media/wp-media/'
														)}
														alt={post.scoreBlock.homeLogo.alt}
													/>
												</div>
												<div className='team-score'>
													<div>
														<span>{post.scoreBlock.homeScore}</span>
													</div>
												</div>
											</div>
											<div className='right-block'>
												<div className='team-score'>
													<div>
														<span>{post.scoreBlock.awayScore}</span>
													</div>
												</div>
												<div className='team-logo'>
													<img
														src={post.scoreBlock.awayLogo.src.replace(
															'https://cms.theunitedstand.com/wp-content/uploads/',
															'/api/media/wp-media/'
														)}
														alt={post.scoreBlock.awayLogo.alt}
													/>
												</div>
											</div>
										</div>
										<h2>
											{truncateText(
												post.title,
												width <= 576 && index >= 4 ? smallLimit : normalTitleLimit
											)}
										</h2>
										<hr />
										<div className='article-grid__post--details_stats match-rating'>
											<span>The United Stand</span>
											<span>{timeElapsed(post.published)}</span>
										</div>
									</div>
									<div className='article-grid__post--icons'>
										<div className='article-grid__post--icons_left'>
											<div>
												<FontAwesomeIcon icon={['far', 'heart']} />
												<span>{post.likes ? abbrNum(post.likes, 1) : 0}</span>
											</div>
											<div>
												<SVGIcon icon='eye-icon' />
												<span>{post.views ? abbrNum(post.views, 1) : 0}</span>
											</div>
										</div>
										<div className='article-grid__post--icons_right'>
											<SVGIcon icon='read-icon' />
										</div>
									</div>
								</Link>
							</>
						);
					}

					const article = post.article || post;
					const isArticle = post.article;

					let postClass =
						(article.category && slugify(post.article.category, { lower: true }).replace("'", '')) ||
						(article.scoreBlock && slugify(article.scoreBlock.category, { lower: true })) ||
						(article.categories[0] &&
							slugify(article.categories[0].name, {
								lower: true,
							}).replace("'", ''));

					// Breaking news time check
					const date = new Date();
					date.setHours(date.getHours() - 6);
					const pastDate = date.getTime();
					const postedDate = new Date(article.createdAt).getTime();

					if (postClass === 'breaking-news' && pastDate > postedDate) {
						postClass = 'uncategorised';
					}

					const category =
						article.category ||
						(article.scoreBlock && article.scoreBlock.category) ||
						article.categories[0].name;

					let articleImageSrc = '';

					if (article.featuredImageSrc) {
						articleImageSrc = article.featuredImageSrc.replace(
							'https://cms.theunitedstand.com/wp-content/uploads/',
							'/api/media/wp-media/'
						);
					} else {
						articleImageSrc =
							(article.file &&
								article.file.url.replace(
									'https://cms.theunitedstand.com/wp-content/uploads/',
									'/api/media/wp-media/'
								)) ||
							Placeholder;
					}

					return (
						<>
							{sponsor}
							{adUnit}
							<Link
								to={`/articles/single/${article.slug}`}
								key={index}
								className={`article-grid__post ${article.scoreBlock ? 'rating-' : ''}${postClass}`}
							>
								<div className='article-grid__post--image'>
									<div>
										<img src={articleImageSrc} alt={article.imageAlt || article.featuredImageAlt} />
										<div className='article-grid__post--image_banner'>
											<span>{category}</span>
										</div>
									</div>
								</div>
								<div className='article-grid__post--details'>
									<h2>
										{truncateText(
											article.title,
											width <= 576 && index >= 4 ? smallLimit : normalTitleLimit
										)}
									</h2>
									<hr />
									<div className='article-grid__post--details_stats'>
										<span>{article.authorName || 'The United Stand'}</span>
										<span>
											{article.published
												? timeElapsed(article.published)
												: timeElapsed(article.createdAt)}
										</span>
									</div>
									<div className='article-grid__post--details_excerpt'>
										{isArticle ? (
											<p>
												{truncateText(
													removeMd(article.body),
													width <= 576 && index >= 4 ? smallLimit : normalLimit
												)}
											</p>
										) : (
											<p>
												{truncateText(
													he.decode(striptags(article.body)),
													width <= 576 && index >= 4 ? smallLimit : normalLimit
												)}
											</p>
										)}
									</div>
								</div>
								<div className='article-grid__post--icons'>
									<div className='article-grid__post--icons_left'>
										<div>
											<FontAwesomeIcon icon={['far', 'heart']} />
											<span>{article.likes ? abbrNum(article.likes, 1) : 0}</span>
										</div>
										<div>
											<SVGIcon icon='eye-icon' />
											<span>{article.views ? abbrNum(article.views, 1) : 0}</span>
										</div>
									</div>
									<div className='article-grid__post--icons_right'>
										<SVGIcon icon='read-icon' />
									</div>
								</div>
							</Link>
						</>
					);
			  })
			: props.quiz &&
			  props.latestQuizzes &&
			  props.latestQuizzes.map((item, index) => {
					if (index >= limit) {
						return;
					}

					let sponsor = null;
					let adUnit = null;

					if (index === 1) {
						sponsor = <Sponsor key={index + 1} adverts={props.adverts} top={true} classes='in-article' />;
					}

					if (index === 2) {
						adUnit = (
							<div key={index + 1} ref={sponsorRef} className='sponsor-container'>
								{(width >= 992 && width < 1200) || width >= 1800 ? (
									<div
										id='snack_dex4'
										ref={el => {
											if (el) {
												el.style.setProperty('width', '728px', 'important');
												el.style.setProperty('height', '90px', 'important');
												el.style.setProperty('max-width', '728px', 'important');
												el.style.setProperty('max-height', '90px', 'important');
												el.style.setProperty('overflow', 'hidden', 'important');
											}
										}}
									></div>
								) : (
									<div
										id='snack_mex11'
										ref={el => {
											if (el) {
												el.style.setProperty('width', '320px', 'important');
												el.style.setProperty('height', '50px', 'important');
												el.style.setProperty('max-width', '320px', 'important');
												el.style.setProperty('max-height', '50px', 'important');
												el.style.setProperty('overflow', 'hidden', 'important');
											}
										}}
									></div>
								)}
							</div>
						);

						adRendered = true;
					}

					return (
						<>
							{sponsor}
							{adUnit}
							<Link to={`/articles/quizzes/${item.slug}`} key={index} className='article-grid__post quiz'>
								<div className='quiz-icon'>
									<NavIcon icon='quiz' />
								</div>
								<div className='article-grid__post--image'>
									<div>
										<img
											src={item.featuredImageSrc.replace(
												'https://cms.theunitedstand.com/wp-content/uploads/',
												'/api/media/wp-media/'
											)}
											alt={item.featuredImageAlt}
										/>
									</div>
								</div>
								<div className='article-grid__post--details'>
									<h2>
										{truncateText(
											item.title,
											width <= 576 && index >= 4 ? smallLimit : normalTitleLimit
										)}
									</h2>
									<hr />
									<div className='article-grid__post--details_stats'>
										<span>The United Stand</span>
										<span>{timeElapsed(item.published)}</span>
									</div>
									<div className='article-grid__post--details_excerpt'>
										<p>
											{truncateText(
												he.decode(item.subheading.replace(/<[^>]+>/g, '')),
												width <= 576 && index >= 4 ? smallLimit : normalLimit
											)}
										</p>
									</div>
								</div>
								<div className='article-grid__post--icons'>
									<div className='article-grid__post--icons_left'>
										<div>
											<FontAwesomeIcon icon={['far', 'heart']} />
											<span>{item.likes ? abbrNum(item.likes, 1) : 0}</span>
										</div>
										<div>
											<SVGIcon icon='eye-icon' />
											<span>{item.views ? abbrNum(item.views, 1) : 0}</span>
										</div>
									</div>
									<div className='article-grid__post--icons_right'>
										<SVGIcon icon='read-icon' />
									</div>
								</div>
							</Link>
						</>
					);
			  });

	const button = (
		<button
			onClick={() => setLimit(limit + 6)}
			className={`cta dark ${props.latestPosts && props.latestPosts.length <= limit ? 'disabled' : ''}`}
		>
			{props.latestPosts && props.latestPosts.length <= limit ? "You've reached the end" : 'Older Posts'}
		</button>
	);

	return (
		<div className='article-grid-wrapper'>
			<div className='article-grid'>{posts}</div>
			{button}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		latestPosts: state.contentful.latestPosts,
		latestQuizzes: state.quiz.latestQuizzes,
		adBlockDetected: state.general.adBlockDetected,
	};
};

export default connect(mapStateToProps)(ArticleGrid);
