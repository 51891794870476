// YouTube
export const GET_LATEST_UPLOADS = 'GET_LATEST_UPLOADS';
export const GET_PLAYLIST_INFO = 'GET_PLAYLIST_INFO';
export const GET_SOCIAL_STATS = 'GET_SOCIAL_STATS';
export const GET_PLAYLISTS = 'GET_PLAYLISTS';
export const GET_RECOMMENDED_VIDEOS = 'GET_RECOMMENDED_VIDEOS';
export const GET_TRANSFER_PLAYLIST = 'GET_TRANSFER_PLAYLIST';
export const GET_POPULAR_VIDEOS = 'GET_POPULAR_VIDEOS';
export const GET_SINGLE_VIDEO = 'GET_SINGLE_VIDEO';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const STOP_POLLING = 'STOP_POLLING';

// Contentful
export const GET_FEATURED_BANNER = 'GET_FEATURED_BANNER';
export const GET_FEATURED_ITEM = 'GET_FEATURED_ITEM';
export const GET_LATEST_POSTS = 'GET_LATEST_POSTS';
export const GET_MUST_WATCH = 'GET_MUST_WATCH';
export const GET_TOP_STORY = 'GET_TOP_STORY';
export const GET_AUTHORS = 'GET_AUTHORS';
export const GET_SINGLE_ARTICLE = 'GET_SINGLE_ARTICLE';
export const GET_TOP_LIKED_ARTICLES = 'GET_TOP_LIKED_ARTICLES';
export const GET_AUTHOR_POSTS = 'GET_AUTHOR_POSTS';
export const GET_TEAM = 'GET_TEAM';
export const GET_SINGLE_PROFILE = 'GET_SINGLE_PROFILE';
export const CLEAR_SINGLE_PROFILE = 'CLEAR_SINGLE_PROFILE';
export const SEARCH_ARTICLES = 'SEARCH_ARTICLES';
export const SET_FILTER = 'SET_FILTER';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';
export const GET_ADVERTS = 'GET_ADVERTS';
export const GET_ARTICLE_ADVERTS = 'GET_ARTICLE_ADVERTS';
export const GET_MOBILE_AD = 'GET_MOBILE_AD';
export const GET_PUBLISHED_ARTICLE_MONTHS = 'GET_PUBLISHED_ARTICLE_MONTHS';
export const GET_MEDIA_WATCH = 'GET_MEDIA_WATCH';
export const INCREMENT_CLICK_COUNT = 'INCREMENT_CLICK_COUNT';
export const GET_NEXT_ARTICLE = 'GET_NEXT_ARTICLE';
export const CLEAR_SINGLE_ARTICLE = 'CLEAR_SINGLE_ARTICLE';

// Podcasts
export const GET_LATEST_PODCASTS = 'GET_LATEST_PODCASTS';
export const GET_TOP_PODCASTS = 'GET_TOP_PODCASTS';
export const GET_DATED_PODCASTS = 'GET_DATED_PODCASTS';
export const GET_POPULAR_PODCASTS = 'GET_POPULAR_PODCASTS';

// Rankings
export const GET_TRENDING_CONTENT = 'GET_TRENDING_CONTENT';
export const GET_PAST_DAY_CONTENT = 'GET_PAST_DAY_CONTENT';

// Football
export const GET_FIXTURES = 'GET_FIXTURES';
export const GET_LEAGUE_TABLE = 'GET_LEAGUE_TABLE';

// Social
export const GET_ALL_SOCIAL_MEDIA = 'GET_ALL_SOCIAL_MEDIA';
export const GET_LATEST_TWEETS = 'GET_LATEST_TWEETS';
export const GET_LATEST_FACEBOOK_POSTS = 'GET_LATEST_FACEBOOK_POSTS';
export const GET_LATEST_INSTAGRAM_POSTS = 'GET_LATEST_INSTAGRAM_POSTS';

// Quiz
export const GET_SINGLE_QUIZ = 'GET_SINGLE_QUIZ';
export const GET_LATEST_QUIZZES = 'GET_LATEST_QUIZZES';
export const GET_OTHER_QUIZZES = 'GET_OTHER_QUIZZES';

// Match Ratings
export const GET_SINGLE_MATCH_RATING = 'GET_SINGLE_MATCH_RATING';

export const SET_LOADING = 'SET_LOADING';
export const SET_MODAL = 'SET_MODAL';
export const SET_ADBLOCK_DETECTED = 'SET_ADBLOCK_DETECTED';
