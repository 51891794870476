import * as actionTypes from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ALL_SOCIAL_MEDIA:
			return {
				...state,
				all: action.payload,
			};
		case actionTypes.GET_LATEST_TWEETS:
			return {
				...state,
				twitter: action.payload,
			};
		case actionTypes.GET_LATEST_FACEBOOK_POSTS:
			return {
				...state,
				facebook: action.payload,
			};
		case actionTypes.GET_LATEST_INSTAGRAM_POSTS:
			return {
				...state,
				instagram: action.payload,
			};
		default:
			return state;
	}
};
