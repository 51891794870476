import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Images
import Logo from '../../../img/logo.png';
import RedCrumb from '../../../img/red-breadcrumb.svg';
import LightGrayCrumb from '../../../img/light-gray-breadcrumb.svg';

const LegalHeader = props => {
	const [width, setWidth] = useState([100, 100]);

	useEffect(() => {
		const content = document.querySelectorAll(
			'.breadcrumb-dynamic-content > span'
		);

		if (content) {
			const newWidth = [];
			content.forEach((element, index) => {
				newWidth[index] = Math.ceil(
					element.getBoundingClientRect().width
				);
				setWidth(newWidth);
			});
		}
	}, [props]);

	const location = props.location.pathname
		.substr(1)
		.split('-')
		.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
		.join(' ');

	return (
		<div className='legal-wrapper'>
			<div className='container'>
				<div className='legal-top single'>
					<div className='legal-top__left'>
						<div className='legal-top__left--breadcrumbs'>
							<Link
								to='/'
								className='legal-top__left--breadcrumbs_icon'
								style={{ maxWidth: '95px' }}
							>
								<img
									src={RedCrumb}
									alt='Red Arrow pointing right'
								/>
								<div className='breadcrumb-content'>
									<img
										src={Logo}
										alt='The United Stand Logo'
										style={{ maxWidth: '15px' }}
									/>
									<span>Home</span>
								</div>
							</Link>
							<div
								className='legal-top__left--breadcrumbs_icon'
								style={{ maxWidth: width[0] + 80 }}
							>
								<img
									src={LightGrayCrumb}
									alt='Gray arrow pointing right'
								/>
								<div className='breadcrumb-dynamic-content'>
									<span>Legal Stuff</span>
								</div>
							</div>
							<Link
								to={props.location.pathname}
								className='legal-top__left--breadcrumbs_icon'
								style={{ width: width[1] + 60 }}
							>
								<img
									src={LightGrayCrumb}
									alt='Gray arrow pointing right'
								/>
								<div className='breadcrumb-dynamic-content'>
									<span>{location}</span>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(LegalHeader);
