import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import FormField from './FormField';
import Spinner from './Spinner';

const FormModal = props => {
	const [value, setValue] = useState({ name: '', email: '', message: '' });
	const [errors, setErrors] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);

	const inputHandler = e => {
		setValue({
			...value,
			[e.target.name]: e.target.value,
		});
	};

	const submitHandler = e => {
		e.preventDefault();
		setLoading(true);

		const data = {
			...value,
			location: props.location,
		};

		axios
			.post('/api/contact/enquiry', data)
			.then(response => {
				setLoading(false);
				setSuccess(response.data.success);
			})
			.catch(err => {
				setLoading(false);
				setErrors(err.response.data);
			});
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<button
				type='button'
				onClick={handleOpen}
				className={props.buttonClasses}
			>
				{props.buttonText}
			</button>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className='form-modal-container'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className='form-modal-container__wrapper'>
						<div className='form-modal-container__wrapper--cta'>
							<span onClick={handleClose}>
								<FontAwesomeIcon icon={['fas', 'times']} />
							</span>
						</div>
						<div className='form-modal-container__wrapper--content'>
							{!success ? (
								<form noValidate onSubmit={submitHandler}>
									<FormField
										name='name'
										type='text'
										placeholder='Your Name'
										value={value.name}
										onChange={inputHandler}
										error={errors.name}
									/>
									<FormField
										name='email'
										type='email'
										placeholder='Your Email'
										value={value.email}
										onChange={inputHandler}
										error={errors.email}
									/>
									<FormField
										name='message'
										type='textarea'
										placeholder='Your Message...'
										value={value.message}
										onChange={inputHandler}
										error={errors.message}
									/>
									{loading ? (
										<Spinner classes='white' />
									) : (
										<button
											type='submit'
											className='cta yellow cta-large'
										>
											Send Message
										</button>
									)}
								</form>
							) : (
								<p>{success}</p>
							)}
						</div>
					</div>
				</Fade>
			</Modal>
		</React.Fragment>
	);
};

export default FormModal;
