import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';
import axios from 'axios';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import NotFound from '../../../../pages/NotFound';

// Images
import DefaultImage from '../../../../../img/uploader-image.png';
import Trophy from '../../../../../img/trophy.png';
import LargeBlocked from '../../../../../img/large-blocked.png';
import SmallBlocked from '../../../../../img/small-blocked.png';
import TextAdBlocked from '../../../../../img/blocked-text.jpg';

// Utils
import shortDate from '../../../../../utils/shortDate';
import isEmpty from '../../../../../utils/isEmpty';
import abbrNum from '../../../../../utils/abbrNum';
import useWindowSize from '../../../../../utils/useWindowSize';

// Actions
import { getArticleAdverts, getAdverts } from '../../../../../actions/contentfulActions';
import {
	getSingleMatchRating,
	likeMatchRating,
	unlikeMatchRating,
	incrementShareCount,
} from '../../../../../actions/articleActions';
import { setModal } from '../../../../../actions/generalActions';

// Components
import MatchRatingsHeader from './MatchRatingsHeader';
import TrendingContent from '../../Elements/TrendingContent';
import RecommendedBar from '../../Elements/RecommendedBar';
import ShareModal from '../../../../base/Elements/ShareModal';
import SVGIcon from '../../../../base/Elements/SVGIcon';
import FeaturedAuthors from '../../Elements/FeaturedAuthors';
import ContentRec from '../../../../base/Elements/ContentRec';
import LeagueTable from '../../Elements/LeagueTable';
import Sponsor from '../../Elements/Sponsor';
import LatestVideo from '../../Elements/LatestVideo';
import MatchRatingsComplete from './MatchRatingsComplete';

const SingleArticle = props => {
	const [width] = useWindowSize();

	const [like, setLike] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		props.getSingleMatchRating(props.match.params.slug);
		props.getArticleAdverts();
	}, []);

	const displayAdverts = props.articleAdverts && props.articleAdverts.displayAdverts;

	const [renderSponsor, setRenderSponsor] = useState(false);

	useEffect(() => {
		if (displayAdverts && renderSponsor && window.refreshBid && window.killSlot) {
			window.killSlot([
				'snack_dex2',
				'snack_dex3',
				'snack_dex4',
				'snack_mex11',
				'snack_mex4',
				'snack_mex5',
				'snack_mex6',
				'snack_mex7',
				'snack_mex8',
				'snack_mex9',
			]);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}
	}, [displayAdverts, renderSponsor]);

	const videoAd = useCallback(node => {
		if (node !== null && window.$bp) {
			window.$bp('Brid_13660665', {
				id: '25743',
				width: '16',
				height: '9',
				playlist: '13022',
				autoplayStickyAdOnly: true,
			});
		}
	}, []);

	let adNumber = 0;

	useEffect(() => {
		if (displayAdverts && renderSponsor && adNumber && window.refreshBid && window.killSlot) {
			window.killSlot(['snack_mex4', 'snack_mex5', 'snack_mex6', 'snack_mex7']);

			setTimeout(() => {
				window.refreshBid([
					'snack_dex2',
					'snack_dex3',
					'snack_dex4',
					'snack_mex11',
					'snack_mex4',
					'snack_mex5',
					'snack_mex6',
					'snack_mex7',
					'snack_mex8',
					'snack_mex9',
					'snack_mex10',
					'snack_ldb',
				]);
			}, 100);
		}
	}, [displayAdverts, renderSponsor, adNumber]);

	useEffect(() => {
		if (displayAdverts && props.adBlockDetected) {
			const ads = document.querySelectorAll('.text-sponsor__wrapper--inner');

			for (const ad of ads) {
				const child = ad.children && ad.children[0];
				if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
					const element = document.createElement('img');
					element.src = TextAdBlocked;

					const container = document.createElement('div');
					container.classList.add('blocked-image');
					container.appendChild(element);

					child.parentNode.appendChild(container);
					child.parentNode.removeChild(child);
				}
			}
		}
		// if (displayAdverts) {
		// 	setTimeout(() => {
		// 		const ads = document.querySelectorAll('.text-sponsor__wrapper--inner');

		// 		for (const ad of ads) {
		// 			const child = ad.children && ad.children[0];
		// 			if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
		// 				const element = document.createElement('img');
		// 				element.src = TextAdBlocked;

		// 				const container = document.createElement('div');
		// 				container.classList.add('blocked-image');
		// 				container.appendChild(element);

		// 				child.parentNode.appendChild(container);
		// 				child.parentNode.removeChild(child);
		// 			}
		// 		}
		// 	}, 6000);
		// }
	}, [displayAdverts, props.adBlockDetected]);

	useEffect(() => {
		if (displayAdverts && props.adBlockDetected) {
			const ads = document.querySelectorAll(
				'.article-main__top-sponsor, .article-main__content--middle-sponsor, .article-main__bottom-sponsor'
			);

			for (const ad of ads) {
				const child = ad.children && ad.children[0] && ad.children[0].children && ad.children[0].children[0];

				if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
					const size = ad.classList[1];
					const image = size === 'large' ? LargeBlocked : SmallBlocked;
					const element = document.createElement('img');
					element.src = image;

					const container = document.createElement('div');
					container.classList.add('blocked-image');
					container.appendChild(element);

					child.parentNode.appendChild(container);
					child.parentNode.removeChild(child);
				}
			}
		}
		// if (displayAdverts) {
		// 	setTimeout(() => {
		// 		const ads = document.querySelectorAll(
		// 			'.article-main__top-sponsor, .article-main__content--middle-sponsor, .article-main__bottom-sponsor'
		// 		);

		// 		for (const ad of ads) {
		// 			const child =
		// 				ad.children && ad.children[0] && ad.children[0].children && ad.children[0].children[0];

		// 			if (child && child.innerHTML.replace(/\s/g, '').length === 0) {
		// 				const size = ad.classList[1];
		// 				const image = size === 'large' ? LargeBlocked : SmallBlocked;
		// 				const element = document.createElement('img');
		// 				element.src = image;

		// 				const container = document.createElement('div');
		// 				container.classList.add('blocked-image');
		// 				container.appendChild(element);

		// 				child.parentNode.appendChild(container);
		// 				child.parentNode.removeChild(child);
		// 			}
		// 		}
		// 	}, 2000);
		// }
	}, [displayAdverts, props.adBlockDetected]);

	switch (true) {
		case ((width >= 992 && width < 1200) || width >= 1800) && renderSponsor !== 'large':
			setRenderSponsor('large');
			break;
		case ((width < 1800 && width >= 1200) || width < 992) && renderSponsor !== 'small':
			setRenderSponsor('small');
			break;
	}

	const displayTopAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.topGoogleAdsense;
	const displayMiddleAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.middleGoogleAdsense;
	const displayBottomAdsense =
		props.articleAdverts && props.articleAdverts.displayAdverts && props.articleAdverts.bottomGoogleAdsense;

	useEffect(() => {
		const likedContent = JSON.parse(localStorage.getItem('likedContent'));

		if (!isEmpty(likedContent) && likedContent.includes(props.match.params.slug)) {
			setLike(true);
		} else {
			setLike(false);
		}
	}, [props.single]);

	const likeHandler = () => {
		props.likeMatchRating(props.match.params.slug);
		setLike(true);
	};

	const unlikeHandler = () => {
		props.unlikeMatchRating(props.match.params.slug);
		setLike(false);
	};

	const shareHandler = () => {
		props.setModal();
		props.incrementShareCount(props.match.params.slug);
	};

	const authorImage = DefaultImage;
	const authorAlt = 'The United Stand Logo';
	const authorTitle = 'The United Stand Logo';

	const scoreButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const [playerRatings, setPlayerRatings] = useState(null);

	useEffect(() => {
		if (props.singleMatchRating && props.singleMatchRating.players.length > 0) {
			const players = props.singleMatchRating.players.map(_ => {
				return { score: 0, motm: false };
			});
			setPlayerRatings(players);
		}
	}, [props.singleMatchRating]);

	const [submitted, setSubmitted] = useState(false);

	const voteHandler = (index, number) => {
		if (submitted) {
			return;
		}

		const updatedArray = playerRatings.map((item, itemIndex) => {
			if (index !== itemIndex) {
				return item;
			}

			return { ...item, score: number };
		});

		setPlayerRatings(updatedArray);
	};

	const motmHandler = index => {
		if (submitted) {
			return;
		}

		const updatedArray = playerRatings.map((item, itemIndex) => {
			if (index !== itemIndex) {
				return { ...item, motm: false };
			}

			return { ...item, motm: true };
		});

		setPlayerRatings(updatedArray);
	};

	const [complete, setComplete] = useState(false);

	useEffect(() => {
		const completed =
			playerRatings &&
			playerRatings.filter(rating => rating.score !== 0).length === playerRatings.length &&
			playerRatings.filter(rating => rating.motm).length > 0;

		if (completed) {
			setComplete(true);
		}
	}, [playerRatings]);

	const [motm, setMotm] = useState(null);

	const findAndSetMOTM = array => {
		const highestValueIndex = array.reduce(
			(bestIndexSoFar, currentlyTestedValue, currentlyTestedIndex, array) =>
				currentlyTestedValue.motmVotes > array[bestIndexSoFar].motmVotes
					? currentlyTestedIndex
					: bestIndexSoFar,
			0
		);

		const voteSum = array.reduce((acc, curr) => acc + curr.motmVotes, 0);
		const percentage = Math.round((array[highestValueIndex].motmVotes / voteSum) * 100);

		const manOfTheMatch = {
			player: array[highestValueIndex],
			percentage,
			totalMotmVotes: voteSum,
		};

		setMotm(manOfTheMatch);
	};

	const submitVote = async (recaptchaToken = '') => {
		try {
			const response = await axios.post('/api/articles/match-ratings/vote', {
				playerRatings,
				slug: props.singleMatchRating.slug,
				recaptchaToken
			});

			if (response.data.players) {
				findAndSetMOTM(response.data.players);

				if (localStorage.votedContent) {
					const votedContent = JSON.parse(localStorage.getItem('votedContent'));

					if (votedContent.findIndex(item => item.slug === props.singleMatchRating.slug) === -1) {
						votedContent.push({
							slug: props.singleMatchRating.slug,
							playerRatings,
						});
					}

					localStorage.setItem('votedContent', JSON.stringify(votedContent));
				} else {
					const votedContent = [];

					votedContent.push({
						slug: props.singleMatchRating.slug,
						playerRatings,
					});

					localStorage.setItem('votedContent', JSON.stringify(votedContent));
				}

				setSubmitted(true);
			}
		} catch (err) {
			console.log(err);

			if(err.response && err.response.data.error){
				return setError(err.response.data.error);
			}

			setError(err.message || 'An unknown error occured');
		}
	};

	useEffect(() => {
		if (localStorage.votedContent && props.singleMatchRating) {
			const votedContent = JSON.parse(localStorage.getItem('votedContent'));

			const votedIndex = votedContent.findIndex(item => item.slug === props.singleMatchRating.slug);

			if (votedIndex !== -1) {
				setPlayerRatings(votedContent[votedIndex].playerRatings);
				findAndSetMOTM(props.singleMatchRating.players);
				setSubmitted(true);
			}
		}
	}, [props.singleMatchRating]);

	const [myMotm, setMyMotm] = useState(null);

	useEffect(() => {
		if (submitted) {
			const myVoteIndex = playerRatings.findIndex(item => item.motm);
			setMyMotm(props.singleMatchRating.players[myVoteIndex]);
		}
	}, [submitted]);

	if(props.singleMatchRating == null && props.singleMatchRating !== undefined) {
		return <NotFound />;
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
		>
			<main>
				<Helmet>
					<title>
						{props.singleMatchRating
							? `${props.singleMatchRating.title} | The United Stand`
							: ' Match Ratings | The United Stand'}
					</title>
					<meta property='og:url' content={`${window.location.href}`} />
					<meta property='og:title' content={`${props.singleMatchRating && props.singleMatchRating.title}`} />
					<meta
						property='og:description'
						content={`${props.singleMatchRating && props.singleMatchRating.content}`}
					/>
					<meta property='og:type' content='article' />
					<meta
						property='og:image'
						content={
							props.singleMatchRating &&
							props.singleMatchRating.featuredImageSrc.replace(
								'https://cms.theunitedstand.com/wp-content/uploads/',
								'https://theunitedstand.com/api/media/wp-media/'
							)
						}
					/>
					<meta property='twitter:site' content='@UnitedStandMUFC' />
					<meta property='twitter:card' content='summary_large_image' />
					<meta
						name='twitter:image:src'
						content={
							props.singleMatchRating &&
							props.singleMatchRating.featuredImageSrc.replace(
								'https://cms.theunitedstand.com/wp-content/uploads/',
								'https://theunitedstand.com/api/media/wp-media/'
							)
						}
					/>
					<meta name='twitter:image:width' content='280' />
					<meta name='twitter:image:height' content='480' />
				</Helmet>
				<ShareModal
					shareText={
						submitted && myMotm
							? `I voted for ${myMotm.name} ${myMotm.surname} as MOTM on The United Stand's Match Ratings!`
							: "Check out The United Stand's Match Ratings!"
					}
				/>
				<MatchRatingsHeader />
				<div className='single-article-section'>
					<div className='container'>
						<div className='single-article-grid'>
							<div className='single-article-grid__left'>
								{/* <TrendingContent /> */}
								{width < 1200 && <ContentRec classes='mobile-version' />}
								<RecommendedBar carousel={true} classes='hide-desktop' id='top-stories' />
								<Sponsor adverts={props.adverts} top={true} classes='hide-mobile' />
								<LatestVideo />
								<LeagueTable />
								<Sponsor adverts={props.adverts} bottom={true} classes='margin-mobile' />
							</div>
							<div className='single-article-grid__middle'>
								{props.singleMatchRating && props.singleMatchRating.scoreBlock && (
									<div
										className={`score-block ${slugify(props.singleMatchRating.scoreBlock.category, {
											lower: true,
										})}`}
									>
										<div className='score-block__top'>
											<div className='score-block__top--title'>
												<h2>{props.singleMatchRating.scoreBlock.category}</h2>
											</div>
											{props.singleMatchRating.scoreBlock.matchInfo && (
												<div className='score-block__top--info'>
													<p>{props.singleMatchRating.scoreBlock.matchInfo}</p>
												</div>
											)}
											<div className='score-block__top--status'>
												<p>{props.singleMatchRating.scoreBlock.matchStatus}</p>
											</div>
										</div>
										<div className='score-block__middle'>
											<div className='score-block__middle--left'>
												<div>
													<div className='team-logo'>
														<img
															src={props.singleMatchRating.scoreBlock.homeLogo.src.replace(
																'https://cms.theunitedstand.com/wp-content/uploads/',
																'/api/media/wp-media/'
															)}
															alt={props.singleMatchRating.scoreBlock.homeLogo.alt}
														/>
													</div>
													<div className='team-score'>
														<div>
															<span>{props.singleMatchRating.scoreBlock.homeScore}</span>
														</div>
													</div>
												</div>
												<p>{props.singleMatchRating.scoreBlock.homeTeam}</p>
											</div>
											<div className='score-block__middle--right'>
												<div>
													<div className='team-score'>
														<div>
															<span>{props.singleMatchRating.scoreBlock.awayScore}</span>
														</div>
													</div>
													<div className='team-logo'>
														<img
															src={props.singleMatchRating.scoreBlock.awayLogo.src.replace(
																'https://cms.theunitedstand.com/wp-content/uploads/',
																'/api/media/wp-media/'
															)}
															alt={props.singleMatchRating.scoreBlock.awayLogo.alt}
														/>
													</div>
												</div>
												<p>{props.singleMatchRating.scoreBlock.awayTeam}</p>
											</div>
										</div>
										<div className='score-block__status'>
											<div
												className={`score-block__status--ht ${
													props.singleMatchRating.scoreBlock.homeScoreAggregate ? 'agg' : ''
												}`}
											>
												<div>
													<div>
														<span>{props.singleMatchRating.scoreBlock.homeScoreHT}</span>
													</div>
												</div>
												<p>HT</p>
												<div>
													<div>
														<span>{props.singleMatchRating.scoreBlock.awayScoreHT}</span>
													</div>
												</div>
											</div>
											{props.singleMatchRating.scoreBlock.homeScoreAggregate && (
												<div className='score-block__status--aggregate'>
													<div>
														<div>
															<span>
																{props.singleMatchRating.scoreBlock.homeScoreAggregate}
															</span>
														</div>
													</div>
													<p>Agg</p>
													<div>
														<div>
															<span>
																{props.singleMatchRating.scoreBlock.awayScoreAggregate}
															</span>
														</div>
													</div>
												</div>
											)}
										</div>
										{props.singleMatchRating.scoreBlock.penaltiesHome && (
											<div className='score-block__penalties'>
												<div className='score-block__penalties--balls'>
													<div>
														{props.singleMatchRating.scoreBlock.penaltiesHome.map(
															(item, index) => (
																<div key={index} className={`penalty-ball ${item.shot}`}>
																	<SVGIcon icon='football' />
																</div>
															)
														)}
													</div>
													<div>
														{props.singleMatchRating.scoreBlock.penaltiesAway.map(
															(item, index) => (
																<div key={index} className={`penalty-ball ${item.shot}`}>
																	<SVGIcon icon='football' />
																</div>
															)
														)}
													</div>
												</div>
												<p className='score-block__penalties--text'>
													{props.singleMatchRating.scoreBlock.penaltiesText}
												</p>
											</div>
										)}
										{(props.singleMatchRating.scoreBlock.goalScorersHome ||
											props.singleMatchRating.scoreBlock.goalScorersAway) && (
											<div
												className={`score-block__goals ${
													!props.singleMatchRating.scoreBlock.goalScorersAway
														? 'left'
														: !props.singleMatchRating.scoreBlock.goalScorersHome
														? 'right'
														: 'center'
												}`}
											>
												<div
													className={`score-block__goals--left ${
														props.singleMatchRating.scoreBlock.goalScorersAway ? 'shift' : ''
													}`}
												>
													{props.singleMatchRating.scoreBlock.goalScorersHome &&
														props.singleMatchRating.scoreBlock.goalScorersHome.map(
															(item, index) => (
																<div key={index} className='scorer'>
																	<span>{item.player}</span>
																	<div className='scorer__balls'>
																		{[...Array(+item.goals).keys()].map(
																			(_, subIndex) => (
																				<SVGIcon key={subIndex} icon='football' />
																			)
																		)}
																	</div>
																</div>
															)
														)}
												</div>
												<div className='score-block__goals--right'>
													{props.singleMatchRating.scoreBlock.goalScorersAway &&
														props.singleMatchRating.scoreBlock.goalScorersAway.map(
															(item, index) => (
																<div key={index} className='scorer'>
																	<div className='scorer__balls'>
																		{[...Array(+item.goals).keys()].map(
																			(_, subIndex) => (
																				<SVGIcon key={subIndex} icon='football' />
																			)
																		)}
																	</div>
																	<span>{item.player}</span>
																</div>
															)
														)}
												</div>
											</div>
										)}
									</div>
								)}
								<div className='article-main'>
									{props.singleMatchRating && (
										<div className='article-main__category match-ratings'>
											<h2>Match Ratings</h2>
										</div>
									)}
									{displayAdverts && !displayTopAdsense && (
										<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
											{props.articleAdverts.topHeader && <h3>{props.articleAdverts.topHeader}</h3>}

											<a
												href={props.articleAdverts.topImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.topSrc}
														alt={props.articleAdverts.topAlt}
													/>
												</div>
											</a>
										</div>
									)}
									{displayAdverts && displayTopAdsense && (
										<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
											<div className='sponsor-container'>
												{((width >= 992 && width < 1200) || width >= 1800) &&
												props.articleAdverts.topDimensions === 'large' ? (
													<div
														id='snack_dex4'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '728px', 'important');
																el.style.setProperty('height', '90px', 'important');
																el.style.setProperty('max-width', '728px', 'important');
																el.style.setProperty('max-height', '90px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												) : (
													<div
														id='snack_mex11'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '320px', 'important');
																el.style.setProperty('height', '50px', 'important');
																el.style.setProperty('max-width', '320px', 'important');
																el.style.setProperty('max-height', '50px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												)}
											</div>
										</div>
									)}
									{/* {displayAdverts && (
										<div className='article-main__top-sponsor'>
											<div className='sponsor-container'>
												{((width >= 992 && width < 1200) || width >= 1800) && (
													<div id='snack_dex1'></div>
												)}
												{((width < 1800 && width >= 1200) || (width >= 440 && width < 992)) && (
													<div id='snack_mex1'></div>
												)}
												{width < 440 && <div id='snack_mex4'></div>}
											</div>
										</div>
									)} */}
									{/* {displayAdverts && (
										<div className={`article-main__top-sponsor ${props.articleAdverts.topDimensions}`}>
											{props.articleAdverts.topHeader && <h3>{props.articleAdverts.topHeader}</h3>}

											<a
												href={props.articleAdverts.topImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.topSrc}
														alt={props.articleAdverts.topAlt}
													/>
												</div>
											</a>
										</div>
									)} */}
									<div className='article-main__heading'>
										<h1>{props.singleMatchRating && props.singleMatchRating.title}</h1>
									</div>
									<div className='article-main__info'>
										<div className='article-main__info--author'>
											<div className='article-main__info--author_image'>
												<img src={authorImage} alt={authorAlt} title={authorTitle} />
											</div>
											<div className='article-main__info--author_text'>
												<p>
													Created By <span>The United Stand</span>
												</p>
												<p>
													{props.singleMatchRating &&
														shortDate(props.singleMatchRating.published)}
												</p>
											</div>
										</div>
										<div onClick={shareHandler} className='article-main__info--share cta dark'>
											<span>Share This Page</span>
											<FontAwesomeIcon icon='share-alt' />
										</div>
									</div>
									<div className='article-main__content'>
										<div className='article-main__content--stats'>
											<div>
												<SVGIcon icon='eye-icon' />
												<span>
													{(props.singleMatchRating &&
														props.singleMatchRating.views &&
														abbrNum(props.singleMatchRating.views, 1)) ||
														0}
												</span>
											</div>
											<div>
												<FontAwesomeIcon icon={['far', 'heart']} />
												<span>
													{(props.singleMatchRating &&
														props.singleMatchRating.likes &&
														abbrNum(props.singleMatchRating.likes, 1)) ||
														0}
												</span>
											</div>
											<div>
												<SVGIcon icon='share-arrow-full' />
												<span>
													{(props.singleMatchRating &&
														props.singleMatchRating.shares &&
														abbrNum(props.singleMatchRating.shares, 1)) ||
														0}
												</span>
											</div>
										</div>
										<div className='article-main__content--image'>
											<img
												src={
													props.singleMatchRating &&
													props.singleMatchRating.featuredImageSrc.replace(
														'https://cms.theunitedstand.com/wp-content/uploads/',
														'/api/media/wp-media/'
													)
												}
												alt={props.singleMatchRating && props.singleMatchRating.featuredImageAlt}
											/>
											{/* {props.single && props.single.imageSource && (
												<div className='article-main__content--image_source'>
													<ReactMarkdown source={props.single.imageSource} linkTarget='_blank' />
												</div>
											)} */}
										</div>
										<div className='article-main__content--video'>
											<h6>Content Continues Below</h6>
											<div className='article-main__content--video_playlist_container'>
												<div
													ref={videoAd}
													id='Brid_13660665'
													className='brid'
													style={{ width: 16, height: 9 }}
												></div>
											</div>
										</div>
										{displayAdverts && !displayMiddleAdsense && (
											<div
												className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
											>
												{props.articleAdverts.middleHeader && (
													<h3>{props.articleAdverts.middleHeader}</h3>
												)}

												<a
													href={props.articleAdverts.middleImageLink}
													target='_blank'
													rel='nofollow noopener'
												>
													<div>
														<img
															src={props.articleAdverts.middleSrc}
															alt={props.articleAdverts.middleAlt}
														/>
													</div>
												</a>
											</div>
										)}
										{displayAdverts && displayMiddleAdsense && (
											<div
												className={`article-main__content--middle-sponsor ${props.articleAdverts.middleDimensions}`}
											>
												<div className='sponsor-container'>
													{((width >= 992 && width < 1200) || width >= 1800) &&
													props.articleAdverts.middleDimensions === 'large' ? (
														<div
															id='snack_dex2'
															ref={el => {
																if (el) {
																	el.style.setProperty('width', '728px', 'important');
																	el.style.setProperty('height', '90px', 'important');
																	el.style.setProperty('max-width', '728px', 'important');
																	el.style.setProperty('max-height', '90px', 'important');
																	el.style.setProperty('overflow', 'hidden', 'important');
																}
															}}
														></div>
													) : (
														<div
															id='snack_mex8'
															ref={el => {
																if (el) {
																	el.style.setProperty('width', '320px', 'important');
																	el.style.setProperty('height', '50px', 'important');
																	el.style.setProperty('max-width', '320px', 'important');
																	el.style.setProperty('max-height', '50px', 'important');
																	el.style.setProperty('overflow', 'hidden', 'important');
																}
															}}
														></div>
													)}
												</div>
											</div>
										)}
										{props.singleMatchRating && props.singleMatchRating.content && (
											<div
												className='article-main__content--text quiz'
												dangerouslySetInnerHTML={{
													__html: props.singleMatchRating.content.replace(
														'https://cms.theunitedstand.com/wp-content/uploads/',
														'/api/media/wp-media/'
													),
												}}
											></div>
										)}
										<div className='article-main__content--players'>
											{props.singleMatchRating &&
												props.singleMatchRating.players.length > 0 &&
												props.singleMatchRating.players.map((player, playerIndex) => {
													let adUnit = null;

													if (playerIndex % 3 === 0 && playerIndex !== 0 && playerIndex < 15) {
														if (playerIndex === 3) {
															adNumber = 4;
														}

														adUnit = (
															<div className='text-sponsor'>
																<div className='text-sponsor__wrapper'>
																	<div className='text-sponsor__wrapper--heading'>
																		<small>Content After Advert</small>
																	</div>
																	<div className='text-sponsor__wrapper--inner'>
																		<div
																			id={`snack_mex${adNumber}`}
																			ref={el => {
																				if (el) {
																					el.style.setProperty(
																						'width',
																						'300px',
																						'important'
																					);
																					el.style.setProperty(
																						'height',
																						'250px',
																						'important'
																					);
																					el.style.setProperty(
																						'max-width',
																						'300px',
																						'important'
																					);
																					el.style.setProperty(
																						'max-height',
																						'250px',
																						'important'
																					);
																					el.style.setProperty(
																						'overflow',
																						'hidden',
																						'important'
																					);
																				}
																			}}
																		></div>
																	</div>
																</div>
															</div>
														);

														adNumber++;
													}

													return (
														<>
															{adUnit}
															<div
																key={playerIndex}
																className={`article-main__content--players_card ${player.background_colour}`}
															>
																<div className='profile-pic'>
																	{player.position && (
																		<div className='profile-pic__position'>
																			<span>{player.position}</span>
																		</div>
																	)}
																	<div className='profile-pic__image'>
																		<img
																			src={
																				player.picture &&
																				player.picture.url &&
																				player.picture.url.replace(
																					'https://cms.theunitedstand.com/wp-content/uploads/',
																					'/api/media/wp-media/'
																				)
																			}
																			alt={player.picture.alt}
																		/>
																	</div>
																</div>
																<div className='player-info'>
																	{(player.substitute || player.manager) && (
																		<div className='player-info__sub'>
																			<p>
																				{player.substitute
																					? 'Substitute'
																					: 'Manager'}
																			</p>
																		</div>
																	)}
																	<div className='player-info__name'>
																		<span>{player.name}</span>
																		<span>{player.surname}</span>
																	</div>
																	<div className='player-info__score'>
																		{scoreButtons.map((item, index) => {
																			return (
																				<div>
																					<button
																						key={index}
																						onClick={() =>
																							voteHandler(playerIndex, item)
																						}
																						className={
																							playerRatings &&
																							playerRatings[playerIndex] &&
																							playerRatings[playerIndex]
																								.score === item
																								? 'active'
																								: ''
																						}
																					>
																						<span>{item}</span>
																					</button>
																				</div>
																			);
																		})}
																	</div>
																	<div className='player-info__bottom'>
																		<div
																			onClick={() => motmHandler(playerIndex)}
																			className={`player-info__bottom--motm ${
																				playerRatings &&
																				playerRatings[playerIndex] &&
																				playerRatings[playerIndex].motm
																					? 'active'
																					: ''
																			}`}
																		>
																			<FontAwesomeIcon icon={['far', 'star']} />
																		</div>
																		<div className='player-info__bottom--votes'>
																			<div className='player-info__bottom--votes_number'>
																				<SVGIcon icon='tick-box' />
																				<span>
																					{player.scoreVotes
																						? abbrNum(player.scoreVotes, 1)
																						: 0}
																				</span>
																			</div>
																			<p>Today's Score</p>
																		</div>
																		<div className='player-info__bottom--score'>
																			{playerRatings &&
																			playerRatings[playerIndex] &&
																			playerRatings[playerIndex].score > 0 ? (
																				<React.Fragment>
																					{player.scoreVotes
																						? (
																								player.totalScore /
																								player.scoreVotes
																						).toFixed(1)
																						: '0.0'}
																				</React.Fragment>
																			) : (
																				'?.?'
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</>
													);
												})}
											{!motm ? (
												<div className='article-main__content--players_result'>
													{error ? (
														<p>{error}</p>
													) : complete ? (
														<MatchRatingsComplete submitVote={submitVote} />
													) :  (
														<p>You haven't voted for all players/MOTM yet!</p>
													)}
												</div>
											) : (
												<div
													className={`article-main__content--players_motm ${motm.player.background_colour}`}
												>
													<div className='article-main__content--players_motm-inner'>
														<h2>Man of the match</h2>
														<div className='player'>
															<div className='player__profile'>
																<div>
																	<img
																		src={
																			motm.player.picture &&
																			motm.player.picture.url &&
																			motm.player.picture.url.replace(
																				'https://cms.theunitedstand.com/wp-content/uploads/',
																				'/api/media/wp-media/'
																			)
																		}
																		alt={motm.player.picture.alt}
																	/>
																</div>
															</div>
															<div className='player__trophy'>
																<img src={Trophy} alt='Man of the match trophy' />
															</div>
														</div>
														<p className='player-name'>{`${motm.player.name} ${motm.player.surname}`}</p>
														<div className='motm-percentage'>
															<span>{`${motm.percentage}%`}</span>
														</div>
														<div className='motm-votes'>
															<SVGIcon icon='tick-box' />
															<span>
																{motm.totalMotmVotes ? abbrNum(motm.totalMotmVotes, 1) : 0}
															</span>
														</div>
														<p className='thanks-for-voting'>Thank You for Voting!</p>
														<button
															onClick={() => props.setModal()}
															className='cta yellow cta-large'
														>
															Share Your Vote!
														</button>
													</div>
												</div>
											)}
										</div>
										<div className='article-main__content--bottom'>
											{!like ? (
												<div className='article-main__content--bottom_like'>
													<div
														onClick={likeHandler}
														className='article-main__content--bottom_like-icon'
													>
														<FontAwesomeIcon icon={['far', 'heart']} />
													</div>
													<p>
														Like This Quiz <span>if you enjoyed it!</span>
													</p>
												</div>
											) : (
												<div className='article-main__content--bottom_like'>
													<div
														onClick={unlikeHandler}
														className='article-main__content--bottom_like-icon'
													>
														<FontAwesomeIcon icon='heart' />
													</div>
													<p>Thank you!</p>
												</div>
											)}
											<div onClick={shareHandler} className='article-main__content--bottom_share'>
												<FontAwesomeIcon icon='share-alt' />
											</div>
										</div>
									</div>
									{!displayAdverts && <hr />}
									{displayAdverts && !displayBottomAdsense && (
										<div
											className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
										>
											<div className='sponsor-container'>
												{((width >= 992 && width < 1200) || width >= 1800) &&
												props.articleAdverts.bottomDimensions === 'large' ? (
													<div
														id='snack_dex3'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '728px', 'important');
																el.style.setProperty('height', '90px', 'important');
																el.style.setProperty('max-width', '728px', 'important');
																el.style.setProperty('max-height', '90px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												) : (
													<div
														id='snack_mex9'
														ref={el => {
															if (el) {
																el.style.setProperty('width', '320px', 'important');
																el.style.setProperty('height', '50px', 'important');
																el.style.setProperty('max-width', '320px', 'important');
																el.style.setProperty('max-height', '50px', 'important');
																el.style.setProperty('overflow', 'hidden', 'important');
															}
														}}
													></div>
												)}
											</div>
										</div>
									)}
									{displayAdverts && displayBottomAdsense && (
										<div
											className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
										>
											{props.articleAdverts.bottomHeader && (
												<h3>{props.articleAdverts.bottomHeader}</h3>
											)}

											<a
												href={props.articleAdverts.bottomImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.bottomSrc}
														alt={props.articleAdverts.bottomAlt}
													/>
												</div>
											</a>
										</div>
									)}
									{/* {displayAdverts && (
										<div className={'article-main__bottom-sponsor'}>
											<div className='sponsor-container'>
												{width >= 440 && <div id='snack_mob_bot'></div>}
												{width < 440 && <div id='snack_mex6'></div>}
											</div>
										</div>
									)} */}
									{/* {displayAdverts && (
										<div
											className={`article-main__bottom-sponsor ${props.articleAdverts.bottomDimensions}`}
										>
											{props.articleAdverts.bottomHeader && (
												<h3>{props.articleAdverts.bottomHeader}</h3>
											)}

											<a
												href={props.articleAdverts.bottomImageLink}
												target='_blank'
												rel='nofollow noopener'
											>
												<div>
													<img
														src={props.articleAdverts.bottomSrc}
														alt={props.articleAdverts.bottomAlt}
													/>
												</div>
											</a>
										</div>
									)} */}
									<div className='article-main__footer'>
										{props.singleMatchRating && props.singleMatchRating.tags && (
											<React.Fragment>
												<h2>Tags</h2>
												<div className='article-main__footer--tags'>
													{props.singleMatchRating.tags.map((tag, index) => (
														<Link key={index} to={`/search-results/${tag.name}`}>
															{tag.name}
														</Link>
													))}
												</div>
											</React.Fragment>
										)}
									</div>
								</div>
							</div>
							<div className='single-article-grid__right'>
								<RecommendedBar classes='show-desktop' />
								<TrendingContent classes='hide-mobile' />
							</div>
						</div>
					</div>
				</div>
				<TrendingContent id='trending-content' classes='hide-desktop' />
				{width > 1200 && <ContentRec classes='more-padding' />}
				<FeaturedAuthors classes='single' />
			</main>
		</GoogleReCaptchaProvider>
	);
};

const mapStateToProps = state => {
	return {
		articleAdverts: state.contentful.articleAdverts,
		singleMatchRating: state.articles.singleMatchRating,
		adverts: state.contentful.adverts,
		adBlockDetected: state.general.adBlockDetected,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setModal: () => dispatch(setModal()),
		likeMatchRating: slug => dispatch(likeMatchRating(slug)),
		unlikeMatchRating: slug => dispatch(unlikeMatchRating(slug)),
		incrementShareCount: slug => dispatch(incrementShareCount(slug)),
		getArticleAdverts: () => dispatch(getArticleAdverts()),
		getSingleMatchRating: slug => dispatch(getSingleMatchRating(slug)),
		getAdverts: () => dispatch(getAdverts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleArticle);
