import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLeagueTable } from '../../../../actions/footballActions';

const LeagueTable = props => {
	useEffect(() => {
		props.getLeagueTable();
	}, []);

	return (
		<div id='league-table' className='league-table-section'>
			<h2>League Table</h2>
			<div className='league-table'>
				<div className='league-table__header'>
					<h5>Premier League</h5>
				</div>
				<div className='league-table__body'>
					<table>
						<thead>
							<tr>
								<th>Pos</th>
								<th></th>
								<th>Pld</th>
								<th>GD</th>
								<th>Pts</th>
							</tr>
						</thead>
						<tbody>
							{props.leagueTable &&
								props.leagueTable.length > 0 &&
								props.leagueTable.map((item, index) => (
									<tr
										key={index}
										className={item.teamName === 'Manchester United' ? 'manchester' : ''}
									>
										<td>{item.rank}</td>
										<td>
											<div>
												<img src={item.logo} alt={`${item.teamName} Logo`} />
												<p>{item.teamName}</p>
											</div>
										</td>
										<td>{item.all && item.all.matchsPlayed}</td>
										<td>{item.goalsDiff}</td>
										<td>{item.points}</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		leagueTable: state.football.leagueTable,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLeagueTable: () => dispatch(getLeagueTable()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueTable);
