import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Images
import LogoPlus from '../../../../img/logo-plus-large.png';

// Actions
import { getTrendingContent } from '../../../../actions/rankingActions';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';

// Components
import SVGIcon from '../../../base/Elements/SVGIcon';

const TrendingContent = props => {
	useEffect(() => {
		props.getTrendingContent();
	}, []);

	const list =
		props.trending &&
		props.trending.map((item, index) => {
			const link =
				item.type === 'article' ? `/articles/single/${item.slug}` : `/videos/single/${item.identifier}`;

			return (
				<li key={index} className={`trending-list__item ${index % 2 === 0 ? 'dark' : 'light'}`}>
					<Link to={link}>
						<div className='trending-list__item--top'>
							<span>{index + 1}</span>
							<p>{item.title}</p>
						</div>
						<hr />
						<div className='trending-list__item--bottom'>
							<span>{timeElapsed(item.published)}</span>
							{item.type === 'article' ? <SVGIcon icon='read-icon' /> : <SVGIcon icon='play-button' />}
						</div>
					</Link>
				</li>
			);
		});

	return (
		<div id={props.id ? props.id : ''} className={`trending ${props.classes ? props.classes : ''}`}>
			<h2>Trending</h2>
			<ul className='trending-list'>{list}</ul>
			{/* <div className='trending-cta'>
				<h2>Recommended</h2>
				<div>
					<img src={LogoPlus} alt='The United Stand Plus Logo' />
					<h3>Join United Stand Plus</h3>
					<Link to='/join-tus' className='cta cta-large yellow'>
						Join Now
					</Link>
				</div>
			</div> */}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		trending: state.ranking.trending,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTrendingContent: () => dispatch(getTrendingContent()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendingContent);
