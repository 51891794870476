import React from 'react';
import { connect } from 'react-redux';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	RedditShareButton,
	EmailShareButton,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Material UI
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// Actions
import { setModal } from '../../../actions/generalActions';

const ShareModal = props => {
	return (
		<React.Fragment>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className='share-modal-container'
				open={props.open}
				onClose={() => props.setModal()}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={props.open}>
					<div className='share-modal-container__wrapper'>
						<div className='share-modal-container__wrapper--content'>
							<FacebookShareButton
								url={props.link ? props.link : window.location.href}
								quote={props.shareText ? props.shareText : ''}
							>
								<FontAwesomeIcon icon={['fab', 'facebook-square']} />
								<span>Facebook</span>
							</FacebookShareButton>
							<LinkedinShareButton
								url={props.link ? props.link : window.location.href}
								summary={props.shareText ? props.shareText : ''}
							>
								<FontAwesomeIcon icon={['fab', 'linkedin']} />
								<span>LinkedIn</span>
							</LinkedinShareButton>
							<TwitterShareButton
								url={props.link ? props.link : window.location.href}
								title={props.shareText ? props.shareText : ''}
							>
								<FontAwesomeIcon icon={['fab', 'twitter']} />
								<span>Twitter</span>
							</TwitterShareButton>
							<WhatsappShareButton
								url={props.link ? props.link : window.location.href}
								title={props.shareText ? props.shareText : ''}
							>
								<FontAwesomeIcon icon={['fab', 'whatsapp-square']} />
								<span>Whatsapp</span>
							</WhatsappShareButton>
							<RedditShareButton
								url={props.link ? props.link : window.location.href}
								title={props.shareText ? props.shareText : ''}
							>
								<FontAwesomeIcon icon={['fab', 'reddit-square']} />
								<span>Reddit</span>
							</RedditShareButton>
							<EmailShareButton
								url={props.link ? props.link : window.location.href}
								subject={props.shareText ? props.shareText : ''}
							>
								<FontAwesomeIcon icon={['fa', 'envelope-square']} />
								<span>Email</span>
							</EmailShareButton>
						</div>
						<div className='share-modal-container__wrapper--cta'>
							<button className='cta' onClick={() => props.setModal()}>
								Close
							</button>
						</div>
					</div>
				</Fade>
			</Modal>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		open: state.general.modal,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setModal: () => dispatch(setModal()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
