import React from 'react';
import { Link } from 'react-router-dom';
import removeMd from 'remove-markdown';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';
import truncateText from '../../../../utils/truncateText';
import abbrNum from '../../../../utils/abbrNum';
import SVGIcon from '../../../base/Elements/SVGIcon';

const ArticleGrid = props => {
	const posts =
		props.articles &&
		props.articles.map((post, index) => {
			let postClass = slugify(post.article.category, {
				lower: true,
			}).replace("'", '');

			// Breaking news time check
			const date = new Date();
			date.setHours(date.getHours() - 6);
			const pastDate = date.getTime();
			const postedDate = new Date(post.article.createdAt).getTime();

			if (postClass === 'breaking-news' && pastDate > postedDate) {
				postClass = 'uncategorised';
			}

			return (
				<Link
					to={`/articles/single/${post.article.slug}`}
					key={index}
					className={`article-grid__post ${postClass}`}
				>
					<div className='article-grid__post--image'>
						<div>
							<img
								src={post.article.file && post.article.file.url}
								alt={post.article.imageAlt}
								title={post.article.imageTitle}
							/>
							<div className='article-grid__post--image_banner'>
								<span>{post.article.category}</span>
							</div>
						</div>
					</div>
					<div className='article-grid__post--details'>
						<h2>{truncateText(post.article.title, 100)}</h2>
						<hr />
						<div className='article-grid__post--details_stats'>
							<span>{post.article.authorName}</span>
							<span>{timeElapsed(post.article.createdAt)}</span>
						</div>
						<div className='article-grid__post--details_excerpt'>
							<p>{truncateText(removeMd(post.article.body), 125)}</p>
						</div>
					</div>
					<div className='article-grid__post--icons'>
						<div className='article-grid__post--icons_left'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{post.article.likes ? abbrNum(post.article.likes, 1) : 0}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{post.article.views ? abbrNum(post.article.views, 1) : 0}</span>
							</div>
						</div>
						<div className='article-grid__post--icons_right'>
							<SVGIcon icon='read-icon' />
						</div>
					</div>
				</Link>
			);
		});

	return (
		<div className='article-grid-container'>
			<div className='container'>
				<div className='article-grid-wrapper'>
					<div className='article-grid large'>{posts}</div>
				</div>
			</div>
		</div>
	);
};

export default ArticleGrid;
