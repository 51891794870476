import * as actionTypes from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_FIXTURES:
			return {
				...state,
				fixtures: action.payload,
			};
		case actionTypes.GET_LEAGUE_TABLE:
			return {
				...state,
				leagueTable: action.payload,
			};
		default:
			return state;
	}
};
