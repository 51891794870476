import React, { useState, useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';

// Actions
import { getChatMessages, cancelPolling } from '../../../actions/youtubeActions';

// Utils
import animateScroll from '../../../utils/animateScroll';

const LiveChat = props => {
	const liveChatList = useRef(null);
	const highlightedComment = useRef(null);
	const [input, setInput] = useState('');
	const [timer, setTimer] = useState(null);
	const [error, setError] = useState(null);

	const greenScreen = useCallback(node => {
		if (node) {
			scroller.scrollTo('green-screen', { duration: 1000, smooth: true, offset: -100 });

			if (timer !== null) {
				setTimeout(() => {
					document.documentElement.classList.add('overflow-hidden');
				}, 1200);
			}
		}
	});

	useEffect(() => {
		return () => {
			document.documentElement.classList.remove('overflow-hidden');
		};
	}, []);

	const inputHandler = e => {
		setInput(e.target.value);
	};

	const clickHandler = async liveId => {
		try {
			let scrolled = false;

			if (
				liveChatList &&
				liveChatList.current &&
				liveChatList.current.scrollTop < liveChatList.current.scrollHeight - liveChatList.current.offsetHeight
			) {
				scrolled = true;
			} else {
				scrolled = false;
			}

			const [pollingIntervalMillis, liveChatId] = await props.getChatMessages(input, liveId);

			setTimer(
				setTimeout(() => {
					clickHandler(liveChatId);
				}, pollingIntervalMillis)
			);

			if (liveChatList && !scrolled) {
				animateScroll(liveChatList.current, 1000);
			}

			setError(null);
		} catch (err) {
			console.log(err);
			setError(err.input);
		}
	};

	const stopHandler = () => {
		if (timer) {
			clearTimeout(timer);
			setTimer(null);
			document.documentElement.classList.remove('overflow-hidden');
		}
	};

	const selectName = e => {
		e.target.classList.add('clicked');
		const children = e.target.children;

		if (highlightedComment && highlightedComment.current) {
			highlightedComment.current.classList.remove('show');
		}

		const nameDiv = document.createElement('div');
		nameDiv.classList.add('highlighted-comment__name');
		nameDiv.innerText = children[1].innerText;

		const messageDiv = document.createElement('div');
		messageDiv.classList.add('highlighted-comment__message');
		messageDiv.innerText = children[2].innerText;

		const image = document.createElement('img');
		image.src = children[0].src;

		const imageDiv = document.createElement('div');
		imageDiv.classList.add('highlighted-comment__image');
		imageDiv.appendChild(image);

		if (highlightedComment && highlightedComment.current) {
			setTimeout(() => {
				highlightedComment.current.innerHTML = '';
				highlightedComment.current.appendChild(nameDiv);
				highlightedComment.current.appendChild(messageDiv);
				highlightedComment.current.appendChild(imageDiv);
			}, 500);

			setTimeout(() => {
				highlightedComment.current.classList.add('show');
			}, 600);
		}
	};

	return (
		<main className='live-chat-wrapper'>
			<div className='container'>
				<div className='live-chat'>
					<input
						type='text'
						onChange={e => inputHandler(e)}
						value={input}
						placeholder='URL'
						className={error ? 'error' : ''}
					/>
					{error && <small>{error}</small>}
					{props.chatMessages.length > 0 && (
						<>
							<div ref={greenScreen} id='green-screen' className='live-chat__green-screen'>
								<div ref={highlightedComment} className='highlighted-comment'></div>
							</div>
							<div ref={liveChatList} className='live-chat__list'>
								{props.chatMessages.map(message => {
									const {
										id,
										snippet: { displayMessage },
										authorDetails: { displayName, profileImageUrl },
									} = message;
									return (
										<div key={id} onClick={e => selectName(e)}>
											<img src={profileImageUrl} />
											<span>{displayName}</span>
											<span>{displayMessage}</span>
										</div>
									);
								})}
							</div>
						</>
					)}
					<div className='live-chat__buttons'>
						{!timer && <button onClick={() => clickHandler()}>YouTube Comments</button>}
						{timer && <button onClick={stopHandler}>Stop Comments</button>}
					</div>
				</div>
			</div>
		</main>
	);
};

const mapStateToProps = state => {
	return {
		chatMessages: state.youtube.chatMessages,
		polling: state.youtube.polling,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getChatMessages: (url, liveId) => dispatch(getChatMessages(url, liveId)),
		cancelPolling: () => dispatch(cancelPolling()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
