import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Components
import FeaturedBanner from './FeaturedBanner';
import FeaturedItem from './FeaturedItem';
import FeaturedPosts from './FeaturedPosts';
import SocialBanner from './SocialBanner';
import AwardsBanner from './AwardsBanner';
import Products from './Products';
import Testimonials from './Testimonials';
import Fixtures from './Fixtures';

// Actions
import { getLatestUploads } from '../../../actions/youtubeActions';

const Home = props => {
	useEffect(() => {
		props.getLatestUploads();
	}, []);

	return (
		<main>
			<Helmet>
				<title>The United Stand - Real Fan Opinion for Manchester United Fans</title>
			</Helmet>
			<FeaturedBanner />
			<Fixtures />
			<FeaturedItem />
			<FeaturedPosts />
			<SocialBanner />
			<AwardsBanner />
			<div id='store' className='product-wrapper'>
				<h1>The Store</h1>
				<div className='container'>
					<Products />
				</div>
			</div>
			<div className='container'>
				<Testimonials />
			</div>
		</main>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestUploads: () => dispatch(getLatestUploads()),
	};
};

export default connect(null, mapDispatchToProps)(Home);
