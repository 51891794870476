import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-css';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Images
import SmallLogo from '../../../../img/small-logo.png';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';

// Actions
import { getLatestFacebookPosts } from '../../../../actions/socialActions';
import { setModal } from '../../../../actions/generalActions';

// Components
import SocialHeader from '../Elements/SocialHeader';
import RecommendedBar from '../../Videos/RecommendedBar';
import ShareModal from '../../../base/Elements/ShareModal';
import ContentRec from '../../../base/Elements/ContentRec';

const Facebook = props => {
	useEffect(() => {
		props.getLatestFacebookPosts();
	}, []);

	const [link, setLink] = useState(null);

	const clickHandler = url => {
		setLink(url);
		props.setModal();
	};

	const breakpointColumnsObj = {
		default: 4,
		1440: 3,
		1200: 4,
		992: 3,
		768: 2,
		440: 1,
	};

	const posts =
		props.facebook &&
		props.facebook.map((post, index) => {
			if (index >= 20) {
				return;
			}

			const { publishedAt, message, fullPicture, profilePic, permalink } = post.content;

			return (
				<React.Fragment key={index}>
					<div className='facebook'>
						<div className='facebook__top'>
							<div className='facebook__top--left'>
								<img src={profilePic} alt='Profile picture for The United Stand' />
								<div className='facebook__top--left_text'>
									<p>The United Stand</p>
									<span>{timeElapsed(publishedAt)}</span>
								</div>
							</div>
							<div className='facebook__top--right'>
								<FontAwesomeIcon icon={['fab', 'facebook-square']} />
							</div>
						</div>
						<div className='facebook__content'>
							{fullPicture && <img src={fullPicture} alt='Post picture' />}
							<ReactMarkdown source={message} linkTarget='_blank' />
						</div>
						<div className='facebook__bottom'>
							<a href={permalink} target='_blank' rel='noopener noreferrer'>
								<FontAwesomeIcon icon='thumbs-up' />
							</a>
							<div onClick={() => clickHandler(permalink)}>
								<FontAwesomeIcon icon='share' />
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		});

	return (
		<main>
			<ShareModal link={link} />
			<SocialHeader />
			<div className='social-grid-wrapper'>
				<div className='container'>
					<div className='social-grid'>
						<div className='social-grid__left'>
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className='my-masonry-grid'
								columnClassName='my-masonry-grid_column'
							>
								{posts}
							</Masonry>
						</div>
						<div className='social-grid__right'>
							<RecommendedBar />
						</div>
					</div>
				</div>
			</div>
			<ContentRec classes='dark no-border' />
			{/* <div className='bottom-bar gray'></div> */}
		</main>
	);
};

const mapStateToProps = state => {
	return {
		facebook: state.social.facebook,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestFacebookPosts: () => dispatch(getLatestFacebookPosts()),
		setModal: () => dispatch(setModal()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Facebook);
