import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLatestUploads } from '../../../../actions/youtubeActions';

// Components
import VideoHeader from '../Elements/VideoHeader';
import FeaturedVideos from '../Elements/FeaturedVideos';
import VideoGrid from '../Elements/VideoGrid';
import ContentRec from '../../../base/Elements/ContentRec';

const AllVideos = props => {
	useEffect(() => {
		props.getLatestUploads();
	}, []);

	return (
		<main>
			<VideoHeader />
			<FeaturedVideos heading='All Videos' videos={props.latest && props.latest} />
			<VideoGrid title='All Videos' videos={props.latest && props.latest} />
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		latest: state.youtube.latest,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestUploads: () => dispatch(getLatestUploads()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AllVideos);
