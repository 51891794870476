import * as actionTypes from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SINGLE_QUIZ:
			return {
				...state,
				singleQuiz: action.payload,
			};
		case actionTypes.GET_LATEST_QUIZZES:
			return {
				...state,
				latestQuizzes: action.payload,
			};
		case actionTypes.GET_OTHER_QUIZZES:
			return {
				...state,
				otherQuizzes: action.payload,
			};
		default:
			return state;
	}
};
