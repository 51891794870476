import React from 'react';

const Sponsor = props => {
	return (
		<div className={`banner-offer-item ${props.classes ? props.classes : ''}`}>
			{props.top && props.adverts && props.adverts.topImage && (
				<>
					<h2>Advertisement</h2>
					<a
						href={props.adverts.topImageLink}
						target='_blank'
						rel='noopener noreferrer'
						className='banner-offer-item__link'
					>
						<img
							src={props.adverts.topImage.fields.file.url}
							alt={props.adverts.topImage.fields.description}
							title={props.adverts.topImage.fields.title}
						/>
					</a>
				</>
			)}
			{props.bottom && props.adverts && props.adverts.bottomImage && (
				<>
					<h2>Advertisement</h2>
					<a
						href={props.adverts.bottomImageLink}
						target='_blank'
						rel='noopener noreferrer'
						className='banner-offer-item__link'
					>
						<img
							src={props.adverts.bottomImage.fields.file.url}
							alt={props.adverts.bottomImage.fields.description}
							title={props.adverts.bottomImage.fields.title}
						/>
					</a>
				</>
			)}
		</div>
	);
};

export default Sponsor;
