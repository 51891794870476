import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Actions
import { getTestimonials } from '../../../actions/contentfulActions';

const Testimonials = props => {
	useEffect(() => {
		props.getTestimonials();
	}, []);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const testimonials =
		props.testimonials &&
		props.testimonials.map((item, index) => {
			const { name, company, testimonial } = item;

			return (
				<div key={index} className='testimonials__item'>
					<div className='testimonials__item--top'>
						<h2>{name}</h2>
						{company && <h3>{company}</h3>}
					</div>
					<hr />
					<div className='testimonials__item--bottom'>
						<p>{testimonial}</p>
					</div>
				</div>
			);
		});

	return (
		<div className='testimonials'>
			<h1>Testimonials</h1>
			{testimonials && (
				<Carousel
					arrows={false}
					swipeable={true}
					draggable={true}
					showDots={true}
					responsive={responsive}
					ssr={true} // means to render carousel on server-side.
					infinite={true}
					autoPlaySpeed={1000}
					keyBoardControl={true}
					customTransition='all .5s'
					transitionDuration={500}
					containerClass='carousel-container'
					removeArrowOnDeviceType={['tablet', 'mobile']}
					dotListClass='custom-dot-list-style'
					itemClass='carousel-item-padding-40-px'
				>
					{testimonials}
				</Carousel>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		testimonials: state.contentful.testimonials,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTestimonials: () => dispatch(getTestimonials()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
