import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import NotFound from '../../../pages/NotFound';

// Images
import Logo from '../../../../img/logo.png';

// Actions
import { getSingleProfile, clearSingleProfile } from '../../../../actions/contentfulActions';

// Components
import TeamHeader from '../TeamHeader';
import Bio from './Bio';

const SingleProfile = props => {
	useEffect(() => {
		props.getSingleProfile(props.match.params.slug);

		return () => props.clearSingleProfile();
	}, []);

	let nameString = [];

	if (props.profile) {
		const {
			current: {
				details: { name },
			},
		} = props.profile;

		const nameArray = name.split(' ');

		if (nameArray.length < 2) {
			nameString.push(<h1>{nameArray}</h1>);
		} else {
			const stringArray = nameArray.map((word, index) => {
				if (index === 0) {
					return (
						<h1 key={index} className='smaller'>
							{word}
						</h1>
					);
				}

				return word;
			});

			const firstElement = stringArray.shift();
			const secondElement = <h1>{stringArray.join(' ')}</h1>;
			nameString.push(firstElement, secondElement);
		}
	}

	const prevHandler = () => {
		if (!props.profile.previous) {
			return;
		}

		return props.history.push(`/our-team/${props.profile.previous}`);
	};

	const nextHandler = () => {
		if (!props.profile.next) {
			return;
		}

		return props.history.push(`/our-team/${props.profile.next}`);
	};

	if(props.profile == null && props.profile !== undefined) {
		return <NotFound />;
	}

	const profile = props.profile && (
		<React.Fragment>
			<div className='single-profile__left'>
				<img
					src={
						props.profile.current &&
						props.profile.current.details &&
						props.profile.current.details.mainImage &&
						props.profile.current.details.mainImage.url
					}
					alt={
						props.profile.current &&
						props.profile.current.details &&
						props.profile.current.details.mainImageAlt
					}
					title={
						props.profile.current &&
						props.profile.current.details &&
						props.profile.current.details.mainImageTitle
					}
				/>
			</div>
			<div className='single-profile__right'>
				<div className='single-profile__right--buttons'>
					<button onClick={prevHandler} className={`${!props.profile.previous ? 'disabled' : ''}`}>
						Previous Member
					</button>
					<button onClick={nextHandler} className={`${!props.profile.next ? 'disabled' : ''}`}>
						Next Member
					</button>
				</div>
				<div className='single-profile__right--bio'>
					{nameString}
					<h3>{props.profile.current.details.title}</h3>
					<ReactMarkdown source={props.profile.current.details.shortBio} linkTarget='_blank' />
				</div>
				<div className='single-profile__right--stats'>
					<div className='single-profile__right--stats_section'>
						<div>
							<p>Joined The United Stand</p>
							<span>{props.profile.current.details.dateJoined}</span>
						</div>
						<div>
							<p>Favourite United Moment</p>
							<span>{props.profile.current.details.favouriteUnitedMoment}</span>
						</div>
					</div>
					<div className='single-profile__right--stats_section'>
						<div>
							<p>Favourite United Player (All-Time)</p>
							<span>{props.profile.current.details.favPlayerAllTime}</span>
						</div>
						<div>
							<p>Favourite United Player (Current)</p>
							<span>{props.profile.current.details.favPlayerCurrent}</span>
						</div>
					</div>
				</div>
				<div className='single-profile__right--social'>
					{props.profile.current.details.twitter && (
						<a href={props.profile.current.details.twitter} target='_blank' rel='noopener noreferrer'>
							<FontAwesomeIcon icon={['fab', 'twitter']} />
							<span>Twitter</span>
						</a>
					)}
					{props.profile.current.details.instagram && (
						<a href={props.profile.current.details.instagram} target='_blank' rel='noopener noreferrer'>
							<FontAwesomeIcon icon={['fab', 'instagram']} />
							<span>Instagram</span>
						</a>
					)}
					{props.profile.current.details.facebook && (
						<a href={props.profile.current.details.facebook} target='_blank' rel='noopener noreferrer'>
							<FontAwesomeIcon icon={['fab', 'facebook-square']} />
							<span>Facebook</span>
						</a>
					)}
				</div>
			</div>
		</React.Fragment>
	);

	return (
		<main>
			<TeamHeader />
			<div className='container'>
				<div className='single-profile'>{profile}</div>
			</div>
			<Bio profile={props.profile} />
			<div className='profile-bottom-bar'>
				<div className='profile-bottom-bar__image'>
					<div>
						<img src={Logo} alt='The United Stand Logo' />
					</div>
				</div>
			</div>
		</main>
	);
};

const mapStateToProps = state => {
	return {
		profile: state.contentful.profile,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getSingleProfile: slug => dispatch(getSingleProfile(slug)),
		clearSingleProfile: () => dispatch(clearSingleProfile()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleProfile);
