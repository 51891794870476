import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

// Images
import Logo from '../../../img/logo-large.png';

// Actions
import { getTeam } from '../../../actions/contentfulActions';

// Components
import TeamHeader from './TeamHeader';
import TeamGrid from './TeamGrid';
import WebAuthors from './WebAuthors';

const Team = props => {
	useEffect(() => {
		props.getTeam();
	}, []);

	return (
		<main>
			<TeamHeader />
			<div className='container'>
				<div className='team-intro'>
					<div className='team-intro__image'>
						<img src={Logo} alt='The United Stand Logo' />
					</div>
					<div className='team-intro__text'>
						<h1>About Us</h1>
						{props.team && (
							<ReactMarkdown
								source={props.team.aboutUs}
								linkTarget='_blank'
							/>
						)}
					</div>
				</div>
			</div>
			<TeamGrid team={props.team && props.team.ourTeam} />
			<WebAuthors team={props.team && props.team.webAuthors} />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		team: state.contentful.team,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTeam: () => dispatch(getTeam()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
