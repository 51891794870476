import * as actionTypes from './types';

export const setModal = () => dispatch => {
	dispatch({
		type: actionTypes.SET_MODAL,
	});
};

export const setAdBlockDetected = () => dispatch => {
	dispatch({
		type: actionTypes.SET_ADBLOCK_DETECTED,
	});
};
