import React, { useState, useEffect, useRef } from 'react';

// Images
import ArrowLeft from '../../../../img/arrow-left.png';
import ArrowRight from '../../../../img/arrow-right.png';

// Utils
import abbrNum from '../../../../utils/abbrNum';
import formatDate from '../../../../utils/formatDate';
import truncateText from '../../../../utils/truncateText';

// Components
import AudioControls from './AudioControls';

const PodcastSlider = props => {
	const progressWrapper = useRef(null);

	const [position, setPosition] = useState(0);

	const [maxWidth, setMaxWidth] = useState(0);

	const [sliderWidth, setSliderWidth] = useState(0);

	const [active, setActive] = useState({
		left: false,
		right: true,
	});

	const widthHandler = () => {
		const sliders = document.querySelectorAll(
			`.podcast-playlist-slider__single.index-${props.index}`
		);

		let widthLimit = 0;
		let width = 0;

		for (const slider of sliders) {
			widthLimit += slider.getBoundingClientRect().width + 20;
			width = slider.getBoundingClientRect().width;
		}

		setMaxWidth(widthLimit);
		setSliderWidth(width);
	};

	let resizeId;
	const resize = () => {
		clearTimeout(resizeId);
		resizeId = setTimeout(widthHandler, 500);
	};

	useEffect(() => {
		const sliders = document.querySelectorAll(
			`.podcast-playlist-slider__single.index-${props.index}`
		);

		let widthLimit = 0;
		let width = 0;

		for (const slider of sliders) {
			widthLimit += slider.getBoundingClientRect().width + 20;
			width = slider.getBoundingClientRect().width;
		}

		setMaxWidth(widthLimit);
		setSliderWidth(width);

		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, [widthHandler]);

	const clickHandler = layout => {
		if (layout === 'left') {
			if (position >= 0 || position + (sliderWidth + 20) >= 0) {
				setActive({ left: false, right: true });
				setPosition(0);
				return;
			}
			setActive({ left: true, right: true });
			setPosition(position + (sliderWidth + 20));
			return;
		}

		if (layout === 'right') {
			const container = document.querySelector(
				'.podcast-playlist-container .container'
			);
			const limit =
				maxWidth -
				Math.abs(position) -
				container.getBoundingClientRect().width;

			const sliderWrapper = document.querySelector(
				'.podcast-playlist-slider-wrapper'
			);

			if (window.innerWidth >= 768 && limit < 172) {
				setActive({ left: true, right: false });
				// setPosition(position - limit - 172);
				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					)
				);
				return;
			}

			if (
				window.innerWidth < 768 &&
				window.innerWidth >= 576 &&
				limit < 193
			) {
				setActive({ left: true, right: false });
				// setPosition(position - limit - 193);
				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					)
				);
				return;
			}

			if (window.innerWidth < 576 && limit < 300) {
				setActive({ left: true, right: false });
				// setPosition(position - limit - 300);
				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					)
				);
				// setPosition(position - limit + (window.innerWidth - 30));
				return;
			}

			setActive({ left: true, right: true });
			setPosition(position - (sliderWidth + 20));
		}
	};

	const getSliderRef = () => {
		return progressWrapper;
	};

	return (
		<div className={`podcast-playlist-container ${props.classes}`}>
			<div className='container'>
				<div className='podcast-playlist-wrapper'>
					<h3 className={props.index === 0 ? 'first-row' : ''}>
						{props.playlist.date}
					</h3>
					<div className='podcast-playlist-nav-wrapper'>
						<div className='podcast-playlist-slider-wrapper'>
							<div
								ref={progressWrapper}
								className='podcast-playlist-slider'
								style={{
									position: 'relative',
									left: `${position}px`,
									width: `${maxWidth}px`,
								}}
							>
								{props.playlist.podcasts.map(
									(podcast, index) => {
										return (
											<div
												key={index}
												className={`podcast-playlist-slider__single index-${props.index}`}
											>
												<div className='podcast-playlist-slider__single--image'>
													<img
														src={
															podcast.details
																.channel.urls
																.logo_image
																.original
														}
														alt='Podcast Thumbnail'
													/>
												</div>
												<div className='podcast-playlist-slider__single--details'>
													<p>
														{window.innerWidth < 576
															? truncateText(
																	podcast
																		.details
																		.title,
																	25
															  )
															: truncateText(
																	podcast
																		.details
																		.title,
																	35
															  )}
													</p>
													<div className='podcast-playlist-slider__single--details_stats'>
														<span>
															{formatDate(
																new Date(
																	podcast.details.uploaded_at
																)
															)}
														</span>
														<span>
															{abbrNum(
																podcast.details
																	.counts
																	.plays,
																1
															)}{' '}
															Listens
														</span>
													</div>
													<AudioControls
														getParentElement={
															getSliderRef
														}
														podcast={
															podcast.details
														}
													/>
												</div>
											</div>
										);
									}
								)}
							</div>
						</div>
						<div className='podcast-playlist-wrapper__navigation'>
							<div
								onClick={() => clickHandler('left')}
								className={`podcast-playlist-wrapper__navigation--left ${
									active.left ? 'active' : ''
								}`}
							>
								<img src={ArrowLeft} alt='Arrow Left' />
							</div>
							<div
								onClick={() => clickHandler('right')}
								className={`podcast-playlist-wrapper__navigation--right ${
									active.right ? 'active' : ''
								}`}
							>
								<img src={ArrowRight} alt='Arrow Right' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PodcastSlider;
