import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { Link as SmoothScroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

// Images
import Logo from '../../../img/logo.png';
import PlusLogo from '../../../img/logo-plus.png';
import LargeBlocked from '../../../img/large-blocked.png';
import UnitedStandPicks from '../../../img/united-stand-picks.png';

// Utils
import useWindowSize from '../../../utils/useWindowSize';
import useDetectAdBlock from '../../../utils/useDetectAdBlock';

// Components
import NavIcon from '../../base/Elements/NavIcon';

// Actions
import { setAdBlockDetected } from '../../../actions/generalActions';

const Header = props => {
	const adBlockDetected = useDetectAdBlock();

	useEffect(() => {
		if (adBlockDetected && props.location && props.location.pathname !== '/live-chat-messages') {
			props.setAdBlockDetected();
		}
	}, [adBlockDetected]);

	const getAnchorLink = (path, name) => {
		if (props.location.pathname === '/') {
			const url = path.split('#')[1];
			return (
				<SmoothScroll to={url} smooth={true} offset={-60} onClick={() => setActive(false)}>
					<span>{name}</span>
				</SmoothScroll>
			);
		} else {
			return (
				<HashLink to={path} onClick={() => setActive(false)}>
					<span>{name}</span>
				</HashLink>
			);
		}
	};

	const menuOptions = [
		{
			title: 'Written',
			gridMenu: true,
			children: [
				{
					title: 'Latest News',
					icon: 'written',
					link: '/articles/latest-news',
				},
				{
					title: 'Media Watch',
					icon: 'media',
					link: '/articles/latest-news#media-watch',
					noActiveClass: true,
					hashLink: true,
				},
				{
					title: 'Transfers',
					icon: 'transfers-diagonal',
					link: '/articles/transfer-news',
					classes: 'transfers',
				},
				{
					title: 'Matchdays',
					icon: 'match',
					link: '/articles/match-reviews',
				},
				{
					title: 'Fan Content',
					icon: 'fan',
					link: '/articles/fan-editorial',
				},
				{
					title: 'Quizzes',
					icon: 'quiz',
					link: '/articles/quizzes',
				},
			],
		},
		{
			title: 'Videos',
			gridMenu: true,
			children: [
				{
					title: 'Latest Videos',
					icon: 'videos',
					link: '/videos',
				},
				{
					title: 'Playlists',
					icon: 'playlists',
					link: '/videos/playlists',
				},
				{
					title: 'Transfers',
					icon: 'transfers-diagonal',
					link: '/videos/transfers',
					classes: 'transfers',
				},
				{
					title: 'Most Popular',
					icon: 'popular',
					link: '/videos/most-popular',
				},
				{
					title: 'All Videos',
					icon: 'all-videos',
					link: '/videos/all-videos',
				},
				{
					title: 'Timeline',
					icon: 'timeline',
					link: '/timeline',
				},
			],
		},
		{
			title: 'Podcasts',
			link: '/podcasts',
		},
		{
			title: 'More',
			tabletMenu: true,
			children: [
				{
					title: 'Timeline',
					link: '/timeline',
					icon: 'timeline',
				},
				{
					title: 'Social',
					link: '/social-wall',
					icon: 'social',
				},
			],
		},
		{
			title: 'Our Team',
			link: '/our-team',
			mobileOnly: true,
		},
		{
			title: 'Store',
			link: '/#store',
			mobileOnly: true,
		},
		{
			title: 'Legal Stuff',
			mobileOnly: true,
			children: [
				{
					title: 'Privacy Policy',
					link: '/privacy-policy',
				},
				{
					title: 'Cookie Policy',
					link: '/cookie-policy',
				},
				{
					title: 'Terms of Service',
					link: '/terms-of-service',
				},
			],
		},
	];

	const [clicked, setClicked] = useState(false);

	const clickHandler = index => {
		if (clicked === index) {
			setClicked(false);
		} else {
			setClicked(index);
		}
	};

	const [active, setActive] = useState(false);

	const menu = menuOptions.map((item, index) => {
		if (!item.mobileOnly) {
			return !item.children ? (
				<li onClick={() => clickHandler(index)} key={index} className={clicked === index ? 'active' : ''}>
					<Link to={item.link}>
						<span>{item.title}</span>
					</Link>
				</li>
			) : item.tabletMenu ? (
				<React.Fragment>
					{item.children.map((child, index) => (
						<li key={index} className='tablet-item' onClick={() => setClicked(false)}>
							<Link to={child.link}>
								<span>{child.title}</span>
							</Link>
						</li>
					))}
					<li onClick={() => clickHandler(index)} key={index} className={`tablet-parent ${clicked === index ? 'active' : ''}`}>
						<div>
							<span>{item.title}</span>
							<button onClick={() => setClicked(false)} className={`hamburger hamburger--spin ${clicked === index ? 'is-active' : ''}`} type='button'>
								<span className='hamburger-box'>
									<span className='hamburger-inner'></span>
								</span>
							</button>
						</div>
					</li>
				</React.Fragment>
			) : (
				<li onClick={() => clickHandler(index)} key={index} className={clicked === index ? 'active' : ''}>
					<div>
						<span>{item.title}</span>
						<FontAwesomeIcon icon='caret-down' />
					</div>
				</li>
			);
		}
	});

	const menuPanels = menuOptions.map((item, index) => {
		if (item.children && !item.mobileOnly) {
			return (
				<div className={`header__bottom--panel ${item.tabletMenu ? 'tablet' : ''} ${clicked === index ? 'active' : ''}`}>
					<div className='header__bottom--panel_categories'>
						{item.children.map((child, childIndex) => {
							if (child.noActiveClass) {
								if (child.hashLink) {
									return (
										<HashLink
											key={childIndex}
											to={child.link}
											className={`header__bottom--panel_categories-item ${child.classes ? child.classes : ''}`}
											onClick={() => setClicked(false)}
										>
											<NavIcon icon={child.icon} />
											<span>{child.title}</span>
										</HashLink>
									);
								} else {
									return (
										<Link
											key={childIndex}
											to={child.link}
											className={`header__bottom--panel_categories-item ${child.classes ? child.classes : ''}`}
											onClick={() => setClicked(false)}
										>
											<NavIcon icon={child.icon} />
											<span>{child.title}</span>
										</Link>
									);
								}
							} else {
								return (
									<NavLink
										key={childIndex}
										exact
										to={child.link}
										className={`header__bottom--panel_categories-item ${child.classes ? child.classes : ''}`}
										onClick={() => setClicked(false)}
									>
										<NavIcon icon={child.icon} />
										<span>{child.title}</span>
									</NavLink>
								);
							}
						})}
					</div>
					<div onClick={() => setClicked(false)} className='header__bottom--panel_close'>
						<FontAwesomeIcon icon='times' />
					</div>
				</div>
			);
		}
	});

	const [dropdown, setDropdown] = useState(false);

	const dropdownHandler = index => {
		if (dropdown === index) {
			setDropdown(false);
		} else {
			setDropdown(index);
		}
	};

	const mobileMenu = menuOptions.map((item, topIndex) => {
		return (
			<React.Fragment>
				{item.children && !item.tabletMenu ? (
					<li key={topIndex}>
						<div className={dropdown === topIndex ? 'active-parent' : 'inactive-parent'}>
							<div onClick={() => dropdownHandler(topIndex)}>
								<span>{item.title}</span>
								<FontAwesomeIcon icon='caret-down' />
							</div>
						</div>
						<div className={`dropdown ${item.gridMenu ? 'grid-menu' : ''} ${dropdown === topIndex ? 'active' : ''}`}>
							<ul>
								{item.children.map((child, index) => (
									<li key={index}>
										<NavLink exact to={`${child.link}`} className={child.classes ? child.classes : ''} onClick={() => setActive(false)}>
											{item.gridMenu && <NavIcon icon={child.icon} />}
											<span>{child.title}</span>
										</NavLink>
									</li>
								))}
							</ul>
						</div>
					</li>
				) : item.tabletMenu ? (
					<React.Fragment>
						{item.children.map((child, index) => (
							<li key={index}>
								<div className='inactive-parent'>
									<NavLink to={child.link} onClick={() => setActive(false)}>
										<span>{child.title}</span>
									</NavLink>
								</div>
							</li>
						))}
					</React.Fragment>
				) : (
					<li key={topIndex}>
						<div className='inactive-parent'>
							{item.link.includes('#') ? (
								getAnchorLink(item.link, item.title)
							) : (
								<NavLink to={item.link} onClick={() => setActive(false)}>
									<span>{item.title}</span>
								</NavLink>
							)}
						</div>
					</li>
				)}
			</React.Fragment>
		);
	});

	const [search, setSearch] = useState('');

	const inputHandler = e => {
		setSearch(e.target.value);
	};

	const keySubmitHandler = e => {
		if (e.keyCode === 13 && search) {
			props.history.push(`/search-results/${search}`);
			setClicked(false);
			setDropdown(false);
			setActive(false);
		}
	};

	const clickSearchHandler = () => {
		if (!search) {
			return;
		}
		setClicked(false);
		setDropdown(false);
		setActive(false);
		props.history.push(`/search-results/${search}`);
	};

	const stickyNavOptions = [
		{
			title: 'Written',
			link: '/articles',
			icon: 'written',
		},
		{
			title: 'Videos',
			link: '/videos',
			icon: 'videos',
		},
		{
			title: 'Podcasts',
			link: '/podcasts',
			icon: 'podcasts',
		},
		{
			title: 'Timeline',
			link: '/timeline',
			icon: 'timeline',
		},
		{
			title: 'Social',
			link: '/social-wall',
			icon: 'social',
		},
	];

	const [scrolled, setScrolled] = useState(false);

	useScrollPosition(
		({ prevPos, currPos }) => {
			if (currPos.y <= 0) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}

			if (currPos.y > prevPos.y) {
				setScrolled(false);
			}
		},
		null,
		null,
		false,
		400
	);

	const [closeAd, setCloseAd] = useState(false);

	const [width] = useWindowSize();

	const [renderSponsor, setRenderSponsor] = useState(false);

	switch (true) {
		case width >= 992 && !renderSponsor:
			setRenderSponsor(true);
			break;
		case width < 992 && renderSponsor:
			setRenderSponsor(false);
			break;
	}

	useEffect(() => {
		if (renderSponsor && window.refreshBid) {
			setTimeout(() => {
				window.refreshBid(['snack_ldb']);
			}, 100);
		}
	}, [renderSponsor]);

	// const largeAd = useCallback(
	// 	node => {
	// 		if (node !== null && renderSponsor && window.refreshBid && window.killSlot) {
	// 			window.killSlot(['snack_ldb']);
	// 			window.refreshBid(['snack_ldb']);
	// 		}
	// 	},
	// 	[renderSponsor]
	// );

	useEffect(() => {
		if (renderSponsor && props.adBlockDetected) {
			const ad = document.querySelector('#snack_ldb');

			if (ad && ad.innerHTML.replace(/\s/g, '').length === 0) {
				const element = document.createElement('img');
				element.src = LargeBlocked;

				const container = document.createElement('div');
				container.classList.add('blocked-image');
				container.appendChild(element);

				ad.parentNode.appendChild(container);
				ad.parentNode.removeChild(ad);
			}
		}
		// if (renderSponsor) {
		// 	setTimeout(() => {
		// 		const ad = document.querySelector('#snack_ldb');

		// 		if (ad && ad.innerHTML.replace(/\s/g, '').length === 0) {
		// 			const element = document.createElement('img');
		// 			element.src = LargeBlocked;

		// 			const container = document.createElement('div');
		// 			container.classList.add('blocked-image');
		// 			container.appendChild(element);

		// 			ad.parentNode.appendChild(container);
		// 			ad.parentNode.removeChild(ad);
		// 		}
		// 	}, 6000);
		// }
	}, [props.adBlockDetected]);

	return (
		<React.Fragment>
			{renderSponsor && props.location.pathname !== '/live-chat-messages' && (
				<div className={`main-head-spons ${closeAd ? 'close' : ''}`}>
					<div className='main-head-spons__container'>
						<div id='snack_ldb'></div>
					</div>
					<div className='main-head-spons__close'>
						<span>Close Advertisement</span>
						<FontAwesomeIcon onClick={() => setCloseAd(true)} icon='times' />
					</div>
				</div>
			)}
			<div
				className={`header desktop ${
					props.location.pathname.includes('video') ||
					props.location.pathname.includes('podcasts') ||
					props.location.pathname.includes('social') ||
					props.location.pathname.includes('live-chat-messages')
						? 'dark'
						: ''
				}`}
			>
				<div className='header__top'>
					<div className='container'>
						<div className='header__top--wrapper'>
							<a href='https://www.youtube.com/channel/UCMmVPVb0BwSIOWVeDwlPocQ?sub_confirmation=1' target='_blank' rel='noopener noreferrer' className='cta'>
								Subscribe
							</a>
							<div className='header__top--wrapper_search'>
								<input
									type='text'
									placeholder='Search The United Stand...'
									name='search'
									value={search}
									onChange={e => inputHandler(e)}
									onKeyUp={e => keySubmitHandler(e)}
								/>
								<div onClick={clickSearchHandler} className='header__top--wrapper_search-glass'>
									<FontAwesomeIcon icon={['fas', 'search']} />
								</div>
							</div>
							<div className='header__top--wrapper_social'>
								<a href='https://www.facebook.com/UnitedStandMUFC/' target='_blank' rel='noopener noreferrer'>
									<FontAwesomeIcon icon={['fab', 'facebook-square']} />
								</a>
								<a href='https://www.instagram.com/theunitedstandtv/' target='_blank' rel='noopener noreferrer'>
									<FontAwesomeIcon icon={['fab', 'instagram']} />
								</a>
								<a href='https://twitter.com/UnitedStandMUFC' target='_blank' rel='noopener noreferrer'>
									<FontAwesomeIcon icon={['fab', 'twitter']} />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='header__bottom'>
					<div className='container'>
						<div className='header__bottom--wrapper'>
							<div className='nav'>
								<ul>{menu}</ul>
							</div>
							<div className='header__bottom--wrapper_logo'>
								<Link to='/' onClick={() => setClicked(false)}>
									<img src={Logo} alt='The United Stand Logo' />
								</Link>
							</div>
							<div className='header__bottom--wrapper_right'>
								<NavLink exact to='/our-team'>
									Our Team
								</NavLink>
								{props.location.pathname === '/' ? (
									<SmoothScroll to='store' smooth={true}>
										Store
									</SmoothScroll>
								) : (
									<HashLink to='/#store'>Store</HashLink>
								)}
								<a href='https://unitedstandpicks.com/' target='_blank' rel='noopener noreferrer'>
									<img src={UnitedStandPicks} alt='The United Stand Picks' />
								</a>
								{/* <NavLink exact to='/login'>
									<FontAwesomeIcon icon='user-circle' />
								</NavLink>
								<NavLink exact to='/account'>
									<img
										src={PlusLogo}
										alt='The United Stand Logo with a plus symbol on the top right'
									/>
								</NavLink> */}
							</div>
						</div>
						<div className='header__bottom--panel_wrapper'>{menuPanels}</div>
					</div>
				</div>
			</div>
			<div className='header mobile'>
				<div className='header-top'>
					<button onClick={() => setActive(!active)} className={`hamburger hamburger--spin ${active ? 'is-active' : ''}`} type='button'>
						<span className='hamburger-box'>
							<span className='hamburger-inner'></span>
						</span>
					</button>
					<Link to='/' onClick={() => setActive(false)}>
						<img src={Logo} alt='The United Stand Logo' />
					</Link>
					<div className='header-top__right'>
						<a href='https://unitedstandpicks.com/' target='_blank' rel='noopener noreferrer'>
							<img src={UnitedStandPicks} alt='The United Stand Picks' />
						</a>
						{/* <NavLink exact to='/login'>
							<FontAwesomeIcon icon='user-circle' />
						</NavLink>
						<NavLink exact to='/account'>
							<img src={PlusLogo} alt='The United Stand Logo with a plus symbol on the top right' />
						</NavLink> */}
					</div>
				</div>
				<div className={`menu-wrapper ${active ? 'active' : ''}`}>
					<div className='header-search'>
						<input
							type='text'
							placeholder='Search The United Stand...'
							name='search'
							value={search}
							onChange={e => inputHandler(e)}
							onKeyUp={e => keySubmitHandler(e)}
						/>
						<div onClick={clickSearchHandler} className='header-search__glass'>
							<FontAwesomeIcon icon={['fas', 'search']} />
						</div>
					</div>
					<nav className='header-bottom'>
						<ul>{mobileMenu}</ul>
					</nav>
				</div>
			</div>
			<div className={`sticky-nav ${scrolled ? 'scrolled' : ''}`}>
				{stickyNavOptions.map((item, index) => (
					<NavLink key={index} to={item.link} className='sticky-nav__item'>
						<NavIcon icon={item.icon} />
						<span>{item.title}</span>
					</NavLink>
				))}
			</div>
			<div className={`mobile-overlay ${active ? 'active' : ''}`}></div>
			{/* <CookiePopup /> */}
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		adBlockDetected: state.general.adBlockDetected,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setAdBlockDetected: () => dispatch(setAdBlockDetected()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
