import axios from 'axios';

import * as actionTypes from './types';

export const getFeaturedBanner = () => dispatch => {
	axios
		.get('/api/contentful/featured-banner')
		.then(response => {
			dispatch({
				type: actionTypes.GET_FEATURED_BANNER,
				payload: response.data,
			});
		})
		.catch(err => console.log(err.response));
};

export const getFeaturedItem = () => dispatch => {
	axios
		.get('/api/contentful/featured-item')
		.then(response => {
			dispatch({
				type: actionTypes.GET_FEATURED_ITEM,
				payload: response.data,
			});
		})
		.catch(err => console.log(err.response));
};

export const getLatestPosts = slug => dispatch => {
	return axios
		.get('/api/contentful/latest-posts', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_LATEST_POSTS,
				payload: response.data,
			});
		})
		.catch(err => {
			console.log(err.response);
			return err.response;
		});
};

export const getTopStory = slug => dispatch => {
	axios
		.get('/api/rankings/single-article', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_TOP_STORY,
				payload: response.data.article || response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getAuthors = () => dispatch => {
	axios
		.get('/api/contentful/authors')
		.then(response => {
			dispatch({
				type: actionTypes.GET_AUTHORS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err.response));
};

export const getSingleArticle = slug => dispatch => {
	dispatch({
		type: actionTypes.GET_SINGLE_ARTICLE,
		payload: undefined,
	});

	axios
		.get('/api/contentful/single-article', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_SINGLE_ARTICLE,
				payload: response.data.article || response.data,
			});
		})
		.catch(err => {
			console.log(err.response);
			
			dispatch({
				type: actionTypes.GET_SINGLE_ARTICLE,
				payload: null,
			});
		});
};

export const getNextArticle = date => async dispatch => {
	try {
		const response = await axios.get('/api/contentful/next-article', { params: { date } });

		dispatch({
			type: actionTypes.GET_NEXT_ARTICLE,
			payload: (response.data && response.data.article) || response.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const getTopLikedArticles = () => dispatch => {
	axios
		.get('/api/contentful/most-liked')
		.then(response => {
			dispatch({
				type: actionTypes.GET_TOP_LIKED_ARTICLES,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const likeArticle = slug => dispatch => {
	if (localStorage.likedContent) {
		const likedContent = JSON.parse(localStorage.getItem('likedContent'));

		if (!likedContent.includes(slug)) {
			likedContent.push(slug);
		}

		localStorage.setItem('likedContent', JSON.stringify(likedContent));
	} else {
		const likedContent = [];

		likedContent.push(slug);

		localStorage.setItem('likedContent', JSON.stringify(likedContent));
	}

	axios.get('/api/contentful/set-like', { params: { type: 'like', slug } }).catch(err => console.log(err));
};

export const unlikeArticle = slug => dispatch => {
	const likedContent = JSON.parse(localStorage.getItem('likedContent'));

	const removeIndex = likedContent.indexOf(slug);

	if (removeIndex !== -1) likedContent.splice(removeIndex, 1);

	localStorage.setItem('likedContent', JSON.stringify(likedContent));

	axios.get('/api/contentful/set-like', { params: { type: 'unlike', slug } }).catch(err => console.log(err));
};

export const incrementShareCount = slug => async dispatch => {
	try {
		await axios.post('/api/contentful/set-share', { slug });
	} catch (err) {
		console.log('Something went wrong registering your share');
		console.log(err);
	}
};

export const getAuthorPosts = slug => dispatch => {
	dispatch({
		type: actionTypes.GET_AUTHOR_POSTS,
		payload: undefined,
	});

	axios
		.get('/api/contentful/author-posts', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_AUTHOR_POSTS,
				payload: response.data,
			});
		})
		.catch(err => {
			console.log(err);

			dispatch({
				type: actionTypes.GET_AUTHOR_POSTS,
				payload: null,
			});
		});
};

export const getTeam = () => dispatch => {
	axios
		.get('/api/contentful/team')
		.then(response => {
			dispatch({
				type: actionTypes.GET_TEAM,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getSingleProfile = slug => dispatch => {
	dispatch({
		type: actionTypes.GET_SINGLE_PROFILE,
		payload: undefined,
	});

	axios
		.get('/api/contentful/profiles', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_SINGLE_PROFILE,
				payload: response.data,
			});
		})
		.catch(err => {
			console.log(err.response);

			dispatch({
				type: actionTypes.GET_SINGLE_PROFILE,
				payload: null,
			});
		});
};

export const clearSingleProfile = () => dispatch => {
	dispatch({
		type: actionTypes.CLEAR_SINGLE_PROFILE,
	});
};

export const searchArticles = query => dispatch => {
	return axios
		.get('/api/contentful/search', { params: { query } })
		.then(response => {
			dispatch({
				type: actionTypes.SEARCH_ARTICLES,
				payload: response.data,
			});

			return response.data;
		})
		.catch(err => console.log(err));
};

export const setFilter = string => dispatch => {
	dispatch({
		type: actionTypes.SET_FILTER,
		payload: string,
	});
};

export const getProducts = () => dispatch => {
	axios
		.get('/api/contentful/products')
		.then(response => {
			dispatch({
				type: actionTypes.GET_PRODUCTS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getTestimonials = () => dispatch => {
	axios
		.get('/api/contentful/testimonials')
		.then(response => {
			dispatch({
				type: actionTypes.GET_TESTIMONIALS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getAdverts = () => dispatch => {
	axios
		.get('/api/contentful/adverts')
		.then(response => {
			dispatch({
				type: actionTypes.GET_ADVERTS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getArticleAdverts = () => dispatch => {
	axios
		.get('/api/contentful/article-adverts')
		.then(response => {
			dispatch({
				type: actionTypes.GET_ARTICLE_ADVERTS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getMobileAd = () => dispatch => {
	axios
		.get('/api/contentful/mobile-ad')
		.then(response => {
			dispatch({
				type: actionTypes.GET_MOBILE_AD,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getPublishedArticleMonths = () => dispatch => {
	axios
		.get('/api/contentful/published-months')
		.then(response => {
			dispatch({
				type: actionTypes.GET_PUBLISHED_ARTICLE_MONTHS,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getMediaWatch = () => dispatch => {
	axios
		.get('/api/contentful/media-watch')
		.then(response => {
			dispatch({ type: actionTypes.GET_MEDIA_WATCH, payload: response.data });
		})
		.catch(err => console.log(err));
};

export const incrementClickCount = link => dispatch => {
	axios.post('/api/contentful/click-counter', { link }).catch(err => console.log(err));
};

export const clearSingleArticle = () => dispatch => {
	return dispatch({
		type: actionTypes.CLEAR_SINGLE_ARTICLE,
	});
};
