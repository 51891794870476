import * as actionTypes from '../actions/types';

const initialState = {
	modal: false,
	adBlockDetected: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_MODAL:
			return {
				...state,
				modal: !state.modal,
			};
		case actionTypes.SET_ADBLOCK_DETECTED:
			return {
				...state,
				adBlockDetected: true,
			};
		default:
			return state;
	}
};
