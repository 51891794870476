import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import slugify from 'slugify';

// Utils
import timeElapsed from '../../../utils/timeElapsed';
import truncateText from '../../../utils/truncateText';
import abbrNum from '../../../utils/abbrNum';

// Components
import SVGIcon from '../../base/Elements/SVGIcon';

const FeaturedArticle = props => {
	const file = props.article && props.article.file;
	const imageTitle = props.article && props.article.imageTitle;
	const imageAlt = props.article && props.article.imageAlt;
	const title = props.article && props.article.title;
	const authorName = props.article && props.article.authorName;
	const createdAt = props.article && props.article.createdAt;
	const body = props.article && props.article.body;
	const slug = props.article && props.article.slug;
	const likes = props.article && props.article.likes;
	const views = props.article && props.article.views;

	const publishedTime = createdAt ? timeElapsed(createdAt) : '';

	const quiz = props.latestQuizzes && props.latestQuizzes[0];

	const featured = props.quiz ? (
		<div className='container'>
			<div className='featured-article quiz'>
				<div className='featured-article__thumbnail'>
					<img
						src={
							quiz &&
							quiz.featuredImageSrc.replace(
								'https://cms.theunitedstand.com/wp-content/uploads/',
								'/api/media/wp-media/'
							)
						}
						alt={quiz && quiz.featuredImageAlt}
					/>
				</div>
				<div className='featured-article__content'>
					<div className='featured-article__content--header'>
						<p>Latest Quiz</p>
						<h1>{quiz && quiz.title}</h1>
					</div>
					<hr />
					<div className='featured-article__content--stats'>
						<span>The United Stand</span>
						<span>{quiz && timeElapsed(quiz.published)}</span>
					</div>
					<div
						className='featured-article__content--description'
						dangerouslySetInnerHTML={{ __html: quiz && quiz.subheading }}
					></div>
					<div className='featured-article__content--bottom'>
						<div className='featured-article__content--bottom_stats'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{quiz && quiz.likes ? abbrNum(quiz.likes, 1) : 0}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{quiz && quiz.views ? abbrNum(quiz.views, 1) : 0}</span>
							</div>
						</div>
						<Link to={`/articles/quizzes/${quiz && quiz.slug}`} className='cta cta-large'>
							Take Quiz
						</Link>
					</div>
				</div>
			</div>
		</div>
	) : props.article && props.article.players ? (
		<div className='container'>
			<div
				className={`featured-article ${
					props.article.scoreBlock && slugify(props.article.scoreBlock.category, { lower: true })
				}`}
			>
				<div className='featured-article__thumbnail'>
					<img
						src={
							props.article &&
							props.article.featuredImageSrc.replace(
								'https://cms.theunitedstand.com/wp-content/uploads/',
								'/api/media/wp-media/'
							)
						}
						alt={props.article && props.article.featuredImageAlt}
					/>
				</div>
				<div className='featured-article__content'>
					<div className='featured-article__content--header'>
						<p>Top Story</p>
						<h1>{title}</h1>
					</div>
					<hr />
					<div className='featured-article__content--stats'>
						<span>The United Stand</span>
						<span>{props.article && timeElapsed(props.article.published)}</span>
					</div>
					<div
						className='featured-article__content--description'
						dangerouslySetInnerHTML={{ __html: props.article && props.article.content }}
					></div>
					<div className='featured-article__content--bottom'>
						<div className='featured-article__content--bottom_stats'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{likes ? abbrNum(likes, 1) : 0}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{views ? abbrNum(views, 1) : 0}</span>
							</div>
						</div>
						<Link to={`/articles/match-ratings/${slug}`} className='cta cta-large'>
							Read Article
						</Link>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className='container'>
			<div className={`featured-article ${props.slug ? props.slug : ''}`}>
				<div className='featured-article__thumbnail'>
					<img
						src={
							file &&
							file.url &&
							file.url.replace(
								'https://cms.theunitedstand.com/wp-content/uploads/',
								'/api/media/wp-media/'
							)
						}
						title={imageTitle}
						alt={imageAlt}
					/>
				</div>
				<div className='featured-article__content'>
					<div className='featured-article__content--header'>
						<p>Top Story</p>
						<h1>{title}</h1>
					</div>
					<hr />
					<div className='featured-article__content--stats'>
						<span>{authorName}</span>
						<span>{publishedTime}</span>
					</div>
					<div className='featured-article__content--description'>
						<ReactMarkdown
							source={body && truncateText(body, 350)}
							disallowedTypes={['image']}
							linkTarget='_blank'
						/>
					</div>
					<div className='featured-article__content--bottom'>
						<div className='featured-article__content--bottom_stats'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{likes}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{views && abbrNum(views, 1)}</span>
							</div>
						</div>
						<Link to={`/articles/single/${slug}`} className='cta cta-large'>
							Read Article
						</Link>
					</div>
				</div>
			</div>
		</div>
	);

	return featured;
};

const mapStateToProps = state => {
	return {
		article: state.contentful.topStory,
		latestQuizzes: state.quiz.latestQuizzes,
	};
};

export default connect(mapStateToProps)(FeaturedArticle);
