import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { searchArticles } from '../../../actions/contentfulActions';

// Components
import SearchHeader from './SearchHeader';
import ArticleGrid from './ArticleGrid';
import Spinner from '../../base/Elements/Spinner';

const Search = props => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!props.match.params.query) {
			return props.history.push('/');
		}

		setLoading(true);
		awaitSearch(props.match.params.query);
	}, []);

	const awaitSearch = async query => {
		try {
			await props.searchArticles(query);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	return (
		<main>
			<SearchHeader />
			{loading ? (
				<div className='search-no-results-wrapper'>
					<div className='container'>
						<div className='no-results'>
							<Spinner />
						</div>
					</div>
				</div>
			) : props.search && props.search.length > 0 ? (
				<ArticleGrid articles={props.search} filter={props.searchFilter} />
			) : (
				<div className='search-no-results-wrapper'>
					<div className='container'>
						<div className='no-results'>
							<h2>
								Sorry, nothing could be found for{' '}
								{`'${props.match.params.query}'. Try searching for something else.`}
							</h2>
						</div>
					</div>
				</div>
			)}
			<div className='article-bottom-bar'></div>
		</main>
	);
};

const mapStateToProps = state => {
	return {
		search: state.contentful.search,
		searchFilter: state.contentful.searchFilter,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		searchArticles: query => dispatch(searchArticles(query)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
