import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import removeMd from 'remove-markdown';
import slugify from 'slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Utils
import truncateText from '../../../../utils/truncateText';
import timeElapsed from '../../../../utils/timeElapsed';
import abbrNum from '../../../../utils/abbrNum';
import SVGIcon from '../../../base/Elements/SVGIcon';

const NewsTimeline = props => {
	const [limit, setLimit] = useState(8);

	const gridItems =
		props.content &&
		props.content.map((item, index) => {
			if (index >= limit) {
				return;
			}
			if (item.type === 'article') {
				const {
					title,
					slug,
					imageTitle,
					imageAlt,
					file: { url: imageSrc },
					category,
					authorName,
					createdAt,
					body,
					likes,
					views,
				} = item;

				let extraClass;
				switch (true) {
					case category === 'Transfers':
						extraClass = 'transfers';
						break;
					default:
						extraClass = 'story';
				}

				let tagString;
				switch (true) {
					case category === 'Transfers':
						tagString = 'Transfer News';
						break;
					default:
						tagString = 'News Story';
				}

				let postClass = slugify(category, {
					lower: true,
				}).replace("'", '');

				// Breaking news time check
				const date = new Date();
				date.setHours(date.getHours() - 6);
				const pastDate = date.getTime();
				const postedDate = new Date(createdAt).getTime();

				if (postClass === 'breaking-news' && pastDate > postedDate) {
					postClass = 'uncategorised';
				}

				return (
					<div key={index} className='timeline-grid__item--wrapper'>
						<hr />
						<div
							className={`timeline-grid__item--wrapper-icon ${extraClass}`}
						>
							<div>
								<div></div>
							</div>
							<span>{tagString}</span>
						</div>
						<Link
							to={`/articles/single/${slug}`}
							key={index}
							className={`timeline-grid__item ${postClass}`}
						>
							<div className='timeline-grid__item--image'>
								<img
									src={imageSrc}
									alt={imageAlt}
									title={imageTitle}
								/>
								<div className='timeline-grid__item--image_banner'>
									<span>{category}</span>
								</div>
							</div>
							<div className='timeline-grid__item--details'>
								<h2>{truncateText(title, 35)}</h2>
								<hr />
								<div className='timeline-grid__item--details_stats'>
									<span>{authorName}</span>
									<span>{timeElapsed(createdAt)}</span>
								</div>
								<div className='timeline-grid__item--details_excerpt'>
									<p>{truncateText(removeMd(body), 125)}</p>
								</div>
							</div>
							<div className='timeline-grid__item--icons'>
								<div className='timeline-grid__item--icons_left'>
									<div>
										<FontAwesomeIcon
											icon={['far', 'heart']}
										/>
										<span>{likes}</span>
									</div>
									<div>
										<SVGIcon icon='eye-icon' />
										<span>{abbrNum(views, 1)}</span>
									</div>
								</div>
								<div className='timeline-grid__item--icons_right'>
									<SVGIcon icon='read-icon' />
								</div>
							</div>
						</Link>
					</div>
				);
			} else {
				const {
					videoId,
					thumbnails,
					title,
					publishedAt,
					views,
					description,
					pageViews,
				} = item;

				const image =
					thumbnails.maxres ||
					thumbnails.standard ||
					thumbnails.high ||
					thumbnails.medium ||
					thumbnails.default;

				return (
					<div key={index} className='timeline-grid__item--wrapper'>
						<hr />
						<div className='timeline-grid__item--wrapper-icon'>
							<div>
								<div></div>
							</div>
							<span>Video</span>
						</div>
						<Link
							to={`/videos/single/${videoId}`}
							key={index}
							className='timeline-grid__item video'
						>
							<div className='timeline-grid__item--image'>
								<img
									src={image.url}
									alt='YouTube video thumbnail'
									title='YouTube video thumbnail'
								/>
							</div>
							<div className='timeline-grid__item--details'>
								<h2>{truncateText(title, 35)}</h2>
								<hr />
								<div className='timeline-grid__item--details_stats'>
									<span>{timeElapsed(publishedAt)}</span>
									<span>{abbrNum(views, 0)} views</span>
								</div>
								<div className='timeline-grid__item--details_excerpt'>
									<p>
										{truncateText(
											removeMd(description),
											125
										)}
									</p>
								</div>
							</div>
							<div className='timeline-grid__item--icons'>
								<div className='timeline-grid__item--icons_left'>
									<div>
										<SVGIcon icon='eye-icon' />
										<span>
											{pageViews
												? abbrNum(pageViews, 1)
												: 0}
										</span>
									</div>
								</div>
								<div className='timeline-grid__item--icons_right'>
									<SVGIcon icon='play-button' />
								</div>
							</div>
						</Link>
					</div>
				);
			}
		});

	return (
		<div className='timeline-grid'>
			{gridItems}
			<button onClick={() => setLimit(limit + 8)} className='cta dark'>
				{props.content && props.content.length <= limit
					? "You've reached the end"
					: 'Older Posts'}
			</button>
		</div>
	);
};

export default NewsTimeline;
