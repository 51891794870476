import React from 'react';

const FormField = ({
	type,
	name,
	id,
	classes,
	placeholder,
	children,
	value,
	error,
	onChange,
	...props
}) => {
	const field =
		type === 'textarea' ? (
			<div className='form-group'>
				<textarea
					className={`${classes ? classes : ''} ${
						error ? 'error' : ''
					}`}
					name={name}
					id={id}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
				/>
				{error && <small className='error'>{error}</small>}
			</div>
		) : (
			<div className='form-group'>
				<input
					type={type}
					name={name}
					id={id}
					className={`${classes ? classes : ''} ${
						error ? 'error' : ''
					}`}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
				/>
				{error && <small className='error'>{error}</small>}
			</div>
		);

	return field;
};

export default FormField;
