import React, { useState, useEffect } from 'react';

// Images
import Arrow from '../../../img/arrow-light.png';

// Components
import FixtureItem from './FixtureItem';
import Spinner from '../../base/Elements/Spinner';

// Utils
import useWindowSize from '../../../utils/useWindowSize';

const FixtureSlider = props => {
	const [position, setPosition] = useState(0);

	const [maxWidth, setMaxWidth] = useState(0);

	const [sliderWidth, setSliderWidth] = useState(0);

	const [active, setActive] = useState({
		left: false,
		right: true,
	});

	const widthHandler = () => {
		const sliders = document.querySelectorAll(`.fixture-container`);

		let widthLimit = 0;
		let width = 0;

		for (const slider of sliders) {
			widthLimit += slider.getBoundingClientRect().width + 0;
			width = slider.getBoundingClientRect().width;
		}

		setMaxWidth(widthLimit);
		setSliderWidth(width);
	};

	let resizeId;
	const resize = () => {
		clearTimeout(resizeId);
		resizeId = setTimeout(widthHandler, 500);
	};

	useEffect(() => {
		const sliders = document.querySelectorAll(`.fixture-container`);

		let widthLimit = 0;
		let width = 0;

		for (const slider of sliders) {
			widthLimit += slider.getBoundingClientRect().width + 0;
			width = slider.getBoundingClientRect().width;
		}

		setMaxWidth(widthLimit);
		setSliderWidth(width);

		window.addEventListener('resize', resize);

		return () => window.removeEventListener('resize', resize);
	}, [widthHandler]);

	const clickHandler = layout => {
		if (layout === 'left') {
			if (position >= 0 || position + (sliderWidth + 0) >= 0) {
				setActive({ left: false, right: true });
				setPosition(0);
				return;
			}
			setActive({ left: true, right: true });
			setPosition(position + sliderWidth);
			return;
		}

		if (layout === 'right') {
			const container = document.querySelector(
				'.fixtures-container .container'
			);
			const limit =
				maxWidth -
				Math.abs(position) -
				container.getBoundingClientRect().width;

			const sliderWrapper = document.querySelector(
				'.fixtures-slider-wrapper'
			);

			if (window.innerWidth >= 768 && limit < 172) {
				setActive({ left: true, right: false });

				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					) - 20
				);
				return;
			}

			if (
				window.innerWidth < 768 &&
				window.innerWidth >= 576 &&
				limit < 193
			) {
				setActive({ left: true, right: false });

				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					) - 20
				);
				return;
			}

			if (window.innerWidth < 576 && limit < 300) {
				setActive({ left: true, right: false });

				setPosition(
					-Math.abs(
						maxWidth -
							sliderWrapper.getBoundingClientRect().width +
							32
					) + 20
				);

				return;
			}

			setActive({ left: true, right: true });
			setPosition(position - (sliderWidth + 0));
		}
	};

	const [width, height] = useWindowSize();

	// For setting the position of the fixture slider based on screen width
	useEffect(() => {
		const fixturesNodeList = document.querySelectorAll(
			'.fixture-container'
		);

		const fixtures = fixturesNodeList && Array.from(fixturesNodeList);

		if (fixtures) {
			const ftItems = fixtures.filter(
				element =>
					element.childNodes[0].childNodes[2].textContent.includes(
						'FT'
					) ||
					element.childNodes[0].childNodes[2].textContent.includes(
						'Match Postponed'
					)
			);

			if (width > 1800 && ftItems.length >= 7) {
				return setPosition(-(220 * Math.abs(ftItems.length - 7)));
			}
			if (width > 1440 && ftItems.length >= 5) {
				return setPosition(-(220 * Math.abs(ftItems.length - 5)));
			}
			if (width > 1200 && ftItems.length >= 4) {
				return setPosition(-(220 * Math.abs(ftItems.length - 4)));
			}
			if (width < 1200) {
				return setPosition(-(220 * Math.abs(ftItems.length - 1)));
			}
		}
	}, [props.fixtures, width]);

	const fixtures = props.fixtures ? (
		props.fixtures.map((item, index) => (
			<FixtureItem key={index} fixture={item} />
		))
	) : (
		<Spinner />
	);

	return (
		<div className={`fixtures-container ${props.classes}`}>
			<div className='container'>
				<div
					className={`fixtures-wrapper ${
						props.lessMargin ? 'less-margin' : ''
					}`}
				>
					<div className='fixtures-nav-wrapper'>
						<div className='fixtures-slider-wrapper'>
							<div
								className='fixtures-slider'
								style={{
									position: 'relative',
									left: `${position}px`,
									width: `${maxWidth}px`,
								}}
							>
								{fixtures}
							</div>
						</div>
						<div className='fixtures-wrapper__navigation'>
							<div
								onClick={() => clickHandler('left')}
								className={`fixtures-wrapper__navigation--left ${
									active.left ? 'active' : ''
								}`}
							>
								<img src={Arrow} alt='Arrow' />
							</div>
							<div
								onClick={() => clickHandler('right')}
								className={`fixtures-wrapper__navigation--right ${
									active.right ? 'active' : ''
								}`}
							>
								<img src={Arrow} alt='Arrow' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FixtureSlider;
