import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Images
import YouTube from '../../../img/youtube.png';
import Views from '../../../img/youtube-views.png';
import Twitter from '../../../img/twitter.svg';
import Instagram from '../../../img/instagram.svg';
import Facebook from '../../../img/facebook.svg';
import Likes from '../../../img/facebook-likes.png';

// Actions
import { getSocialStats } from '../../../actions/youtubeActions';

// Components
import Badge from './Badge';

const SocialBanner = props => {
	useEffect(() => {
		props.getSocialStats();
	}, []);

	const iconDetails = [
		{
			largeStat: (props.socialStats && props.socialStats.youtubeSubscribers) || 0,
			smallStat: (props.socialStats && props.socialStats.youtubeViews) || 0,
			smallIcon: Views,
			smallAlt: 'Play button inside an eye icon signifying YouTube views',
			bottomIcon: YouTube,
			bottomAlt: 'YouTube Icon',
		},
		{
			largeStat: (props.socialStats && props.socialStats.twitterFollowers) || 0,
			bottomIcon: Twitter,
			bottomAlt: 'Twitter Icon',
		},
		{
			largeStat: (props.socialStats && props.socialStats.instagramFollowers) || 0,
			bottomIcon: Instagram,
			bottomAlt: 'Instagram Icon',
		},
		{
			largeStat: 88259,
			smallStat: (props.socialStats && props.socialStats.facebookLikes) || 0,
			smallIcon: Likes,
			smallAlt: 'Thumbs up icon',
			bottomIcon: Facebook,
			bottomAlt: 'Facebook Icon',
		},
	];

	const icons = iconDetails.map((icon, index) => (
		<Badge
			key={index}
			largeStat={icon.largeStat}
			smallStat={icon.smallStat}
			smallIcon={icon.smallIcon}
			smallAlt={icon.smallAlt}
			bottomIcon={icon.bottomIcon}
			bottomAlt={icon.bottomAlt}
		/>
	));

	return (
		<section className='social-reach'>
			<div className='container'>
				<h1>Our Social Reach</h1>
				<p>
					The United Stand is the world’s biggest Independent Unofficial Manchester United Fan Channel.
					Created by real fans of Manchester United for the fans, our social reach boasts one of the largest
					followings in the world. Check out our social stats below and become a fellow United Stander today!
				</p>
				<div className='social-reach__icons'>{icons}</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => {
	return {
		socialStats: state.youtube.socialStats,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getSocialStats: () => dispatch(getSocialStats()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialBanner);
