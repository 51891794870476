import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import slugify from 'slugify';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Actions
import { getTopLikedArticles, getAdverts } from '../../../../actions/contentfulActions';
import { getOtherQuizzes } from '../../../../actions/quizActions';

// Utils
import timeElapsed from '../../../../utils/timeElapsed';
import truncateText from '../../../../utils/truncateText';
import useWindowSize from '../../../../utils/useWindowSize';

// Components
import SVGIcon from '../../../base/Elements/SVGIcon';

const RecommendedBar = props => {
	const responsive = {
		sm: {
			breakpoint: { max: 5000, min: 768 },
			partialVisibilityGutter: 20,
			items: 3,
		},
		xs: {
			breakpoint: { max: 768, min: 440 },
			partialVisibilityGutter: 20,
			items: 2,
		},
		nano: {
			breakpoint: { max: 440, min: 0 },
			partialVisibilityGutter: 120,
			items: 1,
		},
	};

	useEffect(() => {
		if (props.quiz) {
			props.getOtherQuizzes(props.slug);
		} else {
			props.getTopLikedArticles();
		}
		props.getAdverts();
	}, []);

	const [width] = useWindowSize();

	let limit = 50;

	switch (true) {
		case width < 576:
			limit = 55;
			break;
		case width < 1440:
			limit = 40;
			break;
		default:
			limit = 50;
	}

	const articles =
		!props.quiz && props.topLiked
			? props.topLiked.map((article, index) => {
					const {
						article: {
							title,
							slug,
							file: { url: image },
							imageAlt,
							imageTitle,
							createdAt: published,
							authorName,
							category,
						},
					} = article;

					let postClass = slugify(category, {
						lower: true,
					}).replace("'", '');

					// Breaking news time check
					const date = new Date();
					date.setHours(date.getHours() - 6);
					const pastDate = date.getTime();
					const postedDate = new Date(article.createdAt).getTime();

					if (postClass === 'breaking-news' && pastDate > postedDate) {
						postClass = 'uncategorised';
					}

					return (
						<Link
							key={index}
							to={`/articles/single/${slug}`}
							className={`recommended__articles--list_card ${postClass}`}
						>
							<div className='recommended__articles--list_card-image'>
								<img
									src={image.replace(
										'https://cms.theunitedstand.com/wp-content/uploads/',
										'/api/media/wp-media/'
									)}
									alt={imageAlt}
									title={imageTitle}
								/>
							</div>
							<div className='recommended__articles--list_card-content'>
								<p>{truncateText(title, limit)}</p>
								<hr />
								<div className='recommended__articles--list_card-content__stats'>
									<span>{authorName}</span>
									<span>{timeElapsed(published)}</span>
								</div>
								<SVGIcon icon='read-icon' />
							</div>
						</Link>
					);
			  })
			: props.quiz &&
			  props.otherQuizzes &&
			  props.otherQuizzes.map((item, index) => (
					<Link
						key={index}
						to={`/articles/quizzes/${item.slug}`}
						className='recommended__articles--list_card quiz'
					>
						<div className='recommended__articles--list_card-image'>
							<img
								src={item.featuredImageSrc.replace(
									'https://cms.theunitedstand.com/wp-content/uploads/',
									'/api/media/wp-media/'
								)}
								alt={item.featuredImageAlt}
							/>
						</div>
						<div className='recommended__articles--list_card-content'>
							<p>{truncateText(item.title, 50)}</p>
							<hr />
							<div className='recommended__articles--list_card-content__stats'>
								<span>The United Stand</span>
								<span>{timeElapsed(item.published)}</span>
							</div>
							<SVGIcon icon='read-icon' />
						</div>
					</Link>
			  ));

	return (
		<div className={`recommended articles ${props.classes ? props.classes : ''}`}>
			<div>
				{/* {props.adverts && props.adverts.topImage && (
					<div className='recommended__offer'>
						<h2>Exclusive Offer</h2>
						<a
							href={props.adverts.topImageLink}
							target='_blank'
							rel='noopener noreferrer'
							className='recommended__offer--image'
						>
							<img
								src={props.adverts.topImage.fields.file.url}
								alt={props.adverts.topImage.fields.description}
								title={props.adverts.topImage.fields.title}
							/>
						</a>
					</div>
				)} */}
				<div id={props.id ? props.id : ''} className='recommended__articles'>
					{props.quiz && articles && articles.length > 0 ? (
						<h2>Other Quizzes</h2>
					) : (
						articles && articles.length > 0 && <h2>Top Stories</h2>
					)}
					<div className='recommended__articles--list hide-small'>{articles}</div>
					{props.carousel && (
						<div className='recommended__articles--carousel'>
							{articles && (
								<Carousel
									arrows={false}
									swipeable={true}
									draggable={true}
									showDots={true}
									responsive={responsive}
									ssr={true} // means to render carousel on server-side.
									infinite={true}
									autoPlaySpeed={1000}
									keyBoardControl={true}
									customTransition='all .5s'
									transitionDuration={500}
									containerClass='carousel-container'
									removeArrowOnDeviceType={['tablet', 'mobile']}
									dotListClass='custom-dot-list-style'
									itemClass='carousel-item-padding-40-px'
									partialVisible={true}
								>
									{articles}
								</Carousel>
							)}
						</div>
					)}
				</div>
				{/* {props.adverts && props.adverts.bottomImage && (
					<div className='recommended__ad'>
						<h2>Advertisement</h2>
						<a
							href={props.adverts.bottomImageLink}
							target='_blank'
							rel='noopener noreferrer'
							className='recommended__ad--image'
						>
							<img
								src={props.adverts.bottomImage.fields.file.url}
								alt={props.adverts.bottomImage.fields.description}
								title={props.adverts.bottomImage.fields.title}
							/>
						</a>
					</div>
				)} */}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		topLiked: state.contentful.topLiked,
		adverts: state.contentful.adverts,
		otherQuizzes: state.quiz.otherQuizzes,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getTopLikedArticles: () => dispatch(getTopLikedArticles()),
		getAdverts: () => dispatch(getAdverts()),
		getOtherQuizzes: slug => dispatch(getOtherQuizzes(slug)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecommendedBar));
