import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getFixtures } from '../../../actions/footballActions';

// Components
import FixtureSlider from './FixtureSlider';

const Fixtures = props => {
	useEffect(() => {
		props.getFixtures();
	}, []);

	return (
		<div className='fixtures-banner'>
			<div className='container'>
				<FixtureSlider fixtures={props.fixtures} />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		fixtures: state.football.fixtures,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getFixtures: () => dispatch(getFixtures()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Fixtures);
