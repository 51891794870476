import countdown from 'countdown';

const timeElapsed = dateTime => {
	const elapsed = countdown(new Date(dateTime));

	let string;

	switch (true) {
		case elapsed.years !== 0:
			string = `${elapsed.years} ${
				elapsed.years === 1 ? 'year' : 'years'
			} ago`;
			break;
		case elapsed.months !== 0:
			string = `${elapsed.months} ${
				elapsed.months === 1 ? 'month' : 'months'
			} ago`;
			break;
		case elapsed.days !== 0:
			string = `${elapsed.days} ${
				elapsed.days === 1 ? 'day' : 'days'
			} ago`;
			break;
		case elapsed.hours !== 0:
			string = `${elapsed.hours} ${
				elapsed.hours === 1 ? 'hour' : 'hours'
			} ago`;
			break;
		case elapsed.minutes !== 0:
			string = `${elapsed.minutes} ${
				elapsed.minutes === 1 ? 'minute' : 'minutes'
			} ago`;
			break;
		case elapsed.seconds !== 0:
			string = `${elapsed.seconds} ${
				elapsed.seconds === 1 ? 'second' : 'seconds'
			} ago`;
			break;
		default:
			string = '';
	}

	return string;
};

export default timeElapsed;
