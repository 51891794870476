import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import NotFound from '../../../pages/NotFound';

// Actions
import { getAuthorPosts } from '../../../../actions/contentfulActions';

// Components
import ArticleHeader from '../Single/ArticleHeader';
import ArticleGrid from './ArticleGrid';

const Author = props => {
	useEffect(() => {
		props.getAuthorPosts(props.match.params.slug);
	}, []);

	if(props.authorPosts == null && props.authorPosts !== undefined || (props.authorPosts && props.authorPosts.length == 0)) {
		return <NotFound />
	}

	return (
		<main>
			<Helmet>
				<title>Author | The United Stand</title>
			</Helmet>
			<ArticleHeader
				article={
					props.authorPosts &&
					props.authorPosts.length > 0 &&
					props.authorPosts[0].article
				}
			/>
			<ArticleGrid articles={props.authorPosts} />
			<div className='article-bottom-bar'></div>
		</main>
	);
};

const mapStateToProps = state => {
	return {
		authorPosts: state.contentful.authorPosts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAuthorPosts: slug => dispatch(getAuthorPosts(slug)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Author);
