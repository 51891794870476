import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLatestPodcasts } from '../../../../actions/podcastActions';

// Components
import PodcastHeader from '../Elements/PodcastHeader';
import LatestPodcast from '../Elements/LatestPodcast';
import PodcastGrid from '../Elements/PodcastGrid';
import ContentRec from '../../../base/Elements/ContentRec';

const AllPodcasts = props => {
	useEffect(() => {
		props.getLatestPodcasts();
	}, []);

	return (
		<main>
			<PodcastHeader />
			<LatestPodcast title='Latest Podcast' podcast={props.latest && props.latest[0].details} />
			<PodcastGrid title='Latest Podcasts' podcasts={props.latest} />
			<ContentRec classes='dark no-border' />
		</main>
	);
};

const mapStateToProps = state => {
	return {
		latest: state.podcast.latest,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestPodcasts: () => dispatch(getLatestPodcasts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPodcasts);
