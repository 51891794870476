const animateScroll = (element, duration) => {
	if (!element) {
		return;
	}
	let target = element.scrollHeight;
	target = Math.round(target);
	duration = Math.round(duration);
	if (duration < 0) {
		return false;
	}
	if (duration === 0) {
		element.scrollTop = target;
		return true;
	}

	const startTime = Date.now();
	const endTime = startTime + duration;

	const startTop = element.scrollTop;
	const distance = target - startTop;

	// based on http://en.wikipedia.org/wiki/Smoothstep
	const smooth_step = function (start, end, point) {
		if (point <= start) {
			return 0;
		}
		if (point >= end) {
			return 1;
		}
		const x = (point - start) / (end - start); // interpolation
		return x * x * (3 - 2 * x);
	};

	// This is to keep track of where the element's scrollTop is
	// supposed to be, based on what we're doing
	let previousStop = element.scrollTop;

	// This is like a think function from a game loop
	const scrollFrame = function () {
		if (element.scrollTop !== previousStop) {
			return false;
		}

		// set the scrollTop for this frame
		const now = Date.now();
		const point = smooth_step(startTime, endTime, now);
		const frameTop = Math.round(startTop + distance * point);
		element.scrollTop = frameTop;

		// check if we're done!
		if (now >= endTime) {
			return true;
		}

		// If we were supposed to scroll but didn't, then we
		// probably hit the limit, so consider it done; not
		// interrupted.
		if (element.scrollTop === previousStop && element.scrollTop !== frameTop) {
			return true;
		}
		previousStop = element.scrollTop;

		// schedule next frame for execution
		setTimeout(scrollFrame, 0);
	};
	// boostrap the animation process
	setTimeout(scrollFrame, 0);
};

export default animateScroll;
