import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

// Actions
import { getLatestUploads } from '../../../actions/youtubeActions';

// Components
import VideoHeader from './Elements/VideoHeader';
import FeaturedVideos from './Elements/FeaturedVideos';
import MustWatch from './Elements/MustWatch';
import PlayListDisplay from './PlayListDisplay';
import ContentRec from '../../base/Elements/ContentRec';

const Videos = props => {
	useEffect(() => {
		props.getLatestUploads();
	}, []);

	return (
		<main>
			<VideoHeader />
			<FeaturedVideos heading='Latest Videos' videos={props.latest && props.latest} />
			<MustWatch width={window.innerWidth} />
			<PlayListDisplay width={window.innerWidth} />
			<ContentRec classes='dark no-border' />
			{/* <div className='bottom-bar'></div> */}
		</main>
	);
};

const mapStateToProps = state => {
	return {
		latest: state.youtube.latest,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLatestUploads: () => dispatch(getLatestUploads()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
