import axios from 'axios';

import * as actionTypes from './types';

export const getFixtures = () => dispatch => {
	axios.get('/api/football/fixtures').then(response => {
		dispatch({
			type: actionTypes.GET_FIXTURES,
			payload: response.data,
		});
	});
};

export const getLeagueTable = () => async dispatch => {
	try {
		const response = await axios.get('/api/football/league-table');

		dispatch({
			type: actionTypes.GET_LEAGUE_TABLE,
			payload: response.data,
		});
	} catch (err) {
		console.log('Something went wrong trying to fetch the current league table :(');
		console.error(err);
	}
};
