import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Layout
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';

// Pages
import Home from './components/pages/Home/Home';
import Videos from './components/pages/Videos';
import Playlists from './components/pages/Videos/Playlists';
import Articles from './components/pages/Articles';
import Transfers from './components/pages/Videos/Transfers';
import MostPopular from './components/pages/Videos/Popular';
import AllVideos from './components/pages/Videos/All';
import SingleVideo from './components/pages/Videos/Single';
import SingleArticle from './components/pages/Articles/Single';
import Quizzes from './components/pages/Articles/Quizzes';
import SingleQuiz from './components/pages/Articles/Quizzes/Single';
import SingleMatchRating from './components/pages/Articles/MatchRatings/Single';
import Author from './components/pages/Articles/Author';
import Team from './components/pages/Team';
import SingleProfile from './components/pages/Team/Single';
import Podcasts from './components/pages/Podcasts';
import PopularPodcasts from './components/pages/Podcasts/Popular';
import AllPodcasts from './components/pages/Podcasts/All';
import Search from './components/pages/Search';
import Timeline from './components/pages/Timeline';
import Social from './components/pages/Social';
import Facebook from './components/pages/Social/Facebook';
import Instagram from './components/pages/Social/Instagram';
import Twitter from './components/pages/Social/Twitter';
import PrivacyPolicy from './components/pages/Legal/PrivacyPolicy';
// import TermsAndConditions from './components/pages/Legal/TermsAndConditions';
import CookiePolicy from './components/pages/Legal/CookiePolicy';
import TermsOfService from './components/pages/Legal/TermsOfService';
import Coming from './components/pages/Coming';
// import BrandGuidelines from './components/pages/Legal/BrandGuidelines';
import NotFound from './components/pages/NotFound';
import Ads from './components/pages/Ads';
import LiveChat from './components/pages/LiveChat';

// Elements
import MobileAd from './components/base/Elements/MobileAd';

// Article redirect
import ArticleRedirect from './components/util/ArticleRedirect';

// Utils
import ScrollToTop from './utils/ScrollToTop';

function App() {
	return (
		<div className='App'>
			<Header />
			<ScrollToTop>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/videos' component={Videos} />
					<Route exact path='/videos/playlists' component={Playlists} />
					<Route exact path='/videos/transfers' component={Transfers} />
					<Route exact path='/videos/most-popular' component={MostPopular} />
					<Route exact path='/videos/all-videos' component={AllVideos} />
					<Route exact path='/videos/single/:videoId' render={props => <SingleVideo key={props.match.params.videoId} {...props} />} />
					<Route exact path='/articles' component={ArticleRedirect} />
					<Route exact path='/articles/quizzes' component={Quizzes} />
					<Route exact path='/articles/:slug' render={props => <Articles key={props.match.params.slug} {...props} />} />
					<Route exact path='/articles/single/:slug' render={props => <SingleArticle key={props.match.params.slug} {...props} />} />
					<Route exact path='/articles/author/:slug' component={Author} />
					<Route exact path='/articles/quizzes/:slug' render={props => <SingleQuiz key={props.match.params.slug} {...props} />} />
					<Route exact path='/articles/match-ratings/:slug' render={props => <SingleMatchRating key={props.match.params.slug} {...props} />} />
					<Route exact path='/our-team' component={Team} />
					<Route exact path='/our-team/:slug' render={props => <SingleProfile key={props.match.params.slug} {...props} />} />
					<Route exact path='/podcasts' component={Podcasts} />
					<Route exact path='/podcasts/most-popular' component={PopularPodcasts} />
					<Route exact path='/podcasts/all-podcasts' component={AllPodcasts} />
					<Route exact path='/search-results/:query?' render={props => <Search key={props.match.params.query} {...props} />} />
					<Route exact path='/timeline' component={Timeline} />
					<Route exact path='/social-wall' component={Social} />
					<Route exact path='/social-wall/facebook' component={Facebook} />
					<Route exact path='/social-wall/instagram' component={Instagram} />
					<Route exact path='/social-wall/twitter' component={Twitter} />
					<Route exact path='/privacy-policy' component={PrivacyPolicy} />
					<Route exact path='/cookie-policy' component={CookiePolicy} />
					<Route exact path='/terms-of-service' component={TermsOfService} />
					{/* <Route
						exact
						path='/terms-and-conditions'
						component={TermsAndConditions}
					/> */}
					{/* <Route
						exact
						path='/brand-guidelines'
						component={BrandGuidelines}
					/> */}
					<Route exact path='/store' component={Coming} />
					<Route exact path='/login' component={Coming} />
					<Route exact path='/account' component={Coming} />
					<Route exact path='/join-tus' component={Coming} />
					<Route exact path='/man-utd' component={Coming} />
					<Route exact path='/events' component={Coming} />
					<Route exact path='/timeline/live-updates' component={Coming} />
					<Route exact path='/quizzes' component={Coming} />
					<Route exact path='/ads' component={Ads} />
					<Route exact path='/live-chat-messages' component={LiveChat} />
					<Route component={NotFound} />
				</Switch>
			</ScrollToTop>
			<ToastContainer />
			<MobileAd />
			<Footer />
		</div>
	);
}

export default App;
