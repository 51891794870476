import axios from 'axios';

import * as actionTypes from './types';

export const getSingleQuiz = slug => dispatch => {
	dispatch({
		type: actionTypes.GET_SINGLE_QUIZ,
		payload: undefined,
	});

	axios
		.get('/api/quiz/single', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_SINGLE_QUIZ,
				payload: response.data,
			});
		})
		.catch(err => {
			console.log(err);
			dispatch({
				type: actionTypes.GET_SINGLE_QUIZ,
				payload: null,
			});
		});
};

export const likeQuiz = slug => dispatch => {
	if (localStorage.likedContent) {
		const likedContent = JSON.parse(localStorage.getItem('likedContent'));

		if (!likedContent.includes(slug)) {
			likedContent.push(slug);
		}

		localStorage.setItem('likedContent', JSON.stringify(likedContent));
	} else {
		const likedContent = [];

		likedContent.push(slug);

		localStorage.setItem('likedContent', JSON.stringify(likedContent));
	}

	axios.get('/api/quiz/set-like', { params: { type: 'like', slug } }).catch(err => console.log(err));
};

export const unlikeQuiz = slug => dispatch => {
	const likedContent = JSON.parse(localStorage.getItem('likedContent'));

	const removeIndex = likedContent.indexOf(slug);

	if (removeIndex !== -1) likedContent.splice(removeIndex, 1);

	localStorage.setItem('likedContent', JSON.stringify(likedContent));

	axios.get('/api/quiz/set-like', { params: { type: 'unlike', slug } }).catch(err => console.log(err));
};

export const incrementShareCount = slug => async dispatch => {
	try {
		await axios.post('/api/quiz/set-share', { slug });
	} catch (err) {
		console.log('Something went wrong registering your share');
		console.log(err);
	}
};

export const getLatestQuizzes = () => dispatch => {
	axios
		.get('/api/quiz/all')
		.then(response => {
			dispatch({
				type: actionTypes.GET_LATEST_QUIZZES,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};

export const getOtherQuizzes = slug => dispatch => {
	axios
		.get('/api/quiz/other', { params: { slug } })
		.then(response => {
			dispatch({
				type: actionTypes.GET_OTHER_QUIZZES,
				payload: response.data,
			});
		})
		.catch(err => console.log(err));
};
