import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Images
// import ShoppingBag from '../../../img/shopping.png';

// Utils
import SVGIcon from '../../base/Elements/SVGIcon';

// Actions
import { getProducts } from '../../../actions/contentfulActions';

const Products = props => {
	useEffect(() => {
		props.getProducts();
	}, []);

	const responsive = {
		extraLargeDesktop: {
			breakpoint: { min: 3000 },
			items: 6,
		},
		largeDesktop: {
			breakpoint: { max: 3000, min: 1800 },
			items: 6,
		},
		desktop: {
			breakpoint: { max: 1800, min: 1200 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 1200, min: 768 },
			items: 3,
		},
		mobile: {
			breakpoint: { max: 768, min: 0 },
			items: 2,
		},
	};

	const products =
		props.products &&
		props.products.map((product, index) => {
			const {
				name,
				image: {
					imageTitle,
					imageAlt,
					file: { url: imageSrc },
				},
				link,
				price,
			} = product;

			return (
				<a
					key={index}
					href={link}
					target='_blank'
					rel='noopener noreferrer'
					className='product-item'
				>
					<div className='product-item__image'>
						<img src={imageSrc} alt={imageAlt} title={imageTitle} />
					</div>
					<p>{name}</p>
					<hr />
					<div className='product-item__bottom'>
						<p>{`£${price}`}</p>
						<SVGIcon icon='shopping-icon' />
						{/* <img
							src={ShoppingBag}
							alt='Red circle with white shopping bag icon'
						/> */}
					</div>
				</a>
			);
		});

	return (
		<div className='product-container'>
			{products && (
				<Carousel
					arrows={false}
					swipeable={true}
					draggable={true}
					showDots={true}
					responsive={responsive}
					ssr={true} // means to render carousel on server-side.
					infinite={true}
					autoPlaySpeed={1000}
					keyBoardControl={true}
					customTransition='all .5s'
					transitionDuration={500}
					containerClass='carousel-container'
					removeArrowOnDeviceType={['tablet', 'mobile']}
					dotListClass='custom-dot-list-style'
					itemClass='carousel-item-padding-40-px'
				>
					{products}
				</Carousel>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		products: state.contentful.products,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getProducts: () => dispatch(getProducts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
