import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faFacebookSquare,
	faInstagram,
	faTwitter,
	faLinkedin,
	faWhatsappSquare,
	faRedditSquare,
} from '@fortawesome/free-brands-svg-icons';
import {
	faCaretDown,
	faUserCircle,
	faTimes,
	faShareAlt,
	faHeart as altHeart,
	faEnvelopeSquare,
	faPlay,
	faSearch,
	faRetweet,
	faShare,
	faThumbsUp,
	faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';

// Import styles
import './sass/main.scss';

// Import the provider and store
import { Provider } from 'react-redux';
import store from './store';

library.add(
	faFacebookSquare,
	faInstagram,
	faTwitter,
	faCaretDown,
	faUserCircle,
	faTimes,
	faShareAlt,
	faHeart,
	altHeart,
	faLinkedin,
	faWhatsappSquare,
	faRedditSquare,
	faEnvelopeSquare,
	faPlay,
	faSearch,
	faRetweet,
	faShare,
	faThumbsUp,
	faStar,
	faAngleRight
);

const app = (
	<BrowserRouter>
		<Provider store={store}>
			<CookiesProvider>
				<App />
			</CookiesProvider>
		</Provider>
	</BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
