import React from 'react';
import { NavLink } from 'react-router-dom';

// Images
import Logo from '../../../img/logo-large.png';

// Components
import LegalHeader from './LegalHeader';

const TermsOfService = () => {
	return (
		<main>
			<LegalHeader />
			<div className='legal-background'>
				<div className='container'>
					<div className='legal-grid'>
						<div className='legal-grid__left'>
							<div>
								<h2>Links</h2>
								<div className='legal-grid__left--links'>
									<ul>
										<li>
											<NavLink to='/privacy-policy'>Privacy Policy</NavLink>
										</li>
										<li>
											<NavLink to='/cookie-policy'>Cookie Policy</NavLink>
										</li>
										<li>
											<NavLink to='/terms-of-service'>Terms of Service</NavLink>
										</li>
										{/* <li>
											<NavLink to='/terms-and-conditions'>
												Terms & Conditions
											</NavLink>
										</li> */}
										{/* <li>
											<NavLink to='/gdpr-policy'>
												GDPR Policy
											</NavLink>
										</li>
										<li>
											<NavLink to='/brand-guidelines'>
												Brand Guidelines
											</NavLink>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className='legal-grid__middle'>
							<h1>Terms of Service</h1>
							<p>
								<strong>
									By using this website, users are agreeing to be bound by the YouTube Terms of
									Service.
								</strong>
							</p>
							<ul>
								<li>
									More information here:{' '}
									<a href='https://www.youtube.com/t/terms' target='_blank' rel='noopener noreferrer'>
										https://www.youtube.com/t/terms
									</a>
								</li>
							</ul>
						</div>
						<div className='legal-grid__right'>
							<img src={Logo} alt='The United Stand Logo' />
						</div>
					</div>
				</div>
			</div>
			<div className='article-bottom-bar'></div>
		</main>
	);
};

export default TermsOfService;
