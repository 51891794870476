import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Images
import Quote from '../../../../img/quote.png';

// Actions
import { getAuthors } from '../../../../actions/contentfulActions';

const FeaturedAuthors = props => {
	useEffect(() => {
		props.getAuthors();
	}, []);

	const authorCards =
		props.authors &&
		props.authors.map((author, index) => (
			<div key={index} className='featured-authors__container--card'>
				<div className='featured-authors__container--card_image'>
					<img
						src={author.smallImage.url}
						alt={author.smallImageAlt}
						title={author.smallImageTitle}
					/>
				</div>
				<h3>{author.name}</h3>
				<hr />
				<img src={Quote} alt='Open quotation mark' />
				<p>{author.quote}</p>
				<Link to={`/articles/author/${author.slug}`}>
					See All Posts
				</Link>
			</div>
		));

	return (
		<div className='container'>
			<div
				className={`featured-authors ${
					props.classes ? props.classes : ''
				}`}
			>
				<h1>Featured Authors</h1>
				<div className='featured-authors__container'>{authorCards}</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		authors: state.contentful.authors,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getAuthors: () => dispatch(getAuthors()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedAuthors);
