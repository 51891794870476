import axios from 'axios';

import * as actionTypes from './types';

export const getSingleMatchRating = slug => dispatch => {
 	dispatch({
		type: actionTypes.GET_SINGLE_MATCH_RATING,
		payload: undefined,
	});

	axios
		.get('/api/articles/single-match-rating', { params: { slug } })
		.then(response => {
			return dispatch({
				type: actionTypes.GET_SINGLE_MATCH_RATING,
				payload: response.data,
			});
		})
		.catch(err => {
			console.log(err);
			
			dispatch({
				type: actionTypes.GET_SINGLE_MATCH_RATING,
				payload: null,
			});
		});
};

export const likeMatchRating = slug => _ => {
	if (localStorage.likedContent) {
		const likedContent = JSON.parse(localStorage.getItem('likedContent'));

		if (!likedContent.includes(slug)) {
			likedContent.push(slug);
		}

		localStorage.setItem('likedContent', JSON.stringify(likedContent));
	} else {
		const likedContent = [];

		likedContent.push(slug);

		localStorage.setItem('likedContent', JSON.stringify(likedContent));
	}

	axios
		.get('/api/articles/single-match-rating/set-like', { params: { type: 'like', slug } })
		.catch(err => console.log(err));
};

export const unlikeMatchRating = slug => _ => {
	const likedContent = JSON.parse(localStorage.getItem('likedContent'));

	const removeIndex = likedContent.indexOf(slug);

	if (removeIndex !== -1) likedContent.splice(removeIndex, 1);

	localStorage.setItem('likedContent', JSON.stringify(likedContent));

	axios
		.get('/api/articles/single-match-rating/set-like', { params: { type: 'unlike', slug } })
		.catch(err => console.log(err));
};

export const incrementShareCount = slug => async dispatch => {
	try {
		await axios.post('/api/articles/match-ratings/set-share', { slug });
	} catch (err) {
		console.log('Something went wrong registering your share');
		console.log(err);
	}
};
