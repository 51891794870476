import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getPlaylists } from '../../../actions/youtubeActions';

// Components
import PlaylistSlider from './Playlists/PlaylistSlider';
import RecommendedBar from './RecommendedBar';

const PlayListDisplay = props => {
	useEffect(() => {
		props.getPlaylists();
	}, []);

	const playlists =
		props.playlists.content.length > 0 &&
		props.playlists.content.map((playlist, index) => {
			if (index > 3) return;

			return <PlaylistSlider playlist={playlist} key={index} index={index} lessMargin={true} />;
		});
	return (
		<div id='video-playlists' className='playlists'>
			<div className='container'>
				<div className='playlists-wrapper'>
					<div className='playlists-wrapper__playlists'>{playlists}</div>
					<RecommendedBar width={props.width} />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		playlists: state.youtube.playlists,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPlaylists: () => dispatch(getPlaylists()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayListDisplay);
