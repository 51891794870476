const scrollElementToTop = (element, duration) => {
	if (element.scrollTop === 0) return;

	const cosParameter = element.scrollTop / 2;
	let scrollCount = 0,
		oldTimestamp = null;

	function step(newTimestamp) {
		if (oldTimestamp !== null) {
			// if duration is 0 scrollCount will be Infinity
			scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
			if (scrollCount >= Math.PI) return (element.scrollTop = 0);
			element.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
		}
		oldTimestamp = newTimestamp;
		window.requestAnimationFrame(step);
	}
	window.requestAnimationFrame(step);
};

export default scrollElementToTop;
