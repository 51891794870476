import React from 'react';
import { NavLink } from 'react-router-dom';

// Images
import Logo from '../../../img/logo-large.png';

// Components
import LegalHeader from './LegalHeader';

const PrivacyPolicy = () => {
	return (
		<main>
			<LegalHeader />
			<div className='legal-background'>
				<div className='container'>
					<div className='legal-grid'>
						<div className='legal-grid__left'>
							<div>
								<h2>Links</h2>
								<div className='legal-grid__left--links'>
									<ul>
										<li>
											<NavLink to='/privacy-policy'>Privacy Policy</NavLink>
										</li>
										<li>
											<NavLink to='/cookie-policy'>Cookie Policy</NavLink>
										</li>
										<li>
											<NavLink to='/terms-of-service'>Terms of Service</NavLink>
										</li>
										{/* <li>
											<NavLink to='/terms-and-conditions'>
												Terms & Conditions
											</NavLink>
										</li> */}
										{/* <li>
											<NavLink to='/gdpr-policy'>
												GDPR Policy
											</NavLink>
										</li>
										<li>
											<NavLink to='/brand-guidelines'>
												Brand Guidelines
											</NavLink>
										</li> */}
									</ul>
								</div>
							</div>
						</div>
						<div className='legal-grid__middle'>
							<h1>Privacy Policy</h1>
							<p>
								<strong>What we need</strong>
							</p>
							<p>
								The United Stand Ltd will be what’s known as the ‘Controller’ of the personal data you
								provide to us. We only collect basic personal data about you which does not include any
								special types of information or location based information.
							</p>
							<p>
								<strong>This does however include:</strong>
							</p>
							<ul>
								<li>Name</li>
								<li>Email</li>
								<li>Phone</li>
								<li>Company name</li>
								<li>Website</li>
							</ul>
							<p>
								<strong>Why we need it</strong>
							</p>
							<p>
								We need to know your basic personal data in order to provide you with notice writing and
								analysis services in line with this overall contract. We will not collect any personal
								data from you we do not need in order to provide and oversee this service to you.
							</p>
							<p>
								The web forms will collect the information upon submission, we then use this information
								in order to contact yourself as per your request.
							</p>
							<p>
								<strong>What we do with it</strong>
							</p>
							<p>
								All the personal data we process is processed by our staff in the UK however for the
								purposes of IT hosting and maintenance this information is located on servers within the
								European Union. No 3rd parties have access to your personal data unless the law allows
								them to do so.
							</p>
							<p>
								We have a Data Protection regime in place to oversee the effective and secure processing
								of your personal data.
							</p>
							<p>
								<strong>Do you transfer data outside the European Economic Area (EEA)?</strong>
							</p>
							<p>We do not transfer any data outside of the EEA.</p>
							<p>
								<strong>How long we keep it</strong>
							</p>
							<p>
								We are required under UK tax law to keep your basic personal data (name, address,
								contact details) for a minimum of 2 years after which time it will be destroyed. Your
								information we use for marketing purposes will be kept with us until you notify us that
								you no longer wish to receive this information. Please contact via the details are the
								end of the policy if you wish to have you details destroyed.
							</p>
							<p>
								<strong>What are your rights?</strong>
							</p>
							<p>
								If at any point you believe the information we process on you is incorrect you can
								request to see this information and even have it corrected or deleted. If you wish to
								raise a complaint on how we have handled your personal data, you can contact The United
								Stand Ltd who will investigate the matter.
							</p>
							<p>
								If you are not satisfied with our response or believe we are processing your personal
								data not in accordance with the law you can complain to the Information Commissioner’s
								Office (ICO).
							</p>
							<p>
								<strong>How to get in touch with us?</strong>
							</p>
							<p>
								If you would like to contact our Data Controllers then please contact The United Stand
								Ltd using the details below.
							</p>
							<p>
								<strong>The United Stand Ltd</strong>
							</p>
							<ul>
								<li>
									<a href='mailto:soccerboxtv@gmail.com'>soccerboxtv@gmail.com</a>
								</li>
							</ul>
							<p>
								<strong>Google Privacy Policy</strong>
							</p>
							<ul>
								<li>
									<a
										href='https://www.google.com/policies/privacy'
										target='_blank'
										rel='noopener noreferrer'
									>
										https://www.google.com/policies/privacy
									</a>
								</li>
							</ul>
						</div>
						<div className='legal-grid__right'>
							<img src={Logo} alt='The United Stand Logo' />
						</div>
					</div>
				</div>
			</div>
			<div className='article-bottom-bar'></div>
		</main>
	);
};

export default PrivacyPolicy;
