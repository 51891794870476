import { combineReducers } from 'redux';

import youtubeReducer from './youtubeReducer';
import contentfulReducer from './contentfulReducer';
import rankingReducer from './rankingReducer';
import generalReducer from './generalReducer';
import podcastReducer from './podcastReducer';
import socialReducer from './socialReducer';
import footballReducer from './footballReducer';
import quizReducer from './quizReducer';
import articleReducer from './articleReducer';

export default combineReducers({
	youtube: youtubeReducer,
	contentful: contentfulReducer,
	ranking: rankingReducer,
	general: generalReducer,
	podcast: podcastReducer,
	social: socialReducer,
	football: footballReducer,
	quiz: quizReducer,
	articles: articleReducer,
});
