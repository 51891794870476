import React, { useState, useEffect } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

// Images
import Logo from '../../../../img/logo.png';
import RedCrumb from '../../../../img/red-breadcrumb.svg';
import GrayCrumb from '../../../../img/gray-breadcrumb.svg';

const SocialHeader = props => {
	const [width, setWidth] = useState(false);

	const [section, setSection] = useState(false);

	const locationArray = props.location.pathname.split('/');
	locationArray.shift();

	useEffect(() => {
		if (locationArray.length === 2) {
			const formattedString = locationArray[1]
				.split('-')
				.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
				.join(' ');

			setSection(formattedString);
		}
	}, []);

	useEffect(() => {
		const content = document.querySelectorAll(
			'.breadcrumb-dynamic-content > span'
		);

		const newWidth = [];

		if (content) {
			content.forEach((element, index) => {
				newWidth[index] = Math.ceil(
					element.getBoundingClientRect().width
				);
				setWidth(newWidth);
			});
		}
	}, [section]);

	return (
		<div className='social-wrapper'>
			<Helmet>
				<title>
					{`${section ? section : 'Social Wall'} | The United Stand`}
				</title>
			</Helmet>
			<div className='container'>
				<div className='social-top'>
					<div className='social-top__left'>
						<div className='social-top__left--breadcrumbs'>
							<Link
								to='/'
								className='social-top__left--breadcrumbs_icon'
								style={{ maxWidth: '95px' }}
							>
								<img
									src={RedCrumb}
									alt='Red Arrow pointing right'
								/>
								<div className='breadcrumb-content'>
									<img
										src={Logo}
										alt='The United Stand Logo'
										style={{ maxWidth: '15px' }}
									/>
									<span>Home</span>
								</div>
							</Link>
							{locationArray.map((item, index) => {
								const toLink =
									index === 0
										? `/${item}`
										: `/${
												locationArray[index - 1]
										  }/${item}`;

								const string = item
									.split('-')
									.map(
										word =>
											`${word
												.charAt(0)
												.toUpperCase()}${word.slice(1)}`
									)
									.join(' ');
								return (
									<Link
										to={toLink}
										className={`social-top__left--breadcrumbs_icon`}
										style={{ maxWidth: width[index] + 80 }}
										key={index}
									>
										<img
											src={GrayCrumb}
											alt='Gray arrow pointing right'
										/>
										<div className='breadcrumb-dynamic-content'>
											<span>{string}</span>
										</div>
									</Link>
								);
							})}
						</div>
						<div className='social-top__left--title'>
							<h1>Social Wall</h1>
							{section && <h1>{section}</h1>}
						</div>
					</div>
					<div className='social-top__right'>
						<NavLink to='/social-wall/twitter'>
							<FontAwesomeIcon icon={['fab', 'twitter']} />
							<span>Twitter</span>
						</NavLink>
						<NavLink to='/social-wall/instagram'>
							<FontAwesomeIcon icon={['fab', 'instagram']} />
							<span>Instagram</span>
						</NavLink>
						<NavLink to='/social-wall/facebook'>
							<FontAwesomeIcon
								icon={['fab', 'facebook-square']}
							/>
							<span>Facebook</span>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SocialHeader);
