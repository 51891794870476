import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Images
import Logo from '../../../img/logo-large.png';

// Components
import ComingHeader from './ComingHeader';

const Coming = props => {
	const clickHandler = () => {
		props.history.goBack();
	};

	return (
		<main>
			<Helmet>
				<title>Coming Soon | The United Stand</title>
			</Helmet>
			<ComingHeader />
			<div className='container'>
				<div className='coming-soon'>
					<div onClick={clickHandler} className='go-back'>
						<span>Go Back</span>
					</div>
					<div>
						<img src={Logo} alt='The United Stand Logo' />
						<h1>Feature Coming Soon</h1>
						<p>
							This feature hasn’t been completed yet as Drawty and
							his minions haven’t had time to finish it... but
							don’t worry, it will be available in spring 2020! In
							the meantime, why not check out all the latest
							content pages on the site to cure your boredom? Or
							why not check out The United Stand’s other social
							media channels?
						</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default withRouter(Coming);
