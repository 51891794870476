import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import VideoModal from './VideoModal';

// Actions
import { getFeaturedItem } from '../../../actions/contentfulActions';

// Utils
import timeElapsed from '../../../utils/timeElapsed';
import truncateText from '../../../utils/truncateText';
import abbrNum from '../../../utils/abbrNum';
import useWindowSize from '../../../utils/useWindowSize';
import SVGIcon from '../../base/Elements/SVGIcon';

const FeaturedItem = props => {
	useEffect(() => {
		props.getFeaturedItem();
	}, []);

	const [width, height] = useWindowSize();

	let limit = 350;

	if (width >= 1200 && width < 1440) {
		limit = 150;
	} else {
		limit = 320;
	}

	let featuredOutput;

	if (props.featuredItem && props.featuredItem.type === 'video') {
		const thumbnail =
			props.featuredItem &&
			(props.featuredItem.thumbnails.maxres ||
				props.featuredItem.thumbnails.standard ||
				props.featuredItem.thumbnails.high ||
				props.featuredItem.thumbnails.medium ||
				props.featuredItem.thumbnails.default);

		const title = props.featuredItem && props.featuredItem.title;
		const description =
			props.featuredItem && props.featuredItem.description;
		const publishedAt =
			props.featuredItem && props.featuredItem.publishedAt;
		const views = props.featuredItem && props.featuredItem.views;

		const publishedTime = publishedAt ? timeElapsed(publishedAt) : '';

		featuredOutput = (
			<div className='featured-video'>
				<div className='featured-video__thumbnail'>
					<img
						src={thumbnail && thumbnail.url}
						alt='Thumbnail for The United Stand YouTube video'
					/>
				</div>
				<div className='featured-video__content'>
					<div className='featured-video__content--header'>
						<p>Featured Video</p>
						<h1>
							<ReactMarkdown
								source={title && truncateText(title, 60)}
								linkTarget='_blank'
								disallowedTypes={['paragraph']}
								unwrapDisallowed
							/>
						</h1>
					</div>
					<hr />
					<div className='featured-video__content--stats'>
						<span>{publishedTime}</span>
						<span>{abbrNum(+views, 0)} views</span>
					</div>
					<div className='featured-video__content--description'>
						<ReactMarkdown
							source={
								description && truncateText(description, limit)
							}
							linkTarget='_blank'
						/>
					</div>
					<VideoModal
						id={props.featuredItem && props.featuredItem.videoId}
						buttonText='Watch Video'
						buttonClasses='cta cta-large'
					/>
				</div>
			</div>
		);
	}

	if (props.featuredItem && props.featuredItem.type === 'article') {
		const {
			file,
			imageTitle,
			imageAlt,
			title,
			createdAt,
			authorName,
			body,
			likes,
			views,
			slug,
		} = props.featuredItem;

		const publishedTime = createdAt ? timeElapsed(createdAt) : '';

		featuredOutput = (
			<div className='featured-article'>
				<div className='featured-article__thumbnail'>
					<img
						src={file && file.url}
						title={imageTitle}
						alt={imageAlt}
					/>
				</div>
				<div className='featured-article__content'>
					<div className='featured-article__content--header'>
						<p>Top Story</p>
						<h1>{title}</h1>
					</div>
					<hr />
					<div className='featured-article__content--stats'>
						<span>{authorName}</span>
						<span>{publishedTime}</span>
					</div>
					<div className='featured-article__content--description'>
						<ReactMarkdown
							source={body && truncateText(body, limit)}
							linkTarget='_blank'
						/>
					</div>
					<div className='featured-article__content--bottom'>
						<div className='featured-article__content--bottom_stats'>
							<div>
								<FontAwesomeIcon icon={['far', 'heart']} />
								<span>{likes}</span>
							</div>
							<div>
								<SVGIcon icon='eye-icon' />
								<span>{views && abbrNum(views, 1)}</span>
							</div>
						</div>
						<Link
							to={`/articles/single/${slug}`}
							className='cta cta-large'
						>
							Read Article
						</Link>
					</div>
				</div>
			</div>
		);
	}

	return <div className='container'>{featuredOutput}</div>;
};

const mapStateToProps = state => {
	return {
		featuredItem: state.contentful.featuredItem,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getFeaturedItem: () => dispatch(getFeaturedItem()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedItem);
