import React, { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function MatchRatingsComplete({ submitVote: providedSubmitVote, onChange }) {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');

        return token;
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const submitVote = async () => {
        const token = await handleReCaptchaVerify();
        providedSubmitVote(token);
    };

    return (
        <button onClick={submitVote} className="cta yellow cta-large">
            Submit your vote!
        </button>
    );
}

export default MatchRatingsComplete;
