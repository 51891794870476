import React from 'react';

// Images
import Logo from '../../../img/logo-shadow.png';
import NewComerTrophy from '../../../img/newcomer-trophy.png';
import SilverTrophy from '../../../img/silver-play-trophy.png';
import InfluencerTrophy from '../../../img/influencer-trophy.png';
import SocialTrophy from '../../../img/social-trophy.png';

const AwardsBanner = props => {
	const trophyDetails = [
		{
			image: NewComerTrophy,
			alt: 'Best Newcomer award for 2015',
			text: 'Best Newcomer 2015',
			subtext: 'The United Stand',
		},
		{
			image: SilverTrophy,
			alt: 'YouTube Silver Play Award for 2017',
			text: 'YouTube Silver Play 2017',
			subtext: 'The United Stand',
		},
		{
			image: InfluencerTrophy,
			alt: 'Best Influencer Award for 2019',
			text: 'Best Influencer 2019',
			subtext: 'Mark Goldbridge',
		},
		{
			image: SocialTrophy,
			alt: 'Best Social Account Award for 2019',
			text: 'Best Social Account 2019',
			subtext: '@GoldbridgeWorld',
			additionalText: '[Associated]',
		},
	];

	const trophies = trophyDetails.map((trophy, index) => (
		<div key={index} className='awards-banner__trophies--trophy'>
			<img src={trophy.image} alt={trophy.alt} />
			<div className='awards-banner__trophies--trophy_text'>
				<p>{trophy.text}</p>
				<span>{trophy.subtext}</span>
				{trophy.additionalText && (
					<React.Fragment>
						<br />
						<span>{trophy.additionalText}</span>
					</React.Fragment>
				)}
			</div>
		</div>
	));

	return (
		<section className='awards-banner'>
			<div className='awards-banner__logo'>
				<img src={Logo} alt='The United Stand Logo' />
			</div>
			<h1>Our Team Awards</h1>
			<h1>Our Awards</h1>
			<div className='awards-banner__trophies'>{trophies}</div>
		</section>
	);
};

export default AwardsBanner;
