import React, { useState } from 'react';

// Material UI
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// Components
import Spinner from '../../base/Elements/Spinner';

const VideoModal = props => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<button
				type='button'
				onClick={handleOpen}
				className={props.buttonClasses}
			>
				{props.buttonText}
			</button>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className='video-modal-container'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className='video-modal-container__wrapper'>
						<div className='video-modal-container__wrapper--content'>
							{props.id ? (
								<React.Fragment>
									<iframe
										title='video-modal'
										width='560'
										height='315'
										src={`https://www.youtube.com/embed/${props.id}`}
										frameBorder='0'
										allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
									></iframe>
								</React.Fragment>
							) : (
								<Spinner />
							)}
						</div>
						<div className='video-modal-container__wrapper--cta'>
							<button className='cta' onClick={handleClose}>
								Close
							</button>
						</div>
					</div>
				</Fade>
			</Modal>
		</React.Fragment>
	);
};

export default VideoModal;
